import {InfoCircleOutlined} from "@ant-design/icons";
import {Button, Form, Input, message, Space} from "antd";
import classNames from "classnames";
import {Formik} from "formik";
import React, {FC, memo} from "react";
import {GET_BOOKINS} from "../../containers/bookings/bookings.graphql";
import NotFound from "../../containers/common/NotFound";
import {
    GET_SETTLED_BOOKINGS,
    GET_UNSETTLED_BOOKINGS,
} from "../../containers/invoice/invoice.graphql";
import {
    EditBookingInput,
    GetBookingByIdQuery,
    GetBookinsDocument,
    useUpdateNoticeStaffMutation,
} from "../../generated/graphql";
import {createInputHelpers} from "../../helpers/createInputHelpers";
import {isDefined} from "../../helpers/typeScriptHelpers";
import i18n from "../../services/i18n";
import PanelCard from "../PanelCard/PanelCard";
import css from "./PanelCardNotes.less";

type PanelCardNotesProps = {
    booking: GetBookingByIdQuery["bookingById"];
    style?: React.CSSProperties;
};

const PanelCardNotes: FC<PanelCardNotesProps> = ({booking, style}) => {
    const [updateNoticeStaff, {loading}] = useUpdateNoticeStaffMutation();

    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        ...style,
    };

    if (booking === undefined) {
        return <NotFound />;
    }

    return (
        <Formik<EditBookingInput>
            initialValues={booking}
            // validationSchema={EditBookingInput}
            onSubmit={async (values, {setSubmitting}) => {
                try {
                    await updateNoticeStaff({
                        variables: {
                            id: booking.id,
                            noticeStaff: values.noticeStaff ?? "",
                        },
                        refetchQueries: [
                            {query: GetBookinsDocument},
                            "GetBookinsQuery",
                            {query: GET_BOOKINS},
                            "GetUnsettledBookings",
                            {query: GET_UNSETTLED_BOOKINGS},
                            "GetSettledBookings",
                            {query: GET_SETTLED_BOOKINGS},
                            // "GetBookingsByCourse",
                            // {query: GET_BOOKINGS_BY_COURSE},
                        ],
                        awaitRefetchQueries: true,
                    }).then((res) => {
                        message.success(
                            i18n.containers.invoice.InvoiceDetails.message.bookingUpdated(),
                        );
                    });
                } catch (error) {
                    message.error(
                        i18n.containers.invoice.InvoiceDetails.message.bookingUpdateError(),
                    );
                    console.error(error);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {(formikProps) => {
                const {values, submitForm} = formikProps;
                const {
                    help,
                    handleBlur,
                    handleTextInputChange,
                } = createInputHelpers<EditBookingInput>(formikProps);

                return (
                    <PanelCard
                        columns={2}
                        title={i18n.containers.invoice.InvoiceDetails.notes()}
                        gapAfter
                    >
                        <>
                            <div
                                className={classNames(
                                    css.withDivider,
                                    css.container,
                                )}
                                style={actualStyle}
                            >
                                <div className={css.label}>
                                    {"Notiz BucherIn"}
                                </div>
                                <div className={css.entry}>
                                    <Form.Item help={help("noticeBooker")}>
                                        <Input.TextArea
                                            className={css.textArea}
                                            rows={2}
                                            disabled
                                            value={
                                                isDefined(values.noticeBooker)
                                                    ? values.noticeBooker
                                                    : ""
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <Space className={css.hint}>
                                    <InfoCircleOutlined />
                                    {
                                        "Notizen des Buchers können nicht geändert werden."
                                    }
                                </Space>
                            </div>
                            <div
                                className={classNames(
                                    css.withDivider,
                                    css.container,
                                )}
                                style={actualStyle}
                            >
                                <div className={css.label}>
                                    {"Notiz MitarbeiterIn"}
                                </div>
                                <div className={css.entry}>
                                    <Form.Item help={help("noticeStaff")}>
                                        <Input.TextArea
                                            className={css.textArea}
                                            rows={2}
                                            value={
                                                isDefined(values.noticeStaff)
                                                    ? values.noticeStaff
                                                    : ""
                                            }
                                            onChange={handleTextInputChange(
                                                "noticeStaff",
                                            )}
                                            onBlur={handleBlur("noticeStaff")}
                                        />
                                    </Form.Item>
                                    <Button
                                        htmlType="submit"
                                        onClick={submitForm}
                                        loading={loading}
                                        type={
                                            booking === values
                                                ? "default"
                                                : "primary"
                                        }
                                        className={css.submitButton}
                                    >
                                        {i18n.containers.invoice.InvoiceDetails.saveNote()}
                                    </Button>
                                </div>
                            </div>
                        </>
                    </PanelCard>
                );
            }}
        </Formik>
    );
};

export default memo(PanelCardNotes);
