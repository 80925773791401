import {Table, TablePaginationConfig} from "antd";
import React, {FC, memo, useState} from "react";
import {FilterValue} from "antd/lib/table/interface";
import {
    ArrayDataOptions,
    GetExportedSepaXmlsQuery,
    useGetExportedSepaXmlsQuery,
} from "../../generated/graphql";
import {exportedSpaXmlColumns, TableType} from "./ExportedSepaXmlColumns";

const pageSize = 15;

type ExportedSepaXmlListProps = {};

const ExportedSepaXmlList: FC<ExportedSepaXmlListProps> = () => {
    const [options, setOptions] = useState<ArrayDataOptions>({
        filter: [],
        limit: pageSize,
        offset: 0,
    });

    const {data: exportedSepaXmlData} = useGetExportedSepaXmlsQuery({
        variables: {
            options,
        },
    });

    const exportedSepaXmls: GetExportedSepaXmlsQuery["exportedSepaXmls"] = exportedSepaXmlData?.exportedSepaXmls ?? {
        total: 0,
        existsMore: false,
        items: [],
    };

    const onChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
    ) => {
        const newOptions = {...options};

        newOptions.offset = ((pagination.current as number) - 1) * pageSize;
        newOptions.limit = pageSize;

        setOptions(newOptions);
    };

    return (
        <div style={{marginTop: 20}}>
            <h4>{"Exporthistorie"}</h4>
            <Table<TableType>
                size="small"
                columns={exportedSpaXmlColumns}
                dataSource={exportedSepaXmls.items}
                onChange={(pagination, filters) => {
                    onChange(pagination, filters);
                }}
                pagination={{
                    pageSize,
                    total: exportedSepaXmls.total,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} von ${total} Dateien`,
                    position: ["bottomRight", "topRight"],
                    size: "small",
                }}
            />
        </div>
    );
};

export default memo(ExportedSepaXmlList);
