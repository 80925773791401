import loadable from "react-loadable";
import LoadingContent, {
    LoadingLoggedIn,
} from "../views/LoadingContent/LoadingContent";

export const Attendees = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "attendees", webpackPrefetch: true */ "./customers/Customers"
        ),
    loading: LoadingLoggedIn,
});

export const Courses = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "courses", webpackPrefetch: true */ "./courses/Courses"
        ),
    loading: LoadingLoggedIn,
});

export const Locations = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "locations", webpackPrefetch: true */ "./locations/Locations"
        ),
    loading: LoadingLoggedIn,
});

export const Bookings = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "bookings", webpackPrefetch: true */ "./bookings/Bookings"
        ),
    loading: LoadingLoggedIn,
});

export const MyBookings = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "myBookings", webpackPrefetch: true */ "./myBookings/MyBookings"
        ),
    loading: LoadingLoggedIn,
});

export const Staff = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "staff", webpackPrefetch: true */ "./staff/Staff"
        ),
    loading: LoadingLoggedIn,
});

export const StaffLogin = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "staff", webpackPrefetch: true */ "./staffLogin/StaffLogin"
        ),
    loading: LoadingLoggedIn,
});

export const Settings = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "staff", webpackPrefetch: true */ "./settings/Settings"
        ),
    loading: LoadingLoggedIn,
});

export const Login = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "staff", webpackPrefetch: true */ "./login/Login"
        ),
    loading: LoadingContent,
});

export const Setup = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "setup", webpackPrefetch: true */ "./setup/Setup"
        ),
    loading: LoadingContent,
});

export const NotFound = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "not-found", webpackPrefetch: true */ "./common/NotFound"
        ),
    loading: LoadingContent,
});

export const TopLevelPage = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "top-level-page", webpackPrefetch: true */ "./topLevelPage/TopLevelPage"
        ),
    loading: LoadingContent,
});

export const TopLevelPageMobile = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "top-level-page", webpackPrefetch: true */ "./topLevelPageMobile/TopLevelPageMobile"
        ),
    loading: LoadingContent,
});

export const WelcomePage = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "top-level-page", webpackPrefetch: true */ "./welcomePage/WelcomePage"
        ),
    loading: LoadingContent,
});

export const Invoice = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "invoice", webpackPrefetch: true */ "./invoice/Invoice"
        ),
    loading: LoadingLoggedIn,
});

export const Templates = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "templates", webpackPrefetch: true */ "./templates/Templates"
        ),
    loading: LoadingLoggedIn,
});

export const Payments = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "payments", webpackPrefetch: true */ "./payments/Payments"
        ),
    loading: LoadingLoggedIn,
});

export const Notifications = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "payments", webpackPrefetch: true */ "./notifications/Notifications"
        ),
    loading: LoadingLoggedIn,
});

export const Account = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "payments", webpackPrefetch: true */ "./account/Account"
        ),
    loading: LoadingContent,
});

export const ChangeLog = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "payments", webpackPrefetch: true */ "./changelog/ChangeLog"
        ),
    loading: LoadingContent,
});

export const Badges = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "payments", webpackPrefetch: true */ "./badges/Badges"
        ),
    loading: LoadingContent,
});

export const PermissionDenied = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "payments", webpackPrefetch: true */ "./permissions/Permissions"
        ),
    loading: LoadingContent,
});

export const InstructorRouter = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "instructorRouter", webpackPrefetch: true */ "./instructorRouter/InstructorRouter"
        ),
    loading: LoadingContent,
});

export const LessonsToday = loadable({
    loader: async () =>
        import(
            /* webpackChunkName: "lessonsToday", webpackPrefetch: true */ "./lessonsToday/LessonsToday"
        ),
    loading: LoadingContent,
});
