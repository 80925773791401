// import {Form} from "@ant-design/compatible";
// import "@ant-design/compatible/assets/index.css";
import {Badge, Cascader, Form, Modal, Space, Tooltip} from "antd";
import {FormikProps} from "formik";
import moment, {Moment} from "moment";
import React, {FC, memo} from "react";
import {SelectValue} from "antd/lib/select";
import {blue, orange} from "@ant-design/colors";
import {
    createInputHelpers,
    filterInstructors,
} from "../../../helpers/createInputHelpers";
import {isDefined, ISODateString} from "../../../helpers/typeScriptHelpers";
import TableDatePicker from "../../../views/TableDatePicker/TableDatePicker";
import {
    setOnlyDate,
    prepareLocationsForCascader,
    setOnlyTime,
} from "../courseHelpers";
import {
    useGetLocationsSortedByCityQuery,
    CourseInstructorIdInput,
    useGetInstructorsQuery,
    GetHolidaysByCompanyAndDateRangeQuery,
} from "../../../generated/graphql";
import {getPopupContainer} from "../../../helpers/getPopupContainer";
import TableSelectInstructors from "../../../views/TableSelectInstructors/TableSelectInstructors";
import TableTimePicker from "../../../views/TableTimePicker/TableTimePicker";
import i18n from "../../../services/i18n";
import DeleteButton from "../../../views/DeleteButton/DeleteButton";
import {PossibleFormikPropsType} from "../CourseCreate/CourseCreateForm";

/*
 * EditableDate Component
 */

type EditableDateProps = {
    placeholder: string;
    date: ISODateString;
    index: number;
    formikProps: FormikProps<PossibleFormikPropsType>;
    testId: string;
    style?: React.CSSProperties;
};

const formItemStyle: React.CSSProperties = {
    margin: 0,
};

const RowIndexComponent: FC<{
    rowIndex: number;
    holiday?: GetHolidaysByCompanyAndDateRangeQuery["holidaysByCompanyAndDateRange"][number];
    style?: React.CSSProperties;
    className?: string;
}> = ({rowIndex, style, holiday, className}) => {
    const start = holiday && moment(holiday.start).format("DD.MM");
    const end = holiday && moment(holiday.end).format("DD.MM");
    const startEndText =
        holiday && start === end ? start : `(${start} - ${end})`;
    const isCustom = holiday?.stateCode === "CUSTOM";

    return (
        <span
            data-testid="CourseLessonTableIndex"
            style={{
                display: "inline-block",
                ...style,
                ...formItemStyle,
            }}
        >
            {holiday ? (
                <Tooltip
                    title={
                        <Space
                            direction="vertical"
                            size={3}
                            style={{textAlign: "center"}}
                        >
                            {holiday.name}
                            {startEndText}
                        </Space>
                    }
                    placement="right"
                >
                    <Badge
                        count={`${rowIndex + 1}`}
                        color={
                            isCustom
                                ? holiday.color ?? orange[3]
                                : holiday.holidayStateColor
                        }
                        className={className}
                    />
                </Tooltip>
            ) : (
                <>
                    {/* <>{`${rowIndex + 1}`}</> */}
                    <Badge
                        count={`${rowIndex + 1}`}
                        color={blue[3]}
                        className={className}
                    />
                </>
            )}
        </span>
    );
};

export const RowIndex = memo(RowIndexComponent);

const EditableDateComponent: FC<EditableDateProps> = ({
    placeholder,
    date,
    index,
    formikProps,
    testId,
    style,
    ...props
}) => {
    const {setFieldValue, values} = formikProps;

    const {nestedValidateStatus, nestedHelp} = createInputHelpers<
        PossibleFormikPropsType
    >(formikProps);

    const handleChange = (date: Moment | null, dateString: ISODateString) => {
        const startDateTime: ISODateString | undefined =
            values.lessons[index]?.startDateTime;
        const endDateTime: ISODateString | undefined =
            values.lessons[index]?.endDateTime;

        if (startDateTime === undefined) {
            throw new Error(
                `"StartDateTime" for lesson number ${index + 1} is not set.`,
            );
        }

        if (endDateTime === undefined) {
            throw new Error(
                `"StartDateTime" for lesson number ${index + 1} is not set.`,
            );
        }

        setFieldValue(
            `lessons[${index}].startDateTime`,
            date === null
                ? date
                : setOnlyDate(startDateTime, date.toISOString()),
        );
        setFieldValue(
            `lessons[${index}].endDateTime`,
            date === null ? date : setOnlyDate(endDateTime, date.toISOString()),
        );
    };

    return (
        <div data-testid={testId} style={{width: "7rem", ...style}} {...props}>
            <Form.Item
                validateStatus={nestedValidateStatus(
                    `lessons[${index}].startDateTime`,
                )}
                help={nestedHelp(`lessons[${index}].startDateTime`)}
                style={formItemStyle}
            >
                <TableDatePicker
                    placeholder={placeholder}
                    value={moment(date)}
                    onChange={handleChange}
                />
            </Form.Item>
        </div>
    );
};

export const EditableDate = memo(EditableDateComponent);

/*
 *  EditableStartDateTimeComponent
 */

type EditableStartDateTimeProps = {
    startTime: ISODateString;
    index: number;
    formikProps: FormikProps<PossibleFormikPropsType>;
    testId: string;
    style?: React.CSSProperties;
};

const EditableStartDateTimeComponent: FC<EditableStartDateTimeProps> = ({
    startTime,
    testId,
    index,
    formikProps,
    style,
    ...props
}) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const {setFieldValue, values} = formikProps;

    const {nestedValidateStatus, nestedHelp} = createInputHelpers<
        PossibleFormikPropsType
    >(formikProps);

    const handleChange = (value: Moment | null) => {
        if (value === null) {
            return;
        }
        const startDateTime: ISODateString | undefined =
            values.lessons[index]?.startDateTime;

        if (startDateTime === undefined) {
            throw new Error(
                `"StartDateTime" for lesson number ${index + 1} is not set.`,
            );
        }

        Modal.confirm({
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.confirmBatchEdit.modalTitle(),
            content: i18n.containers.courses.CourseCreate.courseStartDateTime.confirmBatchEdit.modalContent(),
            open: modalOpen,
            okText: i18n.containers.courses.CourseCreate.courseStartDateTime.confirmBatchEdit.modalOk(),
            cancelText: i18n.containers.courses.CourseCreate.courseStartDateTime.confirmBatchEdit.modalCancel(),
            icon: null,
            onOk() {
                values.lessons.forEach((lesson, i) => {
                    setFieldValue(
                        `lessons[${i}].startDateTime`,
                        setOnlyTime(lesson.startDateTime, value.toISOString()),
                    );
                });
                setModalOpen(false);
            },
            onCancel() {
                setFieldValue(
                    `lessons[${index}].startDateTime`,
                    setOnlyTime(startDateTime, value.toISOString()),
                );
                setModalOpen(false);
            },
        });
    };

    return (
        <div data-testid={testId} style={style} {...props}>
            <Form.Item
                validateStatus={nestedValidateStatus(
                    `lessons[${index}].startDateTime`,
                )}
                help={nestedHelp(`lessons[${index}].startDateTime`)}
                style={formItemStyle}
            >
                <TableTimePicker
                    defaultTime={startTime}
                    value={moment(startTime)}
                    onChange={(value) => {
                        handleChange(value);
                        setModalOpen(true);
                    }}
                />
            </Form.Item>
        </div>
    );
};

export const EditableStartDateTime = memo(EditableStartDateTimeComponent);

/*
 * EditableEndDateTIme Component
 */

type EditableEndDateTimeProps = {
    endTime: ISODateString;
    index: number;
    formikProps: FormikProps<PossibleFormikPropsType>;
    testId: string;
    style?: React.CSSProperties;
};

const EditableEndDateTimeComponent: FC<EditableEndDateTimeProps> = ({
    endTime,
    index,
    formikProps,
    testId,
    style,
    ...props
}) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const {setFieldValue, values} = formikProps;

    const {nestedValidateStatus, nestedHelp} = createInputHelpers<
        PossibleFormikPropsType
    >(formikProps);

    const handleChange = (value: Moment | null) => {
        if (value === null) {
            return;
        }
        const endDateTime: ISODateString | undefined =
            values.lessons[index]?.endDateTime;

        if (endDateTime === undefined) {
            throw new Error(
                `"EndDateTime" for lesson number ${index + 1} is not set.`,
            );
        }
        Modal.confirm({
            title: i18n.containers.courses.CourseCreate.courseEndDateTime.confirmBatchEdit.modalTitle(),
            content: i18n.containers.courses.CourseCreate.courseEndDateTime.confirmBatchEdit.modalContent(),
            open: modalOpen,
            okText: i18n.containers.courses.CourseCreate.courseEndDateTime.confirmBatchEdit.modalOk(),
            cancelText: i18n.containers.courses.CourseCreate.courseEndDateTime.confirmBatchEdit.modalCancel(),
            icon: null,
            onOk() {
                values.lessons.forEach((lesson, i) => {
                    setFieldValue(
                        `lessons[${i}].endDateTime`,
                        setOnlyTime(lesson.endDateTime, value.toISOString()),
                    );
                });
                setModalOpen(false);
            },
            onCancel() {
                setFieldValue(
                    `lessons[${index}].endDateTime`,
                    setOnlyTime(endDateTime, value.toISOString()),
                );
                setModalOpen(false);
            },
        });
    };

    const timeError = () => {
        if (formikProps.errors?.lessons?.[index]?.endDateTime) {
            return true;
        }

        return false;
    };

    return (
        <div data-testid={testId} style={style} {...props}>
            <Tooltip
                placement="right"
                title={
                    timeError()
                        ? i18n.containers.courses.CourseCreate.courseEndDateTime.endTimeBeforeStart()
                        : undefined
                }
            >
                <Form.Item
                    validateStatus={nestedValidateStatus(
                        `lessons[${index}].endDateTime`,
                    )}
                    help={nestedHelp(`lessons[${index}].endDateTime`)}
                    style={formItemStyle}
                >
                    <TableTimePicker
                        defaultTime={endTime}
                        value={moment(endTime)}
                        onChange={(value) => {
                            handleChange(value);
                            setModalOpen(true);
                        }}
                        timeError={() => timeError()}
                    />
                </Form.Item>
            </Tooltip>
        </div>
    );
};

export const EditableEndDateTime = memo(EditableEndDateTimeComponent);

/*
 * EditableLocation Component
 */

type EditableLocationType = {
    index: number;
    formikProps: FormikProps<PossibleFormikPropsType>;
    testId: string;
    style?: React.CSSProperties;
};

const EditableLocationComponent: FC<EditableLocationType> = ({
    index,
    testId,
    formikProps,
    style,
    ...props
}) => {
    const {setFieldValue, values} = formikProps;

    const {nestedValidateStatus, nestedHelp} = createInputHelpers<
        PossibleFormikPropsType
    >(formikProps);

    const {
        data: locationsSortedByCityResult,
    } = useGetLocationsSortedByCityQuery();
    const {locationId, venueId} = values.lessons[index];
    const locationsData =
        locationsSortedByCityResult?.locationsSortedByCity ?? [];

    const cityGroup = locationsData.find((locationData) =>
        locationData.locations.some(({id}) => id === locationId),
    );

    const location = cityGroup?.locations.find(({id}) => id === locationId);
    const venue = location?.venues.find(({id}) => id === venueId);

    const cascaderValue =
        cityGroup !== undefined && location !== undefined && venue !== undefined
            ? [cityGroup.city, location.id, venue.id]
            : undefined;

    return (
        <div data-testid={testId} style={style} {...props}>
            <Form.Item
                validateStatus={nestedValidateStatus([
                    `lessons[${index}].venueId`,
                ])}
                help={nestedHelp([`lessons[${index}].venueId`])}
                required
                style={formItemStyle}
            >
                <Cascader
                    getPopupContainer={getPopupContainer}
                    options={prepareLocationsForCascader(locationsData)}
                    value={cascaderValue}
                    onChange={([city, locationId, venueId]) => {
                        setFieldValue(
                            `lessons[${index}].locationId`,
                            locationId,
                        );
                        setFieldValue(`lessons[${index}].venueId`, venueId);
                    }}
                    size="small"
                    allowClear={false}
                    style={{marginLeft: -5}}
                />
            </Form.Item>
        </div>
    );
};

export const EditableLocation = memo(EditableLocationComponent);

/*
 * EditableLocation Component
 */

type EditableInstructorComponentProps = {
    selectedInstructors: Array<CourseInstructorIdInput>;
    maxTagCount: number;
    index: number;
    formikProps: FormikProps<PossibleFormikPropsType>;
    testId: string;
    style?: React.CSSProperties;
};

const EditableInstructorComponent: FC<EditableInstructorComponentProps> = ({
    selectedInstructors,
    maxTagCount,
    index,
    testId,
    formikProps,
    style,
    ...props
}) => {
    const {
        nestedValidateStatus,
        nestedHelp,
        handleNestedBlur,
    } = createInputHelpers<PossibleFormikPropsType>(formikProps);
    const {setFieldValue} = formikProps;
    const getValue = () => {
        if (isDefined(selectedInstructors)) {
            return selectedInstructors.map(({id}) => id);
        }

        return undefined;
    };
    const {data} = useGetInstructorsQuery();
    const getInstructors = () => {
        if (data === undefined) {
            return [];
        }

        return data.instructors.map((instructor) => {
            return {
                ...instructor,
                label: instructor.firstname + " " + instructor.lastname,
            };
        });
    };

    const instructors = getInstructors() ?? [];

    return (
        <div data-testid={testId} style={style} {...props}>
            <Form.Item
                validateStatus={nestedValidateStatus(
                    `lessons[${index}].instructors`,
                )}
                help={nestedHelp(`lessons[${index}].instructors`)}
                style={formItemStyle}
            >
                <TableSelectInstructors
                    maxTagCount={maxTagCount}
                    onChange={(values: SelectValue) => {
                        const filterAllInstructors = filterInstructors(
                            instructors,
                        );
                        const filtered = filterAllInstructors(
                            values as Array<string>,
                        );

                        setFieldValue(
                            `lessons[${index}].instructors`,
                            filtered.length <= 0 ? undefined : filtered,
                        );
                    }}
                    onBlur={handleNestedBlur(`lessons[${index}].instructors`)}
                    value={getValue()}
                    status={getValue() === undefined ? "error" : undefined}
                    options={instructors}
                />
            </Form.Item>
        </div>
    );
};

export const EditableInstructor = memo(EditableInstructorComponent);

type ActionsProps = {
    handleRemove: (i: number) => void;
    lesson: PossibleFormikPropsType;
    index: number;
    testId: string;
    style?: React.CSSProperties;
    formikProps: FormikProps<PossibleFormikPropsType>;
};

const ActionsComponent: FC<ActionsProps> = ({
    handleRemove,
    index,
    testId,
    style,
    lesson,
    formikProps,
    ...props
}) => {
    const handleConfirm = () => {
        handleRemove(index);
    };

    const lessonCount = formikProps.values.lessons.length ?? 1;

    return (
        <div data-testid={testId} style={style} {...props}>
            <Form.Item style={formItemStyle}>
                <DeleteButton
                    data-testid={testId}
                    size="small"
                    confirmText={i18n.containers.courses.CourseCreate.modals.confirmDelete.title()}
                    disabled={lessonCount === 1}
                    onConfirm={handleConfirm}
                    disabledText={i18n.containers.courses.CourseCreate.modals.confirmDelete.deleteDisabledText()}
                />
            </Form.Item>
        </div>
    );
};

export const Actions = memo(ActionsComponent);
