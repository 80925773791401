import {Button, message} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {
    GetBookingByIdDocument,
    GetPaidScheduledPaymentsDocument,
    GetPaidScheduledPaymentsQueryVariables,
    GetUnpaidScheduledPaymentsDocument,
    TablesEnum,
    useGetPaidScheduledPaymentsQuery,
    useUpdateManyScheduledPaymentIdsMutation,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import Page from "../../views/Page/Page";
import PaymentsList from "./PaymentsList";
import {TableType} from "./PaymentsListColumns";
import {useLocalStorageOptions} from "../../helpers/columnsLocalStorageHandler";

type DonePaymentType = {
    hideTitle?: boolean;
    customColumns?: Array<Extract<keyof TableType, string>>;
    hidePagination?: boolean;
    tablesEnum?: TablesEnum;
    hideXmlBtn?: boolean;
    style?: React.CSSProperties;
    bookingId?: string;
    hiddenFilters?: Array<Extract<keyof TableType, string>>;
    minPadding?: boolean;
    pollInterval?: number;
    tableTitle?: string | React.ReactNode;
    hideSpecialFunctions?: boolean;
    timeStamp?: number;
    hideFooter?: boolean;
};

const DonePaymentsList: FC<DonePaymentType> = ({
    hideTitle,
    customColumns,
    hidePagination,
    tablesEnum = TablesEnum.DonePaymentsList,
    hideXmlBtn,
    style,
    bookingId,
    hiddenFilters,
    minPadding,
    pollInterval,
    tableTitle,
    hideSpecialFunctions,
    timeStamp,
    hideFooter = false,
}) => {
    const localStorageName = tablesEnum;
    const [totalResults, setTotalResults] = useState<number>(0);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName,
        defaultOptions: {},
        totalResults,
    });

    const defaultColumns: Array<Extract<keyof TableType, string>> = [
        "bookingNumber",
        "courseType",
        "courseNumber",
        "booker",
        "attendee",
        "location",
        "firstCourseLesson",
        "invoice",
        "paymentMethod",
        "paymentDone",
        "paymentAmount",
        "setUnPaidAction",
    ];

    // GET DATA FOR LIST --------------------------------------------------------------------------------------------------------------------
    const {data, loading, refetch} = useGetPaidScheduledPaymentsQuery({
        variables: {
            options: options as GetPaidScheduledPaymentsQueryVariables["options"],
            bookingId,
        },
        fetchPolicy: "network-only",
        pollInterval,
    });

    useEffect(() => {
        totalResults !== data?.paidScheduledPayments?.total &&
            setTotalResults(data?.paidScheduledPayments?.total ?? 0);
    }, [data, totalResults]);

    // useEffect(() => {
    //     refetch();
    // }, [refetch, data]);

    useEffect(() => {
        refetch();
    }, [refetch, tableTitle, timeStamp]);

    const tableData = {
        dataSource: data?.paidScheduledPayments?.items ?? [],
        existMore: data?.paidScheduledPayments?.existsMore ?? false,
        total: data?.paidScheduledPayments?.total ?? 0,
        loading,
    };

    // MARK SELECTION OF PAYMENTS ----------------------------------------------------

    const [selection, setSelection] = useState<Array<string>>([]);

    const [
        updateManyScheduledPaymentMutation,
    ] = useUpdateManyScheduledPaymentIdsMutation({});

    const setAsUnPaid = async () => {
        const hide = message.loading(
            i18n.containers.payments.DonePaymentsList.operate(),
        );

        try {
            await updateManyScheduledPaymentMutation({
                variables: {
                    paymentIds: selection,
                    editScheduledPaymentData: {
                        paymentDone: null,
                    },
                },
                refetchQueries: [
                    "GetPaidScheduledPayments",
                    {query: GetPaidScheduledPaymentsDocument},
                    "GetUnpaidScheduledPayments",
                    {query: GetUnpaidScheduledPaymentsDocument},
                    "GetBookingById",
                    {query: GetBookingByIdDocument, variables: {id: bookingId}},
                ],
            });
        } catch (error) {
            console.log("updateManyScheduledPaymentMutation: ", error);
        } finally {
            refetch();
            hide();
            // setSelection([]);
            message.success(
                i18n.containers.payments.DonePaymentsList.markedAsUnPaidFinished(),
                2,
            );
        }
    };

    const onOk = () => {
        setAsUnPaid()
            .catch((err) => console.log(err))
            .then(() => setSelection([]));
    };

    const MarkSelection = () => {
        return (
            <Button type="primary" size="small" onClick={() => onOk()}>
                {i18n.containers.payments.DonePaymentsList.markItemsAsUnPaid()}
            </Button>
        );
    };

    return (
        <Page
            data-testid="DonePaymentsList"
            content={
                <PaymentsList
                    loading={loading}
                    tableTitle={
                        tableTitle ??
                        i18n.containers.payments.DonePaymentsList.tableTitle()
                    }
                    defaultColumns={customColumns ?? defaultColumns}
                    // initialColumns={columns}
                    tableData={tableData}
                    setOptions={setOptions}
                    options={options}
                    refetch={refetch}
                    // optionsCallBack={(
                    //     options: React.SetStateAction<SortFilterOptionsType>,
                    // ) => setOptions(options)}
                    specialFunctions={
                        hideSpecialFunctions
                            ? undefined
                            : [<MarkSelection key="markSelectionUnpaid" />]
                    }
                    selection={(
                        selectedItems: React.SetStateAction<Array<string>>,
                    ) => setSelection(selectedItems)}
                    tablesEnum={tablesEnum}
                    hidePagination={hidePagination}
                    style={style}
                    hiddenFilters={hiddenFilters}
                    minPadding={minPadding}
                    contentType="donePayment"
                    hideFooter={hideFooter}
                />
            }
        />
    );
};

export default memo(DonePaymentsList);
