import DOMPurify from "dompurify";

export const formattedText = (text: string) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const italicRegex = /\*(.*?)\*/g;
    const highlightedRegex = /%(.*?)%/g;
    const formatted = text
        .replace(boldRegex, "<strong>$1</strong>")
        .replace(italicRegex, "<i>$1</i>")
        .replace(highlightedRegex, "<mark>$1</mark>");

    return DOMPurify.sanitize(formatted);
};
