import React, {FC, memo} from "react";
import moment from "moment";
import {DatePicker} from "antd";
import {DatePickerProps} from "antd/es/date-picker/interface";
import css from "./TableDatePicker.less";
import {getPopupContainer} from "../../helpers/getPopupContainer";
import {ISODateString} from "../../helpers/typeScriptHelpers";

type TableDatePickerProps = DatePickerProps & {
    defaultDate?: ISODateString;
};
const TableDatePicker: FC<TableDatePickerProps> = ({
    defaultDate = new Date().toISOString(),
    ...restProps
}) => {
    return (
        <DatePicker
            className={css.root}
            defaultValue={moment(defaultDate)}
            size="small"
            format="dd DD.MM.YY"
            getCalendarContainer={getPopupContainer}
            allowClear={false}
            {...restProps}
        />
    );
};

export default memo(TableDatePicker);
