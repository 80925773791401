import React, {FC, memo, useState} from "react";
import moment from "moment";
import {Badge, Button, Card, InputNumber, List, Space} from "antd";
import {CheckCircleTwoTone, ReloadOutlined} from "@ant-design/icons";
import {green, grey, orange, red} from "@ant-design/colors";
import {
    DashboardConfig,
    DesignColor,
    DraggableCard,
} from "../DashboardOverview/DashboardOverview";
import css from "./Cards.less";
import {
    GetUpcomingCoursesByTimeRangeMinQuery,
    GetUpcomingCoursesByTimeRangeQuery,
    useGetUpcomingCoursesByTimeRangeMinQuery,
    useGetUpcomingCoursesByTimeRangeQuery,
} from "../../../generated/graphql";
import {
    renderCourseNumber,
    renderCourseType,
} from "../../../views/TableColumnRenderers/TableColumnRenderers";
import {URL_COURSES_DETAILS_ID} from "../../../models/url";
import ProgressElement from "./Elements/ProgressElement";

type CardUpcomingCoursesProps = {
    color: DesignColor;
    dashboardConfig: DashboardConfig;
    style?: React.CSSProperties;
};

const pageSize = 4;
const maxDays = 60;

const CardUpcomingCourses: FC<CardUpcomingCoursesProps> = ({
    color,
    dashboardConfig,
    style,
}) => {
    const {cardHeight, innerCardHeight} = dashboardConfig;
    const [timeStamp, setTimeStamp] = useState<number>(moment().unix());
    const [options, setOptions] = useState({
        limit: pageSize,
        offset: 0,
    });

    const actions = [
        <Button
            key="reload"
            type="link"
            icon={<ReloadOutlined />}
            // onClick={() => refetch()}
            onClick={() => setTimeStamp(moment().unix())}
        />,
    ];
    const [dayAmount, setDayAmount] = useState<number>(14);

    const extra = [
        <Button
            key="reload"
            type="link"
            icon={<ReloadOutlined />}
            // onClick={() => refetch()}
            onClick={() => setTimeStamp(moment().unix())}
        />,
    ];

    const titleText = `Kursstart in den nächsten ${dayAmount} Tagen`;

    const title = (
        <Space size={5} style={{fontSize: "1.3em"}}>
            {titleText}
        </Space>
    );

    const headStyle: React.CSSProperties = {
        backgroundColor: color[3],
    };

    const gridStyle: React.CSSProperties = {};

    type CardCSSProperties = React.CSSProperties & {
        "--color-1": string;
        "--color-2": string;
        "--color-3": string;
        "--color-4": string;
        "--color-5": string;
        "--inner-card-height": number;
    };

    const actualStyle: CardCSSProperties = {
        "--color-1": color[1],
        "--color-2": color[2],
        "--color-3": color[3],
        "--color-4": color[4],
        "--color-5": color[5],
        "--inner-card-height": innerCardHeight ?? 200,
    };

    const today = moment().format("YYYY-MM-DD HH:mm");
    const inSevenDays = moment()
        .add(dayAmount, "days")
        .format("YYYY-MM-DD HH:mm");

    const {
        data: upcomingCourses,
        loading: loadingUpcomingCourses,
    } = useGetUpcomingCoursesByTimeRangeQuery({
        variables: {
            startDateTime: today,
            endDateTime: inSevenDays,
            options,
        },
        // fetchPolicy: "network-only",
    });

    const coursesWithPagination =
        upcomingCourses?.getUpcomingCoursesByTimeRange;

    const dataSource: GetUpcomingCoursesByTimeRangeQuery["getUpcomingCoursesByTimeRange"]["items"] =
        upcomingCourses?.getUpcomingCoursesByTimeRange.items ?? [];

    //  CALCULATE ABSOLUTE CAPACITY ----------------------------------------------------------------------
    const {
        data: upcomingCoursesWithoutPagination,
        loading: loadingUpcomingCoursesWithoutPagination,
    } = useGetUpcomingCoursesByTimeRangeMinQuery({
        variables: {
            startDateTime: today,
            endDateTime: inSevenDays,
            options: {limit: 300},
        },
        // fetchPolicy: "network-only",
    });

    const dataSourceWithoutPagination: GetUpcomingCoursesByTimeRangeMinQuery["getUpcomingCoursesByTimeRange"]["items"] =
        upcomingCoursesWithoutPagination?.getUpcomingCoursesByTimeRange.items ??
        [];

    // --------------------------------------------------------------------------------------------------

    const calculateCapacity = ({
        freePlaces,
        maxAttendees,
    }: {
        freePlaces: number;
        maxAttendees: number;
    }) => {
        return Math.round(((maxAttendees - freePlaces) / maxAttendees) * 100);
    };

    const getColor = (calculateCapacity: number, intencity?: number) => {
        if (calculateCapacity > 90) {
            return green[intencity ?? 3];
        } else if (calculateCapacity > 75) {
            return orange[intencity ?? 3];
        }

        return red[intencity ?? 3];
    };

    const calculatedAbsoluteCapacity = calculateCapacity({
        freePlaces:
            dataSourceWithoutPagination.reduce((acc, course) => {
                return acc + course.freePlaces;
            }, 0) / dataSourceWithoutPagination.length,
        maxAttendees:
            dataSourceWithoutPagination.reduce((acc, course) => {
                return acc + course.maxAttendees;
            }, 0) / dataSourceWithoutPagination.length,
    });

    type UpcomingCourse = GetUpcomingCoursesByTimeRangeQuery["getUpcomingCoursesByTimeRange"]["items"][number];

    const CourseCapacity = ({course}: {course: UpcomingCourse}) => {
        const {maxAttendees, freePlaces, placeReservationsCount} = course;
        const bookedPlaces = maxAttendees - freePlaces;

        return (
            <div>
                <ProgressElement
                    breakPoints={{green: 100, orange: 60, red: 50}}
                    percent={calculateCapacity({
                        freePlaces,
                        maxAttendees,
                    })}
                />
                <div className={css.subLine}>
                    {placeReservationsCount > 0 ? (
                        <div
                            className={css.placeReservationsCount}
                        >{`${placeReservationsCount} Platzreservierungen`}</div>
                    ) : (
                        <Space size={3} className={css.noPlaceReservations}>
                            <CheckCircleTwoTone twoToneColor={green[3]} />
                            {"Keine Platzreservierungen"}
                        </Space>
                    )}

                    <div>{`${bookedPlaces} von ${maxAttendees} Plätzen gebucht`}</div>
                </div>
            </div>
        );
    };

    const ListTitle = ({course}: {course: UpcomingCourse}) => {
        const courseDetailsLink = URL_COURSES_DETAILS_ID;

        const courseNumber = renderCourseNumber({
            courseNumber: course.prefixedCourseNumber ?? undefined,
            courseId: course.id,
            detailsLink: courseDetailsLink,
            style: {padding: 0, marginBottom: -10},
        });

        return (
            <div className={css.title}>
                <div className={css.firstRow}>
                    <div>{`${course.location.name}`}</div>
                    <div>
                        {`${moment(course.startDateTime).format("L")} ${moment(
                            course.startDateTime,
                        ).format("LT")}`}
                    </div>
                </div>
                <div className={css.secondRow}>
                    {renderCourseType({
                        name: course.courseType.name,
                        color: course.courseType.color,
                        style: {marginLeft: -5, marginBottom: -5},
                    })}
                    {/* {`${course.prefixedCourseNumber}`} */}
                    {courseNumber}
                </div>
            </div>
        );

        return (
            <>{`${course.courseType.name} ${course.prefixedCourseNumber}`}</>
        );
    };

    const handleDayAmountChange = (value: number) => {
        console.log("handleDayAmountChange", value);
        if (!value) return;
        if (value > maxDays) {
            setDayAmount(maxDays);
        }

        setDayAmount(value);
    };

    const renderExtra = () => {
        return (
            <Space>
                <InputNumber
                    style={{width: 110}}
                    value={dayAmount}
                    onPressEnter={(value) => {
                        const valueAsNumber = Number(value.target.value);

                        console.log("onPressEnter", valueAsNumber);
                        if (!valueAsNumber) return;
                        handleDayAmountChange(valueAsNumber);
                    }}
                    onBlur={(value) => {
                        const valueAsNumber = Number(value.target.value);

                        console.log("onBlur", valueAsNumber);
                        if (!valueAsNumber) return;
                        handleDayAmountChange(valueAsNumber);
                    }}
                    addonAfter="Tage"
                    max={maxDays}
                    onStep={(value, info) => {
                        console.log("onStep", value, info);
                        if (!value) return;
                        handleDayAmountChange(value);
                    }}
                />
            </Space>
        );
    };

    const onChange: (page: number, pageNumber: number) => void = (
        page,
        pageNumber,
    ) => {
        const updatedOptions = {
            ...options,
            limit: pageSize,
            offset: (page > 0 ? page - 1 : 0) * pageSize,
        };

        setOptions(updatedOptions);
    };

    const averageCapacity = Math.round(
        calculateCapacity({
            freePlaces:
                dataSource.reduce((acc, course) => {
                    return acc + course.freePlaces;
                }, 0) / dataSource.length,
            maxAttendees:
                dataSource.reduce((acc, course) => {
                    return acc + course.maxAttendees;
                }, 0) / dataSource.length,
        }),
    );

    const badgeText = loadingUpcomingCoursesWithoutPagination ? (
        undefined
    ) : (
        <Space size={4}>
            {"Belegung Ø"}
            {calculatedAbsoluteCapacity}
            {"%"}
        </Space>
    );

    const renderCard = () => {
        return (
            <Card
                // className={css.inputNumberWithArrows}
                size="small"
                title={title}
                hoverable
                headStyle={headStyle}
                bodyStyle={{
                    ...actualStyle,
                    height: innerCardHeight,
                    overflow: "auto",
                }}
                // actions={actions}
                // extra={extra}
                extra={renderExtra()}
                style={{
                    ...style,
                    ...actualStyle,
                    borderRadius: 15,
                    overflow: "hidden",
                    height: cardHeight,
                }}
                // className="card"
            >
                <List
                    size="small"
                    className={css.upcomingList}
                    style={{
                        margin: -12,
                        height: innerCardHeight,
                        overflow: "auto",
                    }}
                    loading={loadingUpcomingCourses}
                    locale={{emptyText: "Keine Kurse gefunden"}}
                    pagination={{
                        pageSize,
                        // current: options.offset + 1,
                        onChange: (page, pageNumber) => {
                            onChange(page, pageNumber);
                        },
                        responsive: true,
                        total: coursesWithPagination?.total ?? 0,
                        // eslint-disable-next-line react/display-name
                        showTotal: (total, range) => {
                            return (
                                <span className={css.pagination}>{`${
                                    range[0]
                                }-${
                                    range[1]
                                } von ${coursesWithPagination?.total ??
                                    0} Kursen`}</span>
                            );
                        },
                        size: "small",
                        hideOnSinglePage: true,
                        style: {marginTop: -15},
                        showSizeChanger: false,
                    }}
                >
                    {dataSource.length === 0 ? (
                        <div className={css.emptyBox}>
                            <div className={css.noCourses}>
                                <div>{`In den nächsten ${dayAmount} Tagen starten keine Kurse`}</div>
                            </div>
                        </div>
                    ) : (
                        dataSource.map((course: UpcomingCourse) => {
                            return (
                                <List.Item
                                    key={course.id}
                                    style={{
                                        borderLeft: `7px solid ${getColor(
                                            calculateCapacity({
                                                freePlaces: course.freePlaces,
                                                maxAttendees:
                                                    course.maxAttendees,
                                            }),
                                            2,
                                        )}`,
                                        backgroundColor: `${getColor(
                                            calculateCapacity({
                                                freePlaces: course.freePlaces,
                                                maxAttendees:
                                                    course.maxAttendees,
                                            }),
                                            0,
                                        )}`,
                                        marginTop: 2,
                                        padding: "2px 12px",
                                    }}
                                >
                                    <List.Item.Meta
                                        title={
                                            <div className={css.title}>
                                                <ListTitle course={course} />
                                            </div>
                                        }
                                        description={
                                            <CourseCapacity course={course} />
                                        }
                                    />
                                </List.Item>
                            );
                        })
                    )}
                </List>
            </Card>
        );
    };

    if (dataSource.length === 0) {
        return renderCard();
    }

    return (
        <Badge.Ribbon
            text={dataSource.length > 0 && badgeText}
            color={
                loadingUpcomingCoursesWithoutPagination
                    ? grey[1]
                    : calculatedAbsoluteCapacity > 90
                    ? green[3]
                    : calculatedAbsoluteCapacity > 75
                    ? orange[3]
                    : red[3]
            }
            placement="start"
            // style={{marginTop: Number(innerCardHeight) + 3}}
            style={{marginTop: -20}}
        >
            {renderCard()}
        </Badge.Ribbon>
    );
};

export const cardUpcomingCourses: Pick<
    DraggableCard,
    "title" | "actions" | "extra"
> = {
    title: "Bald startende Kurse",
    extra: <Button key="reload" type="link" icon={<ReloadOutlined />} />,
    // extra: <><InputNumber value={dayAmount}/></>,
};

export default memo(CardUpcomingCourses);
