import React, {FC, memo} from "react";
import {DbTemplate} from "./Email";
import {
    GetMjmlTemplateByIdQuery,
    TemplateType,
    useGetMjmlTemplateByIdQuery,
} from "../../../generated/graphql";
import MjmlLoader from "../../../views/MjmlPreview/MjmlLoader/MjmlLoader";
import css from "./Email.less";

type EmailPreviewProps = {
    dbTemplate: DbTemplate;
};

const EmailPreview: FC<EmailPreviewProps> = ({dbTemplate}) => {
    const {templateType, id, subject, headerText, footerText, signature} =
        dbTemplate.data?.template ?? {};
    const mailjetTemplateId =
        templateType === TemplateType.FreeText ||
        templateType === TemplateType.NoTemplate
            ? "4250298"
            : "2046014";

    const {
        data: mjmlTemplateData,
        loading: mjmlTemplateLoading,
        error: mjmlTemplateError,
    } = useGetMjmlTemplateByIdQuery({
        variables: {templateId: mailjetTemplateId},
    });

    const mjmlTemplate:
        | GetMjmlTemplateByIdQuery["getEmailTemplateById"]
        | undefined = mjmlTemplateData?.getEmailTemplateById;

    if (!mjmlTemplate || !id) {
        return <div>{"Loading..."}</div>;
    }

    return (
        <div className={css.mjmlPreview}>
            <MjmlLoader
                variables={{subject, headerText, footerText, signature}}
                mjmlTemplate={mjmlTemplate.Data}
                loading={mjmlTemplateLoading}
                dbTemplateId={id}
            />
        </div>
    );
};

export default memo(EmailPreview);
