import {SendOutlined} from "@ant-design/icons";
import {Button, ButtonProps, Modal, Space, Tooltip, message} from "antd";
import React, {FC, useState} from "react";
import i18n from "../../../services/i18n";
import {
    GetBookingByIdQuery,
    MailDataType,
    SendInvoicesToSelectionOfBookingInput,
    useSendBookingConfirmationMutation,
    useSendInvoicesToSelectionOfBookingsMutation,
} from "../../../generated/graphql";
import {handleError, KnownError} from "../../../errors/handleErrors";
import {SentMailsResult} from "../../../../server/mailjet.types";
import {isDefined} from "../../../helpers/typeScriptHelpers";
import EmailTemplatePicker, {
    SelectionType,
} from "../../templates/EmailRefactoring/EmailTemplatePicker";

const buttonProps: ButtonProps = {
    size: "small",
    type: "text",
    block: true,
    style: {textAlign: "left"},
};

type MenuItemProps = {
    booking: GetBookingByIdQuery["bookingById"];
    booker: string;
    isReversal?: boolean;
};

// SEND INVOICE OR REVERSAL INVOICE ----------------------------------------------------------------------------------

export const SendInvoice: FC<MenuItemProps> = ({
    booking,
    booker,
    isReversal = false,
}) => {
    const [sending, setSending] = useState(false);
    const [sendInvoice] = useSendInvoicesToSelectionOfBookingsMutation();
    const sendInvoiceToBooker = async (
        bookingId?: string,
        isReversalInvoice?: boolean,
    ) => {
        if (!bookingId) {
            return;
        }
        try {
            setSending(true);
            const sendInvoicesToSelectionOfBookingsInput: SendInvoicesToSelectionOfBookingInput = {
                bookingIds: [bookingId],
                reversal: isReversalInvoice,
            };

            await sendInvoice({
                variables: {sendInvoicesToSelectionOfBookingsInput},
            });
            if (isReversalInvoice) {
                message.success(
                    i18n.containers.invoice.InvoiceDetails.successSendingReversalInvoice(),
                );
            } else {
                message.success(
                    i18n.containers.invoice.InvoiceDetails.successSendingInvoice(),
                );
            }
            setSending(false);
        } catch (e) {
            console.log(e);
            message.error(
                i18n.containers.invoice.InvoiceDetails.errorSendingInvoice(),
            );
            setSending(false);
        }
    };

    type DataType = {
        bookerHasNoMail: string;
        modalTitle: string;
        modalContent: string;
        modalOkText: string;
        modalCancelText: string;
        buttonLabel: string;
    };

    let text: DataType = {
        bookerHasNoMail: i18n.containers.invoice.InvoiceDetails.bookerHasNoEmail(),
        modalTitle: i18n.containers.invoice.InvoiceDetails.resendInvoice(),
        modalContent: i18n.containers.invoice.InvoiceDetails.resendInvoiceConfirmation(
            {receiver: booker},
        ),
        modalOkText: i18n.containers.invoice.InvoiceDetails.resendInvoice(),
        modalCancelText: i18n.containers.invoice.InvoiceDetails.cancel(),
        buttonLabel: i18n.containers.invoice.InvoiceDetails.resendInvoice(),
    };

    if (isReversal) {
        text = {
            bookerHasNoMail: i18n.containers.invoice.InvoiceDetails.bookerHasNoEmail(),
            modalTitle: i18n.containers.invoice.InvoiceDetails.resendReversalInvoice(),
            modalContent: i18n.containers.invoice.InvoiceDetails.resendInvoiceConfirmation(
                {receiver: booker},
            ),
            modalOkText: i18n.containers.invoice.InvoiceDetails.resendReversalInvoice(),
            modalCancelText: i18n.containers.invoice.InvoiceDetails.cancel(),
            buttonLabel: i18n.containers.invoice.InvoiceDetails.resendReversalInvoice(),
        };
    }

    return (
        <Tooltip
            title={
                Boolean(booking?.attendee?.booker?.email) === false
                    ? text.bookerHasNoMail
                    : ""
            }
        >
            <Button
                {...buttonProps}
                icon={<SendOutlined />}
                loading={sending}
                disabled={!booking.id || !booking.attendee?.booker?.email}
                onClick={() => {
                    Modal.confirm({
                        title: text.modalTitle,
                        content: text.modalContent,
                        okText: text.modalOkText,
                        cancelText: text.modalCancelText,
                        onOk: async () => {
                            await sendInvoiceToBooker(booking.id, isReversal);
                        },
                    });
                }}
            >
                {text.buttonLabel}
            </Button>
        </Tooltip>
    );
};

// SEND BOOKING CONFIRMATION ----------------------------------------------------------------------------------------

export const SendBookingConfirmation: FC<MenuItemProps> = ({
    booking,
    booker,
}) => {
    const bookingOrWaitlistConfirmationText = i18n.containers.bookings.BookingSummary.sendBookingConfirmation.bookingConfirmation();
    const bookerMailIsMissing = !isDefined(booking.attendee?.booker?.email);

    const [
        sendBookingConfirmation,
        {loading: sendBookingConfirmationLoading},
    ] = useSendBookingConfirmationMutation({
        // refetchQueries: [
        //     {query: GetBookinsDocument},
        //     "GetBookins",
        //     {query: GET_BOOKINS},
        // ],
    });

    const resendBookingConfirmation = async () => {
        const bookingId = booking.id;

        const knownErrors: Array<KnownError> = [
            {
                key: "No booker email found",
                title: "Keine E-Mail-Adresse gefunden",
                msg:
                    "Es konnte keine E-Mail-Adresse für den Bucher gefunden werden.",
            },
            {
                key: "default",
                title: "Fehler",
                msg: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.messages.errorText(
                    {bookingOrWaitlistConfirmationText},
                ),
            },
        ];

        if (bookingId) {
            try {
                message.loading({
                    key: `sendMail`,
                    content: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.messages.loadingText(
                        {bookingOrWaitlistConfirmationText},
                    ),
                });

                await sendBookingConfirmation({variables: {bookingId}}).then(
                    (res) => {
                        // console.log("sendTemplateMail res::: ", res);
                        const sentMailResult: SentMailsResult =
                            res.data?.sendBookingConfirmation;

                        if (sentMailResult) {
                            const mailCount = sentMailResult.sentMailCount;
                            const emailText =
                                mailCount === 1 ? "E-Mail" : "E-Mails";
                            const receivers = sentMailResult.sentMessages.map(
                                (message) => message.To[0].Email,
                            );
                            const receiverString = receivers.join(", ");

                            message.success({
                                key: "sendMail",
                                content: `${mailCount} ${emailText} erfolgreich versendet. Empfänger: ${receiverString}`,
                            });
                        }
                    },
                );
                // onSuccess();
            } catch (error) {
                handleError(
                    "sendBookingConfirmationError",
                    error,
                    "message",
                    knownErrors,
                );
            }
        }
    };

    return (
        <Tooltip
            title={
                bookerMailIsMissing
                    ? i18n.containers.bookings.BookingSummary.sendBookingConfirmation.toolTipNoEmailText(
                          {bookingOrWaitlistConfirmationText},
                      )
                    : i18n.containers.bookings.BookingSummary.sendBookingConfirmation.toolTipText(
                          {bookingOrWaitlistConfirmationText},
                      )
            }
            placement="left"
        >
            <Button
                {...buttonProps}
                loading={sendBookingConfirmationLoading}
                icon={<SendOutlined />}
                disabled={bookerMailIsMissing}
                onClick={() =>
                    Modal.confirm({
                        title: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.modal.title(
                            {bookingOrWaitlistConfirmationText},
                        ),
                        content: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.modal.text(
                            {bookingOrWaitlistConfirmationText},
                        ),
                        okText: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.modal.okText(
                            {bookingOrWaitlistConfirmationText},
                        ),
                        onOk: resendBookingConfirmation,
                        style: {
                            marginTop: 100,
                        },
                        icon: null,
                    })
                }
            >
                {bookingOrWaitlistConfirmationText}
            </Button>
        </Tooltip>
    );
};

// SEND BLANK MAIL --------------------------------------------------------------------------------------------------

type SendBlankMailProps = {
    booking: GetBookingByIdQuery["bookingById"];
    booker: string;
};

export const SendBlankMail: FC<SendBlankMailProps> = ({booking, booker}) => {
    const [open, setOpen] = useState(false);
    const bookerMailIsMissing = !isDefined(booking.attendee?.booker?.email);
    const buttonData = {
        label: "Vorlage wählen",
        icon: <SendOutlined />,
        onClick: () => sendBlankMail(),
    };
    const {label} = buttonData;
    const notImplementedText = `${label} Ist derzeit noch nicht implementiert`;
    const sendBlankMail = () => {
        message.warn(notImplementedText);
    };

    const selectedBooking = booking as SelectionType;

    return (
        <Tooltip
            title="Eine Vorlage verwenden um den Bucher zu benachrichtigen."
            placement="left"
        >
            <EmailTemplatePicker
                open={open}
                setOpen={(open) => setOpen(open)}
                mailDataType={MailDataType.Booking}
                selection={[selectedBooking]}
                customButtonContent={
                    <Button
                        {...buttonProps}
                        disabled={bookerMailIsMissing}
                        onClick={() => setOpen(!open)}
                    >
                        <Space>
                            <SendOutlined />
                            {label}
                        </Space>
                    </Button>
                }
            />
        </Tooltip>
    );
};
