import {TableType as CourseTypeTableType} from "../../containers/courses/CourseTypes/CourseTypesAllColumns";
import {PaymentInterval} from "../../generated/graphql";
import {CurrencyShort} from "../renderAmount";
import {csvAge, csvCoursePrice, csvDate} from "./TableColumnExports";

export const courseTypeValues = (
    key: string,
    row: CourseTypeTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    // eslint-disable-next-line max-params
): string => {
    // console.log("row", row);

    const paymentInterval = row.paymentInterval ?? PaymentInterval.PerCourse;
    const minAge = row.minAge ?? 0;
    const maxAge = row.maxAge ?? 99;

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "shortHandId":
            return `${row.shortHandId}`;
        case "name":
            return `${row.name}`;
        case "fee":
            return csvCoursePrice(row.fee, currencyShort, paymentInterval);
        case "minAge":
            // return `${row.minAge}`;
            return csvAge(minAge);
        case "maxAge":
            return csvAge(maxAge);
        case "minAttendees":
            return `${row.minAttendees}`;
        case "maxAttendees":
            return `${row.maxAttendees}`;
        case "description":
            return `${row.description}`;
        case "websiteInfo":
            return `${row.websiteInfo}`;
        case "updateDate":
            return csvDate(row.updateDate, true);
        case "annotation":
            return `${row.annotation}`;
        case "courseCount":
            return `${row.courseCount}`;
        default:
            return notExportable;
    }
};
