import React, {FC, memo} from "react";
import {Modal, Button} from "antd";
import classnames from "classnames";
import i18n from "../../services/i18n";
import css from "./AbortCard.less";

type AbortCardProps = {
    title: string;
    text?: string;
    handleOk: () => void;
    handleCancel: () => void;
    visible: boolean;
};

const AbortCard: FC<AbortCardProps> = ({
    title,
    text = "",
    handleOk,
    handleCancel,
    visible,
}) => {
    return (
        <Modal
            bodyStyle={{padding: "5px"}}
            wrapClassName={css.abortWrapper}
            className={classnames(css.root, css.withoutBorder)}
            // visible={visible}
            open={visible}
            title={title}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={true}
            style={{marginTop: 150}}
            footer={[
                <Button
                    className={classnames(css.continue)}
                    key="submit"
                    onClick={handleCancel}
                >
                    {i18n.views.AbortCard.continue()}
                </Button>,
                <Button
                    className={classnames(css.cancel)}
                    key="back"
                    onClick={handleOk}
                >
                    {i18n.views.AbortCard.cancel()}
                </Button>,
            ]}
        />
    );
};

export default memo(AbortCard);
