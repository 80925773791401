import React, {FC, memo, ReactNode} from "react";
import {Layout} from "antd";
import css from "./Page.less";

type PageProps = {
    /** The content of the page */
    content?: ReactNode;

    style?: React.CSSProperties;
};

const Page: FC<PageProps> = ({content = null, ...restProps}) => {
    return (
        <Layout.Content id="page" className={css.root} {...restProps}>
            {content}
        </Layout.Content>
    );
};

export default memo(Page);
