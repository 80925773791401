import React, {FC, memo, ReactNode} from "react";
import {Form} from "antd";
import {FormProps} from "antd/es/form/Form";
import css from "./LayoutForm.less";

type LayoutFormProps = {
    children?: ReactNode;
    columns?: number;
} & FormProps;

type LayoutFormCSSProperties = React.CSSProperties & {
    "--column-count": number;
};

const LayoutForm: FC<LayoutFormProps> = ({
    columns = 6,
    style,
    layout = "vertical",
    ...props
}) => {
    const actualStyle: LayoutFormCSSProperties = {
        "--column-count": columns,
        ...style,
    };

    return (
        <Form
            className={css.root}
            colon={false}
            {...props}
            style={actualStyle}
            layout={layout}
        />
    );
};

export default memo(LayoutForm);
