/* eslint-disable react/display-name */
/* eslint-disable babel/new-cap */
import React, {FC, useRef, useState} from "react";
import {ColumnProps} from "antd/lib/table";
import {InputRef} from "antd";
import css from "../../../views/TableColumnRenderers/TableColumnRenderers.less";
import {
    GetBookinsQuery,
    CourseLesson,
    ArrayDataOptions,
    PaymentInterval,
    Maybe,
    PaymentTransferType,
} from "../../../generated/graphql";
import {
    renderAdditionalInfo,
    RenderAmount,
    renderAmount,
    renderAttendeeFullName,
    renderBirthdayAsAge,
    renderBookerFullName,
    renderBookingNumber,
    RenderBookingPrice,
    renderBookingType,
    renderBooleanAsCheckmark,
    renderContinuousText,
    renderCourseLessons,
    renderCourseNumber,
    renderCourseType,
    renderDate,
    // renderEmailNotifications,
    renderFirstCourseLesson,
    renderFullName,
    renderInstructors,
    renderLastCourseLesson,
    renderPaymentDate,
    renderPaymentMethod,
    renderPaymentType,
    renderPercent,
    renderTextStandard,
    renderTimeFromNow,
    renderUnknown,
} from "../../../views/TableColumnRenderers/TableColumnRenderers";
import i18n from "../../../services/i18n";
import {isDefined} from "../../../helpers/typeScriptHelpers";
import columnSortFilterTest, {
    InputType,
} from "../../../helpers/columnSortFilterTest";
import RenderInvoicePreview from "../../../views/RenderInvoicePreview/RenderInvoicePreview";
import useHighestRole from "../../../helpers/useHighestRole";
import {renderEmailNotifications} from "../../../views/TableColumnRenderers/renderEmailNotifications";
import RenderActions, {RenderActionsProps} from "./ColumnActions/RenderActions";
// import RenderActions from "./ColumnActions/RenderActions";

export type EditParamsType = {bookingId: string} & Pick<
    TableType,
    | "attendee"
    | "coursePriceId"
    | "coursePrice"
    | "courseLessons"
    | "bookingNumberPrefix"
    | "noticeStaff"
    | "paymentType"
    | "isSpecial"
    | "grossPrice"
    | "netPrice"
    | "vat"
>;

type ExtraColumns = {
    rowIndex: "rowIndex";
    actions: "actions";
    courseType: "courseType";
    prefixedCourseNumber: "prefixedCourseNumber";
    booker: "booker";
    bookerFullName: "bookerFullName";
    attendeeFullName: "attendeeFullName";
    city: "city";
    location: "location";
    firstCourseLesson: "firstCourseLesson";
    lastCourseLesson: "lastCourseLesson";
    billed: "billed";
    invoiceingActions: "invoiceingActions";
    invoiceNumber: "invoiceNumber";
    settledActions: "settledActions";
    id: "id";
    nonDisclosureNotice: "nonDisclosureNotice";
    phoneNumber: "phoneNumber";
    mobileNumber: "mobileNumber";
    email: "email";
    birthday: "birthday";
    age: "age";
    dateOfBooking: "dateOfBooking";
    emailNotifications: "emailNotifications";
    // startDateTimeBooking: "startDateTimeBooking";
    credit: "credit";
    instructors: "instructors";
    lessonCount: "lessonCount";
};

export type TableType = {
    dataIndex?: string;
} & GetBookinsQuery["bookings"]["items"][number] &
    ExtraColumns;

export const rowKey: (course: TableType) => TableType["id"] = (booking) =>
    booking.id;

export const useHandleColumns = (
    columnsToShow: Array<Extract<keyof TableType, string>>,
    editLink: string,
    detailsLink: string,
    options: Maybe<ArrayDataOptions> | undefined,
    setOptions: (options: ArrayDataOptions) => void,
    actionColumn?: Function,
    tableData?: Array<TableType>,
    activeFilters?: ArrayDataOptions["filter"],
    selectedRowKeys?: Array<string>,
    hideFiltersAndSorters?: boolean,
    courseDetailsLink?: string,
    refetch?: () => void,
    // eslint-disable-next-line max-params
) => {
    const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
    const shownColumns: Array<ColumnProps<TableType>> = [];
    const highestRole = useHighestRole();

    const getColumnsToShow = (
        columns: Array<{dataIndex: string} & TableType>,
    ) => {
        columns.forEach((col) => {
            if (columnsToShow.some((value) => value === col.dataIndex)) {
                shownColumns.push(col);
            }
        });

        moveLeftAligndColumnToFirstPosition(shownColumns);
        moveActionColumnToLastPosition(shownColumns);
        moveRowIndexToFirstPosition(shownColumns);
    };

    const moveActionColumnToLastPosition = (
        shownColumns: Array<ColumnProps<TableType>>,
    ) => {
        shownColumns.forEach((col) => {
            if (col.fixed === "right") {
                shownColumns.push(
                    shownColumns.splice(shownColumns.indexOf(col), 1)[0],
                );
            }
        });
    };

    const moveLeftAligndColumnToFirstPosition = (
        shownColumns: Array<ColumnProps<TableType>>,
    ) => {
        shownColumns.forEach((col) => {
            if (col.fixed === "left") {
                shownColumns.unshift(
                    shownColumns.splice(shownColumns.indexOf(col), 1)[0],
                );
            }
        });
    };

    const moveRowIndexToFirstPosition = (
        shownColumns: Array<ColumnProps<TableType>>,
    ) => {
        shownColumns.forEach((col) => {
            if (col.dataIndex === "rowIndex") {
                shownColumns.unshift(
                    shownColumns.splice(shownColumns.indexOf(col), 1)[0],
                );
            }
        });
    };

    // const columns: Array<TableType> = [];
    const columns: Array<{dataIndex: string} & TableType> = [];

    const column: ColumnProps<TableType> = {
        title: "",
        fixed: false,
    };

    columnsToShow.forEach((col: Extract<keyof TableType, string>) => {
        const c = {...column};

        const colTitle = i18n.views.TableColumnTitles[col]();

        const rawTitle = colTitle;
        const title =
            rawTitle.length <= 12 ? (
                rawTitle
            ) : (
                <span style={{whiteSpace: "pre-line"}}>
                    {rawTitle.replace(" ", "\n")}
                </span>
            );

        const standardEllipsis = false;

        c.ellipsis = standardEllipsis;
        c.title = title;
        c.dataIndex = col;
        c.width = 100 + colTitle.length * 2;

        let path = c.dataIndex;

        const sortFilter = ({
            dataIndex,
            disabled,
            type,
            selectOptions,
        }: {
            dataIndex: string;
            disabled?: boolean;
            type: InputType;
            selectOptions?: Array<{
                label: string;
                value: string;
            }>;
            activeFilters?: ArrayDataOptions["filter"] | undefined;
        }) =>
            // eslint-disable-next-line max-params
            {
                if (hideFiltersAndSorters) {
                    return null;
                }

                Object.entries(
                    columnSortFilterTest({
                        dataIndex,
                        type,
                        selectOptions,
                        options,
                        setOptions,
                        dropdownOpen,
                        setDropdownOpen,
                    }),
                ).map(([key, value]) => {
                    if (key !== "filteredValue") {
                        // @ts-ignore
                        return (c[key] = disabled ? null : value);
                    }

                    return null;
                });
            };

        switch (col) {
            case "rowIndex":
                c.fixed = "left";
                c.width = "6px";
                c.className = css.rowIndex;
                c.align = "center";
                c.render = (text, record: TableType, index: number) =>
                    index + 1;
                break;
            case "actions":
                c.fixed = "right";
                c.width = 10;
                c.align = "center";
                if (actionColumn) {
                    // c.render = (text, record: TableType) => ({
                    //     displayName: "Aktionen",
                    //     children: (
                    //         <RenderActions
                    //             record={record}
                    //             editLink={editLink}
                    //             detailsLink={detailsLink}
                    //             actionColumn={actionColumn}
                    //             // selectedRowKeys={selectedRowKeys}
                    //             refetch={refetch}
                    //         />
                    //     ),
                    // });
                    c.render = (text, record: TableType) =>
                        renderActions({
                            record,
                            editLink,
                            detailsLink,
                            actionColumn,
                            // selectedRowKeys,
                            refetch,
                        });
                } else {
                    // c.render = (text, record: TableType) => ({
                    //     displayName: "Aktionen",
                    //     children: (
                    //         <RenderActions
                    //             record={record}
                    //             editLink={editLink}
                    //             detailsLink={detailsLink}
                    //             // actionColumn={actionColumn}
                    //             // selectedRowKeys={selectedRowKeys}
                    //             refetch={refetch}
                    //         />
                    //     ),
                    // });
                    c.render = (text, record: TableType) =>
                        renderActions({
                            record,
                            editLink,
                            detailsLink,
                            // actionColumn
                            // selectedRowKeys
                            refetch,
                        });
                }
                break;
            case "invoiceingActions":
                if (actionColumn) {
                    c.fixed = "right";
                    c.width = 10;
                    c.align = "center";
                    c.render = (text, record: TableType) =>
                        actionColumn(record);
                }
                break;
            case "settledActions":
                if (actionColumn) {
                    c.title = null;
                    c.fixed = "right";
                    c.width = 10;
                    c.align = "center";
                    c.render = (text, record: TableType) =>
                        actionColumn(record);
                }
                break;
            case "bookingNumber":
                c.fixed = "left";
                c.width = 120;
                c.render = (_, record: TableType) => {
                    return {
                        children: renderBookingNumber({
                            bookingNumberPrefix: record.bookingNumberPrefix,
                            bookingNumber: record.bookingNumber,
                            bookingType: record.bookingType,
                            className: css.primary,
                            canceled: Boolean(record.canceled !== null),
                            bookingId: record.id,
                            isSettled: Boolean(record.invoice),
                            highestRole,
                        }),
                    };
                };
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "number",
                });
                break;
            case "courseType":
                path = "courseLessons.0.course.courseType.name";
                c.render = (text, record: TableType) =>
                    renderCourseType({
                        name: record.courseLessons[0].course.courseType.name,
                        color: record.courseLessons[0].course.courseType.color,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                break;
            case "prefixedCourseNumber":
                c.render = (text, record: TableType) =>
                    renderCourseNumber({
                        courseNumber:
                            record.courseLessons[0].course
                                .prefixedCourseNumber ?? undefined,
                        courseId: record.courseLessons[0].course.id,
                        // editLink: URL_INVOICE_COURSE_DETAILS.replace(
                        //     ":id",
                        //     record.courseLessons[0].course.id,
                        // ),
                        detailsLink: courseDetailsLink,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "bookerFullName":
                c.render = (text: unknown, record: TableType) =>
                    renderBookerFullName(
                        record.attendee?.booker?.id,
                        record.attendee?.booker?.firstname,
                        record.attendee?.booker?.lastname,
                        css.primary,
                        record.attendee?.booker?.nonDisclosureNotice ?? "",
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "booker":
                c.render = (text: unknown, record: TableType) =>
                    renderBookerFullName(
                        record.attendee?.booker?.id,
                        record.attendee?.booker?.firstname,
                        record.attendee?.booker?.lastname,
                        css.primary,
                        record.attendee?.booker?.nonDisclosureNotice ?? "",
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "phoneNumber":
                c.render = (text: unknown, record: TableType) =>
                    renderTextStandard(record.attendee?.booker?.phoneNumber);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "mobileNumber":
                c.render = (text: unknown, record: TableType) =>
                    renderTextStandard(record.attendee?.booker?.mobileNumber);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "email":
                c.render = (text: unknown, record: TableType) =>
                    renderTextStandard(record.attendee?.booker?.email);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "attendeeFullName":
                path = "attendee.lastname";
                c.render = (text, record: TableType) =>
                    renderAttendeeFullName(
                        record.attendee?.booker?.id,
                        record.attendee?.firstname,
                        record.attendee?.lastname,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "attendee":
                path = "attendee.lastname";
                c.render = (text, record: TableType) =>
                    renderFullName(
                        record.attendee?.firstname,
                        record.attendee?.lastname,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "birthday":
                c.render = (text, record: TableType) =>
                    renderDate(record.attendee?.birthday);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "date",
                });
                c.sorter = false;
                c.sorter = false;
                break;
            case "age":
                c.render = (text, record: TableType) =>
                    renderBirthdayAsAge(record.attendee?.birthday);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "city":
                path = "courseLessons.0.location.city";
                c.render = (text, record: TableType) =>
                    renderTextStandard(record.courseLessons[0].location.city);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "location":
                path = "courseLessons.0.location.name";
                c.render = (text, record: TableType) =>
                    renderTextStandard(record.courseLessons[0].location.name);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "firstCourseLesson":
                path = "courseLessons.0.startDateTime";
                c.render = (text, record: TableType) =>
                    renderFirstCourseLesson({
                        courseLessons: record.courseLessons as Array<
                            CourseLesson
                        >,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                break;
            case "lastCourseLesson":
                // path = "lastCourseLesson";
                c.render = (text, record: TableType) =>
                    renderLastCourseLesson({
                        courseLessons: record.courseLessons as Array<
                            CourseLesson
                        >,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                break;
            case "coursePrice": // This is representing the price of the course
                path = "coursePrice";
                c.render = (text, record: TableType) =>
                    renderAmount(record.coursePrice.grossPrice);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "number",
                });
                c.sorter = false;
                break;
            case "grossPrice": // This is showing the price of the booking
                // c.title = i18n.views.TableColumnTitles.coursePrice();
                c.width = 80;
                c.render = (text, record: TableType) => ({
                    displayName: "Brutto",
                    children: <RenderBookingPrice record={record} />,
                });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "number",
                });
                c.sorter = false;
                break;
            case "netPrice":
                c.render = (text, record: TableType) => ({
                    displayName: "Netto",
                    children: <RenderAmount amount={record.netPrice ?? 0} />,
                });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "number",
                });
                c.sorter = false;
                break;
            case "vat":
                c.render = (text, record: TableType) =>
                    renderPercent(record.vat);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "number",
                });
                c.sorter = false;
                break;
            case "billed":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderBooleanAsCheckmark(
                        isDefined(record.invoice) === true,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "boolean",
                });
                c.sorter = false;
                break;
            case "updateDate":
                c.render = (text, record: TableType) =>
                    renderTimeFromNow({date: record.updateDate, short: true});
                c.sorter = false;
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "date",
                });
                break;
            case "createDate":
                c.render = (text, record: TableType) =>
                    renderTimeFromNow({date: record.createDate, short: true});
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "date",
                });
                c.sorter = false;
                break;
            case "bookedBy":
                c.align = "center";
                c.width = 100;
                c.render = (text, record: TableType) =>
                    renderBooleanAsCheckmark(record.bookedBy === "Booker");

                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Mitarbeiter",
                            value: "Staff",
                        },
                        {
                            label: "Online",
                            value: "Booker",
                        },
                    ],
                });
                c.sorter = false;
                break;
            case "noticeStaff":
                c.render = (text, record: TableType) =>
                    renderContinuousText(record.noticeStaff);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "payDate":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderPaymentDate(record.payDate);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                c.sorter = true;
                break;
            case "bookingType":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderBookingType(record.bookingType);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: i18n.containers.bookings.BookingList.bookingTypes.Booking(),
                            value: "Booking",
                        },
                        {
                            label: i18n.containers.bookings.BookingList.bookingTypes.Waitlist(),
                            value: "Waitlist",
                        },
                        {
                            label: i18n.containers.bookings.BookingList.bookingTypes.PlaceReservation(),
                            value: "PlaceReservation",
                        },
                    ],
                });
                break;
            case "invoice":
                path = "invoice.invoiceNumber";
                c.align = "center";
                // eslint-disable-next-line react/display-name
                c.render = (text, record: TableType) => {
                    const getInvoiceData = (record: TableType) => {
                        if (record.invoice === null) {
                            return undefined;
                        }
                        if (record.reversalInvoice) {
                            const {
                                invoiceNumberPrefix,
                                invoiceNumber,
                                id,
                            } = record.reversalInvoice;

                            return {
                                id,
                                invoiceNumber,
                                invoiceNumberPrefix,
                                // canceled: Boolean(record.canceled !== null),
                                isReversalInvoice: true,
                            };
                        }

                        const {
                            invoiceNumberPrefix,
                            invoiceNumber,
                            id,
                        } = record.invoice;

                        return {
                            id,
                            invoiceNumber,
                            invoiceNumberPrefix,
                            // canceled: Boolean(record.canceled !== null),
                            isReversalInvoice: false,
                        };
                    };

                    const invoiceData = getInvoiceData(record);

                    if (invoiceData === undefined) {
                        return <div>{"-"}</div>;
                    }

                    return <RenderInvoicePreview invoiceData={invoiceData} />;
                };
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "invoiceNumber":
                path = "invoice.invoiceNumber";
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderTextStandard(
                        isDefined(record.invoice?.invoiceNumber)
                            ? `${record.invoice?.invoiceNumberPrefix} - ${record.invoice?.invoiceNumber}`
                            : "-",
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "paymentType":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderPaymentType(record.paymentType);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "courseLessons":
                c.render = (text, record: TableType) =>
                    renderCourseLessons(record.courseLessons[0].course.id);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "isSpecial":
                c.align = "center";
                c.render = (text, record: TableType) => {
                    const courseLessonCount = record.courseLessons.length;
                    const coursePrice =
                        record.courseLessons[0].course.paymentInterval ===
                        PaymentInterval.PerLesson
                            ? record.coursePrice.grossPrice * courseLessonCount
                            : record.coursePrice.grossPrice;

                    return renderBooleanAsCheckmark(
                        Boolean(
                            // record.isSpecial ||
                            record.grossPrice !== coursePrice,
                        ),
                    );
                };
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "boolean",
                });
                c.sorter = false;
                break;
            case "emailNotifications":
                c.width = 160;
                c.render = (text, record: TableType) =>
                    renderEmailNotifications(record.emailNotifications);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "selectMultiple",
                    selectOptions: [
                        {
                            label: "Buchungsbestätigung",
                            value: "BookingConfirmation",
                        },
                        {
                            label: "Wartelistenbestätigung",
                            value: "WaitingListConfirmation",
                        },
                        {
                            label: "Rechnung",
                            value: "SettlementConfirmation",
                        },
                        {
                            label: "Stornierung",
                            value: "SettledBookingCancellation",
                        },
                    ],
                });
                c.sorter = false;
                break;
            case "bookingConfirmationSent":
                c.width = 160;
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderBooleanAsCheckmark(
                        Boolean(record.bookingConfirmationSent),
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Versendet",
                            value: "true",
                        },
                        {
                            label: "Nicht versendet",
                            value: "false",
                        },
                    ],
                });
                c.sorter = false;
                break;
            case "waitingListConfirmationSent":
                c.width = 170;
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderBooleanAsCheckmark(
                        Boolean(record.waitingListConfirmationSent),
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Versendet",
                            value: "true",
                        },
                        {
                            label: "Nicht versendet",
                            value: "false",
                        },
                    ],
                });
                c.sorter = false;
                break;
            case "invoiceSent":
                c.width = 100;
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderBooleanAsCheckmark(Boolean(record.invoiceSent));
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Versendet",
                            value: "true",
                        },
                        {
                            label: "Nicht versendet",
                            value: "false",
                        },
                    ],
                });
                c.sorter = false;
                break;
            case "cancelationSent":
                c.width = 180;
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderBooleanAsCheckmark(Boolean(record.cancelationSent));
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Versendet",
                            value: "true",
                        },
                        {
                            label: "Nicht versendet",
                            value: "false",
                        },
                    ],
                });
                c.sorter = false;
                break;
            case "nonDisclosureNotice":
                c.render = (text, record: TableType) =>
                    renderContinuousText(
                        record.attendee?.booker?.nonDisclosureNotice ?? "-",
                    );
                break;
            case "dateOfBooking":
                c.width = 140;
                c.render = (text, record: TableType) =>
                    renderDate(record.createDate, true);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                c.sorter = true; // THIS DOES NOT WORK
                break;
            case "noticeBooker":
                c.render = (text, record: TableType) =>
                    renderContinuousText(text ?? "-");
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "credit":
                c.title = i18n.views.TableColumnTitles.bookerCredit();
                c.render = (text, record: TableType) =>
                    renderAmount(record.attendee?.booker?.credit ?? 0);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "number",
                });
                c.sorter = false;
                break;
            case "instructors":
                c.render = (text, record: TableType) =>
                    renderInstructors(
                        record.courseLessons[0].course.instructors,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "lessonCount":
                c.render = (text, record: TableType) =>
                    renderTextStandard(
                        record.courseLessons[0].course.lessonCount,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "number",
                });
                c.sorter = false;
                break;
            case "canceled":
                c.render = (text, record: TableType) =>
                    renderDate(record.canceled, false);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "date",
                });
                c.sorter = false;
                break;
            case "paymentMethod":
                c.render = (text, record: TableType) =>
                    renderPaymentMethod(record.paymentMethod);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Überweisung",
                            value: PaymentTransferType.BankTransfer,
                        },
                        {
                            label: "Lastschrift",
                            value: PaymentTransferType.SepaDebit,
                        },
                        {label: "Bar", value: PaymentTransferType.Cash},
                    ],
                });
                c.sorter = false;
                break;
            case "additionalInfo":
                c.render = (text, record: TableType) =>
                    renderAdditionalInfo({
                        additionalInfo: record.additionalInfo,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    // type: "search",
                    type: "select",
                    selectOptions: [
                        {
                            label: "Mit Zusatzinfo",
                            value: "true",
                        },
                        {
                            label: "Ohne Zusatzinfo",
                            value: "false",
                        },
                    ],
                });
                break;
            default:
                c.fixed = false;
                c.render = (text) => renderUnknown(text);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
        }
        // @ts-ignore
        columns.push(c);
    });

    // getColumnsToShow(columnsWithDataIndex);
    getColumnsToShow(columns);

    return shownColumns;
};

// ACTION BUTTONS---------------------------------------------------------------------------

const renderActions = ({
    record,
    editLink,
    detailsLink,
    actionColumn,
    selectedRowKeys,
    refetch,
}: RenderActionsProps) => {
    return (
        <RenderActions
            record={record}
            editLink={editLink}
            detailsLink={detailsLink}
            actionColumn={actionColumn}
            selectedRowKeys={selectedRowKeys}
            refetch={refetch}
            waitListCountFromBooking={record.waitListCount ?? undefined}
        />
    );
};
