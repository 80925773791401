import React, {FC, memo} from "react";
import classnames from "classnames";
import css from "./Title.less";
import {isDefined} from "../../helpers/typeScriptHelpers";

type TitleProps = {
    /** Determines the level of the title (renders as `h` element) */
    level?: 1 | 2 | 3 | 4 | 5 | 6;

    children?: React.ReactNode;

    /** A subline beneath the headline */
    subtitle?: React.ReactNode;

    className?: string;
};

const Title: FC<TitleProps> = ({level = 1, subtitle, children, className}) => {
    const TitleTag = `h${level}`;

    if (isDefined(subtitle) === false) {
        return (
            <TitleTag className={classnames(css.title, className)}>
                {children}
            </TitleTag>
        );
    }

    return (
        <header className={css.root}>
            <TitleTag className={classnames(css.title, className)}>
                {children}
            </TitleTag>
            <p className={css.subtitle}>{subtitle}</p>
        </header>
    );
};

export default memo(Title);
