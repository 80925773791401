import {TableType as AttendeeTableType} from "../../containers/customers/AttendeesOverviewColumns";
import {CurrencyShort} from "../renderAmount";
import {isDefined} from "../typeScriptHelpers";
import {
    csvArray,
    csvBirthdayAsAge,
    csvBoolean,
    csvCustomerNumber,
    csvDate,
    csvGender,
} from "./TableColumnExports";

export const attendeeValues = (
    key: string,
    row: AttendeeTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    // eslint-disable-next-line max-params
): string => {
    // console.log("row", row);

    const bookings = isDefined(row.bookings) ? row.bookings : [];

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "customerNumber":
            return csvCustomerNumber(
                row.customerNumber,
                row.customerNumberPrefix,
            );
        case "firstname":
            return `${row.firstname}`;
        case "lastname":
            return `${row.lastname}`;
        case "gender":
            return csvGender(row.gender);
        case "annotation":
            return `${row.annotation}`;
        case "addition":
            return `${row.addition}`;
        case "birthday":
            return csvDate(row.birthday);
        case "age":
            return csvBirthdayAsAge(row.birthday);
        case "bookings":
            return csvArray(
                bookings.map(
                    (booking) =>
                        `${booking.courseLessons[0].course.prefixedCourseNumber}`,
                ),
            );
        case "member":
            return csvBoolean(Boolean(row.member));
        case "bookingsKo9":
            return csvArray(
                bookings.map(
                    (booking) =>
                        `${booking.courseName} - ${csvDate(
                            booking.firstCourseLesson,
                        )}`,
                ),
            );
        case "bookerFullName":
            return `${row.booker.firstname} ${row.booker.lastname}`;
        default:
            return notExportable;
    }
};
