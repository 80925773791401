import React, {FC, memo} from "react";
import {Card} from "antd";
import css from "./TransferMultipleAttendees.less";
import CourseList from "../../../courses/CourseEdit/CourseList";
import {
    GetCoursesWithPaginationQuery,
    GetCoursesWithPaginationQueryVariables,
    TablesEnum,
    useGetCoursesWithPaginationQuery,
} from "../../../../generated/graphql";
import {useLocalStorageOptions} from "../../../../helpers/columnsLocalStorageHandler";
import {TableType} from "../../../courses/CourseEdit/CourseListColumns";
import SelectedCourseDetails from "./SelectedCourseDetails";

type TransferCourseSelectorProps = {
    currentCourseId: string;
    selectedCourse: TableType | null;
    setSelectedCourse: (course: TableType) => void;
};

const TransferCourseSelector: FC<TransferCourseSelectorProps> = ({
    currentCourseId,
    selectedCourse,
    setSelectedCourse,
}) => {
    const [courseSelectorOpen, setCourseSelectorOpen] = React.useState(
        Boolean(!selectedCourse),
    );
    const tablesEnum = TablesEnum.TransferCourseSelector;
    const [totalResults, setTotalResults] = React.useState<number>(0);
    const localStorageName = tablesEnum;
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName,
        defaultOptions: {},
        totalResults,
    });

    const {data, loading, error, refetch} = useGetCoursesWithPaginationQuery({
        variables: {
            options: options as GetCoursesWithPaginationQueryVariables["options"],
        },
        fetchPolicy: "network-only",
    });

    React.useEffect(() => {
        totalResults !== data?.coursesWithPagination?.total &&
            setTotalResults(data?.coursesWithPagination?.total ?? 0);
    }, [data, totalResults]);

    const fetchedResults:
        | GetCoursesWithPaginationQuery["coursesWithPagination"]
        | undefined = data?.coursesWithPagination;

    const coursesWithoutCurrentCourse = fetchedResults?.items?.filter(
        (course) => course.id !== currentCourseId,
    );

    const tableData = {
        dataSource: coursesWithoutCurrentCourse ?? [],
        // dataSource: fetchedResults?.items ?? [],
        existMore: fetchedResults?.existsMore ?? false,
        total: fetchedResults?.total ?? 0,
        loading,
    };

    const handleCourseSelection = (selectedCourses: Array<TableType>) => {
        setSelectedCourse(selectedCourses[0]);
        setCourseSelectorOpen(false);
    };

    if (courseSelectorOpen) {
        return (
            <Card
                title={<div className={css.title}>{"Zielkurs wählen"}</div>}
                className={css.transferCourseSelector}
            >
                <CourseList
                    tableData={tableData}
                    options={options}
                    setOptions={setOptions}
                    refetch={refetch}
                    tablesEnum={tablesEnum}
                    defaultPageSize={10}
                    bookingCourse
                    toolBarButtons={["columnSelector"]}
                    hiddenColumns={["actions"]}
                    defaultColumns={[
                        "prefixedCourseNumber",
                        "courseType",
                        "firstCourseLesson",
                        "location",
                        "freePlaces",
                        "placeReservationsCount",
                    ]}
                    handleCourseSelection={handleCourseSelection}
                />
            </Card>
        );
    }

    return (
        <SelectedCourseDetails
            className={css.selectedCourseDetails}
            courseId={selectedCourse?.id}
            setCourseSelectorOpen={setCourseSelectorOpen}
        />
    );
};

export default memo(TransferCourseSelector);
