import {format, Locale} from "date-fns";
import {de} from "date-fns/locale";

type LocalesType = {
    [name: string]: Locale;
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
const formatDate = (date: Date, formatStr = "PP") =>
    format(date, formatStr, {
        locale: de, // locales[window.navigator.language],
    });

export default formatDate;
