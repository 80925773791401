import {TableType as LocationTableType} from "../../containers/locations/LocationOverview/LocationOverviewTableColumns";
import {csvArray, csvGeneralLocationContact} from "./TableColumnExports";
import {CurrencyShort} from "../renderAmount";

export const locationValues = (
    key: string,
    row: LocationTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    // eslint-disable-next-line max-params
): string => {
    // console.log("row", row);

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "abbreviation":
            return `${row.abbreviation}`;
        case "name":
            return `${row.name}`;
        case "city":
            return `${row.city}`;
        case "email":
            return `${row.generalLocationContact.email}`;
        case "phoneNumbers":
            return `${row.generalLocationContact.phoneNumbers}`;
        case "venues":
            return csvArray(row.venues.map((venue) => venue.name));
        case "generalLocationContact":
            return csvGeneralLocationContact(row.generalLocationContact);
        case "courses":
            return csvArray(
                row.courses.map((course) => course.prefixedCourseNumber),
            );
        case "instructors":
            return csvArray(
                row.instructors.map(
                    (instructor) =>
                        `${instructor.firstname} ${instructor.lastname}`,
                ),
            );
        case "phoneNumber":
            return `${row.generalLocationContact.phoneNumber}`;

        default:
            return notExportable;
    }
};
