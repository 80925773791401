export const getBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            const result = reader.result as string;
            const base64Data = result.split(",")[1]; // Split the result on the comma and get the second part

            resolve(base64Data); // Resolve with just the Base64 data
            reader.abort(); // Cleanup
        };

        reader.onerror = (error) => {
            reader.abort(); // Cleanup
            reject(new Error(`Failed to read file: ${error.message}`));
        };
    });
};
