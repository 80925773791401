import {SettingOutlined} from "@ant-design/icons";
import {Button, Checkbox, Form, InputNumber, Popover, Space} from "antd";
import React, {FC, memo} from "react";
import {blue} from "@ant-design/colors";
import {ArrayDataOptions, BookingType} from "../../../generated/graphql";

export type SettingProps = {
    start: string;
    end: string;
    days: number;
    toShow: Array<BookingType>;
};

type CardBookingsStartedCoursesMenuProps = {
    options?: ArrayDataOptions;
    setOptions: (options: ArrayDataOptions) => void;
    days: number;
    refetch: () => void;
};

const toolTipWidth = 300;

const CardBookingsStartedCoursesMenu: FC<CardBookingsStartedCoursesMenuProps> = ({
    options,
    setOptions,
    days,
    refetch,
}) => {
    const [open, setOpen] = React.useState(false);
    const currentFilters = options?.filter ?? [];
    const currentBookingTypesFilter = currentFilters.filter(
        (f) => f.column === "bookingType",
    );

    const description =
        "Hier kannst du einstellen, wie viele Tage in die Vergangenheit geschaut werden sollen, um Buchungen zu finden, die nach dem Kursstart getätigt wurden.";

    const handleDaysChange = (value: number | undefined | null) => {
        if (value) {
            const endDate = new Date();
            const startDate = new Date(endDate);

            startDate.setDate(endDate.getDate() - value);

            const currentFilter: ArrayDataOptions["filter"] =
                options?.filter ?? [];
            const currentFiltersWithoutDateOfBooking = currentFilter.filter(
                (f) => f.column !== "dateOfBooking",
            );

            const newOptions: ArrayDataOptions = {
                ...options,
                filter: [
                    ...currentFiltersWithoutDateOfBooking,
                    {
                        column: "dateOfBooking",
                        table: "bookings",
                        values: [
                            startDate.toISOString(),
                            endDate.toISOString(),
                        ],
                    },
                ],
            };

            setOptions(newOptions);
            refetch();
        }
    };

    const handleBookingTypeChange = (type: BookingType) => {
        // Find the existing bookingType filter
        const bookingTypeFilter = currentFilters.find(
            (f) => f.column === "bookingType",
        );

        // Determine the new set of booking types
        const newBookingTypes: Array<string> = bookingTypeFilter
            ? bookingTypeFilter.values.includes(type)
                ? bookingTypeFilter.values.filter((t) => t !== type) // Remove the type
                : [...bookingTypeFilter.values, type] // Add the type
            : [type]; // Initialize with the type if no filter exists

        // Create the updated filters array
        const newFilters =
            newBookingTypes.length > 0
                ? [
                      ...currentFilters.filter(
                          (f) => f.column !== "bookingType",
                      ), // Remove existing bookingType filter
                      {
                          column: "bookingType",
                          table: "bookings",
                          values: newBookingTypes.map(String),
                      },
                  ]
                : currentFilters.filter((f) => f.column !== "bookingType"); // Remove bookingType filter if no types are selected

        // Update the options with the new filters
        setOptions({
            ...options,
            filter: newFilters,
        });

        // Trigger a refetch to apply the new filters
        refetch();
    };

    const checkBoxoptions = [
        {label: "Buchungen", value: BookingType.Booking},
        {
            label: "Wartelisteneinträge",
            value: BookingType.Waitlist,
        },
    ];

    return (
        <Popover
            open={open}
            title="Einstellungen"
            trigger={["click"]}
            placement="left"
            destroyTooltipOnHide={true}
            style={{paddingBottom: "0 !important"}}
            content={
                <Form
                    layout="vertical"
                    colon={false}
                    // initialValues={settings}
                    style={{marginBottom: -10}}
                >
                    <div
                        style={{
                            whiteSpace: "pre-line",
                            width: toolTipWidth - 30,
                            padding: "5px 7px",
                            border: `1px solid ${blue[1]}`,
                            borderRadius: "5px",
                            marginBottom: "10px",
                            marginTop: 5,
                        }}
                    >
                        {description}
                    </div>
                    <Form.Item>
                        <Space
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <InputNumber
                                value={days}
                                onChange={(value) =>
                                    handleDaysChange(Number(value))
                                }
                            />

                            <div>{"Tage"}</div>
                        </Space>
                    </Form.Item>
                    <Checkbox.Group
                        value={currentBookingTypesFilter.flatMap(
                            (f) => f.values,
                        )}
                    >
                        {checkBoxoptions.map((option) => {
                            return (
                                <Checkbox
                                    key={option.label}
                                    value={option.value}
                                    onChange={() =>
                                        handleBookingTypeChange(option.value)
                                    }
                                >
                                    {option.label}
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={() => setOpen(false)}
                            block
                            style={{marginTop: 30, marginBottom: -30}}
                        >
                            {"OK"}
                        </Button>
                    </Form.Item>
                </Form>
            }
        >
            <Button
                icon={<SettingOutlined />}
                type="text"
                onClick={() => setOpen(!open)}
            />
        </Popover>
    );
};

export default memo(CardBookingsStartedCoursesMenu);
