import {Layout} from "antd";
import React, {FC, memo} from "react";
import {Link} from "react-router-dom";
import LogoTypographic from "../../assets/logo-typographic.svg";
import css from "./LoginHeader.less";
import {URL_HOME} from "../../models/url";

type Link = {
    href: string;
    label: string;
};

type LoginHeaderProps = {
    link: Link;
    children?: React.ReactNode;
    style?: React.CSSProperties;
};

const LoginHeader: FC<LoginHeaderProps> = ({
    link = {href: "/", label: "home"},
    children,
    style,
}) => {
    return (
        <Layout.Header className={css.root} style={style}>
            <div className={css.innerHeader}>
                {/* <a className={css.link} href={link.href}>
                    {link.label}
                </a> */}
                <div />
                <Link
                    to={{
                        pathname: URL_HOME,
                    }}
                >
                    <img
                        src={LogoTypographic}
                        alt="logo-typographic"
                        className={css.logoTypographic}
                    />
                </Link>
            </div>
            {children}
        </Layout.Header>
    );
};

export default memo(LoginHeader);
