import React, {FC, useContext, useState} from "react";
import {Button, Divider, Upload, message} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/lib/upload";
import {ORGANIZATION_ID} from "../../../const";
import {URIContext} from "../../common/ApolloWrapper";
import {SwissQRFileUploadResult} from "../../../../server/swissQRFile/swissQRFile.inputTypes";
import {TableType} from "./SwissQrCamtFIleUpload";
import {
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
} from "../../../generated/graphql";

export type SingleUploadRecord = {
    id: string;
    valutaDate: string;
    amount: number;
    swissQrRef: string;
    successful: boolean;
    swissQRFile: {
        fileId: string;
        fileContent: string;
        koOrganizationId: string;
    };
    error: string;
};

type FileUploadComponentProps = {
    liftedResult: (result: Array<TableType> | undefined) => void;
    setModalOpen: (open: boolean) => void;
};

const FileUploadComponent: FC<FileUploadComponentProps> = ({
    liftedResult,
    setModalOpen,
}) => {
    const [file, setFile] = useState<
        UploadChangeParam["file"]["originFileObj"] | undefined
    >();
    const [uploading, setUploading] = useState(false);
    const [uploadResult, setUploadResult] = useState<SwissQRFileUploadResult>();

    const uri = useContext(URIContext);

    const onFileChange = (info: UploadChangeParam) => {
        setFile(undefined);
        setUploadResult(undefined);
        setFile(info.file.originFileObj);
        liftedResult(undefined);
    };

    const onRemove = () => {
        console.log("onRemove");
        setFile(undefined);
        setUploadResult(undefined);
        liftedResult(undefined);
        console.log("file", file);
        console.log("uploadResult", uploadResult);
    };

    const onUpload = async ({analysis}: {analysis: boolean}) => {
        const operations = {
            query: `
                mutation UploadSingleCamtFile($file: Upload!, $analysis: Boolean) {
                    singleUpload(file: $file, analysis: $analysis)
                }
            `,
            variables: {
                file: null,
                analysis,
            },
            refetchQueries: [
                "GetPaidScheduledPayments",
                {query: GetPaidScheduledPaymentsDocument},
                "GetUnpaidScheduledPayments",
                {
                    query: GetUnpaidScheduledPaymentsDocument,
                    variables: {options: {}},
                },
                // "GetBookingById",
                // {query: GetBookingByIdDocument, variables: {id: record.booking.id}},
            ],
        };

        const map = {
            "0": ["variables.file"],
        };

        const formData = new FormData();

        formData.append("operations", JSON.stringify(operations));
        formData.append("map", JSON.stringify(map));
        formData.append("0", file);

        try {
            setUploading(true);

            const response: Response = await fetch(uri, {
                method: "POST",
                body: formData,
                headers: {
                    "x-ko-organization-id": ORGANIZATION_ID,
                },
                credentials: "include",
            });

            const data = await response.json();

            console.log("data::::", data);

            if (data.errors) {
                console.log("data.errors", data.errors);
                const errorMessage = data.errors[0].message;

                if (errorMessage.includes("File already exists")) {
                    message.error("Diese Datei wurde bereits hochgeladen.");
                } else {
                    message.error(data.errors[0].message);
                }

                return;
            }

            setUploadResult(data);
            liftedResult(data.data.singleUpload.records);

            if (data?.data) {
                if (analysis) {
                    message.success("Datei erfolgreich geprüft");
                } else {
                    message.success("Datei erfolgreich hochgeladen");
                }
            } else if (data.errors) {
                console.log("data", data);
                console.log("data.errors", data.errors);
                message.error(data.errors[0].message);
            }
            setUploading(false);
            if (!analysis) {
                setModalOpen(false);
            }
        } catch (e) {
            console.error(e);
            message.error(e.toString());
        } finally {
            setUploading(false);
        }
    };

    return (
        <div>
            <Divider orientation="left">
                {"Zu importierende Datei wählen"}
            </Divider>
            <Upload onChange={onFileChange} maxCount={1} onRemove={onRemove}>
                <Button
                    style={{
                        width: "calc(1200px - 50px)",
                    }}
                    block
                    icon={<UploadOutlined />}
                >
                    {"Datei wählen"}
                </Button>
            </Upload>
            {Boolean(file) && Boolean(!uploadResult) && (
                <Button
                    block
                    disabled={Boolean(uploadResult)}
                    onClick={async () => onUpload({analysis: true})}
                    loading={uploading}
                >
                    {uploading ? "prüfe..." : "Datei prüfen"}
                </Button>
            )}
            {file && uploadResult && (
                <Button
                    block
                    type="primary"
                    disabled={Boolean(!uploadResult)}
                    onClick={async () => onUpload({analysis: false})}
                    loading={uploading}
                >
                    {uploading ? "lade hoch..." : "Datei hochladen"}
                </Button>
            )}
        </div>
    );
};

export default FileUploadComponent;
