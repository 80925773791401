const layoutBase = 5;
const typoBase = 8 / 16;
const typoRatio = 6 / 5;

export const layoutScale = (factor: number): string => {
    // Round to whole device pixels
    const result = layoutBase * factor;

    return result + "px";
};

export const typoScale = (factor: number): string => {
    const result = typoRatio ** factor * typoBase;

    return result + "rem";
};
