import {Badge, Space, Table, Tooltip} from "antd";
import {ColumnProps, TablePaginationConfig} from "antd/lib/table";
import React, {FC, memo, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {blue, orange} from "@ant-design/colors";
import {
    Course,
    CourseLesson,
    GetCourseLessonsByCourseIdQuery,
    GetHolidaysByCompanyAndDateRangeQuery,
    PaymentInterval,
    useGetCourseLessonsByCourseIdQuery,
    useGetHolidaysByCompanyAndDateRangeQuery,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {
    renderAmount,
    renderDateAsWeekdayTag,
    renderDateFormatted,
    renderStartEndTime,
} from "../TableColumnRenderers/TableColumnRenderers";
import css from "./CourseLessonTable.less";
import {sortByStartDateTime} from "../../helpers/sortingFunctions";
import {QueryItem} from "../../types";
import {RowIndex} from "../../containers/courses/CourseViews/CourseLessonViews";

type CourseLessonType = Pick<
    CourseLesson,
    "id" | "startDateTime" | "endDateTime" | "locationId"
>;

type CourseLessonTableProps = {
    courseId: Course["id"];
    totalPrice?: number;
    paymentInterval?: PaymentInterval;
    freePlaces?: number;
    defaultPageSize?: number;
};

type GetCourseLessonsByCourseIdQueryItem = QueryItem<
    GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]
>;

type TableType = GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]["0"];

const CourseLessonTable: FC<CourseLessonTableProps> = ({
    courseId,
    // courseLessons,
    totalPrice,
    paymentInterval,
    freePlaces,
    defaultPageSize = 10,
}) => {
    const {data, loading} = useGetCourseLessonsByCourseIdQuery({
        variables: {courseId},
    });
    const [paginationConfig, setPaginationConfig] = useState<
        TablePaginationConfig
    >({
        current: 1,
        pageSize: defaultPageSize,
        total: 0,
    });
    const [holidays, setHolidays] = useState<
        GetHolidaysByCompanyAndDateRangeQuery["holidaysByCompanyAndDateRange"]
    >([]);
    const courseLessons: Array<GetCourseLessonsByCourseIdQueryItem> =
        data?.courseLessonsByCourseId ?? [];

    const pricePerLesson = totalPrice ? totalPrice / courseLessons.length : 0;

    const pageSize = paginationConfig.pageSize ?? 10;
    const currentPage = paginationConfig.current ?? 1;

    // HANDLE HOLIDAYS ---------------------------------------------------------------------------------

    const startAndEndDates = (): {start: string; end: string} | undefined => {
        if (courseLessons.length === 0) {
            return undefined;
        }
        const start = moment(courseLessons[0].startDateTime)
            .startOf("day")
            .format();
        const end = moment(courseLessons[courseLessons.length - 1].endDateTime)
            .endOf("day")
            .format();

        return {start, end};
    };

    const {
        data: holidayData,
        loading: loadingHoldiays,
    } = useGetHolidaysByCompanyAndDateRangeQuery({
        skip: !startAndEndDates(),
        variables: {
            start: startAndEndDates()?.start,
            end: startAndEndDates()?.end,
        },
    });

    useEffect(() => {
        if (holidayData && !loadingHoldiays) {
            setHolidays(holidayData.holidaysByCompanyAndDateRange);
        }
    }, [holidayData, loadingHoldiays]);

    const getHolidayInfo = (
        date: Moment,
    ):
        | GetHolidaysByCompanyAndDateRangeQuery["holidaysByCompanyAndDateRange"][number]
        | undefined => {
        const allHolidaysOfDate = holidays.filter((holiday) => {
            const holidayStart = moment(holiday.start).startOf("day");
            const holidayEnd = moment(holiday.end).endOf("day");

            return (
                moment(date).isSameOrAfter(holidayStart) &&
                moment(date).isSameOrBefore(holidayEnd)
            );
        });

        const customHoliday = allHolidaysOfDate.find((holiday) => {
            return holiday.stateCode === "CUSTOM";
        });

        const holidayToShow = customHoliday ?? allHolidaysOfDate[0];

        return holidayToShow;
    };

    const renderIndex = (
        rowIndex: number,
        holiday?: GetHolidaysByCompanyAndDateRangeQuery["holidaysByCompanyAndDateRange"][number],
    ) => {
        const index = rowIndex;

        return (
            <RowIndex
                rowIndex={index}
                /* style={{...rowStyle}} */ holiday={holiday}
            />
        );
    };

    // COLUMNS ---------------------------------------------------------------------------------

    const columns: Array<ColumnProps<TableType>> = [
        {
            title: "#",
            dataIndex: "number",
            align: "center",
            render: (text, record, rowIndex) =>
                renderIndex(rowIndex, getHolidayInfo(record.startDateTime)),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "dayOfTheWeek",
            render: (text, record) =>
                renderDateAsWeekdayTag({
                    value: record.startDateTime,
                    style: {
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 600,
                    },
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "startDateTime",
            render: (text, record) =>
                renderDateFormatted(record.startDateTime, "DD. MMM YYYY"),
            // defaultSortOrder: "descend",
            sorter: sortByStartDateTime,
            sortDirections: [],
            sortOrder: "ascend",
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "times",
            render: (text, record) =>
                renderStartEndTime({
                    startTime: record.startDateTime,
                    endTime: record.endDateTime,
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "lessonPrice",
            render: (text, record) =>
                paymentInterval === PaymentInterval.PerLesson
                    ? renderAmount(pricePerLesson)
                    : null,
        },
        // {
        //     title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
        //     dataIndex: "freePlaces",
        //     render: (text, record) => renderFreePlaces(freePlaces ?? 0),
        // },
    ];

    return (
        <div className={css.root}>
            <Table
                loading={loading}
                showHeader={false}
                size="small"
                rowKey="id"
                columns={columns}
                dataSource={courseLessons}
                pagination={
                    courseLessons.length === defaultPageSize
                        ? false
                        : paginationConfig
                }
                onChange={(pagination, filters, sorter) => {
                    setPaginationConfig(pagination);
                }}
                rowClassName={css.extraSmallRow}
            />
        </div>
    );
};

export default memo(CourseLessonTable);
