import React, {memo, useState} from "react";
import {Space, Table} from "antd";
import {ColumnProps, TablePaginationConfig} from "antd/lib/table";
import {
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
} from "antd/lib/table/interface";
import {
    ArrayDataOptions,
    GetTemplatesWithPaginationQuery,
    MailDataType,
    Maybe,
    SortOrder,
    TablesEnum,
    TemplateType,
} from "../../../generated/graphql";
import css from "./TemplateList.less";
import {
    TableType,
    allColumns,
    standardColumns,
    useHandleColumns,
} from "./TemplateListColumns";
import {updateFilters} from "../../../helpers/updateFilters";
import {getPagination} from "../../../helpers/getPagination";
import TableToolBar from "../../common/tableTools/TableToolBar";
import ActiveFilters from "../../common/tableTools/ActiveFilters";

const defaultPageSize = 15;

type TemplateListProps = {
    templates?: GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"];
    total?: number;
    onClick?: (template: TableType) => void;
    pickedTemplateId?: string;
    showEmailEditor?: boolean;
    options?: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    tablesEnum: TablesEnum;
    refetch: () => void;
    mailDataType?: MailDataType;
};

const TemplateList: React.FC<TemplateListProps> = ({
    templates,
    total,
    onClick,
    pickedTemplateId,
    showEmailEditor,
    options,
    setOptions,
    tablesEnum,
    refetch,
}) => {
    const dataSource: Array<TableType> | undefined = templates?.map(
        (template) => {
            return {
                id: template.id,
                name:
                    template.templateType === TemplateType.NoTemplate
                        ? "Ohne Vorlage"
                        : template.name,
                templateType: template.templateType,
                isDefault: template.isDefault,
                mailDataType: template.mailDataType,
                subject: template.subject,
                updateDate: template.updateDate,
                createDate: template.createDate,
            };
        },
    );

    const [columnsToShow, setColumnsToShow] = useState<
        Array<Extract<keyof TableType, string>>
    >(standardColumns);

    const columns: Array<ColumnProps<TableType>> = useHandleColumns(
        columnsToShow,
        options,
        setOptions,
    );

    const onChange: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TableType> | Array<SorterResult<TableType>>,
        extra?: TableCurrentDataSource<TableType>,
    ) => void = (pagination, filters, sorter, extra) => {
        const updatedFilters = updateFilters({
            filters,
            options,
            table: "booking",
        });

        // console.log("updatedFilters:::", updatedFilters);
        // console.groupEnd();

        const sortColumn = sorter.field;
        const sortOrder: Maybe<SortOrder> =
            sorter.order === "ascend"
                ? SortOrder.Ascending
                : sorter.order === "descend"
                ? SortOrder.Descending
                : undefined;

        const updatedOptions = {
            ...options,
            filter: updatedFilters,
            limit: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).limit,
            offset: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).offset,
            sortColumn,
            sortOrder,
        };

        setOptions(updatedOptions);
    };

    return (
        <>
            <Space
                id="targetElement"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    padding: "2px 0px",
                }}
            >
                {dataSource?.length ? (
                    <TableToolBar
                        columns={columns}
                        allColumns={allColumns()}
                        variableKeys={allColumns()}
                        columnsToShow={columnsToShow}
                        setSelectedColumns={(e: Array<keyof TableType>) =>
                            setColumnsToShow(e)
                        }
                        dataSource={dataSource}
                        tablesEnum={tablesEnum}
                        defaultColumns={standardColumns}
                    />
                ) : (
                    <div>{""}</div>
                )}
                <ActiveFilters
                    options={options}
                    setOptions={setOptions}
                    refetch={refetch}
                />
            </Space>
            <Table<TableType>
                // id="targetElement"
                dataSource={dataSource}
                rowKey="id"
                columns={columns}
                onChange={onChange}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (onClick) {
                                onClick(record);
                            }
                        },
                    };
                }}
                rowClassName={(record) => {
                    return record.id === pickedTemplateId && showEmailEditor
                        ? css.selectedRow
                        : css.defaultRow;
                }}
                pagination={{
                    current:
                        options?.offset && options?.limit
                            ? options.offset / options.limit + 1
                            : 1,
                    pageSize: options?.limit ?? defaultPageSize,
                    // showSizeChanger: true,
                    // pageSizeOptions: ["10", "15", "20", "25"],
                    defaultPageSize,
                    total,
                    hideOnSinglePage: true,
                    size: "small",
                }}
                // scroll={
                //     dataSource?.length
                //         ? {
                //               x: `max-content`,
                //               y: "calc(100vh - 280px)",
                //           }
                //         : {x: "auto"}
                // }
                size="small"
            />
        </>
    );
};

export default memo(TemplateList);
