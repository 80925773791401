import React, {FC, memo, useEffect, useState} from "react";
import * as Yup from "yup";
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, Space, Button} from "antd";
import {Formik, FormikActions} from "formik";
import {createInputHelpers} from "../../../helpers/createInputHelpers";
import LayoutForm from "../../../views/LayoutForm/LayoutForm";
import {isDefined} from "../../../helpers/typeScriptHelpers";
import i18n from "../../../services/i18n";
import {
    BookerPaymentMethodInput,
    GetBankDataQuery,
    useGetBankDataQuery,
} from "../../../generated/graphql";

type ModalDataType = Pick<
    BookerPaymentMethodInput,
    "sepaAccountholder" | "iban" | "bic" | "bank"
>;

const {string, object} = Yup;

const SepaDebitValidationSchema = object().shape<ModalDataType>({
    sepaAccountholder: string().required(
        i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.accountHolder.help(),
    ),
    iban: string().required(
        i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.iban.help(),
    ),
    bic: string().required(
        i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.bic.help(),
    ),
    bank: string().required(
        i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.bank.help(),
    ),
});

type SepaDebitPaymentMethodModalProps = {
    initialValues?: ModalDataType;
    onSubmit?: (
        values: ModalDataType,
        formikActions: FormikActions<ModalDataType>,
    ) => void;
    onCancel?: () => void;
    visible?: boolean;
    asModal?: boolean;
};

const defaultInitialValues: ModalDataType = {
    sepaAccountholder: "",
    iban: "",
    bic: "",
    bank: "",
};

const SepaDebitPaymentMethodModal: FC<SepaDebitPaymentMethodModalProps> = ({
    initialValues = defaultInitialValues,
    onSubmit = () => undefined,
    onCancel = () => undefined,
    visible = false,
    asModal = true,
}) => {
    // fetching bank data from iban --------------------------------------------------------------------
    const [iban, setIban] = useState<string>();
    const [bankData, setBankData] = useState<
        GetBankDataQuery["getBankData"]["bankData"]
    >(null);
    const {data: fetchedBankData} = useGetBankDataQuery({
        skip: !iban,
        variables: {iban: iban ?? ""},
        fetchPolicy: "network-only",
    });

    const fetchedBankDataIsValid = fetchedBankData?.getBankData?.valid === true;

    console.log(fetchedBankData?.getBankData?.valid === true);
    useEffect(() => {
        if (fetchedBankData?.getBankData?.valid === true) {
            setBankData(fetchedBankData?.getBankData?.bankData);
        } else {
            setBankData(null);
        }
    }, [fetchedBankData]);
    // -------------------------------------------------------------------------------------------------

    const renderContent = (formikProps, createInputHelpers) => {
        const {values, touched, setFieldValue, handleBlur} = formikProps;
        const {
            handleTextInputChange,
            validateStatus,
            help,
        } = createInputHelpers(formikProps);

        return (
            <LayoutForm columns={2}>
                <Form.Item
                    label={i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.accountHolder.label()}
                    validateStatus={validateStatus("sepaAccountholder")}
                    help={help("sepaAccountholder")}
                    style={{gridColumn: "span 2"}}
                    required
                >
                    <Input
                        data-testid="SepaDebitPaymentMethodModalAccountHolder"
                        onChange={handleTextInputChange("sepaAccountholder")}
                        onBlur={handleBlur("sepaAccountholder")}
                        value={
                            isDefined(values.sepaAccountholder)
                                ? values.sepaAccountholder
                                : ""
                        }
                    />
                </Form.Item>
                <Form.Item
                    label={i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.iban.label()}
                    help={
                        fetchedBankDataIsValid ||
                        fetchedBankData?.getBankData === undefined
                            ? undefined
                            : isDefined(values.iban) === false
                            ? formikProps.touched.iban
                                ? i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.iban.help()
                                : undefined
                            : i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.iban.invalid()
                    }
                    validateStatus={
                        touched.iban === undefined
                            ? undefined
                            : isDefined(values.iban) === false ||
                              fetchedBankDataIsValid === false
                            ? "error"
                            : undefined
                    }
                    style={{gridColumn: "span 2"}}
                    required
                >
                    <Input
                        data-testid="SepaDebitPaymentMethodModalIban"
                        // onChange={handleTextInputChange("iban")}
                        // onBlur={handleBlur("iban")}
                        onChange={(e) => {
                            // handleTextInputChange("iban");
                            formikProps.setFieldTouched("iban", true);
                            setFieldValue("iban", e.target.value);
                            setIban(e.target.value);
                            if (bankData?.bic) {
                                setFieldValue("bic", bankData.bic);
                            }
                            if (bankData?.name) {
                                setFieldValue("bank", bankData.name);
                            }
                            setBankData(fetchedBankData?.getBankData?.bankData);
                        }}
                        onBlur={(e) => {
                            // handleTextInputChange("iban");
                            setFieldValue("iban", e.target.value);
                            setIban(e.target.value);
                            if (bankData?.bic) {
                                setFieldValue("bic", bankData.bic);
                            }
                            if (bankData?.name) {
                                setFieldValue("bank", bankData.name);
                            }
                            formikProps.setFieldTouched("iban", true);
                        }}
                        // onBlur={handleBlur("iban")}
                        value={isDefined(values.iban) ? values.iban : ""}
                    />
                </Form.Item>
                <Form.Item
                    label={i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.bic.label()}
                    validateStatus={validateStatus("bic")}
                    help={help("bic")}
                    style={{gridColumn: "span 2"}}
                    required
                >
                    <Input
                        data-testid="SepaDebitPaymentMethodModalBic"
                        onChange={handleTextInputChange("bic")}
                        onBlur={handleBlur("bic")}
                        value={isDefined(values.bic) ? values.bic : ""}
                    />
                </Form.Item>
                <Form.Item
                    label={i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.fields.bank.label()}
                    validateStatus={validateStatus("bank")}
                    help={help("bank")}
                    style={{gridColumn: "span 2"}}
                    required
                >
                    <Input
                        data-testid="SepaDebitPaymentMethodModalBank"
                        onChange={handleTextInputChange("bank")}
                        onBlur={handleBlur("bank")}
                        value={isDefined(values.bank) ? values.bank : ""}
                    />
                </Form.Item>
            </LayoutForm>
        );
    };

    return (
        <Formik<ModalDataType>
            validationSchema={SepaDebitValidationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, formikActions) => {
                onSubmit(values, formikActions);
                formikActions.resetForm();
            }}
        >
            {(formikProps) => {
                const {submitForm, resetForm} = formikProps;

                if (asModal) {
                    return (
                        <Modal
                            wrapProps={{
                                "data-testid": "SepaDebitPaymentMethodModal",
                            }}
                            title={i18n.containers.customers.CustomersCreate.steps.bookerPaymentMethods.modals.sepaDebit.title()}
                            // visible={visible}
                            open={visible}
                            onOk={submitForm}
                            onCancel={() => {
                                onCancel();
                                resetForm();
                            }}
                            getContainer="#modalmount"
                            zIndex={10000}
                        >
                            {renderContent(formikProps, createInputHelpers)}
                        </Modal>
                    );
                }

                return (
                    <Space direction="vertical" style={{width: "100%"}}>
                        {renderContent(formikProps, createInputHelpers)}
                        <Button block type="primary" onClick={submitForm}>
                            {"Lastschriftdaten Speichern"}
                        </Button>
                    </Space>
                );
            }}
        </Formik>
    );
};

export default memo(SepaDebitPaymentMethodModal);
