import React, {FC, memo} from "react";
import {Maybe} from "type-graphql";
import {Button, Radio} from "antd";
import {ArrayDataOptions, FilterOption} from "../../generated/graphql";
import i18n from "../../services/i18n";

type FilterDropdownSelectProps = {
    dataIndex: string;
    options?: Maybe<ArrayDataOptions>;
    setOptions?: (options: ArrayDataOptions) => void;
    setDropdownOpen?: (dropdownOpen: string | null) => void;
    selectOptions?: Array<{
        label: string;
        value: string;
    }>;
    setNewFilterOption: (column: string, value?: string) => void;
    resetSelectedFilter: (column: string) => void;
};

const FilterDropdownSelect: FC<FilterDropdownSelectProps> = ({
    dataIndex,
    options,
    setOptions,
    setDropdownOpen,
    selectOptions,
    setNewFilterOption,
    resetSelectedFilter,
}) => {
    const [selectedKeys, setSelectedKeys] = React.useState<Array<string>>([]);

    const panelWidth = 168;

    const placeholder = (dataIndex: string) => {
        return `${i18n.views.TableColumnTitles[dataIndex]} suchen`;
    };

    const isChecked = ({
        value,
        selectedKey,
        currentValue,
    }: {
        value?: string;
        selectedKey?: string;
        currentValue?: string;
    }) => {
        if (value === selectedKey) {
            return true;
        } else if (selectedKey === undefined && value === currentValue) {
            return true;
        }

        return false;
    };

    const renderOptions = () => {
        if (selectOptions?.length) {
            return selectOptions.map((option) => {
                return (
                    <Radio
                        key={option.label}
                        value={option.value}
                        checked={isChecked({
                            value: option.value,
                            selectedKey: selectedKeys[0],
                            currentValue: currentFilteredValue,
                        })}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                    >
                        {option.label}
                    </Radio>
                );
            });
        }

        return <>{"-"}</>;
    };

    const currentFilteredValue = options?.filter?.find(
        (filter) => filter.column === dataIndex,
    )?.values[0];

    return (
        <div /* ref={ref} */ style={{padding: 8}}>
            <div
                style={{
                    width: panelWidth,
                    marginBottom: 8,
                    display: "block",
                    // padding: type === "boolean" ? 8 : 0,
                }}
            >
                {renderOptions()}
            </div>
            <Button
                type="primary"
                onClick={() => {
                    setNewFilterOption(dataIndex, selectedKeys[0]);
                }}
                size="small"
                style={{width: "100%"}}
            >
                {"Suchen"}
            </Button>
            <Button
                style={{width: "100%", marginTop: "5px"}}
                danger
                size="small"
                // disabled={!selectedKeys.length}
                disabled={!currentFilteredValue}
                onClick={() => {
                    resetSelectedFilter(dataIndex);
                }}
            >
                {"Filter entfernen"}
            </Button>
        </div>
    );
};

export default memo(FilterDropdownSelect);
