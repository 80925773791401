import {orderBy} from "lodash";
import moment from "moment";
import React, {FC, memo, useEffect, useState} from "react";
import {BookingTemplateVariables} from "../../../../shared/models/template";
import {ModeType} from "../../../containers/bookings/CreateEditBookings/BookingCreate";
import {
    Attendee,
    Booking,
    CourseLesson,
    EditBookingInput,
    NewBookingInput,
    useGetAttendeeVariableForTemplateQuery,
    useGetCourseVariablesForTemplateQuery,
    useGetVariablesForBookingWithCourseQuery,
} from "../../../generated/graphql";
import LoadingContent from "../../LoadingContent/LoadingContent";
import MjmlPreview, {MjMlOutputType} from "../MjmlPreview";
import {processTemplateData} from "./processTemplateData";
import useTemplateData from "./useTemplateData";

export type BookingDataProps = {attendeeId: string; courseId: string};

type MjmlLoaderProps = {
    data?: NewBookingInput | EditBookingInput | Partial<Booking>;
    mode?: ModeType;
    mjmlTemplate?: string;
    variables: {};
    loading?: boolean;
    bookingId?: string;
    bookingData?: BookingDataProps;
    dbTemplateId: string;
    renderWithoutCourseDetails?: boolean;
};

const MjmlLoader: FC<MjmlLoaderProps> = ({
    data,
    mode,
    mjmlTemplate,
    variables,
    loading = true,
    bookingId,
    bookingData,
    dbTemplateId,
    renderWithoutCourseDetails = false,
}) => {
    const [htmlTemplate, setHtmlTemplate] = useState<
        MjMlOutputType | undefined
    >();

    // Custom hook to manage all template related data loading and processing
    const {
        userTemplateTextBlocks,
        mailjetTemplateData,
        loadingAll,
    } = useTemplateData({
        bookingData,
        bookingId,
        dbTemplateId,
        variables,
        renderWithoutCourseDetails,
    });

    const [allVariables, setAllVariables] = useState<
        BookingTemplateVariables | undefined
    >(undefined);

    const {data: attendeeData} = useGetAttendeeVariableForTemplateQuery({
        skip: !bookingData,
        variables: {id: bookingData?.attendeeId ?? ""},
    });

    const {
        data: courseLessonDataNewBooking,
    } = useGetCourseVariablesForTemplateQuery({
        skip: !bookingData,
        variables: {courseId: bookingData?.courseId ?? ""},
    });

    const {
        data: templateVariablesData,
    } = useGetVariablesForBookingWithCourseQuery({
        skip: !bookingId,
        variables: {id: bookingId ?? ""},
    });

    type CourseLessonInput = Pick<
        CourseLesson,
        "id" | "startDateTime" | "location" | "course" | "endDateTime"
    >;
    type AttendeeInput = Pick<
        Attendee,
        "id" | "firstname" | "lastname" | "birthday" | "booker"
    >;

    useEffect(() => {
        const getAllVariables = () => {
            const reformatVariables = (
                attendee: AttendeeInput,
                inputCourseLessons: Array<CourseLessonInput>,
                firstCourseLesson: CourseLessonInput,
            ) => {
                // moment.locale("de");

                const sortedLessons = orderBy(
                    inputCourseLessons,
                    ["startDateTime"],
                    ["asc"],
                );

                const courseLessons = sortedLessons.map((lesson, index) => {
                    return {
                        date: `${moment(lesson.startDateTime).format("LL")}`,
                        time: `${moment(lesson.startDateTime).format(
                            "LT",
                        )} - ${moment(lesson.endDateTime).format("LT")}`,
                        city: lesson.location.city,
                        num: index + 1,
                        location: lesson.location.name,
                        dow: moment(lesson.startDateTime).format("dddd"),
                    };
                });

                const allVariables: BookingTemplateVariables = {
                    bookerFirst: attendee?.booker?.firstname,
                    courseType: firstCourseLesson.course.courseType.name,
                    courseNumber:
                        firstCourseLesson.course.prefixedCourseNumber ??
                        undefined,
                    attendeeFirst: attendee.firstname,
                    attendeeLast: attendee.lastname,
                    Vorname: attendee?.booker?.firstname,
                    Nachname: attendee?.booker?.lastname,
                    Alter: attendee.birthday,
                    organizationName: "string",
                    courseLessons,
                };

                setAllVariables(allVariables);
            };

            if (bookingId) {
                if (templateVariablesData) {
                    const attendee = templateVariablesData.bookingById.attendee;
                    const inputCourseLessons =
                        templateVariablesData.bookingById.courseLessons;
                    const firstCourseLesson = inputCourseLessons[0];

                    return reformatVariables(
                        attendee,
                        inputCourseLessons,
                        firstCourseLesson,
                    );
                }
            } else if (bookingData) {
                if (attendeeData && courseLessonDataNewBooking) {
                    const attendee = attendeeData.attendee;
                    const inputCourseLessons =
                        courseLessonDataNewBooking.courseLessonsByCourseId;
                    const firstCourseLesson =
                        courseLessonDataNewBooking.courseLessonsByCourseId[0];

                    return reformatVariables(
                        attendee,
                        inputCourseLessons,
                        firstCourseLesson,
                    );
                }
            }
        };

        getAllVariables();
    }, [
        attendeeData,
        bookingData,
        bookingId,
        courseLessonDataNewBooking,
        templateVariablesData,
    ]);

    useEffect(() => {
        processTemplateData({
            allVariables,
            userTemplateTextBlocks,
            mailjetTemplateData,
            setHtmlTemplate,
            mjmlTemplate,
            variables,
            templateVariablesData,
            attendeeData,
            courseLessonDataNewBooking,
        });
    }, [
        allVariables,
        attendeeData,
        courseLessonDataNewBooking,
        mailjetTemplateData,
        mjmlTemplate,
        setHtmlTemplate,
        templateVariablesData,
        userTemplateTextBlocks,
        variables,
    ]);

    return (
        <>
            {loadingAll ? (
                <LoadingContent />
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    {htmlTemplate ? (
                        <MjmlPreview
                            mjmlOutput={htmlTemplate} /* loading={loading} */
                            style={{width: "100%"}}
                        />
                    ) : (
                        <h3>{"Template fehlt!"}</h3>
                    )}
                </div>
            )}
        </>
    );
};

export default memo(MjmlLoader);
