import i18n from "../services/i18n";

export const naturalLanguageAge = (ageInMonths: number) => {
    const years = Math.floor(ageInMonths / 12);
    const months = ageInMonths % 12;

    // if (ageInMonths < 24) {
    const year =
        years > 0 ? `${years} ${i18n.containers.courses.CourseTypes.j()}` : "";

    if (months === 0) {
        return year;
    }

    return `${year} ${months} ${i18n.containers.courses.CourseTypes.m()}`;
    // }

    return `${years} ${i18n.containers.courses.CourseTypes.j()}`;
};

export const naturalLanguageAgeShort = (ageInMonths: number) => {
    const years = Math.floor(ageInMonths / 12);
    const months = ageInMonths % 12;

    if (ageInMonths < 24) {
        const year =
            years > 0
                ? `${years} ${i18n.containers.courses.CourseTypes.j()}`
                : "";

        if (months === 0) {
            return year;
        }

        return `${year} ${months} ${i18n.containers.courses.CourseTypes.m()}`;
    }

    return `${years} ${i18n.containers.courses.CourseTypes.j()}`;
};
