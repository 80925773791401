import {Button, Divider, message, Modal, Radio, Space, Tooltip} from "antd";
import React, {FC, memo, useState} from "react";
import {blue} from "@ant-design/colors";
import {EyeOutlined} from "@ant-design/icons";
import {
    Course,
    GetCoursesWithPaginationDocument,
    useUpdateMultipleCoursesShowInWebMutation,
} from "../../../generated/graphql";
import LoadingContent from "../../../views/LoadingContent/LoadingContent";

type BulkShowInWebProps = {
    courseIds: Array<Course["id"]>;
    key: number;
    btnClassName?: string;
    onFinished: () => void;
    refetch?: () => void;
};

const BulkShowInWeb: FC<BulkShowInWebProps> = ({
    courseIds,
    key,
    btnClassName,
    onFinished,
    refetch,
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInWeb, setShowInWeb] = useState(false);
    const count = courseIds.length > 1 ? courseIds.length : 1;
    const showInWebText = showInWeb
        ? "Im Web anzeigen"
        : "Nicht im Web anzeigen";
    const coursePlural = count > 1 ? "der Kurse" : "des Kurses";
    const coursePlural2 = count > 1 ? "die Kurse" : "der Kurs";
    const wirdPlural = count > 1 ? "werden" : "wird";
    const description = `Diese Einstellung ändert die Sichtbarkeit ${coursePlural} auf der Webseite ("Im Web anzeigen"). Allerdings beeinflusst diese Einstellung nur die Einstellung ${coursePlural} an sich. Falls andere Bedingungen bestehen, die die Sichtbarkeit im Web beinflussen, werden diese hiermit nicht geändert, wodurch ${coursePlural2} eventuell weiterhin nicht angezeigt ${wirdPlural}.`;

    const [updateShowInWeb] = useUpdateMultipleCoursesShowInWebMutation({
        refetchQueries: [
            "GetCoursesWithPaginationDocument",
            {
                query: GetCoursesWithPaginationDocument,
                variables: {options: {}},
            },
        ],
    });

    const handleOk = async () => {
        if (!courseIds.length) {
            message.error("Keine Kurse ausgewählt");

            return;
        }
        setLoading(true);
        message.loading({
            key: "showInWebMsg",
            content: "Ändere Sichtbarkeit im Web...",
        });
        try {
            await updateShowInWeb({
                variables: {
                    courseIds,
                    showInWeb,
                },
            }).then(() => {
                message.success({
                    key: "showInWebMsg",
                    content: `Sichtbarkeit ${coursePlural} im Web erfolgreich auf "${showInWebText}" geändert`,
                    duration: 10,
                });
            });
        } catch (error) {
            await message.error({
                key: "showInWebMsg",
                content: "Fehler beim Ändern der Sichtbarkeit im Web",
            });
            console.error(error);
        } finally {
            refetch?.();
            message.destroy("showInWebMsg");
            setOpen(false);
            setLoading(false);
        }
    };

    const RadioGroup = () => {
        const onChange = (e) => {
            setShowInWeb(e.target.value);
        };

        return (
            <Space direction="vertical">
                <div style={{fontWeight: 500, fontSize: "1.1em"}}>
                    {"Auf was soll die Auswahl geändert werden?"}
                </div>
                <Radio.Group onChange={onChange} value={showInWeb}>
                    <Space direction="horizontal">
                        <Radio
                            style={{fontWeight: showInWeb ? 600 : 400}}
                            value={true}
                        >
                            {"Im Web anzeigen"}
                        </Radio>
                        <Radio
                            style={{
                                fontWeight: showInWeb === false ? 600 : 400,
                            }}
                            value={false}
                        >
                            {"Nicht im Web anzeigen"}
                        </Radio>
                    </Space>
                </Radio.Group>
            </Space>
        );
    };

    if (open) {
        return (
            <Modal
                title={`Sichtbarkeit ${coursePlural} im Web ändern`}
                open={open}
                onCancel={() => setOpen(false)}
                okText={loading ? "wird geändert..." : "Jetzt ändern"}
                okButtonProps={{
                    loading,
                    block: loading,
                    style: {margin: "auto"},
                }}
                cancelButtonProps={{
                    disabled: loading,
                    hidden: loading,
                    style: {marginRight: 7},
                }}
                bodyStyle={{textAlign: "center"}}
                onOk={handleOk}
                destroyOnClose
            >
                {loading ? (
                    <LoadingContent content="Ändere Sichtbarkeit im Web..." />
                ) : (
                    <>
                        <p>{description}</p>
                        <Divider style={{margin: 10}} />
                        <RadioGroup />
                    </>
                )}
            </Modal>
        );
    }

    return (
        <Tooltip title="Sichtbarkeit von Kursen ändern">
            <Button
                key={key}
                onClick={() => setOpen(true)}
                size="small"
                type="link"
                style={{color: blue[5]}}
                shape="circle"
                className={btnClassName}
            >
                <EyeOutlined />
            </Button>
        </Tooltip>
    );
};

export default memo(BulkShowInWeb);
