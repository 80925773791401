/* eslint-disable react/no-array-index-key */
import {red} from "@ant-design/colors";
import {SendOutlined} from "@ant-design/icons";
import {Button, message, Modal, Tooltip} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {
    Attendee,
    Booker,
    Booking,
    Course,
    EmailData,
    GetReceiversByCourseIdsQuery,
    Invoice,
    MailDataType,
    ScheduledPayment,
    TemplateType,
    useGetBookersByCourseIdsLazyQuery,
} from "../../../../generated/graphql";
import EmailTemplates from "../../../templates/EmailTemplates";
import MailTemplateEditor from "../../../templates/MailTemplateEditor/MailTemplateEditor";
import css from "./SendEmailTemplate.less";

export type CompatibleMailDataType =
    | MailDataType.Attendee
    | MailDataType.Booker
    | MailDataType.Booking
    | MailDataType.Course
    | MailDataType.Invoice
    | MailDataType.ScheduledPayment;

type SendEmailTemplateProps = {
    input:
        | {mailDataType: CompatibleMailDataType; selection: Array<Attendee>}
        | {mailDataType: CompatibleMailDataType; selection: Array<Booker>}
        | {mailDataType: CompatibleMailDataType; selection: Array<Booking>}
        | {mailDataType: CompatibleMailDataType; selection: Array<Course>}
        | {mailDataType: CompatibleMailDataType; selection: Array<Invoice>}
        | {
              mailDataType: CompatibleMailDataType;
              selection: Array<ScheduledPayment>;
          };
    // input:
    //     | {mailDataType: MailDataType.Attendee; selection: Array<Attendee>}
    //     | {mailDataType: MailDataType.Booker; selection: Array<Booker>}
    //     | {mailDataType: MailDataType.Booking; selection: Array<Booking>}
    //     | {mailDataType: MailDataType.Course; selection: Array<Course>}
    //     | {mailDataType: MailDataType.Invoice; selection: Array<Invoice>}
    //     | {
    //           mailDataType: MailDataType.ScheduledPayment;
    //           selection: Array<ScheduledPayment>;
    //       };
};

type FetchedBookers = GetReceiversByCourseIdsQuery["getBookersByCourseIds"];

const SendEmailTemplate: FC<SendEmailTemplateProps> = ({input}) => {
    const [templateSelectorVisible, setTemplateSelectorVisible] = useState(
        false,
    );

    const bookerNamesWithoutEmail: Array<string> = [];
    const bookersWithEmail: Array<string> = [];

    // GET BOOKERS OF COURSE ------------------------------------------------------------------------

    const [courseId, setCourseId] = useState<string | undefined>();
    const [bookersOfCourse, setBookersOfCourse] = useState<FetchedBookers>();

    useEffect(() => {
        const selectedCourseId = input.selection[0].id;

        if (
            input.mailDataType === MailDataType.Course &&
            selectedCourseId !== courseId
        ) {
            setCourseId(selectedCourseId);
        }
    }, [courseId, input]);

    const [
        getBookersOfCourse,
        {data: bookersOfCourseData},
    ] = useGetBookersByCourseIdsLazyQuery();

    useEffect(() => {
        if (
            input.mailDataType === MailDataType.Course &&
            !bookersOfCourse &&
            courseId
        ) {
            getBookersOfCourse({
                variables: {courseIds: [courseId]},
            });

            const fetchedBookers: FetchedBookers | undefined =
                bookersOfCourseData?.getBookersByCourseIds;

            // push fetchedBookers fullNames that don't have an email address to bookerNamesWithoutEmail
            if (fetchedBookers) {
                setBookersOfCourse(fetchedBookers);
            }
        }
    }, [
        bookersOfCourse,
        bookersOfCourseData,
        courseId,
        getBookersOfCourse,
        input.mailDataType,
    ]);

    // ----------------------------------------------------------------------------------------------

    const [
        templateEmailEditorVisible,
        setTemplateEmailEditorVisible,
    ] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState<{
        dbTemplateId: string;
        templateType: TemplateType;
    }>();

    // Collect data ---------------------------------------------------------------------------------

    const emailData: EmailData = {
        receivers: [],
        ids: [],
        // Payments are basically bookings, so we need to change the mailDataType to Booking
        mailDataType:
            input.mailDataType === MailDataType.ScheduledPayment
                ? MailDataType.Booking
                : input.mailDataType,
    };

    // HANDLING MISSING E-MAIL-ADDRESSES ------------------------------------------------------------

    const showMissingEmailsModal = (bookerNamesWithoutEmail: Array<string>) => {
        const bookerWithoutEmailContent = () => {
            const withoutDuplicates: Array<string> = [];

            bookerNamesWithoutEmail.forEach((name) => {
                // console.log("name: ", name);
                if (!withoutDuplicates.includes(name)) {
                    withoutDuplicates.push(name);
                }
            });

            return (
                <div>
                    <p style={{textAlign: "center"}}>
                        {
                            "Bei folgenden Buchern ist keine Email-Adresse hinterlegt:"
                        }
                    </p>
                    <ul style={{paddingLeft: 0}}>
                        {withoutDuplicates.map((name, index) => (
                            <li
                                key={`${name}-${index}`}
                                style={{
                                    fontWeight: 500,
                                    textAlign: "center",
                                    listStyle: "none",
                                    listStylePosition: "outside",
                                }}
                            >
                                {name}
                            </li>
                        ))}
                    </ul>
                    <p style={{textAlign: "center"}}>
                        {
                            "Diese Bucher können daher nicht benachrichtigt werden."
                        }
                    </p>
                </div>
            );
        };

        if (bookerNamesWithoutEmail.length > 0) {
            return Modal.warning({
                width: 450,
                title: (
                    <div
                        style={{
                            textAlign: "center",
                            fontSize: "1.2rem",
                            fontWeight: 600,
                        }}
                    >{`Achtung, Es fehlen Email Adressen`}</div>
                ),
                content: bookerWithoutEmailContent(),
                okButtonProps: {block: true},
                icon: null,
            });
        }

        return null;
    };

    // GET RECEIVERS ACCORDING TO THE INPUT TYPE -----------------------------------------------------
    switch (input.mailDataType) {
        case MailDataType.Booker:
            console.log(
                "MAIL TO SELECTION OF BOOKER:",
                bookerNamesWithoutEmail,
            );
            input.selection.forEach((booker) => {
                const firstname = booker.firstname;
                const lastname = booker.lastname;
                const bookerFullName = `${firstname} ${lastname}`;

                if (
                    booker.email &&
                    !emailData.receivers.includes(booker.email)
                ) {
                    emailData.receivers.push(booker.email);
                    emailData.ids.push(booker.id);
                    if (firstname && lastname) {
                        bookersWithEmail.push(bookerFullName);
                    }
                } else if (firstname && lastname) {
                    bookerNamesWithoutEmail.push(bookerFullName);
                }
            });

            break;
        case MailDataType.Attendee:
            console.log(
                "MAIL TO SELECTION OF ATTENDEE:",
                bookerNamesWithoutEmail,
            );
            input.selection.forEach((attendee) => {
                const firstname = attendee.booker?.firstname;
                const lastname = attendee.booker?.lastname;
                const bookerFullName = `${firstname} ${lastname}`;

                if (
                    attendee.booker?.email &&
                    !emailData.receivers.includes(attendee.booker.email)
                ) {
                    emailData.receivers.push(attendee.booker.email);
                    if (firstname && lastname) {
                        bookersWithEmail.push(bookerFullName);
                    }
                } else if (firstname && lastname) {
                    bookerNamesWithoutEmail.push(bookerFullName);
                }
                emailData.ids.push(attendee.id);
            });
            break;
        case MailDataType.Booking:
            console.log(
                "MAIL TO SELECTION OF BOOKING:",
                bookerNamesWithoutEmail,
            );

            input.selection.forEach((booking) => {
                const firstname = booking.attendee?.booker?.firstname;
                const lastname = booking.attendee?.booker?.lastname;
                const bookerFullName = `${firstname} ${lastname}`;

                if (booking.attendee?.booker?.email) {
                    emailData.receivers.push(booking.attendee.booker.email);
                    emailData.ids.push(booking.id);
                    if (firstname && lastname) {
                        bookersWithEmail.push(bookerFullName);
                    }
                } else if (firstname && lastname) {
                    bookerNamesWithoutEmail.push(bookerFullName);
                }
                // console.groupEnd();
            });
            break;
        case MailDataType.ScheduledPayment:
            console.log(
                "MAIL TO SELECTION OF SCHEDULEDPAYMENT:",
                bookerNamesWithoutEmail,
            );
            input.selection.forEach((scheduledPayment) => {
                const booking = scheduledPayment.booking;
                const email = booking.attendee?.booker?.email;
                const firstname = booking.attendee?.booker?.firstname;
                const lastname = booking.attendee?.booker?.lastname;
                const fullName = `${firstname} ${lastname}`;
                const isValidEmail = email && email !== " " && email !== "";

                if (email && isValidEmail === true) {
                    console.log(`"${email}"`);
                    emailData.receivers.push(email);
                    emailData.ids.push(booking.id);
                    bookersWithEmail.push(fullName);
                } else if (firstname && lastname) {
                    bookerNamesWithoutEmail.push(fullName);
                }
            });
            break;
        case MailDataType.Course:
            console.log(
                "MAIL TO SELECTION OF COURSE:",
                bookerNamesWithoutEmail,
            );
            if (input.selection.length > 1) {
                console.log(
                    `mehr als 1 Kurs ausgewählt. Verwendet wird nur der erste Kurs (${input.selection[0].prefixedCourseNumber})`,
                );
            }
            emailData.ids.push(input.selection[0].id);

            bookersOfCourse?.forEach((booker) => {
                const firstname = booker.firstname;
                const lastname = booker.lastname;
                const fullName = `${firstname} ${lastname}`;

                if (!booker.email && firstname && lastname) {
                    bookerNamesWithoutEmail.push(fullName);
                } else if (firstname && lastname) {
                    bookersWithEmail.push(fullName);
                }
            });
            break;

        default:
            break;
    }

    // Handle Tempalte selection ---------------------------------------------------------------------------------

    const handleSelectedTemplate = (
        dbTemplateId: string,
        templateType: TemplateType,
    ) => {
        setSelectedTemplate({dbTemplateId, templateType});
        setTemplateSelectorVisible(false);
        setTemplateEmailEditorVisible(true);
    };

    // handle Mail was sent ---------------------------------------------------------------------------------

    const handleMailWasSent = (e: boolean, emailsToSendCount?: number) => {
        console.log("handleMailWasSent ==> ", e, emailsToSendCount);
        setTemplateEmailEditorVisible(false);
    };

    // Return ---------------------------------------------------------------------------------

    const renderSelector = () => {
        return (
            <Modal
                title="Email an Auswahl senden"
                // visible={templateSelectorVisible}
                destroyOnClose={true}
                open={templateSelectorVisible}
                onCancel={() => setTemplateSelectorVisible(false)}
                width="100vw"
                style={{
                    top: 0,
                    width: "100vw",
                    height: "100vh",
                }}
            >
                {/* <MailTemplateEditor emailData={emailData} /> */}
                <EmailTemplates
                    useCase="SELECT"
                    liftSelectedTemplate={handleSelectedTemplate}
                    emailData={emailData}
                />
            </Modal>
        );
    };

    const renderEditor = () => {
        return (
            <MailTemplateEditor
                asModal={true}
                visible={templateEmailEditorVisible}
                useCase="SENDMAIL"
                // useCase="EDITOR"
                emailData={emailData}
                mailDataType={input.mailDataType}
                switchEditMode="EDIT"
                dbTemplateId={selectedTemplate?.dbTemplateId}
                templateType={selectedTemplate?.templateType}
                // confirmAction={() => setTemplateEmailEditorVisible(false)}
                confirmAction={(e, emailsToSendCount?: number) =>
                    handleMailWasSent(e, emailsToSendCount)
                }
                receivers={emailData.receivers}
                // receivers={receivers}
            />
        );
    };

    return (
        <div className={css.root}>
            <span>
                <Tooltip
                    title={
                        input.mailDataType === MailDataType.Course &&
                        input.selection.length > 1
                            ? "Versenden von E-Mails Nur an einen Kurs möglich"
                            : "E-Mails an Auswahl"
                    }
                    color={
                        input.mailDataType === MailDataType.Course &&
                        input.selection.length > 1
                            ? red[3]
                            : undefined
                    }
                    mouseEnterDelay={0.3}
                >
                    <Button
                        type="link"
                        size="small"
                        icon={<SendOutlined style={{fontSize: "0.95em"}} />}
                        disabled={
                            input.mailDataType === MailDataType.Course &&
                            input.selection.length > 1
                        }
                        onClick={() => {
                            setTemplateSelectorVisible(true);
                            showMissingEmailsModal(bookerNamesWithoutEmail);
                        }}
                    />
                </Tooltip>
                <>
                    {renderSelector()}
                    {renderEditor()}
                </>
            </span>
        </div>
    );
};

export default memo(SendEmailTemplate);
