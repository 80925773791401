import {ColumnProps} from "antd/lib/table";
import {TableType} from "./AttendanceListColumns";

export const getColumnsToShow = (
    columns: Array<{dataIndex: string} & TableType>,
    columnsToShow: Array<Extract<keyof TableType, string>>,
) => {
    const shownColumns: Array<ColumnProps<TableType>> = [];

    columns.forEach((col) => {
        if (columnsToShow.some((value) => value === col.dataIndex)) {
            shownColumns.push(col);
        }
    });

    moveLeftAligndColumnToFirstPosition(shownColumns);
    moveActionColumnToLastPosition(shownColumns);
    moveRowIndexToFirstPosition(shownColumns);

    return shownColumns;
};

export const moveActionColumnToLastPosition = (
    shownColumns: Array<ColumnProps<TableType>>,
) => {
    shownColumns.forEach((col) => {
        if (col.fixed === "right") {
            shownColumns.push(
                shownColumns.splice(shownColumns.indexOf(col), 1)[0],
            );
        }
    });
};

export const moveLeftAligndColumnToFirstPosition = (
    shownColumns: Array<ColumnProps<TableType>>,
) => {
    shownColumns.forEach((col) => {
        if (col.fixed === "left") {
            shownColumns.unshift(
                shownColumns.splice(shownColumns.indexOf(col), 1)[0],
            );
        }
    });
};

export const moveRowIndexToFirstPosition = (
    shownColumns: Array<ColumnProps<TableType>>,
) => {
    shownColumns.forEach((col) => {
        if (col.dataIndex === "rowIndex") {
            shownColumns.unshift(
                shownColumns.splice(shownColumns.indexOf(col), 1)[0],
            );
        }
    });
};
