import React, {FC, memo} from "react";
import {Button} from "antd";
import PanelCardCourseData from "../../../../views/PanelCardCourseData/PanelCardCourseData";

type SelectedCourseDetailsProps = {
    courseId?: string;
    setCourseSelectorOpen: (open: boolean) => void;
    className?: string;
};

const SelectedCourseDetails: FC<SelectedCourseDetailsProps> = ({
    courseId,
    setCourseSelectorOpen,
    className,
}) => {
    const CourseDetails = () => {
        return (
            <div className={className}>
                {courseId ? (
                    <PanelCardCourseData
                        courseId={courseId}
                        overview /* lessons */
                        extra={
                            <Button
                                type="primary"
                                onClick={() => setCourseSelectorOpen(true)}
                            >
                                {"Anderen Kurs auswählen"}
                            </Button>
                        }
                        gapAfter={false}
                    />
                ) : null}
            </div>
        );
    };

    return <CourseDetails />;
};

export default memo(SelectedCourseDetails);
