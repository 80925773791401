/* eslint-disable max-params */
import React, {FC, memo, useEffect, useRef, useState} from "react";
import {ColumnProps} from "antd/lib/table";
import moment, {Moment} from "moment";
import {
    Button,
    Checkbox,
    DatePicker,
    InputRef,
    message,
    Popconfirm,
    Popover,
    Space,
    Spin,
    Tooltip,
} from "antd";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {
    DeleteOutlined,
    EditOutlined,
    InfoCircleTwoTone,
    MoreOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import {blue, red} from "@ant-design/colors";
import css from "../../views/TableColumnRenderers/TableColumnRenderers.less";
import {
    ArrayDataOptions,
    CourseLesson,
    GetBookingByIdDocument,
    GetPaidScheduledPaymentsDocument,
    GetScheduledPaymentsQuery,
    GetScheduledPaymentsSummaryDocument,
    GetUnpaidScheduledPaymentsDocument,
    Maybe,
    PaymentTransferType,
    useDeleteScheduledPaymentMutation,
    useSetNewPaymentDoneDateMutation,
    useSetScheduledPaymentPaidMutation,
    useSetScheduledPaymentUnpaidMutation,
    useSetSepaClearanceMutation,
} from "../../generated/graphql";
import {
    renderUnknown,
    renderDate,
    renderBookingNumber,
    renderCourseType,
    renderCourseNumber,
    renderTextStandard,
    renderFirstCourseLesson,
    renderPaymentType,
    renderDueDateColored,
    renderBookerFullName,
    renderAttendeeFullName,
    RenderAmount,
    renderPaymentMethod,
    renderPercent,
    renderInvoiceTitle,
} from "../../views/TableColumnRenderers/TableColumnRenderers";
import i18n from "../../services/i18n";
import {QueryItem} from "../../types";
import RenderInvoicePreview from "../../views/RenderInvoicePreview/RenderInvoicePreview";
import {URL_INVOICE_COURSE_DETAILS} from "../../models/url";
import CalendarPopover from "../../views/CalendarPopover/CalendarPopover";
import columnSortFilterTest, {
    InputType,
} from "../../helpers/columnSortFilterTest";
import {useCurrency} from "../../helpers/currencyHelper";
import useHighestRole from "../../helpers/useHighestRole";
import roles from "../../../shared/models/roles";

type ExtraColumns = {
    // actions: "actions";
    setPaidAction: "setPaidAction";
    setUnPaidAction: "setUnPaidAction";
    bookingNumber: "bookingNumber";
    courseType: "courseType";
    courseNumber: "courseNumber";
    booker: "booker";
    attendee: "attendee";
    city: "city";
    location: "location";
    firstCourseLesson: "firstCourseLesson";
    paymentType: "paymentType";
    paymentMethod: "paymentMethod";
    sepaClearance: "sepaClearance";
    vat: "vat";
    invoiceDate: "invoiceDate";
};

export type TableType = GetScheduledPaymentsQuery["scheduledPayments"]["items"][0] &
    ExtraColumns;
// export type TableType = GetCoursesQuery["courses"][0] & ExtraColumns;

export type GetScheduledPaymentsQueryItem = QueryItem<
    GetScheduledPaymentsQuery["scheduledPayments"]["items"]
>;
// export type GetCoursesQueryItem = QueryItem<GetCoursesQuery["courses"]>;

export const rowKey: (course: TableType) => TableType["id"] = (
    scheduledPayment,
) => scheduledPayment.id;

export const useHandleColumns = (
    columnsToShow: Array<Extract<keyof TableType, string>>,
    options: Maybe<ArrayDataOptions> | undefined,
    setOptions: (options: ArrayDataOptions) => void,
    editLink: string,
    detailsLink: string,
    // tableData?: Array<GetScheduledPaymentsQueryItem>,
    isExpandable?: boolean,
    activeFilters?: ArrayDataOptions["filter"],
    refetch?: () => void,
    editPaymentOpen?: boolean,
    setEditPaymentOpen?: (editPaymentOpen: boolean) => void,
    setRecordToEdit?: (record: TableType) => void,

    // eslint-disable-next-line max-params
) => {
    const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
    const shownColumns: Array<ColumnProps<TableType>> = [];

    const currency = useCurrency();

    const getColumnsToShow = () => {
        columns.forEach((col) => {
            if (columnsToShow.includes(col.dataIndex)) {
                shownColumns.push(col);
            }
        });
        moveActionColumnToLastPosition(shownColumns);
    };

    const moveActionColumnToLastPosition = (
        shownColumns: Array<ColumnProps<TableType>>,
    ) => {
        shownColumns.forEach((col) => {
            if (col.fixed === "right") {
                shownColumns.push(
                    shownColumns.splice(shownColumns.indexOf(col), 1)[0],
                );
            }
        });
    };

    const columns: Array<ColumnProps<TableType>> = [];

    const column: ColumnProps<TableType> = {
        title: "",
        fixed: false,
    };

    const inputRef = useRef<InputRef>(null);

    const ref = inputRef.current?.focus({
        cursor: "all",
    });

    columnsToShow.forEach((col: keyof TableType) => {
        const c = {...column};

        const colTitle = i18n.views.TableColumnTitles[col]();

        const rawTitle = colTitle;
        const title =
            rawTitle.length <= 12 ? (
                rawTitle
            ) : (
                <span style={{whiteSpace: "pre-line"}}>
                    {rawTitle.replace(" ", "\n")}
                </span>
            );

        const standardEllipsis = false;

        c.ellipsis = standardEllipsis;
        c.title = <Tooltip title={title}>{title}</Tooltip>;
        c.dataIndex = col;
        c.width = 100 + colTitle.length * 2;

        let path = c.dataIndex;

        const sortFilter = ({
            dataIndex,
            disabled,
            type,
            selectOptions,
            activeFilters,
        }: {
            dataIndex: string;
            disabled?: boolean;
            type: InputType;
            selectOptions?: Array<{
                label: string;
                value: string;
            }>;
            activeFilters?: ArrayDataOptions["filter"] | undefined;
        }) =>
            // eslint-disable-next-line max-params
            {
                Object.entries(
                    columnSortFilterTest({
                        dataIndex,
                        type,
                        selectOptions,
                        options,
                        setOptions,
                        dropdownOpen,
                        setDropdownOpen,
                    }),
                ).map(([key, value]) => {
                    if (key !== "filteredValue") {
                        // @ts-ignore
                        return (c[key] = disabled ? null : value);
                    }

                    return null;
                });
            };

        switch (col) {
            case "setPaidAction":
                c.fixed = isExpandable ? false : "right";
                c.width = 10;
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderActions(
                        record,
                        editLink,
                        detailsLink,
                        "setAsPaid",
                        refetch,
                        editPaymentOpen,
                        setEditPaymentOpen,
                        setRecordToEdit,
                    );
                break;
            case "setUnPaidAction":
                c.fixed = isExpandable ? false : "right";
                c.width = 10;
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderActions(
                        record,
                        editLink,
                        detailsLink,
                        "setAsUnpaid",
                        refetch,
                        editPaymentOpen,
                        setEditPaymentOpen,
                        setRecordToEdit,
                    );
                break;
            case "bookingNumber":
                c.fixed = "left";
                path = "booking.bookingNumber";
                c.width = 100;
                c.render = (text, record: TableType) =>
                    renderBookingNumber({
                        bookingId: record.booking.id,
                        bookingNumberPrefix: record.booking.bookingNumberPrefix,
                        bookingNumber: record.booking.bookingNumber,
                        bookingType: record.booking.bookingType,
                        className: css.primary,
                        canceled: Boolean(record.booking.canceled !== null),
                        isSettled: Boolean(record.booking.invoice !== null),
                    });
                break;
            case "courseType":
                // path = "courseLessons.0.course.courseType.name";
                c.render = (text, record: TableType) =>
                    renderCourseType({
                        name:
                            record.booking.courseLessons[0].course.courseType
                                .name,
                        color:
                            record.booking.courseLessons[0].course.courseType
                                .color,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "courseNumber":
                c.render = (text, record: TableType) =>
                    renderCourseNumber({
                        courseNumber:
                            record.booking.courseLessons[0].course
                                .prefixedCourseNumber ?? undefined,
                        courseId: record.booking.courseLessons[0].course.id,

                        detailsLink: URL_INVOICE_COURSE_DETAILS.replace(
                            ":id",
                            record.booking.courseLessons[0].course.id,
                        ),
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "booker":
                c.render = (text, record: TableType) =>
                    renderBookerFullName(
                        record.booking.attendee?.booker?.id,
                        record.booking.attendee?.booker?.firstname,
                        record.booking.attendee?.booker?.lastname,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "attendee":
                c.render = (text, record: TableType) =>
                    renderAttendeeFullName(
                        record.booking.attendee?.booker?.id,
                        record.booking.attendee?.firstname,
                        record.booking.attendee?.lastname,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "city":
                c.render = (text, record: TableType) =>
                    renderTextStandard(
                        record.booking.courseLessons[0].location.city,
                    );
                break;
            case "location":
                c.render = (text, record: TableType) =>
                    renderTextStandard(
                        record.booking.courseLessons[0].location.name,
                    );
                break;
            case "firstCourseLesson":
                c.render = (text, record: TableType) =>
                    renderFirstCourseLesson({
                        courseLessons: record.booking.courseLessons as Array<
                            CourseLesson
                        >,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                c.sorter = false;
                break;
            case "createDate":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderDate(record.createDate);

                break;
            case "paymentDue":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderDueDateColored(record.paymentDue);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                c.sorter = false;
                break;
            case "paymentDone":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    record.paymentDone
                        ? renderDate(record.paymentDone)
                        : renderDueDateColored(
                              record.paymentDue,
                              i18n.containers.payments.PaymentsList.notPaid(),
                          );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                // c.sorter = false;
                break;
            case "paymentAmount":
                c.render = (text, record: TableType) => ({
                    displayName: "paymentAmount",
                    children:
                        record.openAmount && record.openAmount > 0 ? (
                            <Tooltip
                                title={`Es wurde bereits mindestens eine Teilzahlung getätigt. Der Originalbetrag lag bei ${record.paymentAmount} ${currency.short}`}
                            >
                                <Space
                                    size={5}
                                    style={{
                                        fontWeight: 650,
                                        color: blue[7],
                                    }}
                                >
                                    <InfoCircleTwoTone />
                                    <Space size={2}>
                                        <RenderAmount
                                            amount={
                                                record.openAmount ??
                                                record.paymentAmount
                                            }
                                            style={{
                                                fontWeight: 650,
                                                color: blue[7],
                                            }}
                                        />
                                        <span>{"*"}</span>
                                    </Space>
                                </Space>
                            </Tooltip>
                        ) : (
                            <>
                                <RenderAmount
                                    amount={record.paymentAmount}
                                    style={{fontWeight: 550}}
                                />
                            </>
                        ),
                });
                break;
            case "openAmount":
                c.render = (text, record: TableType) => ({
                    displayName: "openAmount",
                    children: (
                        <RenderAmount
                            amount={record.openAmount ?? record.paymentAmount}
                        />
                    ),
                });
                break;
            case "invoice":
                // path = "invoice.invoiceNumber";
                c.align = "center";
                // eslint-disable-next-line react/display-name
                c.render = (text, record: TableType) => {
                    const getInvoiceData = (record: TableType) => {
                        if (record.invoice === null) {
                            return undefined;
                        }

                        const {
                            invoiceNumberPrefix,
                            invoiceNumber,
                            id,
                        } = record.invoice;

                        return {
                            id,
                            invoiceNumber,
                            invoiceNumberPrefix,
                            // canceled: Boolean(record.canceled !== null),
                            isReversalInvoice: false,
                        };
                    };

                    const invoiceData = getInvoiceData(record);

                    if (invoiceData === undefined) {
                        return <div>{"-"}</div>;
                    }

                    return <RenderInvoicePreview invoiceData={invoiceData} />;
                };
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "paymentType":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderPaymentType(record.booking.paymentType);
                break;
            case "paymentMethod":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderPaymentMethod(record.paymentMethod);

                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {
                            label: "Überweisung",
                            value: PaymentTransferType.BankTransfer,
                        },
                        {
                            label: "Lastschrift",
                            value: PaymentTransferType.SepaDebit,
                        },
                        {label: "Bar", value: PaymentTransferType.Cash},
                    ],
                });
                c.sorter = false;
                break;
            case "sepaClearance":
                c.align = "center";
                // eslint-disable-next-line react/display-name
                c.render = (text, record: TableType) =>
                    record.paymentMethod?.sepaAccountholder ? (
                        <SepaClearanceCheckbox
                            record={record}
                            refetch={refetch}
                        />
                    ) : (
                        <Tooltip title="Bezahlmethode ist nicht Lastschrift">
                            {renderTextStandard("-")}
                        </Tooltip>
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {label: "Freigegeben", value: "true"},
                        {label: "Nicht freigegeben", value: "false"},
                    ],
                });
                c.sorter = false;
                break;
            case "vat":
                c.render = (text, record: TableType) => ({
                    displayName: "vat",
                    children: <div>{renderPercent(record.booking.vat)}</div>,
                });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "select",
                    selectOptions: [
                        {label: "20%", value: "0.2"},
                        {label: "19%", value: "0.19"},
                        {label: "7%", value: "0.07"},
                        {label: "0%", value: "0.0"},
                    ],
                });
                c.sorter = false;
                break;
            case "title":
                c.title = i18n.views.TableColumnTitles.invoiceTitle();
                c.fixed = false;
                c.render = (text, record) =>
                    renderInvoiceTitle(record.title ?? undefined);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "invoiceDate":
                c.align = "center";
                c.render = (text, record: TableType) => ({
                    displayName: "invoiceDate",
                    children: record.invoice?.updateDate ? (
                        renderDate(record.invoice?.updateDate)
                    ) : (
                        <div>{"-"}</div>
                    ),
                });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                c.sorter = false;
                break;
            default:
                c.fixed = false;
                c.render = (text) => renderUnknown(text);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                break;
        }
        columns.push(c);
    });

    getColumnsToShow();

    return shownColumns;
};

// ACTION BUTTONS---------------------------------------------------------------------------

const SepaClearanceCheckbox = ({
    record,
    refetch,
}: {
    record: TableType;
    refetch?: () => void;
}) => {
    const {sepaClearance} = record;
    const [loading, setLoading] = useState<boolean>(false);

    const [setClearance] = useSetSepaClearanceMutation();

    const updateSepaClearance = async (checked: boolean) => {
        try {
            setLoading(true);
            await setClearance({
                variables: {id: record.id, sepaClearance: checked},
            }).then((res) => {
                console.log("res:::", res);
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
        } finally {
            refetch?.();
        }
    };

    const onChange = (e: CheckboxChangeEvent) => {
        updateSepaClearance(e.target.checked);
    };

    if (loading) {
        return <Spin size="small" />;
    }

    return <Checkbox checked={Boolean(sepaClearance)} onChange={onChange} />;
};

const renderActions = (
    record: TableType,
    editLink: string,
    detailsLink: string,
    action: "setAsPaid" | "setAsUnpaid",
    refetch?: () => void,
    editPaymentOpen?: boolean,
    setEditPaymentOpen?: (editPaymentOpen: boolean) => void,
    setRecordToEdit?: (record: TableType) => void,
) => {
    return (
        <RenderActions
            record={record}
            editLink={editLink}
            detailsLink={detailsLink}
            action={action}
            refetch={refetch}
            editPaymentOpen={editPaymentOpen}
            setEditPaymentOpen={(editPaymentOpen) =>
                setEditPaymentOpen?.(editPaymentOpen)
            }
            setRecordToEdit={(record) => setRecordToEdit?.(record)}
        />
    );
};

type RenderActionsProps = {
    record: TableType;
    editLink: string;
    detailsLink: string;
    action: "setAsPaid" | "setAsUnpaid";
    refetch?: () => void;
    editPaymentOpen?: boolean;
    setEditPaymentOpen?: (editPaymentOpen: boolean) => void;
    setRecordToEdit?: (record: TableType) => void;
};

const RenderActions: FC<RenderActionsProps> = ({
    record,
    editLink,
    detailsLink,
    action,
    refetch,
    editPaymentOpen,
    setEditPaymentOpen,
    setRecordToEdit,
}) => {
    const highestRole = useHighestRole();
    const refetchQueries = [
        "GetUnpaidScheduledPayments",
        {query: GetPaidScheduledPaymentsDocument},
        "GetUnpaidScheduledPayments",
        {
            query: GetUnpaidScheduledPaymentsDocument,
            variables: {options: {}},
        },
        "GetBookingById",
        {query: GetBookingByIdDocument, variables: {id: record.booking.id}},
    ];

    // As summary is only shown for certain roles, we need to check if the user has one of those roles -----------------
    const summaryIsShown = [
        roles.Superuser,
        roles.BetaTester,
        roles.SuperAdmin,
    ].includes(highestRole);

    useEffect(() => {
        if (summaryIsShown) {
            refetchQueries.push(
                "GetScheduledPaymentsSummary",
                {
                    query: GetScheduledPaymentsSummaryDocument,
                    variables: {paymentDone: false},
                },
                "GetScheduledPaymentsSummary",
                {
                    query: GetScheduledPaymentsSummaryDocument,
                    variables: {paymentDone: true},
                },
            );
        }
    }, [refetchQueries, summaryIsShown]);

    // -----------------------------------------------------------------------------------------------------------------

    const [setScheduledPaymentAsPaid] = useSetScheduledPaymentPaidMutation({
        refetchQueries,
    });

    const setAsPaid = async (date: Moment | null, id: string) => {
        const hide = message.loading(
            i18n.containers.payments.OpenPaymentsList.operate(),
            0,
        );

        try {
            await setScheduledPaymentAsPaid({
                variables: {
                    id,
                    paymentDone: date,
                },
            });
        } catch (e) {
            console.log(e);
        } finally {
            hide();
            message.success(
                i18n.containers.payments.OpenPaymentsList.markedAsPaidFinished(),
                0.7,
            );
            refetch?.();
        }
    };

    const [setScheduledPaymentAsUnPaid] = useSetScheduledPaymentUnpaidMutation({
        refetchQueries,
    });

    const setAsUnPaid = async (id: string) => {
        const hide = message.loading(
            i18n.containers.payments.OpenPaymentsList.operate(),
            0,
        );

        try {
            await setScheduledPaymentAsUnPaid({
                variables: {
                    id,
                },
            });
        } catch (e) {
            console.log(e);
        } finally {
            hide();
            message.success(
                i18n.containers.payments.OpenPaymentsList.markedAsUnPaidFinished(),
                0.7,
            );
            refetch?.();
        }
    };

    // CHANGE PAYMENT DATE ---------------------------------------------------------------

    const [paymentDate, setPaymentDate] = useState<Moment | null>(null);

    useEffect(() => {
        if (!paymentDate) {
            setPaymentDate(record.paymentDone);
        }
    }, [paymentDate, record.paymentDone]);

    const [paymentDateModalOpen, setPaymentDateModalOpen] = useState<boolean>(
        false,
    );

    const [
        setNewPaymentDoneDate,
        {loading: setNewPaymentDoneDateLoading},
    ] = useSetNewPaymentDoneDateMutation({refetchQueries});

    const updatePaymentDate = async () => {
        if (!paymentDate) return;

        try {
            await setNewPaymentDoneDate({
                variables: {
                    id: record.id,
                    paymentDoneDate: paymentDate,
                },
            })
                .then((res) => {
                    console.log("res:::", res);
                    setPaymentDateModalOpen(false);
                })
                .then(() => {
                    message.success(
                        i18n.containers.payments.OpenPaymentsList.paymentDateChanged(),
                        0.7,
                    );
                })
                .then(() => {
                    refetch?.();
                });
        } catch (error) {
            console.log("updateInvoice:::", error);
            message.error(
                i18n.containers.payments.OpenPaymentsList.errorOnChangePaymentDate(),
                0.7,
            );
        }
    };

    const EditButton = () => {
        return (
            <>
                {/* <EditScheduledPaymentModal /> */}
                <Button
                    size="small"
                    style={{color: blue[5]}}
                    onClick={() => {
                        setEditPaymentOpen?.(true);
                        setRecordToEdit?.(record);
                    }}
                >
                    <EditOutlined />
                </Button>
            </>
        );
    };

    const [deletePayment] = useDeleteScheduledPaymentMutation({
        refetchQueries,
    });

    const confirmDeletePayment = async () => {
        try {
            await deletePayment({
                variables: {
                    id: record.id,
                },
            })
                .then(() => {
                    message.success(
                        i18n.containers.payments.OpenPaymentsList.deletePayment.paymentDeleted(),
                        0.7,
                    );
                })
                .then(() => {
                    refetch?.();
                });
        } catch (error) {
            console.log("deleteScheduledPayment error:::", error);
            message.error(
                i18n.containers.payments.OpenPaymentsList.deletePayment.errorOnDeletePayment(),
                0.7,
            );
        } finally {
            refetch?.();
        }
    };

    const [tooltipOpen, setTooltipOpen] = useState<boolean | undefined>(
        undefined,
    );

    const deleteScheduledPaymentAction = () => {
        return (
            <Tooltip
                title={i18n.containers.payments.OpenPaymentsList.deletePayment.title()}
                placement="left"
                mouseEnterDelay={0.75}
                destroyTooltipOnHide
                open={tooltipOpen}
            >
                <Popconfirm
                    title={i18n.containers.payments.OpenPaymentsList.deletePayment.text()}
                    onConfirm={confirmDeletePayment}
                    okText={i18n.containers.payments.OpenPaymentsList.deletePayment.delete()}
                    okButtonProps={{
                        danger: true,
                        style: {background: red[3], borderColor: red[3]},
                    }}
                    cancelText={i18n.containers.payments.OpenPaymentsList.deletePayment.cancel()}
                    placement="left"
                    onOpenChange={(open) => {
                        if (open) {
                            setTooltipOpen(false);
                        } else setTooltipOpen(undefined);
                    }}
                >
                    <Button size="small" type="link" style={{color: red[3]}}>
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
            </Tooltip>
        );
    };

    if (action === "setAsPaid") {
        return (
            <ButtonGroup>
                <EditButton />
                <CalendarPopover
                    title={i18n.containers.payments.OpenPaymentsList.paymentDate()}
                    buttonLeftText={i18n.containers.payments.OpenPaymentsList.changePaymentPopover.cancel()}
                    buttonRightClick={(date) =>
                        record.id && setAsPaid(date, record.id)
                    }
                    buttonRightText={i18n.containers.payments.OpenPaymentsList.accept()}
                >
                    <Button type="primary" size="small">
                        {i18n.containers.payments.OpenPaymentsList.markAsPaid()}
                    </Button>
                </CalendarPopover>
                {deleteScheduledPaymentAction()}
            </ButtonGroup>
        );
    }

    const donePaymentActions = () => {
        return (
            <Button.Group>
                <EditButton />
                <Button
                    size="small"
                    onClick={() => record.id && setAsUnPaid(record.id)}
                    disabled={record.booking.canceled !== null}
                >
                    {i18n.containers.payments.OpenPaymentsList.markAsUnPaid()}
                </Button>
                <Popover
                    title={i18n.containers.payments.OpenPaymentsList.changePaymentPopover.title()}
                    open={paymentDateModalOpen}
                    placement="left"
                    trigger="click"
                    overlayClassName={css.root}
                    content={
                        <Space direction="vertical">
                            <DatePicker
                                onChange={(date) => setPaymentDate(date)}
                                style={{width: "100%"}}
                                value={
                                    paymentDate
                                        ? moment(paymentDate)
                                        : undefined
                                }
                                format="DD.MM.YYYY"
                                disabled={setNewPaymentDoneDateLoading}
                            />
                            <Space>
                                <Button
                                    danger
                                    onClick={() =>
                                        setPaymentDateModalOpen(false)
                                    }
                                >
                                    {i18n.containers.payments.OpenPaymentsList.changePaymentPopover.cancel()}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={updatePaymentDate}
                                >
                                    {i18n.containers.payments.OpenPaymentsList.changePaymentPopover.save()}
                                </Button>
                            </Space>
                        </Space>
                    }
                >
                    <Button
                        icon={<MoreOutlined />}
                        size="small"
                        onClick={() =>
                            setPaymentDateModalOpen(!paymentDateModalOpen)
                        }
                    />
                </Popover>
            </Button.Group>
        );
    };

    return donePaymentActions();
};
