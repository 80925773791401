import React, {FC, memo, useEffect, useState} from "react";
import {Button, Col, Radio, Space, Tooltip, message} from "antd";
import uuid from "uuid";
import {TableOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {blue} from "@ant-design/colors";
import TemplateCard from "../../views/TemplateCard/TemplateCard";
import {
    GetTemplatesQuery,
    MailDataType,
    TablesEnum,
    TemplateType,
    useGetTemplatesQuery,
    useGetTemplatesWithPaginationQuery,
} from "../../generated/graphql";
import MailTemplateEditor, {
    UseCase,
} from "./MailTemplateEditor/MailTemplateEditor";
import CreateTemplateModal from "./CreateTemplateModal";
import css from "./EmailTemplates.less";
import {renderTitle} from "../../../shared/templates/handleTemplateVariables";
import Page from "../../views/Page/Page";
import Section from "../../views/Section/Section";
import {EmailData} from "../../../server/email/email.inputTypes";
import i18n from "../../services/i18n";
import TemplateList from "./MailTemplateEditor/TemplateList";
import {useLocalStorageOptions} from "../../helpers/columnsLocalStorageHandler";

type EmailTemplatesProps = {
    useCase?: UseCase;
    liftSelectedTemplate?: (
        dbTemplateId: string,
        templateType: TemplateType,
    ) => void;
    emailData?: EmailData;
};

export type ViewType = "LIST" | "GRID";

const EmailTemplates: FC<EmailTemplatesProps> = ({
    useCase = "EDITOR",
    liftSelectedTemplate,
    emailData,
}) => {
    const tablesEnum: TablesEnum = TablesEnum.EmailTemplatesList;
    const [showEmailEditor, setShowEmailEditor] = useState<boolean>(false);
    const [pickedTemplate, setPickedTemplate] = useState<string | undefined>();
    const [pickedTemplateId, setPickedTemplateId] = useState<string>();
    const [createTemplateModal, setCreateTemplateModal] = useState<boolean>(
        false,
    );
    const [mailDataType, setMailDataType] = useState<MailDataType>(
        emailData?.mailDataType ?? MailDataType.Booker,
    );
    const [viewType, setViewType] = useState<ViewType>("GRID");
    const [totalResults, setTotalResults] = useState<number>(0);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName: tablesEnum,
        defaultOptions: {},
        totalResults,
    });

    const {data} = useGetTemplatesQuery({fetchPolicy: "network-only"});
    const templates: GetTemplatesQuery["templates"] | undefined =
        data?.templates;

    const {
        data: customTemplatesData,
        loading,
        refetch,
    } = useGetTemplatesWithPaginationQuery({
        variables: {
            options,
        },
        fetchPolicy: "network-only",
    });

    const allCustomTemplates = customTemplatesData?.templatesWithPagination;

    useEffect(() => {
        totalResults !== allCustomTemplates?.total &&
            setTotalResults(allCustomTemplates?.total ?? 0);
    }, [allCustomTemplates, totalResults]);

    // const templates:
    //     | GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"]
    //     | undefined = data?.templatesWithPagination?.items;

    const scrollToElement = (elementId: string) => {
        const element = document.getElementById(elementId);

        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    useEffect(() => {
        if (viewType === "LIST") {
            setTimeout(() => {
                scrollToElement("targetElement");
            }, 100);
        }
    }, [viewType, options]);

    const columns = 6;

    type LayoutFormCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: LayoutFormCSSProperties = {
        "--column-count": columns,
        // ...style,
    };

    type RenderTemplatesProps = {
        templates: GetTemplatesQuery["templates"] | undefined;
        mailDataType: MailDataType;
    };

    const RenderTemplates: FC<RenderTemplatesProps> = ({templates}) => {
        if (!templates) {
            return (
                <div className={css.fullWidth}>{"Keine Vorlagen gefunden"}</div>
            );
        }

        const courseCompatible = [
            MailDataType.Course,
            MailDataType.Booking,
            MailDataType.Booker,
        ];

        const filteredTemplates = templates.filter((template) => {
            return (
                (template.mailDataType === mailDataType ||
                    (mailDataType === MailDataType.Course &&
                        courseCompatible.includes(mailDataType))) &&
                template.templateType !== TemplateType.NoTemplate
            );
        });

        const noTemplate = templates.filter((template) => {
            return template.templateType === TemplateType.NoTemplate;
        });

        filteredTemplates.unshift(noTemplate[0]);

        if (useCase === "SELECT") {
            if (filteredTemplates.length === 0) {
                return (
                    <div className={css.fullWidth}>
                        {"Keine Vorlagen gefunden"}
                    </div>
                );
            }

            return (
                <>
                    {filteredTemplates.map((template) => {
                        return (
                            <TemplateCard
                                key={template.id}
                                // className={css.templateCard}
                                style={actualStyle}
                                // style={{
                                //     ...actualStyle,
                                //     border: "3px dotted red",
                                // }}
                                onClick={() => {
                                    setShowEmailEditor(true);
                                    setPickedTemplateId(template.id);
                                    setPickedTemplate(template.templateType);
                                    setMailDataType(
                                        template.mailDataType ??
                                            MailDataType.Booker,
                                    );
                                }}
                                title={
                                    template.templateType ===
                                    TemplateType.FreeText
                                        ? template.name ?? "Kein Name"
                                        : renderTitle(template.templateType) ??
                                          "-"
                                }
                                isCustomTemplate={
                                    template.templateType === "FreeText"
                                }
                                mailDataType={
                                    template.mailDataType ?? MailDataType.Booker
                                }
                                noTemplate={
                                    template.templateType ===
                                    TemplateType.NoTemplate
                                }
                            />
                        );
                    })}
                </>
            );
        }

        return (
            <>
                {templates.map((template) => {
                    return (
                        <TemplateCard
                            key={template.id}
                            // className={css.templateCard}
                            style={actualStyle}
                            onClick={() => {
                                setShowEmailEditor(true);
                                setPickedTemplateId(template.id);
                                setPickedTemplate(template.templateType);
                                setMailDataType(
                                    template.mailDataType ??
                                        MailDataType.Booker,
                                );
                            }}
                            title={
                                template.templateType === TemplateType.FreeText
                                    ? template.name ?? "Kein Name"
                                    : renderTitle(template.templateType) ?? "-"
                            }
                            isCustomTemplate={
                                template.templateType === "FreeText" ||
                                template.templateType === "NoTemplate"
                            }
                            noTemplate={
                                template.templateType ===
                                TemplateType.NoTemplate
                            }
                            mailDataType={
                                template.mailDataType ?? MailDataType.Booker
                            }
                        />
                    );
                })}
            </>
        );
    };

    // WITHOUT TEMPLATE -----------------------------------------------------------------------------------------------------------

    const WithoutTemplateCard = () => {
        return (
            <TemplateCard
                title="Ohne Vorlage"
                onClick={() => {
                    console.log("clicked without template");
                }}
                // key={template.id}
                style={actualStyle}
                // onClick={() => {
                //     setShowEmailEditor(true);
                //     setPickedTemplateId(template.id);
                //     setPickedTemplate(template.templateType);
                //     setMailDataType(
                //         template.mailDataType ??
                //             MailDataType.Booker,
                //     );
                // }}
                // title={
                //     template.templateType ===
                //     TemplateType.FreeText
                //         ? template.name ?? "Kein Name"
                //         : renderTitle(template.templateType) ??
                //             "-"
                // }
                isCustomTemplate={false}
                mailDataType={MailDataType.Booker}
            />
        );
    };

    const AddNewTemplateCard = () => {
        const newId = uuid.v4();

        return (
            <Col key={newId} lg={8} xl={6}>
                <div>
                    <TemplateCard
                        // createNew
                        onClick={() => {
                            setCreateTemplateModal(true);
                        }}
                        title={
                            <div style={{textAlign: "center"}}>
                                {renderTitle(
                                    i18n.containers.templates.Templates.createTemplate.createTemplate(),
                                ) ?? "-"}
                            </div>
                        }
                        createTemplate
                    />
                </div>
            </Col>
        );
    };

    const [defaultTemplates, setDefaultTemplates] = useState<
        GetTemplatesQuery["templates"]
    >();
    const [customTemplates, setCustomTemplates] = useState<
        GetTemplatesQuery["templates"]
    >();

    useEffect(() => {
        const defaultTemplates: GetTemplatesQuery["templates"] = [];
        const customTemplates: GetTemplatesQuery["templates"] = [];

        templates?.map((template) => {
            if (
                template.templateType === TemplateType.FreeText ||
                template.templateType === TemplateType.NoTemplate
            ) {
                customTemplates.push(template);
            } else if (
                template.templateType === TemplateType.Invoice ||
                template.templateType === TemplateType.InvoiceMonthly ||
                template.templateType === TemplateType.InvoiceReversal ||
                template.templateType === TemplateType.InvoiceReversalMonthly ||
                template.templateType ===
                    TemplateType.InvoiceMonthlyConsistent ||
                template.templateType ===
                    TemplateType.InvoiceReversalMonthlyConsistent ||
                template.templateType === TemplateType.AttendanceList
            ) {
                // console.log("scip template:", template.templateType);
            } else {
                defaultTemplates.push(template);
            }
        });
        if (defaultTemplates.length > 0) {
            setDefaultTemplates(defaultTemplates);
        }
        if (customTemplates.length > 0) {
            // DONT SHOW NO TEMPLATE IN EDITOR
            if (useCase === "EDITOR") {
                const customTemplatesWithoutNoTemplate = customTemplates.filter(
                    (template) =>
                        template.templateType !== TemplateType.NoTemplate,
                );

                setCustomTemplates(customTemplatesWithoutNoTemplate);
            } else {
                setCustomTemplates(customTemplates);
            }
        }

        // console.log("defaultTemplates: ", defaultTemplates);
    }, [templates, useCase]);

    return (
        <Page
            content={
                <>
                    <>
                        <CreateTemplateModal
                            setCreateTemplate={(e) => setCreateTemplateModal(e)}
                            createTemplateModal={createTemplateModal}
                        />
                        {pickedTemplateId && pickedTemplate && (
                            <MailTemplateEditor
                                asModal
                                useCase={useCase}
                                visible={showEmailEditor}
                                setVisibility={(e: boolean) =>
                                    setShowEmailEditor(e)
                                }
                                dbTemplateId={pickedTemplateId}
                                templateType={pickedTemplate as TemplateType}
                                liftSelectedTemplate={liftSelectedTemplate}
                                // emailData={{mailDataType: mailDataType ?? MailDataType.Booker, receivers: [], ids: []}}
                                emailData={emailData}
                                mailDataType={
                                    emailData?.mailDataType ?? mailDataType
                                }
                                receivers={emailData?.receivers ?? []}
                            />
                        )}
                    </>
                    <div style={actualStyle} className={css.root}>
                        {useCase === "SELECT" ? null : (
                            <Section
                                headerBackground={true}
                                title={i18n.containers.templates.Templates.sectionTitle.standardTemplates()}
                                // className={
                                //     useCase === "SELECT" ? css.fullWidth : undefined
                                // }
                                content={
                                    <>
                                        <div className={css.gridContainer}>
                                            <RenderTemplates
                                                templates={defaultTemplates}
                                                mailDataType={mailDataType}
                                            />
                                        </div>
                                    </>
                                }
                            />
                        )}
                        <Section
                            headerBackground={false}
                            className={
                                useCase === "SELECT" ? css.fullWidth : undefined
                            }
                            title={
                                <Space
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        {i18n.containers.templates.Templates.sectionTitle.customTemplates()}
                                    </div>
                                    <Space>
                                        <Tooltip
                                            title={
                                                viewType === "GRID"
                                                    ? "Listendarstellung"
                                                    : "Kacheldarstellung"
                                            }
                                        >
                                            <Radio.Group
                                                buttonStyle="solid"
                                                onChange={(e) => {
                                                    setViewType(e.target.value);
                                                }}
                                                value={viewType}
                                            >
                                                <Radio.Button
                                                    value="GRID"
                                                    checked={
                                                        viewType === "GRID"
                                                    }
                                                >
                                                    <TableOutlined
                                                        style={{
                                                            color:
                                                                viewType ===
                                                                "GRID"
                                                                    ? "white"
                                                                    : blue[5],
                                                        }}
                                                    />
                                                </Radio.Button>
                                                <Radio.Button
                                                    value="LIST"
                                                    checked={
                                                        viewType === "LIST"
                                                    }
                                                >
                                                    <UnorderedListOutlined
                                                        style={{
                                                            color:
                                                                viewType ===
                                                                "LIST"
                                                                    ? "white"
                                                                    : blue[5],
                                                        }}
                                                    />
                                                </Radio.Button>
                                            </Radio.Group>
                                        </Tooltip>
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                setCreateTemplateModal(true)
                                            }
                                        >
                                            {i18n.containers.templates.Templates.createTemplate.createTemplate()}
                                        </Button>
                                    </Space>
                                </Space>
                            }
                            content={
                                loading ? (
                                    <div>{"Loading..."}</div>
                                ) : (
                                    <>
                                        {viewType === "LIST" ? (
                                            <TemplateList
                                                templates={
                                                    allCustomTemplates?.items
                                                }
                                                total={totalResults}
                                                onClick={(template) => {
                                                    if (
                                                        template.templateType ===
                                                        TemplateType.NoTemplate
                                                    ) {
                                                        message.error(
                                                            "Email ohne Vorlage kann nicht bearbeitet werden",
                                                        );
                                                    } else {
                                                        setShowEmailEditor(
                                                            true,
                                                        );
                                                        setPickedTemplateId(
                                                            template.id,
                                                        );
                                                        setPickedTemplate(
                                                            template.templateType,
                                                        );
                                                        setMailDataType(
                                                            template.mailDataType ??
                                                                MailDataType.Booker,
                                                        );
                                                    }
                                                }}
                                                pickedTemplateId={
                                                    pickedTemplateId
                                                }
                                                showEmailEditor={
                                                    showEmailEditor
                                                }
                                                options={options}
                                                setOptions={setOptions}
                                                tablesEnum={tablesEnum}
                                                refetch={refetch}
                                            />
                                        ) : (
                                            <div className={css.gridContainer}>
                                                <RenderTemplates
                                                    templates={customTemplates}
                                                    mailDataType={mailDataType}
                                                />
                                                <AddNewTemplateCard />
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        />
                    </div>
                </>
            }
        />
    );
};

export default memo(EmailTemplates);
