import classNames from "classnames";
import React, {FC, memo} from "react";
import NotFound from "../../containers/common/NotFound";
import {GetBookingByIdQuery} from "../../generated/graphql";
import i18n from "../../services/i18n";
import PanelCard from "../PanelCard/PanelCard";
import css from "./PanelCardBooker.less";

type PanelCardBookerProps = {
    attendee: GetBookingByIdQuery["bookingById"]["attendee"];
};

const PanelCardBooker: FC<PanelCardBookerProps> = ({attendee}) => {
    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        // ...style,
    };

    if (attendee?.booker === undefined) {
        return <NotFound />;
    }

    const {booker} = attendee;

    return (
        <PanelCard
            columns={3}
            title={i18n.containers.invoice.InvoiceDetails.attendeeInfo()}
            gapAfter
        >
            <div
                className={classNames(css.withDivider, css.container)}
                style={actualStyle}
            >
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.attendee()}
                </div>
                <div className={css.entry}>
                    {`${attendee.firstname} ${attendee.lastname}`}
                </div>
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.booker()}
                </div>
                <div className={css.entry}>
                    {`${booker?.firstname} ${booker?.lastname}`}
                </div>
            </div>
            <div
                className={classNames(css.withDivider, css.container)}
                style={actualStyle}
            >
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.address()}
                </div>
                <div className={css.entry}>
                    {`${booker?.street} ${booker?.houseNumber}`}
                    <br />
                    {`${booker?.postCode} ${booker?.city}`}
                </div>
            </div>
            <div className={classNames(css.container)} style={actualStyle}>
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.contactData.label()}
                </div>
                <div className={css.entry}>
                    {`${i18n.containers.invoice.InvoiceDetails.contactData.phone()} ${
                        booker?.phoneNumber
                    }`}
                </div>
                <div className={css.entry}>
                    {`${i18n.containers.invoice.InvoiceDetails.contactData.mobile()} ${
                        booker?.mobileNumber
                    }`}
                </div>
                <div className={css.entry}>
                    {`${i18n.containers.invoice.InvoiceDetails.contactData.email()} ${
                        booker?.email
                    }`}
                </div>
            </div>
        </PanelCard>
    );
};

export default memo(PanelCardBooker);
