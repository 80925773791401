import {TableType as BookingTableType} from "../../containers/bookings/BookingList/BookingListColumns";
import {TableType as CourseTableType} from "../../containers/courses/CourseEdit/CourseListColumns";
import {TableType as PaymentTableType} from "../../containers/payments/PaymentsListColumns";
import {TablesEnum} from "../../generated/graphql";
import {CurrencySettings} from "../currencyHelper";
import {CurrencyShort} from "../renderAmount";
import {attendeeValues} from "./attendeeValues";
import {bookerValues} from "./bookerValues";
import {bookingValues} from "./bookingValues";
import {courseTypeValues} from "./courseTypeValues";
import {courseValues} from "./courseValues";
import {locationValues} from "./locationValues";
import {paymentValues} from "./paymentValues";

export type CsvDataRow = {[dataIndex: string]: string | number};

type BookingColumns = Array<Extract<keyof BookingTableType, string>>;
type PaymentColumns = Array<Extract<keyof PaymentTableType, string>>;
type AllColumns = BookingColumns & PaymentColumns;

export const getCSVData = ({
    selectedRows,
    userColumns,
    allColumns,
    hiddenColumns,
    currency,
    tablesEnum,
}: {
    selectedRows?: Array<BookingTableType | CourseTableType>;
    userColumns?: Array<string>;
    // allColumns: {[key in keyof BookingTableType]: string}; // needs to be generic so it covers the different table types
    allColumns: {[key: string]: string}; // needs to be generic so it covers the different table types
    hiddenColumns?: Array<string>;
    currency: CurrencySettings;
    tablesEnum?: TablesEnum;
}): Array<CsvDataRow> => {
    // console.log("tablesEnum", tablesEnum);

    const columnsToShow = userColumns?.filter(
        (column) => !hiddenColumns?.includes(column),
    );

    const dataType:
        | "unknown"
        | "booking"
        | "course"
        | "payments"
        | "location"
        | "courseType"
        | "booker"
        | "attendee" =
        tablesEnum === undefined
            ? "unknown"
            : [
                  TablesEnum.Bookings,
                  TablesEnum.BookingsAttendeeDetails,
                  // TablesEnum.BookingsByCourse,
                  TablesEnum.BookingsByCourseExpandedBookings,
                  TablesEnum.InvoicesUnsetteled,
                  TablesEnum.InvoicesSettled,
                  TablesEnum.CourseDetailsCanceledBookingsList,
              ].includes(tablesEnum)
            ? "booking"
            : [
                  TablesEnum.CourseOverview,
                  TablesEnum.InvoiceListByBookingUnsetteled,
                  TablesEnum.BookingsByCourse,
              ].includes(tablesEnum)
            ? "course"
            : [
                  TablesEnum.OpenPaymentsList,
                  TablesEnum.DonePaymentsList,
              ].includes(tablesEnum)
            ? "payments"
            : [TablesEnum.Locations].includes(tablesEnum)
            ? "location"
            : [TablesEnum.CourseTypes].includes(tablesEnum)
            ? "courseType"
            : [TablesEnum.Bookers].includes(tablesEnum)
            ? "booker"
            : [TablesEnum.AttendeesOverview].includes(tablesEnum)
            ? "attendee"
            : "unknown";

    // console.log("dataType", dataType);

    const notExportable = "nicht exportierbar";

    const currencyShort = currency.short as CurrencyShort;

    // Transforming each selected row into the CSV data row format
    const csvData: Array<CsvDataRow> =
        selectedRows?.map((row, index) => {
            const csvDataRow: CsvDataRow = {};

            const getValue = (key: string): string => {
                switch (dataType) {
                    case "booking":
                        return bookingValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    case "course":
                        return courseValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    case "payments":
                        return paymentValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    case "location":
                        return locationValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    case "courseType":
                        return courseTypeValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    case "booker":
                        return bookerValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    case "attendee":
                        return attendeeValues(
                            key,
                            row,
                            index,
                            currencyShort,
                            notExportable,
                        );
                    default:
                        return notExportable;
                }
            };

            columnsToShow?.forEach((key) => {
                const columnName = allColumns[key];

                if (columnName) {
                    csvDataRow[columnName] = getValue(key);
                }
            });

            return csvDataRow;
        }) ?? [];

    // console.log("csvData", csvData);

    return rearrangeBookings(csvData);
};

const rearrangeBookings = (bookings: Array<CsvDataRow>): Array<CsvDataRow> => {
    return bookings.map((booking) => {
        const {"#": number, ...rest} = booking;

        return {"#": number, ...rest};
    });
};

// // Type guard functions
// const isBookingTableType = (row: any): row is BookingTableType => {
//     // const uniquePropertyOfBooking = row.bookingNumber;
//     const uniquePropertyOfBooking = "prefixedBookingNumber";

//     return row && typeof row === "object" && uniquePropertyOfBooking in row;
// };

// const isCourseTableType = (row: any): row is CourseTableType => {
//     // const uniquePropertyOfCourse = row.courseNumber;
//     const uniquePropertyOfCourse = "prefixedCourseNumber";

//     return row && typeof row === "object" && uniquePropertyOfCourse in row;
// };
