import React, {FC, memo} from "react";
import LayoutNotFound from "../../views/LayoutNotFound/LayoutNotFound";

type NotFoundProps = {};

const NotFound: FC<NotFoundProps> = () => {
    return <LayoutNotFound />;
};

export default memo(NotFound);
