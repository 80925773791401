import React, {FC, memo, ReactNode} from "react";
import css from "./PageContentWrapper.less";

type PageContentWrapperProps = {
    children: ReactNode;
    style?: React.CSSProperties;
};

const PageContentWrapper: FC<PageContentWrapperProps> = ({children, style}) => {
    return (
        <div className={css.root} style={style}>
            {children}
        </div>
    );
};

export default memo(PageContentWrapper);
