export const colorBlack = "#111111";

export const colorWhite = "#ffffff";

// Kursorganizer custom colors
export const colorPrimaryBackground = "#0760FF";

// Based on https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=ee293d&secondary.color=1071cc
export const colorRed = "#ee293d";
export const colorRedLight = "#ff6668";
export const colorRedDark = "#b40016";

export const colorTurquoise = "#00b6ff";
export const colorBlue = colorPrimaryBackground;
export const colorBlueLight = "#4588FF"; // "#5472d3";
export const colorBlueSlight = "#80C2FF";
export const colorBlueDark = "#002171";

export const colorGrey = "#979ea5";
export const colorDarkGrey = "#545454";
export const colorLightGrey = "#929292";
export const colorSuperLightGrey = "#b8b8b8";
export const colorBackgroundBase = "F5F5F5";

// Ant overrides
// See defaults here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

export const primaryColor = colorBlue; // primary color for all components
export const primaryColor75 = "#1990FF";
export const linkColor = colorBlue; // link color
export const successColor = colorBlue; // success state color
export const warningColor = colorRedLight; // warning state color
export const errorColor = colorRed; // error state color

// Disabled states
// export const disabledColor = colorLightGrey;
// export const disabledBg = colorBackgroundBase;

export const primary1 = "tint(@primary-color, 95%)";

export const borderColorBase = colorGrey; // major border color

export const layoutBodyBackground = colorPrimaryBackground;
export const layoutMainAreaBackground = "whitesmoke";
export const layoutHeaderBackground = "transparent";
