import {DownOutlined} from "@ant-design/icons";
import {Button, Dropdown, Form, Input, MenuProps, Space} from "antd";
import classnames from "classnames";
import React, {memo, FC, useState} from "react";
import {EmailVariablesMenu} from "../../../../shared/templates/templateMail.variableTypes";
import {EditTemplateInput, MailDataType} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import css from "./EmailEditor.less";

export type TemplateField = Extract<keyof EditTemplateInput, string>;

export type EmailFormTemplateInput = EditTemplateInput & {id: string};

const {TextArea} = Input;

const defaultFieldContent: EmailFormTemplateInput = {
    // const defaultFieldContent: GetTemplateByIdQuery["template"] = {
    subject: "subject",
    headerText: "headerText",
    footerText: "footerText",
    signature: "signature",
    id: "",
    // templateType: TemplateType.BookingConfirmation,
};

// export type TemplateField = Extract<
//     keyof GetTemplateByIdQuery["template"],
//     string
// >;

type InputFieldRefactoredProps = {
    field: TemplateField;
    templateContent: EmailFormTemplateInput;
    // templateContent: GetTemplateByIdQuery["template"];
    handleTextChange: (description: TemplateField, content: string) => void;
    minRows?: number;
    mailDataType: MailDataType;
    style?: React.CSSProperties;
    isNoTemplate?: boolean;
};

const InputFieldRefactored: FC<InputFieldRefactoredProps> = ({
    field,
    templateContent = defaultFieldContent,
    // templateContent,
    handleTextChange,
    minRows = 1,
    mailDataType,
    style,
    isNoTemplate,
}) => {
    // Handle Different Variable Menus -----------------------------------------------------------------------------
    const variableMenu: Array<EmailVariablesMenu> = [];
    // Booker ------------------------------------------------------------------------------------------------------

    const bookerBasicVariables: EmailVariablesMenu = {
        key: "bookerBasicVariables",
        type: "group",
        label: "Bucher",
        children: [
            {label: "Anrede Förmlich", key: "bookerSalutationFormal"},
            {label: "Anrede Informell", key: "bookerSalutation"},
            {label: "Vorname", key: "bookerFirstName"},
            {label: "Nachname", key: "bookerLastName"},
            {label: "Voller Name", key: "bookerFullName"},
            {label: "Geschlecht", key: "bookerGender"},
            {label: "Geburtsdatum", key: "bookerBirthday"},
            {
                label: "Kundennummer",
                key: "bookerCustomerNumber",
            },
            {label: "Email", key: "bookerEmail"},
            {label: "Anschrift", key: "bookerAddress"},
            {label: "Teilnehmer", key: "bookerAttendees"},
        ],
    };
    const bookerAddressVariables: EmailVariablesMenu = {
        key: "bookerAddressVariables",
        // type: "group",
        label: "Bucher Details",
        children: [
            {label: "Krankenkasse", key: "bookerHealthInsuranceFund"},
            {label: "Datum Erstkontakt", key: "bookerInitialContact"},
            {label: "Newsletter", key: "bookerNewsLetter"},
            {label: "Anmerkung", key: "bookerAnnotation"},
            {label: "Mobilnummer", key: "bookerMobileNumber"},
            {label: "Festnetznummer", key: "bookerPhoneNumber"},
            {label: "PLZ", key: "bookerPostCode"},
            {label: "Stadt", key: "bookerCity"},
            {label: "Straße", key: "bookerStreet"},
            {label: "Zusatz", key: "bookerAddition"},
            {label: "DSGVO", key: "bookerDSGVO"},
            {label: "Bezahlmehtoden", key: "bookerPaymentMethods"},
        ],
    };

    const bookerSepaVariables: EmailVariablesMenu = {
        key: "bookerSepaVariables",
        // type: "group",
        label: "Sepa Lastschrift",
        children: [
            {label: "Kontoinhaber", key: "bookerSepaAccountOwner"},
            {label: "IBAN", key: "bookerIban"},
            {label: "Bankname", key: "bookerBankName"},
        ],
    };

    // Attendee ------------------------------------------------------------------------------------------------------

    const attendeeBasicVariables: EmailVariablesMenu = {
        key: "attendeeBasicVariables",
        type: "group",
        label: "Teilnehmer",
        children: [
            {label: "Vorname", key: "attendeeFirstName"},
            {label: "Nachname", key: "attendeeLastName"},
            {label: "Voller Name", key: "attendeeFullName"},
            {label: "Geschlecht", key: "attendeeGender"},
            {label: "Geburtsdatum", key: "attendeeBirthday"},
            {label: "Alter", key: "attendeeAge"},
            {label: "Zusatz", key: "attendeeAddition"},
            {label: "Mitglied", key: "attendeeMember"},
            {label: "Buchungen", key: "attendeeBookings"},
            {label: "Bucher", key: "attendeeBooker"},
        ],
    };

    // Booking ------------------------------------------------------------------------------------------------------

    const bookingBasicVariables: EmailVariablesMenu = {
        key: "bookingBasicVariables",
        // type: "group",
        label: "Buchung",
        children: [
            {label: "Buchungsnummer", key: "bookingNumber"},
            {label: "Teilnehmer", key: "attendeeFullName"},
            {label: "Bucher", key: "attendeeBooker"},
            {label: "Bruttopreis", key: "grossPrice"},
            {label: "Nettopreis", key: "netPrice"},
            {label: "Mwst.", key: "bookingTaxRate"},
            {label: "Verrechnete Gutschrift", key: "creditedBalance"},
            {label: "Art der Buchung", key: "bookingType"},
            {label: "Abgerechnet", key: "bookingSettled"},
            {label: "Zuetzt bearbeitet am", key: "bookingLastEdited"},
            {label: "Onlinebuchung", key: "bookingOnlineBooking"},
            {label: "Notiz Mitarbeiter ", key: "noticeStaff"},
            {label: "Sonderpreis", key: "bookingSpecialPrice"},
            {label: "Bezahldatum", key: "payDate"},
            // {label: "Rechnung", key: "bookingInvoice"},
            {label: "Bezahlmethode", key: "paymentMethod"},
            {label: "Bezahlart (Vorkasse/Moantlich)", key: "paymentType"},
            {label: "Rechnungsnummer", key: "invoiceNumber"},
        ],
    };

    const bookingCourseVariables: EmailVariablesMenu = {
        key: "bookingCourseVariables",
        // type: "group",
        label: "Kurs",
        children: [
            {label: "Kursnummer", key: "courseNumber"},
            {label: "Kursart", key: "courseType"},
            {label: "Subtitel des Kurses", key: "bookingCourseDescription"},
            {label: "Anmerkungen", key: "bookingCourseAnnotation"},
            {label: "Kursort", key: "location"},
            {label: "Becken/Raum", key: "venue"},
            {label: "Kursstandortname", key: "bookingLocationName"},
            {label: "Straße", key: "bookingLocationStreet"},
            {label: "PLZ", key: "bookingLocationZipCode"},
            {label: "Ort", key: "bookingLocationCity"},
            {label: "Erste Kurseinheit", key: "firstCourseLesson"},
            {label: "Letzte Kurseinheit", key: "lastCourseLessonDate"},
            {label: "Anz. Kurseinheiten", key: "courseLessonCount"},
            {label: "Kurseinheiten", key: "bookingCourseUnits"},
            {label: "Folgekurse-Link", key: "referenceCoursesLink"},
        ],
    };

    const bookingBookerVariables: EmailVariablesMenu = {
        key: "bookingBookerVariables",
        // type: "group",
        label: "Bucher",
        children: [
            {label: "Anrede Förmlich", key: "bookerSalutationFormal"},
            {label: "Anrede Informell", key: "bookerSalutation"},
            {label: "Vorname", key: "bookerFirstName"},
            {label: "Nachname", key: "bookerLastName"},
            {label: "Voller Name", key: "bookerFullName"},
            {label: "IBAN", key: "bookerIban"},
            {label: "Name der Bank", key: "bookerBankName"},
            {label: "Kontoinhaber", key: "bookerSepaAccountOwner"},
            {label: "Anrede Förmlich", key: "bookerSalutationFormal"},
            {label: "Anrede Informell", key: "bookerSalutation"},
            {label: "Vorname", key: "bookerFirstName"},
            {label: "Nachname", key: "bookerLastName"},
            {label: "Voller Name", key: "bookerFullName"},
            {label: "IBAN", key: "bookerIban"},
            {label: "Name der Bank", key: "bookerBankName"},
            {label: "Kontoinhaber", key: "bookerSepaAccountOwner"},
            {label: "Krankenkasse", key: "bookerHealthInsuranceFund"},
            {label: "Datum Erstkontakt", key: "bookerInitialContact"},
            {label: "Newsletter", key: "bookerNewsLetter"},
            {label: "Anmerkung", key: "bookerAnnotation"},
            {label: "Mobilnummer", key: "bookerMobileNumber"},
            {label: "Festnetznummer", key: "bookerPhoneNumber"},
            {label: "PLZ", key: "bookerPostCode"},
            {label: "Stadt", key: "bookerCity"},
            {label: "Straße", key: "bookerStreet"},
            {label: "Zusatz", key: "bookerAddition"},
            {label: "DSGVO", key: "bookerDSGVO"},
            // {label: "Bezahlmehtoden", key: "bookerPaymentMethods"},
            // {label: "Kontoinhaber", key: "bookerSepaAccountOwner"},
            // {label: "IBAN", key: "bookerIban"},
            // {label: "Bankname", key: "bookerBankName"},
        ],
    };

    const bookingAttendeeVariables: EmailVariablesMenu = {
        key: "bookingAttendeeVariables",
        // type: "group",
        label: "Teilnehmer",
        children: [
            {label: "Voller Name", key: "attendee"},
            {label: "Vorname", key: "attendeeFirstName"},
            {label: "Nachname", key: "attendeeLastName"},
            {label: "Geschlecht", key: "attendeeGender"},
            {label: "Geburtsdatum", key: "attendeeBirthday"},
            {label: "Alter", key: "attendeeAge"},
            {label: "Zusatz", key: "attendeeAddition"},
            {label: "Mitglied", key: "attendeeMember"},
            {label: "Buchungen", key: "attendeeBookings"},
        ],
    };

    const bookingInstructorVariables: EmailVariablesMenu = {
        key: "bookingInstructorVariables",
        // type: "group",
        label: "Kursleiter",
        children: [
            {label: "Vorname KL 1", key: "instructorFirstName1"},
            {label: "Nachname KL 1", key: "instructorLastName1"},
            {label: "Email KL 1", key: "instructorEmail1"},
            {label: "Vorname KL 2", key: "instructorFirstName2"},
            {label: "Nachname KL 2", key: "instructorLastName2"},
            {label: "Email KL 2", key: "instructorEmail2"},
        ],
    };

    const bookingLessonVariables: EmailVariablesMenu = {
        key: "bookingLessonVariables",
        label: "Kurseinheiten",
        children: [
            {label: "Anz. Kurseinheiten", key: "courseLessonCount"},
            {label: "Datum Kurseinheit 01", key: "courseLessonDate01"},
            {label: "Datum Kurseinheit 02", key: "courseLessonDate02"},
            {label: "Datum Kurseinheit 03", key: "courseLessonDate03"},
            {label: "Datum Kurseinheit 04", key: "courseLessonDate04"},
            {label: "Datum Kurseinheit 05", key: "courseLessonDate05"},
            {label: "Datum Kurseinheit 06", key: "courseLessonDate06"},
            {label: "Datum Kurseinheit 07", key: "courseLessonDate07"},
            {label: "Datum Kurseinheit 08", key: "courseLessonDate08"},
            {label: "Datum Kurseinheit 09", key: "courseLessonDate09"},
            {label: "Datum Kurseinheit 10", key: "courseLessonDate10"},
            {label: "Datum Kurseinheit 11", key: "courseLessonDate11"},
            {label: "Datum Kurseinheit 12", key: "courseLessonDate12"},
            {label: "Datum Kurseinheit 13", key: "courseLessonDate13"},
            {label: "Datum Kurseinheit 14", key: "courseLessonDate14"},
            {label: "Datum Kurseinheit 15", key: "courseLessonDate15"},
            {label: "Datum Kurseinheit 16", key: "courseLessonDate16"},
            {label: "Datum Kurseinheit 17", key: "courseLessonDate17"},
            {label: "Datum Kurseinheit 18", key: "courseLessonDate18"},
            {label: "Datum Kurseinheit 19", key: "courseLessonDate19"},
            {label: "Datum Kurseinheit 20", key: "courseLessonDate20"},
        ],
    };

    const bookingScheduledPaymentVariables: EmailVariablesMenu = {
        key: "bookingScheduledPaymentVariables",
        label: "Montliche Raten",
        children: [
            {label: "Anz. Raten", key: "scheduledPaymentCount"},
            {label: "Monatsrate 01 Betrag", key: "scheduledPayments1amount"},
            {label: "Monatsrate 01 Datum", key: "scheduledPayments1due"},
            {label: "Monatsrate 02 Betrag", key: "scheduledPayments2amount"},
            {label: "Monatsrate 02 Datum", key: "scheduledPayments2due"},
            {label: "Monatsrate 03 Betrag", key: "scheduledPayments3amount"},
            {label: "Monatsrate 03 Datum", key: "scheduledPayments3due"},
            {label: "Monatsrate 04 Betrag", key: "scheduledPayments4amount"},
            {label: "Monatsrate 04 Datum", key: "scheduledPayments4due"},
            {label: "Monatsrate 05 Betrag", key: "scheduledPayments5amount"},
            {label: "Monatsrate 05 Datum", key: "scheduledPayments5due"},
            {label: "Monatsrate 06 Betrag", key: "scheduledPayments6amount"},
            {label: "Monatsrate 06 Datum", key: "scheduledPayments6due"},
            {label: "Monatsrate 07 Betrag", key: "scheduledPayments7amount"},
            {label: "Monatsrate 07 Datum", key: "scheduledPayments7due"},
            {label: "Monatsrate 08 Betrag", key: "scheduledPayments8amount"},
            {label: "Monatsrate 08 Datum", key: "scheduledPayments8due"},
            {label: "Monatsrate 09 Betrag", key: "scheduledPayments9amount"},
            {label: "Monatsrate 09 Datum", key: "scheduledPayments9due"},
            {label: "Monatsrate 10 Betrag", key: "scheduledPayments10amount"},
            {label: "Monatsrate 10 Datum", key: "scheduledPayments10due"},
            {label: "Monatsrate 11 Betrag", key: "scheduledPayments11amount"},
            {label: "Monatsrate 11 Datum", key: "scheduledPayments11due"},
            {label: "Monatsrate 12 Betrag", key: "scheduledPayments12amount"},
            {label: "Monatsrate 12 Datum", key: "scheduledPayments12due"},
        ],
    };

    // VariablesMenu ------------------------------------------------------------------------------------------------------

    switch (mailDataType) {
        case MailDataType.Booker:
            variableMenu.push(
                bookerBasicVariables,
                bookerAddressVariables,
                bookerSepaVariables,
            );
            break;
        case MailDataType.Attendee:
            variableMenu.push(attendeeBasicVariables);
            break;

        case MailDataType.Booking:
            variableMenu.push(
                bookingBasicVariables,
                bookingCourseVariables,
                bookingBookerVariables,
                bookingAttendeeVariables,
                bookingInstructorVariables,
                bookingLessonVariables,
                bookingScheduledPaymentVariables,
            );
            break;

        case MailDataType.Course:
            variableMenu.push(
                bookingBasicVariables,
                bookingCourseVariables,
                bookingBookerVariables,
                bookingAttendeeVariables,
                bookingInstructorVariables,
                bookingLessonVariables,
            );
            break;

        case MailDataType.AttendanceList:
            variableMenu.push(
                bookingCourseVariables,
                bookingInstructorVariables,
                bookingLessonVariables,
            );
            break;

        case MailDataType.ScheduledPayment:
            variableMenu.push(
                bookingBasicVariables,
                bookingCourseVariables,
                bookingBookerVariables,
                bookingAttendeeVariables,
                bookingInstructorVariables,
                bookingLessonVariables,
                bookingScheduledPaymentVariables,
            );
            break;
        default:
            break;
    }

    // Variable Menus -----------------------------------------------------------------------------

    // Paste variable at cursor position

    // const [cursorPosition, setCursorPosition] = useState<number>(
    //     inputText.length,
    // );
    const [cursorPosition, setCursorPosition] = useState<number>(0);

    const updateCursorPosition = (
        e:
            | React.MouseEvent<HTMLTextAreaElement, MouseEvent>
            | React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
        setCursorPosition(e.currentTarget.selectionStart);
    };

    const insertText = (
        inputText: string,
        variable: string,
        cursorPosition: number,
    ) => {
        const textToInsert = `{{ ${variable} }}`;
        const textBeforeCursorPosition = inputText.substring(0, cursorPosition);
        const textAfterCursorPosition = inputText.substring(
            cursorPosition,
            inputText.length,
        );

        return (
            textBeforeCursorPosition + textToInsert + textAfterCursorPosition
        );
    };

    const menu = (
        field: TemplateField,
        inputText: string | undefined,
    ): MenuProps => {
        return {
            items: variableMenu,
            onClick: ({key}) => {
                // const content = `${inputText} {{ ${key} }}`;
                const inputTextValue = inputText ? inputText : "";
                const content = insertText(inputTextValue, key, cursorPosition);

                handleTextChange(field, content);
            },
        };
    };

    const VariablesMenu = ({
        field,
        inputText,
    }: {
        field: TemplateField;
        inputText: string | undefined;
    }) => {
        return (
            <Dropdown
                menu={menu(field, inputText)}
                className={isNoTemplate ? undefined : css.variablesMenu}
            >
                <Button type="link">
                    <Space>
                        {"Variablen"}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        );
    };

    // Return -----------------------------------------------------------------------------

    const inputField = (field: TemplateField) => {
        const inputText = templateContent[field] ?? "";
        const label = i18n.containers.templates.EmailTemplates.sections[
            field
        ]();

        return (
            <>
                <VariablesMenu field={field} inputText={inputText} />
                <Form.Item
                    label={label}
                    key={field}
                    className={classnames(css.formItem)}
                    // style={{margin: 20, ...style}}
                >
                    <TextArea
                        key={field}
                        className={classnames(css.textArea)}
                        placeholder={i18n.views.EditTextField.placeholder()}
                        allowClear
                        value={templateContent[field] ?? ""}
                        // onChange={(e) => handleTextChange(field, e.target.value)}
                        onChange={(e) => {
                            handleTextChange(field, e.target.value);
                            setCursorPosition(e.target.selectionStart);
                        }}
                        onClick={updateCursorPosition}
                        onKeyUp={updateCursorPosition}
                        autoSize={{minRows, maxRows: 20}}
                    />
                </Form.Item>
            </>
        );
    };

    return inputField(field);
};

export default memo(InputFieldRefactored);
