import React, {FC, memo, useEffect, useState} from "react";
import {Button, Modal} from "antd";
import classnames from "classnames";
import PDFViewer from "pdf-viewer-reactjs";
import {red} from "@ant-design/colors";
import css from "./PDFViewerModal.less";
import {layoutPaddingBig, layoutPaddingRegular} from "../../styles/layout";
import LoadingContent from "../LoadingContent/LoadingContent";

type PDFViewerProps = {
    title?: string;
    fileUrl?: string;
    invoiceBody: string;
    invoiceFileName?: string;
    handleOk: () => void;
    handleCancel: () => void;
    visible: boolean;
    asModal?: boolean;
    kindOfDocument?: string;
    // dimensions?: {
    //     width: number;
    //     height: number;
    // };
};

const PDFViewerModal: FC<PDFViewerProps> = ({
    title,
    handleOk,
    handleCancel,
    visible,
    invoiceBody = "",
    invoiceFileName = "",
    asModal = true,
    kindOfDocument = "Rechnung",
    // dimensions,
}) => {
    const [fileURL, setFileURL] = useState("");

    const documentWidth = document.documentElement.clientWidth;
    const documentHeight = document.documentElement.clientHeight;
    const aspectRatio = documentWidth / documentHeight;

    console.log("aspectRatio: ", aspectRatio);
    console.log("documentWidth: ", documentWidth);
    console.log("documentHeight: ", documentHeight);

    const base64ToBlob = (base64: any) => {
        setFileURL("");
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const file = new Blob([bytes], {
            type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);

        setFileURL(fileURL);

        return file;
    };

    useEffect(() => {
        if (!visible) {
            setFileURL("");
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            base64ToBlob(invoiceBody);
        }
    }, [invoiceBody, visible]);

    useEffect(() => {
        if (!visible && fileURL !== "") {
            setFileURL("");
        }
    }, [fileURL, visible]);

    const now = new Date().toISOString();

    const renderContent = () => {
        if (fileURL && fileURL === "") {
            return (
                <div>{"Leider haben wir diese Pdf nirgens finden können."}</div>
            );
        }

        // console.log("invoiceBody", invoiceBody);

        if (!fileURL || fileURL === "" || !visible) {
            return null;
        }

        return (
            <PDFViewer
                scale={1.5}
                document={{
                    // url: fileURL,
                    base64: invoiceBody,
                }}
                // hideNavbar={true}
                hideRotation
                hideZoom
                navbarOnTop
                navigation={{
                    css: {
                        navbarWrapper: css.navbarWrapper,
                        previousPageBtn: css.previousPageBtn,
                        nextPageBtn: css.nextPageBtn,
                        pageIndicator: css.pageIndicator,
                    },
                }}
                canvasCss={css.pdfViewer}
                loader={<LoadingContent />}
            />
        );
    };

    const footer = [
        <Button
            className={css.download}
            type="primary"
            key="download"
            href={fileURL}
            download={`${invoiceFileName}`}
        >
            {`${kindOfDocument} herunterladen`}
        </Button>,
        <Button
            style={{color: red[3]}}
            key="close"
            className={css.cancel}
            onClick={handleCancel}
        >
            {"Vorschau schließen"}
        </Button>,
    ];

    if (asModal) {
        return (
            <Modal
                key={now} // Ensure that the component gets created from scratch
                bodyStyle={{
                    padding: layoutPaddingRegular,
                    overflow: "auto",
                    maxHeight: `calc(100vh - 220px - ${layoutPaddingBig})`,
                }}
                width="250mm"
                className={classnames(css.root, css.withoutBorder)}
                // visible={visible}
                open={visible}
                title={title}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                footer={footer}
            >
                {renderContent()}
            </Modal>
        );
    }

    return renderContent();
};

export default memo(PDFViewerModal);
