import React, {FC, memo} from "react";
import {Icon as LegacyIcon} from "@ant-design/compatible";
import {Tag} from "antd";
// import i18n from "../../services/i18n";
import {PaymentMethodType} from "../../helpers/getPaymentMethodType";
import {PaymentTransferType} from "../../generated/graphql";
import i18n from "../../services/i18n";

// BY PAYMENT METHOD TYPE --------------------------------------------------------------------------------------------------------------------
type PaymentMethodMap = {
    [key in PaymentMethodType]: string;
};

const paymentMethodTypeIcons: PaymentMethodMap = {
    sepaDebit: "bank",
    esr: "file-text",
    payPal: "bank",
    creditCard: "credit-card",
    cash: "wallet",
    bankTransfer: "bank",
    none: "bank",
};

const pamyentMethodTypeTranslations: PaymentMethodMap = {
    sepaDebit: "Lastschrift",
    esr: "Einzahlungsschein",
    creditCard: "Kreditkarte",
    payPal: "PayPal",
    cash: "Bar",
    bankTransfer: "Überweisung",
    none: "Keine",
};

export const paymentMethodTypeLabels: PaymentMethodMap = {
    sepaDebit: pamyentMethodTypeTranslations.sepaDebit,
    esr: pamyentMethodTypeTranslations.esr,
    creditCard: pamyentMethodTypeTranslations.creditCard,
    cash: pamyentMethodTypeTranslations.cash,
    bankTransfer: pamyentMethodTypeTranslations.bankTransfer,
    payPal: pamyentMethodTypeTranslations.payPal,
    none: pamyentMethodTypeTranslations.none,
};

// BY PAYMENT TRANSFER TYPE --------------------------------------------------------------------------------------------------------------------

type PaymentTransferMap = {
    [key in PaymentTransferType]: string;
};

const paymentTransferTypeIcons: PaymentTransferMap = {
    SepaDebit: "bank",
    Esr: "file-text",
    Paypal: "bank",
    Creditcard: "credit-card",
    Cash: "wallet",
    BankTransfer: "bank",
    None: "bank",
};

export const paymentTransferTypeLabels: PaymentTransferMap = {
    SepaDebit: pamyentMethodTypeTranslations.sepaDebit,
    Esr: pamyentMethodTypeTranslations.esr,
    Creditcard: pamyentMethodTypeTranslations.creditCard,
    Cash: pamyentMethodTypeTranslations.cash,
    BankTransfer: pamyentMethodTypeTranslations.bankTransfer,
    Paypal: pamyentMethodTypeTranslations.payPal,
    None: pamyentMethodTypeTranslations.none,
};

// ------------------------------------------------------------------------------------------------------------------------------------

type PaymentMethodBadgeProps = {
    type?: PaymentMethodType;
    transferType?: PaymentTransferType;
};

const PaymentMethodBadge: FC<PaymentMethodBadgeProps> = ({
    type,
    transferType,
}) => {
    if (!type && !transferType) {
        return null;
    }

    if (type) {
        const label = paymentMethodTypeLabels[type];

        const icon = (
            <LegacyIcon
                type={paymentMethodTypeIcons[type]}
                theme="twoTone"
                style={{marginRight: 3}}
            />
        );

        return (
            <Tag style={{borderColor: "lightgrey"}}>
                {icon} {label}
            </Tag>
        );
    }

    if (transferType) {
        const label = paymentTransferTypeLabels[transferType];

        const icon = (
            <LegacyIcon
                type={paymentTransferTypeIcons[transferType]}
                theme="twoTone"
                style={{marginRight: 3}}
            />
        );

        return (
            <Tag style={{borderColor: "lightgrey"}}>
                {icon} {label}
            </Tag>
        );
    }

    return null;
};

export default memo(PaymentMethodBadge);
