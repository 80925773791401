import {useEffect, useState} from "react";
import {Currency, useGetCurrencySettingQuery} from "../generated/graphql";
import i18n from "../services/i18n";
import {apolloClient} from "../containers/common/ApolloWrapper";

export type CurrencySettings = {
    long: string;
    short: string;
    symbol: string;
    decimalSeperator: string;
};

export const useCurrency = (): CurrencySettings => {
    const [currency, setCurrency] = useState<Currency>(Currency.Eur);
    const [currencySettings, setCurrencySettings] = useState<CurrencySettings>({
        long: i18n.containers.common.Currencies.Eur.long(),
        short: i18n.containers.common.Currencies.Eur.short(),
        symbol: i18n.containers.common.Currencies.Eur.symbol(),
        decimalSeperator: i18n.containers.common.Currencies.Eur.decimalSeperator(),
    });

    const {data: currencyData} = useGetCurrencySettingQuery({
        client: apolloClient,
    });

    const currencySetting: Currency = currencyData?.settings.currency
        ? Currency[currencyData.settings.currency]
        : Currency.Eur;

    useEffect(() => {
        setCurrency(currencySetting);
        setCurrencySettings({
            long: i18n.containers.common.Currencies[currency].long(),
            short: i18n.containers.common.Currencies[currency].short(),
            symbol: i18n.containers.common.Currencies[currency].symbol(),
            decimalSeperator: i18n.containers.common.Currencies[
                currency
            ].decimalSeperator(),
        });
    }, [currency, currencySetting]);

    return currencySettings;
};
