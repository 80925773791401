import React, {FC, memo} from "react";
import css from "./TopLevelHero.less";
import Calendar from "../../assets/topLevelPage/styled-Calendar.svg";
import Cross from "../../assets/topLevelPage/styled-Cross.svg";
import KoLogo from "../../assets/topLevelPage/styled-KoLogo.svg";
import List from "../../assets/topLevelPage/styled-List.svg";
import Next from "../../assets/topLevelPage/styled-Next.svg";
import Dots from "../../assets/topLevelPage/styled-Dots.svg";
import Switch from "../../assets/topLevelPage/styled-Switch.svg";

type TopLevelHeroProps = {
    headLine: string;
    subHeadLine: string;
    slogan?: string;
    crossAlign?: "left" | "center" | "right";
    style?: React.CSSProperties;
};

const TopLevelHero: FC<TopLevelHeroProps> = ({
    headLine = "Headline",
    subHeadLine = "SubHeadline",
    slogan,
    crossAlign = "center",
    style,
}) => {
    return (
        <div className={css.root} style={style}>
            <div className={css.innerHero}>
                <div className={css.typo}>
                    <div className={css.headLine}>{headLine}</div>
                    <div className={css.subHeadLine}>{subHeadLine}</div>
                    <div className={css.slogan}>{slogan}</div>
                </div>
                <div className={css.background}>
                    <img
                        src={KoLogo}
                        alt="ko-logo"
                        width={130}
                        style={{position: "absolute", top: 0, left: "14%"}}
                    />
                    <img
                        src={List}
                        alt="list-icon"
                        width={120}
                        style={{position: "absolute", top: 120, left: "6%"}}
                    />
                    <img
                        src={Next}
                        alt="next-icon"
                        width={160}
                        style={{position: "absolute", top: 210, left: "22%"}}
                    />
                    <img
                        src={Calendar}
                        alt="calendar-icon"
                        width={120}
                        style={{position: "absolute", top: 5, right: "14%"}}
                    />
                    <img
                        src={Dots}
                        alt="dots-icon"
                        height={120}
                        style={{position: "absolute", top: 120, right: "23%"}}
                    />
                    <img
                        src={Switch}
                        alt="switch-icon"
                        width={120}
                        style={{position: "absolute", top: 220, right: "3%"}}
                    />
                    <img
                        id="cross"
                        className={css.cross}
                        src={Cross}
                        alt="cross-icon"
                        width={120}
                        style={{
                            position: "absolute",
                            top: 240,
                            right:
                                crossAlign == "left"
                                    ? "75%"
                                    : crossAlign == "right"
                                    ? "25%"
                                    : undefined,
                        }}
                        // style={{position: "relative", bottom: 5}}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(TopLevelHero);
