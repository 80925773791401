// Anything can get thrown, hence the any type.
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {ErrorInfo, ReactNode} from "react";
import {notification} from "antd";
import i18n from "../../services/i18n";
import env from "../../../shared/const";

type ErrorHandlerProps = {
    children?: ReactNode;
};

type ErrorHandlerState = {
    error?: Error;
};

// Anything can get thrown, hence the any type.
const errorInfoToErrorObject = (errorInfo: any): Error => {
    if (errorInfo instanceof Error) {
        return errorInfo;
    }
    if (Boolean(errorInfo) && "reason" in errorInfo) {
        return errorInfoToErrorObject(errorInfo.reason);
    }

    const errorInstance = new Error("Unexpected exception: " + errorInfo);

    // console.log("---------------------");
    // console.log(`NEW ERROR::: ${errorInstance}`);
    // console.log(errorInstance);
    // console.log("---------------------");

    return errorInstance;
};

class ErrorHandler extends React.PureComponent<ErrorHandlerProps> {
    static getDerivedStateFromError = (error: Error, info: ErrorInfo) => {
        return {
            error,
        };
    };

    state: ErrorHandlerState = {
        error: undefined,
    };

    notificationContainer = React.createRef<HTMLSelectElement>();

    componentDidMount = () => {
        window.addEventListener("error", this.showNotification);
        window.addEventListener("unhandledrejection", this.showNotification);
    };

    componentWillUnmount = () => {
        window.removeEventListener("error", this.showNotification);
        window.removeEventListener("unhandledrejection", this.showNotification);
    };

    componentDidCatch = (error: Error, info: ErrorInfo) => {
        // Intended `console.log` calls to output error details with stacktrace
        env.IS_TESTING === false && console.warn(info.componentStack);

        this.showNotification(error);
    };

    showNotification = (errorInfo: any): void => {
        const error = errorInfoToErrorObject(errorInfo);

        // console.group("showNotification");
        // console.log("error: ", error);
        // console.log("errorInfo: ", errorInfo);
        // console.log("errorInfo.message: ", errorInfo.message);
        // console.log("errorInfo.filename: ", errorInfo.filename);
        // console.groupEnd();

        const errorTextToIgnore = [
            "Script error.",
            "ResizeObserver loop limit exceeded",
            "ResizeObserver loop completed with undelivered notifications.",
        ];

        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        if (errorTextToIgnore.includes(errorInfo.message)) {
            console.log(`!!! Ignoring: ${errorInfo.message}`);

            return;
        }

        env.IS_TESTING === false && console.error(error.stack);
        notification.error({
            message: i18n.containers.common.ErrorHandler.modalTitle(),
            description: error.message,
            getContainer: this.getNotificationContainer,
        });
    };

    getNotificationContainer = () => {
        const container = this.notificationContainer.current;

        return container === null ? document.body : container;
    };

    render = () => {
        // Don't render the children when render() was triggered by componentDidCatch()
        // otherwise we would end up with an infinite loop.
        const children =
            this.state.error === undefined ? this.props.children : undefined;

        return (
            <>
                {children}
                <section ref={this.notificationContainer} />
            </>
        );
    };
}

export default ErrorHandler;
