import React, {memo, useEffect, useState} from "react";
import {Collapse, Form, Input} from "antd";
import {FormikProps} from "formik";
import {
    MailDataType,
    SendSmsInput,
    useGetSmsReceiversCourseQuery,
    useGetSmsReceiversQuery,
} from "../../../generated/graphql";
import {SelectionType} from "../../templates/EmailRefactoring/EmailTemplatePicker";
import SmsReceiverBar from "./SmsReceiverBar";
import {createInputHelpers} from "../../../helpers/createInputHelpers";
import LayoutForm from "../../../views/LayoutForm/LayoutForm";
import {marginS, marginXs} from "../../../styles/layout";
import SmsCourseBookingTypeSelector, {
    BookingTypeOptions,
} from "./SmsCourseBookingTypeSelector";

type SmsModalContentProps = {
    mailDataType: MailDataType;
    selection: Array<SelectionType>;
    formikProps: FormikProps<SendSmsInput>;
    receiverCount: number;
};

const SmsModalContent: React.FC<SmsModalContentProps> = ({
    mailDataType,
    selection,
    formikProps,
    receiverCount,
}) => {
    const [bookingTypeOptions, setBookingTypeOptions] = useState<
        BookingTypeOptions
    >({
        notifyBookings: true,
        notifyWaitlist: false,
        notifyCancelations: false,
    });
    const selectedIds = selection.map((s) => s.id);

    const {data, loading, error} = useGetSmsReceiversQuery({
        variables: {
            mailDataType,
            selectedIds,
        },
        fetchPolicy: "network-only",
    });

    const {
        data: dataCourseReceivers,
        loading: loadingCourseReceivers,
    } = useGetSmsReceiversCourseQuery({
        variables: {
            getReceiversForCourseInput: {
                courseIds: selectedIds,
                notifyBookings: bookingTypeOptions.notifyBookings,
                notifyWaitlist: bookingTypeOptions.notifyWaitlist,
                notifyCancelations: bookingTypeOptions.notifyCancelations,
            },
        },
        fetchPolicy: "network-only",
    });

    const loadingReceivers = loading || loadingCourseReceivers;

    const receivers =
        mailDataType === MailDataType.Course
            ? dataCourseReceivers?.getSmsReceiversCourse
            : data?.getSmsReceivers;

    // const receivers = data?.getSmsReceivers;
    const receiversWithValidNumber = receivers?.filter(
        (receiver) => receiver.valid === "true",
    );

    useEffect(() => {
        if (receiversWithValidNumber) {
            const receiverMobileNumbers = receiversWithValidNumber
                .map((receiver) => receiver.mobileNumber)
                .join(",");

            if (formikProps.values.to.length !== receiverMobileNumbers.length) {
                if (receiversWithValidNumber.length === 0) {
                    formikProps.setFieldValue("to", "");
                } else {
                    formikProps.setFieldValue("to", receiverMobileNumbers);
                }
            }
        }
    }, [formikProps, receiversWithValidNumber]);

    const {values} = formikProps;
    const {
        handleTextInputChange,
        validateStatus,
        handleBlur,
        help,
    } = createInputHelpers(formikProps);

    const getHeader = () => {
        if (receiverCount === 0) {
            return "Keine validen Empfänger";
        }

        if (receiverCount === 1) {
            return "1 valider Empfänger";
        }

        return `${receiverCount} valide Empfänger`;
    };

    return (
        <>
            <SmsCourseBookingTypeSelector
                bookingTypeOptions={bookingTypeOptions}
                setBookingTypeOptions={setBookingTypeOptions}
                style={{marginTop: marginXs}}
            />
            <Collapse defaultActiveKey="1">
                <Collapse.Panel header={getHeader()} key="1">
                    <SmsReceiverBar
                        receivers={receivers}
                        loading={loadingReceivers}
                    />
                </Collapse.Panel>
            </Collapse>

            <LayoutForm columns={1} style={{marginTop: marginS}}>
                <Form.Item help={help("text")} label="Inhalt der SMS">
                    <Input.TextArea
                        value={values.text}
                        onChange={handleTextInputChange("text")}
                        onBlur={handleBlur("text")}
                        status={validateStatus("text")}
                        allowClear
                        maxLength={160}
                        showCount
                        autoSize={{minRows: 10, maxRows: 20}}
                        disabled={receiverCount === 0}
                    />
                </Form.Item>
            </LayoutForm>
        </>
    );
};

export default memo(SmsModalContent);
