import classNames from "classnames";
import React, {FC, memo, ReactNode} from "react";
import css from "./Section.less";

type SectionProps = {
    title?: string | ReactNode;
    menu?: ReactNode;
    content?: ReactNode;
    showHeader?: boolean;
    secondLineLeft?: React.ReactNode;
    minPadding?: boolean;
    style?: React.CSSProperties;
    className?: string;
    headerBackground?: boolean;
};

const Section: FC<SectionProps> = ({
    content = null,
    title = "",
    menu = null,
    showHeader = true,
    secondLineLeft,
    minPadding = false,
    style,
    className,
    headerBackground = true,
}) => {
    return (
        <section
            style={style}
            className={classNames(
                showHeader
                    ? minPadding
                        ? css.minPadding
                        : css.root
                    : minPadding
                    ? css.minPadding
                    : css.noHeader,
                className,
            )}
        >
            {showHeader && (
                <header
                    style={{zIndex: 100}}
                    className={classNames(
                        css.header,
                        headerBackground ? css.headerBackground : null,
                    )}
                >
                    <h3 className={css.title}>{title}</h3>
                    <div className={css.sectionMenu}>{menu}</div>
                </header>
            )}
            <div id="secondLine">
                {secondLineLeft ? (
                    <div className="leftSide">{secondLineLeft}</div>
                ) : null}
            </div>
            {content}
        </section>
    );
};

export default memo(Section);
