import {Button, Modal} from "antd";
import React, {FC, memo} from "react";
import Markdown from "react-markdown";
import {blue} from "@ant-design/colors";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {marginS, panelWidth} from "../../../../styles/layout";
import {
    transferDetailedDescription,
    transferSimpleDescription,
} from "../MarkdownRenderer";
import css from "./TransferMultipleAttendees.less";

type DescriptionType = "simple" | "detailed";

type TransferDragAndDropDescriptionProps = {
    setDescriptionOpen: (open: boolean) => void;
    descriptionOpen: boolean;
    descritpion?: DescriptionType;
};

const TransferDragAndDropDescription: FC<TransferDragAndDropDescriptionProps> = ({
    setDescriptionOpen,
    descriptionOpen,
    descritpion = "detailed",
}) => {
    if (!descriptionOpen) {
        return (
            <Button
                type="link"
                onClick={() => setDescriptionOpen(true)}
                className={css.shimering}
            >
                <InfoCircleTwoTone />
                {"Hilfe zum Umbuchen von Teilnehmern"}
            </Button>
        );
    }

    return (
        <Modal
            open={descriptionOpen}
            onCancel={() => setDescriptionOpen(false)}
            cancelButtonProps={{style: {display: "none"}}}
            title="Hilfe zum Kopieren bzw. Umbuchen von Teilnehmern"
            onOk={() => setDescriptionOpen(false)}
            width={panelWidth / 2}
            bodyStyle={{
                maxHeight: "70vh",
                overflowY: "auto",
                margin: marginS,
                padding: marginS,
                border: `1px solid ${blue[1]}`,
            }}
        >
            <Markdown>
                {descritpion === "detailed"
                    ? transferDetailedDescription
                    : transferSimpleDescription}
            </Markdown>
        </Modal>
    );
};

export default memo(TransferDragAndDropDescription);
