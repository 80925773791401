import React, {FC, memo} from "react";
import {Formik, FormikProps} from "formik";
import {DbTemplate} from "./Email";
import {
    EditTemplateInput,
    GetTemplateByIdQuery,
    MailDataType,
    useUpdateTemplateMutation,
} from "../../../generated/graphql";
import LayoutForm from "../../../views/LayoutForm/LayoutForm";
import css from "./Email.less";
import InputFieldRefactored from "./InputFieldRefactored";

type EmailFormTemplateInput = EditTemplateInput & {id: string};

type EmailEditorProps = {
    dbTemplate: DbTemplate;
    mailDataType: MailDataType;
    setFormikProps: (formikProps: FormikProps<EditTemplateInput>) => void;
    setEditMode: (editMode: boolean) => void;
    isNoTemplate?: boolean;
};

const EmailEditor: FC<EmailEditorProps> = ({
    dbTemplate,
    mailDataType,
    setFormikProps,
    setEditMode,
    isNoTemplate,
}) => {
    const [
        updateMutation,
        {loading: updateLoading, error: createUpdateError},
    ] = useUpdateTemplateMutation();

    return (
        <Formik<EditTemplateInput>
            initialValues={dbTemplate.data?.template as EditTemplateInput}
            validationSchema={{}}
            onSubmit={(values, formikActions) => {
                if (dbTemplate.data?.template?.id) {
                    const id = dbTemplate.data.template.id;

                    const editTemplateData: EditTemplateInput = {
                        subject: values.subject,
                        headerText: values.headerText,
                        footerText: values.footerText,
                        signature: values.signature,
                    };

                    try {
                        updateMutation({
                            variables: {
                                id,
                                editTemplateData,
                            },
                        }).then((res) => {
                            console.log("===> res", res);
                            setEditMode(false);
                        });
                    } catch (error) {
                        console.log("===> error", error);
                    } finally {
                        formikActions.setSubmitting(false);
                    }
                }
            }}
        >
            {(formikProps) => {
                const {values, setFieldValue} = formikProps;

                const handleTextChange = (
                    description: keyof EditTemplateInput,
                    content: string,
                ) => {
                    setFieldValue(description, content);
                    const updatedValues = {
                        ...formikProps.values,
                        [description]: content,
                    };

                    setFormikProps({...formikProps, values: updatedValues});
                };

                const typedValues = values as GetTemplateByIdQuery["template"];

                return (
                    <div
                        // style={{
                        //     border: "2px solid red",
                        //     overflow: "auto",
                        // }}
                        className={css.emailFormContainer}
                    >
                        {dbTemplate.data?.template && (
                            <LayoutForm
                                name="emailForm"
                                columns={1}
                                className={css.emailForm}
                                // layout="horizontal"
                                layout={
                                    isNoTemplate ? "vertical" : "horizontal"
                                }
                            >
                                <InputFieldRefactored
                                    field="subject"
                                    templateContent={typedValues}
                                    handleTextChange={handleTextChange}
                                    mailDataType={mailDataType}
                                    isNoTemplate={isNoTemplate}
                                />
                                <InputFieldRefactored
                                    field="headerText"
                                    templateContent={typedValues}
                                    handleTextChange={handleTextChange}
                                    mailDataType={mailDataType}
                                    isNoTemplate={isNoTemplate}
                                    minRows={isNoTemplate ? 20 : 10}
                                />
                                {!isNoTemplate && (
                                    <>
                                        <InputFieldRefactored
                                            field="footerText"
                                            templateContent={typedValues}
                                            handleTextChange={handleTextChange}
                                            mailDataType={mailDataType}
                                        />
                                        <InputFieldRefactored
                                            field="signature"
                                            templateContent={typedValues}
                                            handleTextChange={handleTextChange}
                                            mailDataType={mailDataType}
                                        />
                                    </>
                                )}
                            </LayoutForm>
                        )}
                    </div>
                );
            }}
        </Formik>
    );
};

export default memo(EmailEditor);
