/* eslint-disable react/no-array-index-key */
import {red} from "@ant-design/colors";
import {
    CloseOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
} from "@ant-design/icons";
import {
    Button,
    Input,
    message as antMessage,
    Modal,
    Space,
    Spin,
    Tag,
    Tooltip,
    Switch,
} from "antd";
import {ApolloError} from "apollo-client";
import classnames from "classnames";
import React, {FC, memo, useEffect, useState} from "react";
import uuid from "uuid";
import {SentMailsResult} from "../../../../server/mailjet.types";
import {renderTitle} from "../../../../shared/templates/handleTemplateVariables";
import {
    EditBookingInput,
    GetMjmlTemplateByIdQuery,
    GetTemplateByIdQuery,
    NewBookingInput,
    Template,
    TemplateType,
    useGetMjmlTemplateByIdQuery,
    useGetTemplateByIdQuery,
    useGetTemplateByTypeQuery,
    useUpdateTemplateMutation,
    useSendEmailTemplateMutation,
    SendTemplateInput,
    MailjetEmailTemplates,
    SendParamsMessageInput,
    EmailData,
    MailDataType,
    useDeleteTemplateMutation,
    SubscriptionType,
    useGetCompanyInfoQuery,
    GetCompanyInfoQuery,
    useGetOwnerEmailQuery,
    GetOwnerEmailQuery,
    useGetReceiversByCourseIdsQuery,
    GetReceiversByCourseIdsQuery,
    GetTemplatesDocument,
    useSetIncludeCourseDetailsMutation,
    GetTemplateByIdDocument,
} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import MjmlLoader from "../../../views/MjmlPreview/MjmlLoader/MjmlLoader";
import {GET_TEMPLATES} from "../templates.graphql";
import InputField from "./InputField";
import css from "./MailTemplateEditor.less";

export type UseCase = "EDITOR" | "PREVIEW" | "SENDMAIL" | "SELECT";

const defaultFieldContent: GetTemplateByIdQuery["template"] = {
    subject: "subject",
    headerText: "headerText",
    footerText: "footerText",
    signature: "signature",
    id: "",
    name: "",
    templateType: TemplateType.FreeText,
};

type MailTemplateEditorProps = {
    style?: React.CSSProperties;
    data?: NewBookingInput | EditBookingInput;
    bookingId?: string;
    visible?: boolean;
    setVisibility?: (e: boolean) => void;
    dbTemplateId?: string;
    useCase?: UseCase;
    templateType?: TemplateType;
    confirmAction?: (e: boolean, emailsToSendCount?: number) => void;
    asModal?: boolean;
    liftSelectedTemplate?: (
        dbTemplateId: string,
        templateType: TemplateType,
    ) => void;
    switchEditMode?: "EDIT" | "PREVIEW";
    emailData?: EmailData;
    mailDataType?: MailDataType;
    receivers: Array<string>;
};

const MailTemplateEditor: FC<MailTemplateEditorProps> = ({
    style,
    data,
    bookingId,
    visible,
    setVisibility = () => console.log("setVisibility not set"),
    dbTemplateId,
    useCase = "PREVIEW",
    templateType,
    confirmAction,
    asModal = false,
    liftSelectedTemplate,
    switchEditMode = "PREVIEW",
    emailData,
    mailDataType,
    receivers,
}) => {
    const [edit, setEdit] = useState<boolean>(switchEditMode === "EDIT");
    const [templateContent, setTemplateContent] = useState<
        GetTemplateByIdQuery["template"]
    >(defaultFieldContent);
    const [templateContentBeforeEdit, setTemplateBeforeEdit] = useState<
        GetTemplateByIdQuery["template"]
    >(defaultFieldContent);

    const [actualDbTemplateId, setActualDbTemplateId] = useState<string>();

    const [templateHasBeenEdited, setTemplateHasBeenEdited] = useState<boolean>(
        false,
    );

    const [emailToSendCount, setEmailToSendCount] = useState<number>();

    useEffect(() => {
        setEdit(switchEditMode === "EDIT");
    }, [switchEditMode]);

    // Fetch Template from DB ------------------------------------------------------------------------------------------------------------------------------

    // There are two ways to fetch the template from the DB:
    // 1. Fetch the template by ID // is preferred
    // 2. Fetch the template by templateType ==> is used for cases like bookingCreation, where the templateType is not yet known

    const {
        data: dbTemplateByTypeData,
        loading: dbLoadingTemplateByType,
        error: dbErrorTemplateByType,
    } = useGetTemplateByTypeQuery({
        skip: Boolean(
            (!templateType || dbTemplateId) ??
                templateType === TemplateType.FreeText,
        ),
        variables: {
            templateType: templateType ?? TemplateType.FreeText,
        },
        fetchPolicy: "network-only",
    });

    const dbTemplateByType = dbTemplateByTypeData?.templateByType;

    const {
        data: dbTemplateByIdData,
        loading: dbLoadingTemplateById,
        error: dbErrorTemplateById,
        refetch: refetchDbTemplateById,
    } = useGetTemplateByIdQuery({
        skip: Boolean(!dbTemplateId),
        variables: {id: dbTemplateId ?? actualDbTemplateId ?? ""},
        fetchPolicy: "network-only",
    });

    const dbTemplateById = dbTemplateByIdData?.template;

    useEffect(() => {
        if (dbTemplateById) {
            setActualDbTemplateId(dbTemplateById.id);
            setTemplateContent(dbTemplateById);
            setTemplateBeforeEdit(dbTemplateById);
        } else if (dbTemplateByType) {
            setActualDbTemplateId(dbTemplateByType.id);
            setTemplateContent(dbTemplateByType);
            setTemplateBeforeEdit(dbTemplateByType);
        } else {
            return () => console.log("template not found");
        }
    }, [dbTemplateById, dbTemplateByType]);

    // Fetch Mjml from mjml ------------------------------------------------------------------------------------------------------------------------------

    const renderWithoutCourseDetails =
        dbTemplateById?.includeCourseDetails === false ||
        dbTemplateByType?.includeCourseDetails === false;

    // 4250298 blankMailNewSyntax
    const mailjetTemplateId =
        templateType === TemplateType.FreeText ||
        templateType === TemplateType.NoTemplate
            ? "4250298"
            : renderWithoutCourseDetails
            ? "5879362" // Template where the CourseDetails are not included
            : "2046014";
    //   const mailjetTemplateId =
    //       templateType === TemplateType.FreeText ? "2288419" : "2046014";

    const {
        data: mjmlTemplateData,
        loading: mjmlTemplateLoading,
        error: mjmlTemplateError,
    } = useGetMjmlTemplateByIdQuery({
        variables: {templateId: mailjetTemplateId},
    });

    const mjmlTemplate:
        | GetMjmlTemplateByIdQuery["getEmailTemplateById"]
        | undefined = mjmlTemplateData?.getEmailTemplateById;

    const [
        createUpdateMutation,
        {loading: updateLoading, error: createUpdateError},
    ] = useUpdateTemplateMutation();

    const [
        deleteTemplate,
        {loading: deleting, error: errorDeleting},
    ] = useDeleteTemplateMutation();

    // Methods ------------------------------------------------------------------------------------------------------------------------------

    const updateTemplate = async () => {
        console.log("updateTemplate");
        if (!templateContent) {
            return null;
        }
        try {
            const {
                headerText,
                subject,
                signature,
                footerText,
                name,
            } = templateContent;

            await createUpdateMutation({
                variables: {
                    id: dbTemplateId ?? actualDbTemplateId ?? "",
                    editTemplateData: {
                        headerText,
                        subject,
                        signature,
                        footerText,
                        name,
                    },
                },
                refetchQueries: [
                    "GetTemplatesQuery",
                    {query: GetTemplatesDocument},
                ],
            });

            if (templateContent === templateContentBeforeEdit) {
                // antMessage.info("Es wurde nichts geändert");
            } else {
                antMessage.success("Vorlage gespeichert");
            }
        } catch (e) {
            console.log("updateTemplate error: ", e);
            throw new Error(`Error on updating templateData: ${e?.message}`);
        } finally {
            refetchDbTemplateById();
        }
    };

    const resetFields = () => {
        setTemplateContent(templateContentBeforeEdit);
        setEdit(false);
    };

    const handleOk = () => {
        updateTemplate();
        resetFields();
        setVisibility(false);
    };

    const handleCancel = () => {
        resetFields();
        setVisibility(false);
    };

    const handleTextChange = (description: TemplateField, content: string) => {
        setTemplateHasBeenEdited(true);
        const updatedContent = {...templateContent};

        updatedContent[description] = content;

        setTemplateContent(updatedContent);
    };

    const handleTemplateSelection = () => {
        if (liftSelectedTemplate && templateType) {
            liftSelectedTemplate(actualDbTemplateId ?? "", templateType);
            resetFields();
            setVisibility(false);
        }
    };

    const handleDeleteTemplate = async () => {
        Modal.confirm({
            content: `Vorlage ${templateContent.name} dauerhaft löschen?`,
            okText: "Entgültig löschen",
            okButtonProps: {
                danger: true,
                loading: deleting,
            },
            okType: "danger",
            style: {
                display: "flex",
                justifyContent: "space-evenly",
                textAlign: "center",
                fontWeight: 600,
                fontSize: "1.2rem",
            },
            icon: null,
            onOk: () => {
                try {
                    deleteTemplate({
                        variables: {id: templateContent.id},
                        refetchQueries: [{query: GET_TEMPLATES}],
                    }).then(() => {
                        antMessage.success("Vorlage gelöscht");
                        setVisibility(false);
                    });
                } catch (error) {
                    console.log("Error deleting template: ", error);
                }
            },
        });
    };

    // Errorview ------------------------------------------------------------------------------------------------------------------------------

    const ErrorView = ({
        messages,
    }: {
        messages: Array<ApolloError | undefined>;
    }) => {
        return (
            <>
                {messages.map((message) => {
                    if (message) {
                        console.log("message: ", message);

                        return (
                            <div key={message?.extraInfo}>
                                {message.message}
                            </div>
                        );
                    }

                    return null;
                })}
            </>
        );
    };

    // Fields --------------------------------------------------------------------------------------------------------------------------------

    type TemplateField = Extract<
        keyof GetTemplateByIdQuery["template"],
        string
    >;

    const textFields = ["subject", "headerText"];

    if (templateType !== TemplateType.NoTemplate) {
        textFields.push("footerText", "signature");
    }

    const nameInputField = templateType === TemplateType.FreeText && (
        // return (
        <div key="name" style={{margin: 20}}>
            <h4>{i18n.containers.templates.EmailTemplates.sections.name()}</h4>
            <Input
                value={templateContent.name ?? undefined}
                onChange={(e) =>
                    handleTextChange("name" as TemplateField, e.target.value)
                }
            />
        </div>
        // );
        // }
    );

    // Modal title --------------------------------------------------------------------------------------------------------------------------------

    const headerSection = (
        dbTemplate:
            | Pick<
                  Template,
                  | "id"
                  | "templateType"
                  | "name"
                  | "headerText"
                  | "footerText"
                  | "signature"
                  | "subject"
              >
            | undefined,
        useCase: UseCase,
    ) => {
        if (dbTemplate?.templateType) {
            const btnLeftType = edit ? "default" : "primary";
            const btnRightType = edit ? "primary" : "default";

            const title =
                dbTemplate.templateType === TemplateType.FreeText
                    ? dbTemplate.name ?? ""
                    : renderTitle(dbTemplate.templateType as TemplateType);

            if (useCase === "SELECT") {
                return (
                    <header className={classnames(css.header)}>
                        <h2>{renderTitle(title)}</h2>
                    </header>
                );
            }

            return (
                <header className={classnames(css.header)}>
                    <h2>{renderTitle(title)}</h2>
                    <div className={classnames(css.btn_container)}>
                        {templateContent.templateType ===
                            TemplateType.FreeText && (
                            <Button
                                // className={classnames(css.btn_prim)}
                                style={{
                                    marginRight: 10,
                                    color: red[4],
                                    border: "none",
                                }}
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                                // type={btnLeftType}
                                // onClick={() => setEdit(false)}
                                onClick={handleDeleteTemplate}
                            >
                                {i18n.containers.templates.EmailTemplates.modalButtons.delete()}
                            </Button>
                        )}
                        <Button
                            className={classnames(css.btn_prim)}
                            type={btnLeftType}
                            onClick={() => setEdit(false)}
                        >
                            {i18n.containers.templates.EmailTemplates.modalButtons.preview()}
                        </Button>

                        <Button
                            className={classnames(css.btn_def)}
                            type={btnRightType}
                            // onClick={handleEdit}
                            onClick={() => setEdit(true)}
                        >
                            {i18n.containers.templates.EmailTemplates.modalButtons.adjust()}
                        </Button>
                    </div>
                </header>
            );
        }

        return <div>{"Error on getting Title"}</div>;
    };

    // Modal footer --------------------------------------------------------------------------------------------------------------------------------

    const shouldSendMail = (confirmed: boolean) => {
        if (confirmAction) confirmAction(confirmed, emailToSendCount);
    };

    const [setIncludeCourseDetails] = useSetIncludeCourseDetailsMutation({
        refetchQueries: [
            "GetTemplateById",
            {query: GetTemplateByIdDocument, variables: {id: dbTemplateId}},
        ],
    });

    const IncludeCourseDetails = ({
        editMode,
        style,
        template,
    }: {
        editMode: boolean;
        style?: React.CSSProperties;
        template?: GetTemplateByIdQuery["template"];
    }) => {
        if (!template) {
            console.log("===> !template");

            return null;
        }

        const {id, isDefault, includeCourseDetails} = template;

        if (!id || !isDefault) {
            return null;
        }

        const includeCourseDetailsText = includeCourseDetails
            ? i18n.containers.templates.EmailTemplates.includeCourseDetails.included()
            : i18n.containers.templates.EmailTemplates.includeCourseDetails.notIncluded();

        const setCourseDetails = async (show: boolean) => {
            try {
                await setIncludeCourseDetails({
                    variables: {
                        templateId: id,
                        includeCourseDetails: show,
                    },
                });
            } catch (error) {
                console.log("===> error", error);
            }
        };

        const description = editMode
            ? i18n.containers.templates.EmailTemplates.includeCourseDetails.descriptionWithHint()
            : i18n.containers.templates.EmailTemplates.includeCourseDetails.description();

        return (
            <Space style={{...style}}>
                <Switch
                    onChange={async (e) => setCourseDetails(e)}
                    checked={includeCourseDetails}
                    disabled={editMode}
                />
                <span>{includeCourseDetailsText}</span>
                <Tooltip title={description}>
                    <InfoCircleTwoTone />
                </Tooltip>
            </Space>
        );
    };

    const Footer = ({useCase}: {useCase: UseCase}) => {
        if (useCase === "PREVIEW") {
            // useCase === "PREVIEW"---------------------------------------------------------------
            console.log("footer PREVIEW");

            return (
                <Space>
                    <>
                        {templateType ===
                            TemplateType.SettlementConfirmation && (
                            <Tooltip title="Feature befindet sich in der Entwicklung :)">
                                <Button key="invoice" disabled>
                                    {"PDF-Rechnung"}
                                </Button>
                            </Tooltip>
                        )}
                    </>
                    <Button
                        key="cancel"
                        onClick={() => shouldSendMail(false)}
                        danger
                        ghost
                    >
                        {i18n.containers.templates.EmailTemplates.modalButtons.doNotSend()}
                    </Button>
                    <Button
                        key="ok"
                        onClick={() => shouldSendMail(true)}
                        loading={updateLoading}
                        type="primary"
                    >
                        {i18n.containers.templates.EmailTemplates.modalButtons.selectTemplate()}
                    </Button>
                </Space>
            );
        } else if (useCase === "EDITOR") {
            // useCase === "EDITOR"---------------------------------------------------------------
            // console.log("footer EDITOR");

            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <IncludeCourseDetails
                        editMode={edit}
                        style={{marginRight: "auto"}}
                        template={dbTemplateById}
                    />
                    <Space>
                        <Button key="back" onClick={handleCancel} danger ghost>
                            {i18n.containers.templates.EmailTemplates.modalButtons.cancel()}
                        </Button>
                        <Button key="submit" onClick={handleOk} type="primary">
                            {i18n.containers.templates.EmailTemplates.modalButtons.save()}
                        </Button>
                    </Space>
                </div>
            );
        } else if (useCase === "SELECT") {
            // useCase === "SELECT"--------------------------------------------------------------------
            console.log("footer SELECT");

            if (templateType === TemplateType.NoTemplate) {
                handleTemplateSelection();
            }

            return (
                <>
                    <Button key="back" onClick={handleCancel} danger ghost>
                        {i18n.containers.templates.EmailTemplates.modalButtons.cancel()}
                    </Button>
                    <Button
                        key="submit"
                        onClick={handleTemplateSelection}
                        type="primary"
                    >
                        {i18n.containers.templates.EmailTemplates.modalButtons.selectTemplate()}
                    </Button>
                </>
            );
        }
        console.log("footer SENDMAIL");

        const noReceiverFound =
            (receivers.length < 1 || receivers[0] === "") &&
            mailDataType !== MailDataType.Course;

        return (
            <Space>
                <Button
                    key="back"
                    onClick={() =>
                        confirmAction ? confirmAction(false) : handleCancel
                    }
                    // onClick={handleCancel}
                    danger
                    ghost
                >
                    {i18n.containers.templates.EmailTemplates.modalButtons.doNotSend()}
                </Button>
                <Tooltip
                    color={red[3]}
                    title={
                        noReceiverFound
                            ? "Emailadresse des Empfängers fehlt."
                            : null
                    }
                >
                    <Button
                        key="submit"
                        onClick={sendMail}
                        // onClick={() => shouldSendMail(true)}
                        loading={sendTemplateMailLoading}
                        type="primary"
                        disabled={noReceiverFound}
                    >
                        {!templateHasBeenEdited ||
                        templateType === TemplateType.NoTemplate
                            ? i18n.containers.templates.EmailTemplates.modalButtons.send()
                            : i18n.containers.templates.EmailTemplates.modalButtons.saveAndSend()}
                    </Button>
                </Tooltip>
            </Space>
        );
    };

    // Variables --------------------------------------------------------------------------------------------------------------------------------

    const variables = {
        subject: templateContent?.subject,
        headerText: templateContent?.headerText,
        footerText: templateContent?.footerText,
        signature: templateContent?.signature,
        course: {
            courseNumber: "TEST-100079",
            name: "Testkurs",
            subTitle: "Spielend Schwimmen lernen",
        },
    };

    // send mail --------------------------------------------------------------------------------------------------------------------------------

    const [
        sendTemplateMail,
        {
            data: sendTemplateMailData,
            error: sendTemplateMailError,
            loading: sendTemplateMailLoading,
        },
    ] = useSendEmailTemplateMutation();

    const sendMail = async () => {
        console.group("sendMail");
        if (templateContent.templateType !== TemplateType.NoTemplate) {
            try {
                await updateTemplate();
            } catch (error) {
                console.log(`Error on updating Template ${error}`);
            }
        }
        const {
            templateType,
            footerText,
            headerText,
            signature,
            subject,
            name,
        } = templateContent;

        const templateName = name ?? "";

        const message: SendParamsMessageInput = {
            From: {Email: "", Name: ""},
            Sender: {Email: "", Name: ""},
            To: [{Email: "", Name: ""}],
            Subject: "",
            Variables: {
                subject: subject ?? "",
                headerText: headerText ?? "",
                footerText: footerText ?? "",
                signature: signature ?? "",
                replyTo: "replyTo",
                bookerId: "bookerId",
                customId: uuid.v4(),
            },
        };

        const typedTemplateType = templateType as TemplateType;

        const typedMailjetTemplateId =
            templateType === "FreeText" || templateType === "NoTemplate"
                ? MailjetEmailTemplates.FreeText
                : MailjetEmailTemplates.BookingConfirmation;

        if (emailData) {
            const sendTemplateInput: SendTemplateInput = {
                templateType: typedTemplateType,
                templateName,
                mailjetTemplateId: typedMailjetTemplateId,
                messages: [message],
                emailData: {
                    receivers: emailData.receivers,
                    ids: emailData.ids,
                    mailDataType: emailData.mailDataType,
                },
            };

            try {
                antMessage.loading({
                    key: "sendMail",
                    content: "E-Mails werden versendet...",
                    duration: 0,
                });

                await sendTemplateMail({
                    variables: {
                        sendTemplateInput,
                    },
                }).then((res) => {
                    // console.log("sendTemplateMail res::: ", res);
                    const sentMailResult: SentMailsResult =
                        res.data?.sendEmailTemplate;

                    if (sentMailResult) {
                        const mailCount = sentMailResult.sentMailCount;
                        const emailText =
                            mailCount === 1 ? "E-Mail" : "E-Mails";
                        const receivers = sentMailResult.sentMessages.map(
                            (message) => message.To[0].Email,
                        );
                        const receiverString = receivers.join(", ");

                        antMessage.success({
                            key: "sendMail",
                            content: `${mailCount} ${emailText} erfolgreich versendet. Empfänger: ${receiverString}`,
                        });
                    }
                });

                if (confirmAction) {
                    confirmAction(true, emailToSendCount);
                }
            } catch (error) {
                let errorText = "Fehler beim E-Mailversand!";

                if (error instanceof Error) {
                    errorText = error.message;

                    if (error.message.includes("Only canceled bookings")) {
                        errorText =
                            "Es wurden keine E-Mails versendet, da nur Buchungen die nicht storniert wurden ausgewählt werden können.";
                    } else if (error.message.includes("subjectToLong")) {
                        errorText =
                            "Text des Betreffs ist zu lang. Es sind maximal 255 Zeichen erlaubt. Bitte korrigiere den Betreff um den E-Mailversand ausführen zu können.";
                    } else if (
                        error.message.includes(
                            "no email addresses for receivers",
                        ) ||
                        error.message.includes("No email addresses")
                    ) {
                        errorText =
                            "Mindestens Empfänger hat keine E-Mail Adresse hinterlegt.";
                    } else if (
                        error.message.includes(
                            "Total number of recipients exceeded",
                        )
                    ) {
                        errorText =
                            "Zu viele Empfänger. Es können derzeit maximal 25 E-Mails auf einmal versendet werden.";
                    } else {
                        errorText = "Unbekannter Fehler beim Senden der Email";
                        console.log("error: ", error);
                    }
                } else {
                    console.log("error: ", error);
                }

                antMessage.error({
                    content: "Fehler beim E-Mailversand!",
                });

                Modal.error({
                    icon: null,
                    title: "Fehler beim E-Mailversand!",
                    content: errorText,
                    cancelText: null,
                    style: {textAlign: "center"},
                    onOk: () => {
                        if (confirmAction) {
                            console.log("confirmAction");
                            confirmAction(false);
                        }
                    },
                    okButtonProps: {block: true},
                });
            }
        } else {
            shouldSendMail(true);
            console.error("emailData is undefined");
        }
        // /////////////////////////////////////////////////////
        console.groupEnd();
    };

    // return --------------------------------------------------------------------------------------------------------------------------------

    const renderContent = () => {
        return (
            <div style={style}>
                <Spin
                    spinning={
                        dbLoadingTemplateById ||
                        dbLoadingTemplateByType ||
                        mjmlTemplateLoading ||
                        updateLoading ||
                        deleting ||
                        sendTemplateMailLoading
                    }
                >
                    <ErrorView
                        messages={[
                            dbErrorTemplateById,
                            dbErrorTemplateByType,
                            mjmlTemplateError,
                            createUpdateError,
                        ]}
                    />
                    {templateContent && mjmlTemplate && (
                        <>
                            {edit ? (
                                <>
                                    {nameInputField}
                                    {textFields.map((field) => {
                                        return (
                                            <InputField
                                                minRows={
                                                    field === "subject"
                                                        ? 1
                                                        : field === "signature"
                                                        ? 2
                                                        : 5
                                                }
                                                key={field}
                                                field={field as TemplateField}
                                                templateContent={
                                                    templateContent
                                                }
                                                handleTextChange={(
                                                    description,
                                                    content,
                                                ) =>
                                                    handleTextChange(
                                                        description,
                                                        content,
                                                    )
                                                }
                                                mailDataType={
                                                    emailData?.mailDataType ??
                                                    mailDataType ??
                                                    MailDataType.Booker
                                                }
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <MjmlLoader
                                    data={data}
                                    bookingId={bookingId}
                                    variables={variables}
                                    //   courseLessons={courseLessons}
                                    mjmlTemplate={mjmlTemplate.Data}
                                    loading={mjmlTemplateLoading}
                                    bookingData={
                                        data?.attendeeId && data?.courseId
                                            ? {
                                                  attendeeId: data.attendeeId,
                                                  courseId: data.courseId,
                                              }
                                            : undefined
                                    }
                                    dbTemplateId={
                                        dbTemplateId ?? actualDbTemplateId ?? ""
                                    }
                                    renderWithoutCourseDetails={
                                        renderWithoutCourseDetails
                                    }
                                />
                            )}
                        </>
                    )}
                </Spin>
            </div>
        );
    };

    // RECEIVERBAR ------------------------------------------------------------------------------------------------------

    const ReceiversBar = () => {
        // IF TYPE IS COURSE WE HAVE TO FETCH THE MAILADDRESSE ----------------------------------------------------------
        const getCourseIds = () => {
            if (emailData?.mailDataType === MailDataType.Course) {
                return emailData.ids;
            }

            return undefined;
        };

        const courseIds = getCourseIds();

        // console.log("courseIds: ", courseIds);

        const {data: receiversOfCourseData} = useGetReceiversByCourseIdsQuery({
            skip: !courseIds || courseIds.length === 0,
            variables: {courseIds: courseIds ?? []},
        });

        const receiversOfCourse:
            | GetReceiversByCourseIdsQuery["getBookersByCourseIds"]
            | undefined = receiversOfCourseData?.getBookersByCourseIds;

        const receiversOfCourseEmails = receiversOfCourse
            ?.map((booker) => {
                return booker.email;
            })
            .filter(
                (email) =>
                    email !== null && email !== undefined && email !== "",
            );

        const emailCount = receiversOfCourseEmails?.length;

        setEmailToSendCount(emailCount);

        // CHECK IF IS DEMO ACCOUNT AND REPLACE EMAIL ADRESSES ----------------------------------------------------------
        const {data: companyInfoData} = useGetCompanyInfoQuery();

        const companyInfo: GetCompanyInfoQuery["companyInfo"] | undefined =
            companyInfoData?.companyInfo;

        const subscriptionType =
            SubscriptionType[
                companyInfo?.subscriptionType ??
                    SubscriptionType.TestSubscription
            ];

        const isDemoAccount =
            subscriptionType === SubscriptionType.TestSubscription;

        const {data: ownerMailData} = useGetOwnerEmailQuery();

        const ownerMail: GetOwnerEmailQuery["ownerEmail"] | string =
            ownerMailData?.ownerEmail.ownerEmail ?? "";

        const checkedReceivers = () => {
            if (
                emailData?.mailDataType === MailDataType.Course &&
                receiversOfCourseEmails
            ) {
                if (isDemoAccount) {
                    return receiversOfCourseEmails.map((receiver) => {
                        return `${ownerMail}*`;
                    });
                }

                return receiversOfCourseEmails;
            }

            if (isDemoAccount) {
                return receivers.map((receiver) => {
                    return `${ownerMail}*`;
                });
            }

            if (mailDataType === MailDataType.Course) {
                return receiversOfCourseEmails;
            }

            return receivers;
        };

        // return --------------------------------------------------------------------------------------------------------------------------------

        return (
            <Space size="small" className={css.receiversBar} wrap>
                {isDemoAccount && (
                    <Tooltip title="Da es sich um einen DemoAccount handelt, wird hier die E-MailAdresse die du in den Settings hinterlegt hast angezeigt.">
                        <InfoCircleOutlined style={{marginRight: "3px"}} />
                    </Tooltip>
                )}

                <div>{"Empfänger:"}</div>
                {checkedReceivers()?.map((receiver, index) => {
                    return (
                        <Tag
                            key={`${receiver}-${index}`}
                            className={css.receiverTag}
                        >
                            {receiver}
                        </Tag>
                    );
                })}
            </Space>
        );
    };

    if (asModal === true) {
        return (
            <Modal
                title={headerSection(templateContent, useCase)}
                // visible={visible}
                open={visible}
                // closable={false}
                closeIcon={
                    <Button
                        onClick={handleCancel}
                        size="large"
                        type="text"
                        style={{marginTop: 15}}
                    >
                        <CloseOutlined />
                    </Button>
                }
                onCancel={() =>
                    confirmAction ? confirmAction(false) : handleCancel
                }
                destroyOnClose={true}
                bodyStyle={{
                    overflow: edit ? "auto" : "hidden",
                    maxHeight: "calc(100vh - 200px)",
                    padding: "0px",
                }}
                style={{top: 40}}
                // width={860}
                width={800}
                footer={<Footer useCase={useCase} />}
            >
                <>
                    <ReceiversBar />
                    {renderContent()}
                </>
            </Modal>
        );
    }

    return renderContent();
};

export default memo(MailTemplateEditor);
