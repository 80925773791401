import React, {memo} from "react";
import {Button, Divider, Space} from "antd";
import css from "./PrivacyNotice.less";
import i18n from "../../services/i18n";
import LayoutWithoutNavigation from "../LayoutWithoutNavigation/LayoutWithoutNavigation";
import LoginHeader from "../LoginHeader/LoginHeader";
import PageContentWrapper from "../PageContentWrapper/PageContentWrapper";
import LoginFooter from "../LoginFooter/LoginFooter";
import env from "../../../shared/const";
import {AlphaLoginText} from "../AlphaLoginText/AlphaLoginText";

const PrivacyNotice = () => {
    const openLoginPageWithDefaultTenant = () => {
        window.location.assign(`/${env.DEV_ORGANIZATION_ID}/login`);
    };

    // return <div className={css.root}>{"test"}</div>;
    return (
        <LayoutWithoutNavigation
            main={
                <div className={css.layoutWithoutNavigation}>
                    <LoginHeader
                        link={{
                            href: i18n.containers.topLevelPage.TopLevelPage.link(),
                            label: i18n.containers.topLevelPage.TopLevelPage.title(),
                        }}
                    />
                    <PageContentWrapper style={{margin: 0}}>
                        <Space
                            className={css.root}
                            direction="vertical"
                            size={1}
                        >
                            <h3>
                                {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.title()}
                            </h3>
                            <h5>
                                {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.headline1()}
                            </h5>
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph1()}
                            <Divider />
                            <h5>
                                {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.headline2()}
                            </h5>
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph2a()}
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph2b()}
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph2c()}
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph2d()}
                            <Divider />
                            <h5>
                                {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.headline3()}
                            </h5>
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph3()}
                            <Divider />
                            <h5>
                                {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.headline4()}
                            </h5>
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph4a()}
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph4b()}
                            <Divider />
                            <h5>
                                {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.headline5()}
                            </h5>
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.paragraph5()}
                            <Divider />
                            {i18n.containers.topLevelPage.TopLevelPage.privacyNotice.source()}
                        </Space>
                        {/* </PageContentWrapper> */}
                        <LoginFooter>
                            <Button
                                onClick={() => openLoginPageWithDefaultTenant()}
                                className={css.loginButton}
                            >
                                <AlphaLoginText size="small" />
                            </Button>
                        </LoginFooter>
                    </PageContentWrapper>
                </div>
            }
        />
    );
};

export default memo(PrivacyNotice);
