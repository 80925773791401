import React, {FC, memo, useEffect, useState} from "react";
import {Badge, Button, Divider, Modal, Space, Table, Tooltip} from "antd";
import {ColumnProps} from "antd/lib/table";
import moment from "moment";
import {
    CloseOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
} from "@ant-design/icons";
import {green, grey, orange, red} from "@ant-design/colors";
import FileUploadComponent /* , {SingleUploadResult} */ from "./FileUploadComponent";
import {
    renderAmount,
    renderBooleanAsCheckmark,
} from "../../../views/TableColumnRenderers/TableColumnRenderers";
import {SwissQRFileUploadResult} from "../../../../server/swissQRFile/swissQRFile.inputTypes";
import {URL_BOOKING_EDIT_ID} from "../../../models/url";
import SettlementDetails from "../../invoice/SettlementDetails";

type ErrorDetail = {
    msg: string;
    description: string;
};

export type KnownError = {
    overpaid: ErrorDetail;
    underpaid: ErrorDetail;
    paid: ErrorDetail;
    "error - invoice not found": ErrorDetail;
};

const knownError: KnownError = {
    overpaid: {
        msg: "Überbezahlt",
        description:
            "Der Betrag ist zu hoch. Der Differenzbetrag wird als Guthaben verbucht.",
    },
    underpaid: {
        msg: "Unterbezahlt",
        description:
            "Der Betrag ist zu niedrig und wird als Teilzahlung verbucht.",
    },
    paid: {msg: "Bezahlt", description: "Der Betrag stimmt überein"},
    "error - invoice not found": {
        msg: "Rechnung nicht gefunden",
        description:
            "Kann keiner Rechnung zugeordnet werden und wird daher ignoriert.",
    },
};

export type TableType = SwissQRFileUploadResult["records"][number] & {
    openAmount: any;
};

type SwissQrCamtFileUploadProps = {
    setModalOpen: (open: boolean) => void;
};

const SwissQrCamtFileUpload: FC<SwissQrCamtFileUploadProps> = ({
    setModalOpen,
}) => {
    const [result, setResult] = useState<Array<TableType>>();

    useEffect(() => {
        console.log("result:::", result);
    }, [result]);

    const ResultBadge = ({result}: {result: keyof KnownError | string}) => {
        const isKnownErrorKey = (key: string): key is keyof KnownError => {
            return key in knownError;
        };

        if (!isKnownErrorKey(result)) {
            return <>{result}</>;
        }

        const color =
            result === "paid"
                ? green
                : result === "overpaid"
                ? orange
                : result === "underpaid"
                ? red
                : grey;

        return (
            <Tooltip title={knownError[result].description}>
                <Space size={5}>
                    <InfoCircleTwoTone />
                    <Badge
                        count={knownError[result].msg}
                        color={color === grey ? color[0] : color[3]}
                    />
                </Space>
            </Tooltip>
        );
    };

    const BookingDetails = ({record}: {record: TableType}) => {
        const [modalOpen, setModalOpen] = useState(false);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!record.scheduledPayment) {
            return <>{"-"}</>;
        }

        const bookingId = record.scheduledPayment.bookingId;
        const detailsUrl = URL_BOOKING_EDIT_ID.replace(":id", bookingId);

        console.log("detailsUrl", detailsUrl);

        return (
            <>
                <Modal
                    title="Details zur Buchung"
                    open={modalOpen}
                    onCancel={() => setModalOpen(false)}
                    width={1220}
                    style={{top: 20}}
                    bodyStyle={{
                        maxHeight: "calc(100vh - 160px)",
                        // maxHeight: "calc(100% - 100px)",
                        overflowY: "auto",
                    }}
                    okButtonProps={{style: {display: "none"}}}
                    cancelButtonProps={{
                        type: "primary",
                        icon: <CloseOutlined />,
                    }}
                    cancelText="Ansicht schliessen"
                >
                    <SettlementDetails
                        bookingId={bookingId}
                        renderWithoutPage
                    />
                </Modal>
                <Button
                    data-testid="bookingDetails"
                    size="small"
                    onClick={() => setModalOpen(!modalOpen)}
                >
                    <InfoCircleOutlined style={{color: "rgb(7, 96, 255)"}} />
                </Button>
            </>
        );
    };

    const PreviewTable = () => {
        const columns: Array<ColumnProps<TableType>> = [
            // const columns: Array<ColumnProps<SingleUploadRecord>> = [
            // {
            //     dataIndex: "id",
            //     title: "ID",
            //     render: (text, record: TableType) => ({
            //         displayName: "ID",
            //         children: <>{text}</>,
            //     }),
            // },
            {
                dataIndex: "amount",
                title: "Eingegangener Betrag",
                render: (text, record: TableType) => ({
                    displayName: "amount",
                    children: <>{renderAmount(text)}</>,
                }),
            },
            {
                dataIndex: "openAmount",
                title: "Offener Betrag",
                render: (text, record: TableType) => ({
                    displayName: "openAmount",
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    children: record.scheduledPayment
                        ? renderAmount(
                              record.scheduledPayment.openAmount ??
                                  record.scheduledPayment.paymentAmount,
                          )
                        : "-",
                }),
            },
            {
                dataIndex: "valutaDate",
                title: "Valuta",
                render: (text, record: TableType) => ({
                    displayName: "valutaDate",
                    children: <>{moment(text).format("L")}</>,
                }),
            },

            {
                dataIndex: "swissQrRef",
                title: "QR-Referenz",
                render: (text, record: TableType) => ({
                    displayName: "swissQrRef",
                    children: <>{text}</>,
                }),
            },
            {
                dataIndex: "successful",
                title: "Valide",
                render: (text, record: TableType) => ({
                    displayName: "successful",
                    children: <>{renderBooleanAsCheckmark(text)}</>,
                }),
            },
            {
                dataIndex: "result",
                title: "Ergebnis",
                render: (text, record: TableType) => ({
                    displayName: "result",
                    // children: <ResultBadge result={text as keyof KnownError} />,
                    children: <ResultBadge result={text} />,
                }),
            },
            {
                dataIndex: "bookingDetails",
                title: "Buchungsdetails",
                align: "right",
                render: (text, record: TableType) => ({
                    displayName: "bookingDetails",
                    children: <BookingDetails record={record} />,
                }),
            },
        ];

        if (!result) return null;

        console.log("result", result);

        return (
            <Table<TableType>
                size="small"
                columns={columns}
                dataSource={result}
            />
        );
    };

    return (
        <>
            {result && <Divider orientation="left">{"Vorschau"}</Divider>}
            <PreviewTable />
            <FileUploadComponent
                liftedResult={(liftedResult: Array<TableType> | undefined) =>
                    setResult(liftedResult)
                }
                setModalOpen={(open: boolean) => setModalOpen(open)}
            />
            {/* <PreviewTable /> */}
        </>
    );
};

export default memo(SwissQrCamtFileUpload);
