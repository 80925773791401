import {ISODateString} from "./typeScriptHelpers";
import {BaseInput} from "../generated/graphql";

export const sortByStartDateTime = (
    {startDateTime: a}: {startDateTime?: ISODateString},
    {startDateTime: b}: {startDateTime?: ISODateString},
) => {
    if (a === b) {
        return 0;
    }

    if (b === undefined) {
        return -1;
    }

    if (a === undefined) {
        return 1;
    }

    return a < b ? -1 : 1;
};

export const sortDefault = (a: string, b: string) => {
    if (a > b) {
        return -1;
    }

    if (a < b) {
        return 1;
    }

    return 0;
};

/**
 * Moves the customer with the provided `customerId` to the beginning of the array.
 */
export const moveCustomerToTop = (customerId: string) => (
    a: {customer: BaseInput},
    b: {customer: BaseInput},
) => {
    if (a.customer.id === customerId) {
        return -1;
    }

    if (b.customer.id === customerId) {
        return 1;
    }

    return 0;
};
