// Imports with side-effects
/* eslint-disable import/no-unassigned-import */
import "./polyfills";
import "react-hot-loader/patch";
/* eslint-enable import/no-unassigned-import */
import ReactDOM from "react-dom";
import {notification} from "antd";
import {Locale} from "../translations";
import app from "./app";
import {
    fetchTranslation,
    setTranslation,
    isSupportedLocale,
} from "./services/i18n";
import {DEFAULT_LOCALE, NOTIFICATION_DEFAULT_OPTIONS} from "./const";

const start = async () => {
    const localeFromLocalStorage = localStorage.getItem("locale");

    const locale: Locale =
        localeFromLocalStorage !== null &&
        isSupportedLocale(localeFromLocalStorage)
            ? localeFromLocalStorage
            : DEFAULT_LOCALE;

    setTranslation(await fetchTranslation(locale));
    ReactDOM.render(app, document.body.firstElementChild);
};

notification.config(NOTIFICATION_DEFAULT_OPTIONS);

start().catch((error) => {
    notification.error({
        message: error.message,
    });
});
