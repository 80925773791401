import moment, {Moment} from "moment";

export const timeRanges: {[key: string]: [Moment, Moment]} = {
    today: [moment().startOf("day"), moment().endOf("day")],
    twentyFour: [moment().subtract(24, "hours"), moment()],
    yesterday: [
        moment()
            .subtract(24, "hours")
            .startOf("day"),
        moment()
            .subtract(24, "hours")
            .endOf("day"),
    ],
    thisWeek: [moment().startOf("week"), moment()],
    lastWeek: [
        moment()
            .subtract(1, "week")
            .startOf("week"),
        moment()
            .subtract(1, "week")
            .endOf("week"),
    ],
    thisMonth: [moment().startOf("month"), moment().endOf("month")],
    lastMonth: [
        moment()
            .subtract(1, "month")
            .startOf("month"),
        moment()
            .subtract(1, "month")
            .endOf("month"),
    ],
    thisYear: [moment().startOf("year"), moment().endOf("year")],
    all: [moment("2000-01-01"), moment()],
};

// Define a type for the keys of the timeRanges object
export type TimeRangeKey = keyof typeof timeRanges;

// Define the type for the timeRangeTitles object
export type TimeRangeTitles = Record<TimeRangeKey, string>;

// Now declare your object with the type
export const timeRangeTitles: TimeRangeTitles = {
    today: "Heute",
    twentyFour: "Letze 24h",
    yesterday: "Gestern",
    thisWeek: "Diese Woche",
    lastWeek: "Letzte Woche",
    thisMonth: "Diesen Monat",
    lastMonth: "Letzten Monat",
    thisYear: "Dieses Jahr",
    all: "Alle",
};
