import React, {FC, memo} from "react";
import moment from "moment";
import {TimePickerProps} from "antd/es/time-picker";
import css from "./TableTimePicker.less";
import {ISODateString} from "../../helpers/typeScriptHelpers";
import CustomTimeInput from "../../containers/common/CustomTimeInput";

type TableTimePickerProps = TimePickerProps & {
    defaultTime?: ISODateString;
    timeError?: () => boolean;
};

const TableTimePicker: FC<TableTimePickerProps> = ({
    defaultTime = new Date().toISOString(),
    timeError,
    ...restProps
}) => {
    const {onChange, value} = restProps;

    return (
        <CustomTimeInput
            size="small"
            value={moment(value)}
            onChange={(value) => {
                console.log("change", value);
                if (onChange && value) {
                    onChange(value, value.format("HH:mm"));
                }
            }}
            className={timeError?.() ? css.inputError : undefined}
            status={timeError?.() ? "error" : undefined}
            style={{marginLeft: -5}}
        />
    );
};

export default memo(TableTimePicker);
