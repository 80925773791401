import {Button, Form, Input, message, Modal, Segmented, Select} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {
    MailDataType,
    NewTemplateInput,
    TemplateType,
    useCreateTemplateMutation,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import DescriptionBox from "../../views/DescriptionBox/DescriptionBox";
import LayoutForm from "../../views/LayoutForm/LayoutForm";

type CreateTemplateModalProps = {
    createTemplateModal?: boolean;
    setCreateTemplate: (value: boolean) => void;
};

const CreateTemplateModal: FC<CreateTemplateModalProps> = ({
    createTemplateModal,
    setCreateTemplate,
}) => {
    const [newTemplateName, setNewTemplateName] = useState<string>();
    const [selectedMailDataType, setSelectedMailDataType] = useState<
        MailDataType
    >(MailDataType.Booker);

    const [
        createTemplate,
        {data, loading, error},
    ] = useCreateTemplateMutation();

    useEffect(() => {
        console.log("data", data);
        console.log("loading", loading);
        console.log("error", error);
    }, [data, error, loading]);

    const handleCancel = () => {
        setNewTemplateName(undefined);
        setCreateTemplate(false);
    };

    const handleTemplateCreation = async () => {
        console.log("newTemplateName: ", newTemplateName);

        const newTemplateData: NewTemplateInput = {
            templateType: TemplateType.FreeText,
            name: newTemplateName,
            mailDataType: selectedMailDataType,
        };

        try {
            await createTemplate({
                variables: {newTemplateData},
                refetchQueries: ["GetTemplates"],
            }).then((res) => {
                message.success("Vorlage erstellt");
                setCreateTemplate(false);
            });
        } catch (e) {
            console.log("error on createTemplate: ", e);
        }
    };

    const Description = () => {
        return (
            <span>
                <p>
                    {i18n.containers.templates.Templates.createTemplate.descriptionContent.p1()}
                </p>
                <p>
                    {i18n.containers.templates.Templates.createTemplate.descriptionContent.p2()}
                </p>
                <p>
                    {i18n.containers.templates.Templates.createTemplate.descriptionContent.p3()}
                </p>
            </span>
        );
    };

    const options: Array<keyof typeof MailDataType> = [
        "Attendee",
        "Booker",
        "Booking",
        "Course",
    ];

    // const options = (Object.keys(MailDataType) as Array<
    //     keyof typeof MailDataType
    // >).map((key) => {
    //     if (key !== "Course" && key !== "Invoice") {
    //         return {
    //             label: i18n.containers.templates.Templates.emailDataType[key](),
    //             value: key,
    //         };
    //     }
    // });

    const renderForm = (
        <LayoutForm columns={1}>
            <Form.Item label="Name der Vorlage">
                <Input onChange={(e) => setNewTemplateName(e.target.value)} />
            </Form.Item>
            <DescriptionBox
                headLine="Hinweis zu Variablen"
                content={<Description />}
            />
            <Form.Item>
                <Segmented
                    block
                    onChange={(value) =>
                        setSelectedMailDataType(value as MailDataType)
                    }
                    value={selectedMailDataType}
                    options={options.map((key) => {
                        return {
                            label: i18n.containers.templates.Templates.emailDataType[
                                key
                            ](),
                            value: key,
                        };
                    })}
                />
            </Form.Item>
        </LayoutForm>
    );

    return (
        <Modal
            // title="Neue Vorlage erstellen"
            title={i18n.containers.templates.Templates.createTemplate.title()}
            // visible={createTemplateModal}
            open={createTemplateModal}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={
                <>
                    <Button key="submit" onClick={handleCancel} danger ghost>
                        {i18n.containers.templates.EmailTemplates.modalButtons.cancel()}
                    </Button>
                    <Button
                        key="back"
                        onClick={handleTemplateCreation}
                        type="primary"
                    >
                        {i18n.containers.templates.EmailTemplates.createTemplate.create()}
                    </Button>
                </>
            }
        >
            <>{renderForm}</>
        </Modal>
    );
};

export default memo(CreateTemplateModal);
