import React, {FC, memo, useState} from "react";
import {ApolloError} from "apollo-client";
import {Card, Modal, Space} from "antd";
import {FormikProps} from "formik";
import {grey} from "@ant-design/colors";
import {
    EditTemplateInput,
    GetTemplateByIdQuery,
    GetTemplatesQuery,
    MailDataType,
    SendMailToSelectionOfCourseInput,
    useGetTemplateByIdQuery,
    GetTemplatesWithPaginationQuery,
    TemplateType,
} from "../../../generated/graphql";
import EmailPreview from "./EmailPreview";
import EmailEditor from "./EmailEditor";
import {marginS, marginXs} from "../../../styles/layout";
import EmailFooter from "./EmailFooter";
import ReceiversBar from "./ReceiversBar";
import {SelectionType} from "./EmailTemplatePicker";
import UploadComponent from "./UploadComponent";
import CourseBookingTypeSelector, {
    NotifySelection,
} from "./SendMailHandlers/CourseBookingTypeSelector";

type EmailProps = {
    templateId: string;
    mailDataType: MailDataType;
    selectedTemplate?:
        | GetTemplateByIdQuery["template"]
        | GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"];
    setSelectedTemplate: (
        template:
            | GetTemplatesQuery["templates"][0]
            | GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"][0]
            | undefined,
    ) => void;
    selection: Array<SelectionType>;
    closeAll?: () => void;
    loading?: boolean;
};

export type DbTemplate = {
    data: GetTemplateByIdQuery | undefined;
    loading: boolean;
    error?: ApolloError;
    refetch: () => void;
};

const Email: FC<EmailProps> = ({
    templateId,
    mailDataType,
    selectedTemplate,
    setSelectedTemplate,
    selection,
    closeAll,
    loading,
}) => {
    const [editMode, setEditMode] = React.useState(false);
    const [formikProps, setFormikProps] = useState<
        FormikProps<EditTemplateInput>
    >();
    const [attachments, setAttachments] = useState<
        SendMailToSelectionOfCourseInput["attachments"]
    >();
    const [notifySelection, setNotifySelection] = useState<
        Array<NotifySelection>
    >([]);
    const [disabledSendButton, setDisabledSendButton] = useState(false);
    const [receiverCount, setReceiverCount] = useState(0);

    const {
        data: dbTemplateByIdData,
        loading: dbLoadingTemplateById,
        error: dbErrorTemplateById,
        refetch: refetchDbTemplateById,
    } = useGetTemplateByIdQuery({
        skip: Boolean(!templateId),
        variables: {id: templateId},
        fetchPolicy: "network-only",
    });

    const dbTemplate: DbTemplate = {
        data: dbTemplateByIdData ?? undefined,
        loading: dbLoadingTemplateById,
        error: dbErrorTemplateById,
        refetch: refetchDbTemplateById,
    };

    const loadingContent = dbLoadingTemplateById || loading;

    if (loadingContent) {
        return <div>{"Loading..."}</div>;
    }

    const emailTitle = (
        mailDataType: MailDataType,
        templateData: DbTemplate["data"],
    ) => {
        if (mailDataType === MailDataType.Invoice) {
            return (
                <Space>
                    <div style={{color: grey[3]}}>{"Vorschau:"}</div>
                    <span>{"Email an Rechnungsempfänger"}</span>
                </Space>
            );
        }

        return (
            <Space>
                <div style={{color: grey[3]}}>{"Vorschau:"}</div>
                {`${templateData?.template?.name}`}
            </Space>
        );
    };

    const isNoTemplate =
        dbTemplate.data?.template.templateType === TemplateType.NoTemplate;

    if (dbTemplate.data) {
        return (
            <Modal
                title={
                    isNoTemplate
                        ? "Vorschau Email ohne Vorlage"
                        : emailTitle(mailDataType, dbTemplate.data)
                }
                open={Boolean(selectedTemplate)}
                onCancel={() => {
                    if (mailDataType === MailDataType.Staff) {
                        closeAll?.();
                    }
                    setSelectedTemplate(undefined);
                }}
                destroyOnClose={true}
                bodyStyle={{
                    maxHeight: "calc(100vh - 200px)",
                    padding: "0px",
                }}
                style={{top: 40}}
                width={800}
                footer={[
                    <EmailFooter
                        key="emailFooter"
                        dbTemplate={dbTemplate}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        formikProps={
                            formikProps as FormikProps<
                                EditTemplateInput & {id: string}
                            >
                        }
                        selection={selection}
                        mailDataType={mailDataType}
                        closeAll={() => {
                            setSelectedTemplate(undefined);
                            closeAll?.();
                        }}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                        notifySelection={notifySelection}
                        setSelectedTemplateUndefined={() => {
                            if (mailDataType === MailDataType.Staff) {
                                closeAll?.();
                            }
                            setSelectedTemplate(undefined);
                        }}
                    />,
                ]}
            >
                <Card
                    headStyle={{padding: `0 ${marginS}`}}
                    bodyStyle={{
                        padding: `${marginXs} 0`,
                        overflow: "auto",
                    }}
                    size="small"
                >
                    <>
                        {mailDataType === MailDataType.Course && (
                            <CourseBookingTypeSelector
                                selection={selection}
                                dbTemplateId={dbTemplate.data.template.id}
                                setNotifySelection={setNotifySelection}
                            />
                        )}
                        <ReceiversBar
                            mailDataType={mailDataType}
                            selection={selection}
                            notifySelection={notifySelection}
                            setDisabledSendButton={setDisabledSendButton}
                            setReceiverCount={setReceiverCount}
                        />
                        {mailDataType !== MailDataType.Invoice && (
                            <UploadComponent
                                setAttachments={setAttachments}
                                attachments={attachments}
                                receiverCount={receiverCount}
                                setDisabledSendButton={setDisabledSendButton}
                            />
                        )}
                        {editMode ? (
                            <EmailEditor
                                dbTemplate={dbTemplate}
                                mailDataType={mailDataType}
                                setFormikProps={setFormikProps}
                                setEditMode={setEditMode}
                                isNoTemplate={isNoTemplate}
                            />
                        ) : (
                            <EmailPreview dbTemplate={dbTemplate} />
                        )}
                    </>
                </Card>
            </Modal>
        );
    }

    return <div>{"Keine Vorlagen gefunden"}</div>;
};

export default memo(Email);
