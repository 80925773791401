import React, {FC, memo} from "react";
import {FormikProps} from "formik";
import {Checkbox, DatePicker, Divider, Form, Input, Select} from "antd";
import moment from "moment";
import {TabKeys} from "./AddScheduledPaymetTabs";
import {
    AddNamedScheduledPaymentInput,
    Booking,
    ScheduledPaymentType,
} from "../../generated/graphql";
import {createInputHelpers} from "../../helpers/createInputHelpers";
import LayoutForm from "../LayoutForm/LayoutForm";
import {isDefined} from "../../helpers/typeScriptHelpers";
import i18n from "../../services/i18n";
import MoneyInput from "../MoneyInput/MoneyInput";
import css from "./AddNamedScheduledPayment.less";

const customPadding = 15;
const columnCount = 5;

type OptionKeyProps =
    | "default"
    | "dunningLevel1"
    | "dunningLevel2"
    | "dunningLevel3"
    | "cancelationFee"
    | "chargeback"
    | "custom";

export type OptionProps = {
    key: OptionKeyProps;
    label: string;
    type: ScheduledPaymentType;
    description: string;
};

type AddNamedScheduledPaymentFormProps = {
    tabKey: TabKeys;
    formikProps: FormikProps<AddNamedScheduledPaymentInput>;
    setEditPaymentOpen: (value: boolean) => void;
    bookingId: Booking["id"];
    activeKey: TabKeys;
};

const AddNamedScheduledPaymentForm: FC<AddNamedScheduledPaymentFormProps> = ({
    tabKey,
    formikProps,
    setEditPaymentOpen,
    bookingId,
    activeKey,
}) => {
    const {
        help,
        validateStatus,
        handleDatePickerChange,
        handleDatePickerBlur,
        handleBlur,
        handleNumberInputChange,
        handleTextInputChange,
        handleCheckboxChange,
    } = createInputHelpers(formikProps);

    const {values, errors} = formikProps;

    const getClassName = (key?: OptionKeyProps) => {
        switch (key) {
            case "default":
                return css.specaialPaymentRowDefault;
            case "dunningLevel1":
                return css.specaialPaymentRowDunningLevel1;
            case "dunningLevel2":
                return css.specaialPaymentRowDunningLevel2;
            case "dunningLevel3":
                return css.specaialPaymentRowDunningLevel3;
            case "cancelationFee":
                return css.specaialPaymentRowCancelationFee;
            case "chargeback":
                return css.specaialPaymentRowChargeback;
            case "custom":
                return css.specaialPaymentRowCustom;
            default:
                return "";
        }
    };

    const conditionalSelectInput = ({activeKey}: {activeKey?: TabKeys}) => {
        if (activeKey === "standard") {
            return (
                <Form.Item
                    label="Bezeichnung auf der Rechnung"
                    validateStatus={validateStatus("title")}
                    help={help("title")}
                    style={{gridColumn: "1 / span 2"}}
                >
                    <Input
                        data-testid="title"
                        onChange={handleTextInputChange("title")}
                        onBlur={handleBlur("title")}
                        // value={values.title}
                        value="automatisch"
                        name="title"
                        disabled
                    />
                </Form.Item>
            );
        }

        if (activeKey === "custom") {
            return (
                <Form.Item
                    label="Bezeichnung auf der Rechnung"
                    validateStatus={validateStatus("title")}
                    help={help("title")}
                    style={{gridColumn: "1 / span 2"}}
                >
                    <Input
                        data-testid="title"
                        onChange={handleTextInputChange("title")}
                        onBlur={handleBlur("title")}
                        value={values.title}
                        name="title"
                    />
                </Form.Item>
            );
        }

        return (
            <Form.Item
                label="Bezeichnung auf der Rechnung"
                validateStatus={validateStatus("type")}
                help={help("type")}
                required
                style={{gridColumn: "span 2"}}
            >
                <Select
                    data-testid="type"
                    onChange={(value) => {
                        formikProps.setFieldValue("type", value);
                        const title = options.filter(
                            (option) => option.type === value,
                        )[0].label;

                        formikProps.setFieldValue("title", title);
                    }}
                    value={values.type}
                    onBlur={handleBlur("type")}
                    style={{width: "100%"}}
                >
                    {options
                        .filter(
                            (option) =>
                                option.key !== "custom" &&
                                option.key !== "default",
                        )
                        .map((option) => (
                            <Select.Option
                                key={option.type}
                                value={option.type}
                                data-testid={option.label}
                                className={getClassName(option.key)}
                            >
                                {option.label}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
        );
    };

    type LayoutFormCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: LayoutFormCSSProperties = {
        // backgroundColor: background,
        margin: `${customPadding * -1}px`,
        padding: `${customPadding}px`,
        "--column-count": columnCount,
    };

    return (
        <LayoutForm
            columns={columnCount}
            name="AddNamedPaymentForm"
            style={{
                ...actualStyle,
                padding: customPadding,
                paddingBottom: 0,
                gridColumn: "1 / span 6",
            }}
            // className={getClassName(selectedOptionKey)}
        >
            {conditionalSelectInput({activeKey})}
            <Form.Item
                label={i18n.views.TableColumnTitles.paymentDue()}
                validateStatus={validateStatus("paymentDue")}
                help={help("paymentDue")}
                required
                style={{gridColumn: "span 1"}}
            >
                <DatePicker
                    data-testid="paymentDue"
                    onChange={handleDatePickerChange("paymentDue")}
                    value={
                        isDefined(values.paymentDue)
                            ? moment(values.paymentDue)
                            : undefined
                    }
                    format="DD.MM.YYYY"
                    onOpenChange={handleDatePickerBlur("paymentDue")}
                    status={
                        values.paymentDue && errors.paymentDue
                            ? "error"
                            : undefined
                    }
                    style={{width: "100%"}}
                />
            </Form.Item>
            <Form.Item
                label={i18n.views.TableColumnTitles.paymentAmount()}
                validateStatus={validateStatus("paymentAmount")}
                help={help("paymentAmount")}
                required
                style={{gridColumn: "span 1"}}
            >
                <MoneyInput
                    data-testid="paymentAmount"
                    onChange={handleNumberInputChange("paymentAmount")}
                    value={
                        isDefined(values.paymentAmount)
                            ? values.paymentAmount
                            : undefined
                    }
                    onBlur={handleBlur("paymentAmount")}
                    style={{width: "100%"}}
                />
            </Form.Item>
            <Form.Item
                data-testid="sepaClearance"
                label={" "}
                validateStatus={validateStatus("sepaClearance")}
                help={help("sepaClearance")}
                // style={{gridColumn: `${columnCount - 1} / span 2`}}
                style={{gridColumn: `${columnCount} / span 1`}}
            >
                <Checkbox
                    onChange={handleCheckboxChange("sepaClearance")}
                    checked={Boolean(values.sepaClearance)}
                >
                    {i18n.views.TableColumnTitles.sepaClearance()}
                </Checkbox>
            </Form.Item>
            <Divider
                style={{
                    gridColumn: `span ${columnCount}`,
                    margin: `0 ${customPadding * -1}px`,
                    width: `calc(100% + ${customPadding * 2}px)`,
                }}
            />
        </LayoutForm>
    );
};

export default memo(AddNamedScheduledPaymentForm);

export const options: Array<OptionProps> = [
    {
        key: "default",
        label: "Standardposten",
        type: ScheduledPaymentType.Standard,
        description:
            "Wenn ein Standardposten gewählt wird, wird keine eigene Bezeichnung auf der Rechnung angezeigt. Er taucht dort auf, wie jeder andere Posten.",
    },
    {
        key: "dunningLevel1",
        label: "Mahnstufe 1",
        type: ScheduledPaymentType.DunningLevel1,
        description:
            "Mahnstufen werden auf der Rechnung als eigener Posten aufgeführt und als solche gekennzeichnet.",
    },
    {
        key: "dunningLevel2",
        label: "Mahnstufe 2",
        type: ScheduledPaymentType.DunningLevel2,
        description:
            "Mahnstufen werden auf der Rechnung als eigener Posten aufgeführt und als solche gekennzeichnet.",
    },
    {
        key: "dunningLevel3",
        label: "Mahnstufe 3",
        type: ScheduledPaymentType.DunningLevel3,
        description:
            "Mahnstufen werden auf der Rechnung als eigener Posten aufgeführt und als solche gekennzeichnet.",
    },
    {
        key: "cancelationFee",
        label: "Stornogebühr",
        type: ScheduledPaymentType.CancelationFee,
        description:
            "Stornogebühren werden auf der Rechnung als eigener Posten aufgeführt und als solche gekennzeichnet.",
    },
    {
        key: "chargeback",
        label: "Rücklastschrift",
        type: ScheduledPaymentType.Chargeback,
        description:
            "Rücklastschriften werden auf der Rechnung als eigener Posten aufgeführt und als solche gekennzeichnet.",
    },
    {
        key: "custom",
        label: "Eigene Beschreibung",
        type: ScheduledPaymentType.Custom,
        description:
            "Hier kannst du eine eigene Bezeichnung eingeben. Der Posten wird dann auf der Rechnung mit dieser Bezeichnung gelistet.",
    },
];
