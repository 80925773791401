import React, {FC, memo} from "react";
import {grey} from "@ant-design/colors";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {Space} from "antd";
import {OptionProps} from "./AddNamedScheduledPaymentForm2";

type AddNamedScheduledPaymentDescriptionPorps = {
    option?: OptionProps;
    style?: React.CSSProperties;
};

const AddNamedScheduledPaymentDescription: FC<AddNamedScheduledPaymentDescriptionPorps> = ({
    option,
    style,
}) => {
    if (!option) {
        return null;
    }

    return (
        <div style={{color: grey[5], ...style}}>
            <h5 style={{color: grey[5]}}>
                <Space>
                    <InfoCircleTwoTone />
                    {option.label}
                </Space>
            </h5>
            <p style={{color: grey[5]}}>{option.description}</p>
        </div>
    );
};

export default memo(AddNamedScheduledPaymentDescription);
