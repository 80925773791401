import React, {FC, memo} from "react";
import {CardProps} from "antd/lib/card";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../DragTypes";
import {DashboardConfig} from "../DashboardOverview/DashboardOverview";

export type DashBoardCardProps = CardProps & {
    id: string;
    style?: React.CSSProperties;
    dashboardConfig: DashboardConfig;
};

const DashBoardCard: FC<DashBoardCardProps> = (props) => {
    const {cardHeight} = props.dashboardConfig;
    const [{isDragging}, dragRef] = useDrag(() => ({
        type: ItemTypes.CARD,
        item: {
            id: props.id,
            title: props.title,
            content: props.children,
        },
        collect: (monitor) => ({
            isDragging: Boolean(monitor.isDragging()),
        }),
    }));

    return (
        <div
            ref={dragRef}
            style={{
                ...props.style,
                opacity: isDragging ? 0.5 : 1,
                borderRadius: "15px !important",
                overflow: "hidden !important",
                backgroundColor: "transparent !important",
            }}
        >
            <div
                // title={props.title}
                style={{
                    ...props.style,
                    // height: isDragging ? 400 : undefined,
                    height: cardHeight,
                }}
                // actions={props.actions}
                // extra={props.extra}
            >
                {props.children}
            </div>
        </div>
    );
};

export default memo(DashBoardCard);
