export const transferDetailedDescription = `
##### Allgemeine Funktionsweise
Dieses Feature ermöglicht es, Teilnehmer von einem Kurs in einen anderen zu verschieben bzw. umzubuchen oder aber zu kopieren.
Teilnehmer können in die verschiedenen Listen des Zielkurses verschoben werden. Dabei wird unterschieden zwischen den Listen "Platzreservierung", "Warteliste" und "Buchung".

- ***Platzreservierungen***: Es gibt die Möglichkeit Platzreservierungen zu ersetzen, falls der Zielkurs solche vorweißt.
- ***Warteliste***: Teilnehmer können unabhängig von den freien Plätzen im Kurs immer auf die Warteliste gesetzt werden..
- ***Buchung***: Teilnehmer können auch direkt in den Kurs gebucht werden, selbst wenn der Zielkurs bereits ausgebucht ist.

---

##### Kopieren vs. Verschieben
**Kopieren:**
 Beim Kopieren wird die ursprüngliche Buchung nicht verändert und es wird eine neue Buchung im Zielkurs angelegt.

**Verschieben:**
 Beim Verschieben wird die ursprüngliche Buchung gelöscht oder storniert (*siehe Liste unten*). Im Zielkurs wird eine neue Buchung angelegt.

 **Umgang mit der alten Buchung beim Verschieben**
- Ist eine Buchung noch nicht abgerechnet, so wird diese einfach gelöscht.
- Wenn eine Buchung bereits abgerechnet wurde, aber noch kein Zahlungseingang erfolgt ist, so wird diese storniert und eine Storno-Rechnung generiert.
- Wenn eine Buchung bereits abgerechnet und bereits eine Teilzahlung erfolgt ist oder komplett bezahlt wurde, so wird der bereits beglichene Betrag auf die neue Buchung übertragen und die alte Buchung storniert.
Danach wird ebenfalls eine Storno-Rechnung generiert.
  - Wenn der bereits bezahlte Betrag höher ist als der neue Buchungsbetrag, belaufen sich die Kosten der neuen Buchung auf *0€ / 0CHF / 0$*. Der Differenzbetrag wird als Guthaben beim Bucher hinterlegt.
`;

export const transferSimpleDescription = `
##### Allgemeine Funktionsweise
Dieses Feature ermöglicht es, Teilnehmer von einem Kurs in einen anderen zu verschieben oder zu kopieren.

Die Teilnehmer werden direkt in den neuen Kurs gebucht!
**Es werden weder Platzreservierungen verwendet, noch werden Teilnehmer auf die Warteliste gesetzt.**
Wenn du genau definieren willst, was mit den Buchungen passieren soll, dann nutze die Funktion "Mehrere Teilnehmer umbuchen" aus dem Dreipunktemenü rechts oben.

---

##### Kopieren vs. Verschieben
**Kopieren:**
 Beim Kopieren wird die ursprüngliche Buchung nicht verändert und es wird eine neue Buchung im Zielkurs angelegt.

**Verschieben:**
 Beim Verschieben wird die ursprüngliche Buchung gelöscht oder storniert (*siehe Liste unten*). Im Zielkurs wird eine neue Buchung angelegt.

 **Umgang mit der alten Buchung beim Verschieben**
- Ist eine Buchung noch nicht abgerechnet, so wird diese einfach gelöscht.
- Wenn eine Buchung bereits abgerechnet wurde, aber noch kein Zahlungseingang erfolgt ist, so wird diese storniert und eine Storno-Rechnung generiert.
- Wenn eine Buchung bereits abgerechnet und bereits eine Teilzahlung erfolgt ist oder komplett bezahlt wurde, so wird der bereits beglichene Betrag auf die neue Buchung übertragen und die alte Buchung storniert.
Danach wird ebenfalls eine Storno-Rechnung generiert.
  - Wenn der bereits bezahlte Betrag höher ist als der neue Buchungsbetrag, belaufen sich die Kosten der neuen Buchung auf *0€ / 0CHF / 0$*. Der Differenzbetrag wird als Guthaben beim Bucher hinterlegt.
`;
