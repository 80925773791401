import {blue} from "@ant-design/colors";
import {MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, MenuProps} from "antd";
import React, {FC, memo} from "react";
import css from "./MoreMenu.less";

type MoreMenuProps = {
    tooltipText?: string;
    moreMenuItems: MenuProps["items"];
    size?: "small" | "middle" | "large";
    style?: React.CSSProperties;
};

const MoreMenu: FC<MoreMenuProps> = ({
    tooltipText,
    moreMenuItems = [{key: "1", label: "test"}],
    size = "small",
    style,
}) => {
    const Menu = () => {
        return (
            <Dropdown
                menu={{items: moreMenuItems}}
                trigger={["click"]}
                placement="bottomRight"
            >
                <MoreOutlined
                    style={{
                        color: "rgb(7, 96, 255)",
                    }}
                />
            </Dropdown>
        );
    };

    return (
        <Button
            size={size}
            style={{border: "1px solid", borderColor: blue[5], ...style}}
        >
            <Menu />
        </Button>
    );
};

export default memo(MoreMenu);
