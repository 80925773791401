import React, {FC, memo} from "react";
import {WarningOutlined} from "@ant-design/icons";
import {InputStatus} from "antd/lib/_util/statusUtils";
import {red} from "@ant-design/colors";
import {Alert, Form, Select, Space, Tag, Tooltip} from "antd";
import {SelectionType} from "../EmailTemplatePicker";
import {useTestSubscription} from "../emailHelpers";
import {useGetAttendeesByIdsQuery} from "../../../../generated/graphql";
import {ReceiverWithAttendeeInfo} from "../ReceiversBar";
import css from "../Email.less";
import LoadingContent from "../../../../views/LoadingContent/LoadingContent";
import ReceiversWithNoMailModal from "./ReceiversWithNoMailModal";

type GetReceiversAttendeeProps = {
    attendees: Array<SelectionType>;
    setDisabledSendButton?: (disabled: boolean) => void;
    setReceiverCount?: (count: number) => void;
};

const GetReceiversAttendee: FC<GetReceiversAttendeeProps> = ({
    attendees,
    setDisabledSendButton,
    setReceiverCount,
}) => {
    const isTestSubscription = useTestSubscription().isTestSubscription;

    const attendeeIds = attendees.map((a) => a.id);

    const {
        data: attendeesData,
        loading: attendeesLoading,
    } = useGetAttendeesByIdsQuery({
        skip: !attendeeIds.length,
        variables: {
            attendeeIds,
        },
        fetchPolicy: "network-only",
    });

    const getReceivers = (): Array<ReceiverWithAttendeeInfo> => {
        if (!attendeesData?.attendeesByIds) return [];

        const receivers: Array<ReceiverWithAttendeeInfo> = [];

        attendeesData.attendeesByIds.forEach((attendee) => {
            const booker = attendee.booker;

            if (!booker) return;

            receivers.push({
                id: booker.id,
                email: booker.email ?? undefined,
                firstname: booker.firstname,
                lastname: booker.lastname,
                attendee: {
                    attendeeId: attendee.id,
                    firstname: attendee.firstname,
                    lastname: attendee.lastname,
                },
            });
        });

        return receivers;
    };

    const receivers = getReceivers();
    const receiverWithMailCount = receivers.filter((receiver) => receiver.email)
        .length;

    setReceiverCount?.(receiverWithMailCount);

    const noReceiverHasEmail = receivers.every(
        (receiver) => !receiver.email || receiver.email === "",
    );

    console.log("receivers", receivers);

    React.useEffect(() => {
        if (setDisabledSendButton) {
            if (!receivers.length || noReceiverHasEmail) {
                setDisabledSendButton(true);
            } else if (receivers.length > maxReceivers) {
                setDisabledSendButton(true);
            } else {
                setDisabledSendButton(false);
            }
        }
    }, [noReceiverHasEmail, receivers, setDisabledSendButton]);

    const Warning = ({missingEmail}: {missingEmail: boolean}) => {
        if (!missingEmail) return null;

        return <WarningOutlined style={{color: "white", marginRight: 7}} />;
    };

    const getValue = () => {
        if (!receivers.length) return undefined;

        return receivers.map((receiver) => {
            return {
                value: receiver.email,
                label: `${receiver.firstname} ${receiver.lastname} | Teilnehmer: ${receiver.attendee.firstname} ${receiver.attendee.lastname}`,
                fieldNames: {"data-booking-id": receiver.id},
                key: `${receiver.id}-${receiver.attendee.attendeeId}`,
            };
        });
    };

    const inputStatus: InputStatus =
        receivers.length && !noReceiverHasEmail
            ? ("success" as InputStatus)
            : ("error" as InputStatus);

    const maxReceivers = 25;

    const alertStyle: React.CSSProperties = {
        border: `2px solid ${red[2]}`,
        textAlign: "center",
        marginLeft: -2,
        marginRight: -2,
    };

    const NoReceiversWarning = () => {
        return (
            <Alert
                style={alertStyle}
                message="Keine Empfänger mit Email-Adresse gefunden"
                description="In der aktuellen Auswahl sind keine Empfänger mit Email-Adresse enthalten."
                type="error"
            />
        );
    };

    const MaxReceiversWarning = () => {
        return (
            <Alert
                style={alertStyle}
                message="Zu viele Empfänger"
                description={`Es können derzeit maximal ${maxReceivers} Empfänger ausgewählt werden.`}
                type="error"
            />
        );
    };

    const DemoWarning = () => {
        return (
            <Alert
                style={{
                    ...alertStyle,
                    fontSize: "0.9em",
                    padding: 3,
                    lineHeight: "1.1em",
                }}
                message="Demoversion!"
                description="Mails werden nicht an den Empfänger gesendet, sondern an die E-Mail-Adresse, die in den Einstellungen hinterlegt ist."
                type="warning"
            />
        );
    };

    const RenderContent = ({
        receivers,
    }: {
        receivers: Array<ReceiverWithAttendeeInfo>;
    }) => {
        return (
            <Form.Item
                label={
                    receivers.length &&
                    !noReceiverHasEmail &&
                    receivers.length < 8 &&
                    receivers.length < 25 &&
                    !isTestSubscription
                        ? "An"
                        : undefined
                }
                className={css.receiversBar}
            >
                {receivers.length > 25 && <MaxReceiversWarning />}
                {isTestSubscription && <DemoWarning />}
                {(!receivers.length || noReceiverHasEmail) && (
                    <NoReceiversWarning />
                )}
                <Select
                    style={{width: `100%`}}
                    open={false}
                    mode="tags"
                    labelInValue={true}
                    value={getValue()}
                    status={inputStatus}
                    defaultValue={getValue()}
                    maxTagCount={50}
                    optionFilterProp="label"
                    loading={attendeesLoading}
                    tagRender={(props) => {
                        console.log("props", props);
                        console.log("props.label", props.label);
                        const missingEmail = !props.value || props.value === "";
                        const firstLabelPart = (props.label as string).split(
                            "|",
                        )[0];
                        const secondLabelPart = (props.label as string).split(
                            "|",
                        )[1];
                        const color = (num: number) =>
                            missingEmail ? red[num] : undefined;
                        const maxLength = receivers.length > 4 ? 10 : 30;
                        const style = {
                            marginRight: 2,
                            color: missingEmail ? "white" : undefined,
                            borderColor: color(2),
                            fontWeight: missingEmail ? 500 : undefined,
                            backgroundColor: color(2),
                        };

                        const tooltipTitle = missingEmail ? (
                            <Space size={2} direction="vertical">
                                {`Für Bucher:in ${firstLabelPart} mit Teilnehmer:in ${secondLabelPart} ist keine Email-Adresse hinterlegt. Bucher:in wird deshalb nicht benachrichtigt.`}
                            </Space>
                        ) : (
                            <Space size={2} direction="vertical">
                                {secondLabelPart}
                                {firstLabelPart}
                                {props.value}
                            </Space>
                        );

                        return (props.label as string).length > maxLength ? (
                            <Tooltip
                                title={tooltipTitle}
                                overlayStyle={{
                                    minWidth: 350,
                                }}
                                placement="bottom"
                                mouseEnterDelay={0.3}
                            >
                                <Tag style={style}>
                                    <Warning missingEmail={missingEmail} />
                                    {firstLabelPart.slice(0, maxLength)}
                                    {"..."}
                                </Tag>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={tooltipTitle}
                                overlayStyle={{
                                    minWidth: 350,
                                }}
                            >
                                <Tag style={style}>
                                    <Warning missingEmail={missingEmail} />
                                    {firstLabelPart}
                                </Tag>
                            </Tooltip>
                        );
                    }}
                >
                    {receivers.map((receiver) => {
                        const receiverName = `${receiver.firstname} ${receiver.lastname}`;
                        const receiverEmail = receiver.email;
                        const key = `${receiver.id}-${receiver.attendee.attendeeId}}`;

                        return (
                            <Select.Option
                                key={key}
                                value={receiverEmail}
                                disabled
                            >
                                <Tooltip title={receiverEmail}>
                                    {receiverName}
                                </Tooltip>
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    if (attendeesLoading) {
        return <LoadingContent content="Empfängerdaten laden" />;
    }

    const receiversWithNoEmail = receivers.filter(
        (receiver) => !receiver.email,
    );

    return (
        <>
            {<ReceiversWithNoMailModal receivers={receiversWithNoEmail} />}
            <RenderContent receivers={receivers} />
        </>
    );
};

export default memo(GetReceiversAttendee);
