import React, {memo, FC, useEffect} from "react";
import {timeRanges, timeRangeTitles} from "./timeRanges";
import {Moment} from "moment";
import {useGetOnlineBookingsByTimeRangeCountQuery} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import {grey, orange} from "@ant-design/colors";
import css from "./Cards.less";
import {Spin, Statistic} from "antd";

type CardBookingsStatsProps = {
    timeRangeString: keyof typeof timeRanges;
    refetchTimeStamp?: number;
};

const CardBookingsStats: FC<CardBookingsStatsProps> = ({
    timeRangeString,
    refetchTimeStamp,
}) => {
    const [timeRange, setTimeRange] = React.useState<[Moment, Moment]>(
        timeRanges[timeRangeString],
    );

    const {data, loading, refetch} = useGetOnlineBookingsByTimeRangeCountQuery({
        variables: {
            startDateTime: timeRange[0].toISOString(),
            endDateTime: timeRange[1].toISOString(),
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        refetch();
    }, [refetchTimeStamp]);

    const count = data?.getOnlineBookingsByTimeRangeCount;
    // @ts-ignore
    // const translatedTimeRange = i18n.containers.dashboard.DashboardOverview.cards.bookings[
    //     timeRangeString
    // ]();

    const translatedTimeRange = timeRangeTitles[timeRangeString];

    const Title = () => {
        return (
            <div /* style={{borderLeft: `2px solid ${grey[1]}`}} */>
                {translatedTimeRange}
            </div>
        );
    };

    type StatsCSSProperties = React.CSSProperties & {
        "--color": string;
        "--colorLight": string;
    };

    const actualStyle: StatsCSSProperties = {
        "--color": orange[5],
        "--colorLight": orange[2],
    };

    if (loading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                }}
            >
                <Spin />
            </div>
        );
    }

    return (
        <Statistic
            value={count}
            title={<Title />}
            loading={loading}
            className={css.statisticCard}
            style={actualStyle}
            groupSeparator="."
            decimalSeparator=","
            valueStyle={{
                fontSize: "3em",
                fontWeight: 600,
                opacity: 0.8,
                paddingRight: 5,
                textAlign: "right",
            }}
        />
    );
};

export default memo(CardBookingsStats);
