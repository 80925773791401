import {FilterValue} from "antd/es/table/interface";
import {ArrayDataOptions, FilterOption, Maybe} from "../generated/graphql";

export const updateFilters = ({
    filters,
    options,
    table,
}: {
    filters: Record<string, FilterValue | null>;
    options?: Maybe<ArrayDataOptions>;
    table: string;
}) => {
    const currentFilters = options?.filter ?? [];
    const updatedFilters: Array<FilterOption> = [...currentFilters];

    currentFilters.forEach((filter) => {
        const {column, values} = filter;

        if (values === null) {
            const index = updatedFilters.findIndex(
                (el) => el.column === column,
            );

            if (index > -1) {
                updatedFilters.splice(index, 1);
            }
        } else {
            const columnAllreadyInFilters = updatedFilters.find(
                (el) => el.column === column,
            );

            if (columnAllreadyInFilters) {
                const index = updatedFilters.findIndex(
                    (el) => el.column === column,
                );

                updatedFilters[index] = {table, column, values};
            } else {
                updatedFilters.push({
                    table,
                    column,
                    values,
                });
            }
        }
    });

    return updatedFilters;
};
