import React, {FC, memo, useEffect, useState} from "react";
import {InputNumber} from "antd";
import {InputNumberProps} from "antd/lib/input-number";
import css from "./MoneyInput.less";
import {Currency, useGetCurrencySettingQuery} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {CurrencySettings, useCurrency} from "../../helpers/currencyHelper";
import {renderAmount2} from "../../helpers/renderAmount";

type MoneyInputProps = Omit<
    InputNumberProps,
    | "onBlur"
    | "onChange"
    | "defaultValue"
    | "formatter"
    | "parser"
    | "precision"
> & {
    onChange?: (value: number | undefined) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};

type MoneyInputCSSProperties = React.CSSProperties & {
    "--input-width": string;
    // "--currency": string;
    "--color": string;
};

const MoneyInput: FC<MoneyInputProps> = ({
    value,
    style,
    disabled,
    onChange = () => undefined,
    onBlur = () => undefined,
    ...props
}) => {
    const currencySettings: CurrencySettings = useCurrency();
    const [width, setWidth] = useState(value?.toString().length ?? 0);

    const handleChange = (value: number | undefined) => {
        setWidth(value?.toString().length ?? 0);
        onChange(value);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setWidth(event.target.value.length);
        onBlur(event);
    };

    const actualStyle: MoneyInputCSSProperties = {
        "--input-width": `${width}ch`,
        // "--currency": '"€"',
        "--color": disabled ? "lightgrey" : "black",
        ...style,
    };

    return (
        <InputNumber
            disabled={disabled}
            style={actualStyle}
            className={css.root}
            onChange={(value) => handleChange(Number(value))}
            onBlur={handleBlur}
            lang={navigator.language}
            precision={2}
            decimalSeparator={currencySettings.decimalSeperator}
            min={0}
            value={value}
            addonAfter={
                <span className={css.prefix}>{currencySettings.symbol}</span>
            }
            {...props}
        />
    );
};

export default memo(MoneyInput);
