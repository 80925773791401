import React, {FC, memo, useState, useEffect} from "react";
import autoTable, {ColumnInput, RowInput} from "jspdf-autotable";
import {jsPDF as JsPDF} from "jspdf";
import moment from "moment";
import css from "./PdfListExport.less";
import {colorDarkGrey, colorGrey} from "../../styles/colors";
import i18n from "../../services/i18n";
import {ExportInputType} from "../ExportModal/ExportModal";

type PdfListExportProps = {
    message?: string;
    csvData: Array<{[key: string]: string}>;
    previewWidth?: number;
    fileName?: string;
    inputType: ExportInputType;
    action: "preview" | "export";
};

const PdfListExport: FC<PdfListExportProps> = ({
    message = "Hi",
    csvData,
    previewWidth = 1100,
    fileName = "Dateiname",
    action,
}) => {
    // console.log("csvData: ", csvData);
    const [tableData, setTableData] = useState<{
        columns: Array<ColumnInput>;
        body: Array<RowInput>;
    }>({columns: [], body: []});

    const [useCase, setUseCase] = useState<"preview" | "export">("preview");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUseCase(action);
    }, [action]);

    useEffect(() => {
        const columns: Array<ColumnInput> = [];
        const body: Array<RowInput> = [];

        Object.keys(csvData[0]).forEach((key) => {
            const column: ColumnInput = {
                dataKey: key,
                header: key,
            };

            columns.push(column);
        });

        csvData.forEach((row) => {
            const bodyRow: Array<string> = [];

            Object.values(row).forEach((value) => {
                bodyRow.push(value);
            });

            body.push(bodyRow);
        });

        setTableData({columns, body});
    }, [csvData]);

    // const generateDoc = (useCase: "export" | "preview") => {
    const generateDoc = (useCase: "export" | "preview") => {
        const pageWidth = 310;
        const pagePadding = 14;
        const innerPageWidth = pageWidth - 2 * pagePadding;

        const doc = new JsPDF({
            orientation: "landscape",
            // orientation: "portrait",
        });

        doc.setLineHeightFactor(1);

        doc.setFontSize(16);
        doc.setTextColor(colorDarkGrey);
        doc.text(fileName, pagePadding + 2, 15);
        doc.setDrawColor(colorGrey);
        // doc.line(pagePadding, 18, innerPageWidth, 18);

        doc.setTextColor(colorDarkGrey);

        doc.setLineHeightFactor(1.3);

        autoTable(doc, {
            startY: 18,
            theme: "grid",
            styles: {
                overflow: "ellipsize",
                fontSize: 9,
                halign: "left",
                cellPadding: {vertical: 2, horizontal: 2},
            },
            rowPageBreak: "auto",
            headStyles: {overflow: "linebreak", fillColor: "#7AB0FF"},
            bodyStyles: {valign: "top", overflow: "linebreak"},
            columnStyles: {
                bookingNumber: {
                    // font: "Helvetica",
                    fontStyle: "normal",
                    fontSize: 10,
                },
            }, // European countries centered

            columns: tableData.columns,
            body: tableData.body,
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content:
                            i18n.containers.courses.AttendanceList.asOf() +
                            ": " +
                            moment().format("ddd - DD.MM.YYYY | HH:mm") +
                            " Uhr",
                        colSpan: 2,
                        rowSpan: 0,
                        styles: {
                            halign: "right",
                            fontSize: 7,
                        },
                    },
                ],
            ],
        });

        let previewPdf = "";

        const savePdf = async () => {
            return doc.save(
                fileName + "-" + moment().format("YYYY-MM-DD") + ".pdf",
            );
        };

        switch (useCase) {
            case "export":
                try {
                    setLoading(true);
                    savePdf().then(() => setLoading(false));
                } catch (error) {
                    throw new Error(`something went wrong here, ${error}`);
                } finally {
                    if (loading === false) {
                        setUseCase("preview");
                    }
                }
                break;
            case "preview":
                previewPdf = doc.output("datauristring");
                break;

            default:
                previewPdf = doc.output("datauristring");
                break;
        }

        return previewPdf;
    };

    const previewSize = (width: number) => {
        const factor = 297 / 210;

        return {
            width,
            height: width / factor,
            modalWidth: width + 50,
        };
    };

    return (
        <div id="PdfPreview">
            <embed
                width={previewSize(previewWidth).width}
                height={previewSize(previewWidth).height}
                src={`${generateDoc(useCase)}#toolbar=0&view=FitH&scrollbar=0`}
            />
        </div>
    );
};

export default memo(PdfListExport);
