import React, {FC, memo} from "react";
import classNames from "classnames";
import css from "./DescriptionBox.less";

type DescriptionBoxProps = {
    headLine: string | React.ReactNode;
    content: string | React.ReactNode;
    highlighted?: string;
    style?: React.CSSProperties;
};

const DescriptionBox: FC<DescriptionBoxProps> = ({
    headLine,
    content,
    highlighted,
    style,
}) => {
    return (
        <div className={css.descriptionBox} style={style}>
            <div className={classNames(css.textLevel4, css.headLine)}>
                {headLine}
            </div>
            <span className={classNames(css.textParagraph, css.content)}>
                {content}
            </span>
            <span className={classNames(css.textParagraph, css.highlighted)}>
                {highlighted}
            </span>
        </div>
    );
};

export default memo(DescriptionBox);
