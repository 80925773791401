import React, {FC, memo} from "react";
import {Card, Skeleton, Row, Col, Badge} from "antd";
import classnames from "classnames";
import {blue, green, grey, magenta, orange, red} from "@ant-design/colors";
import {PlusCircleTwoTone} from "@ant-design/icons";
import css from "./TemplateCard.less";
import i18n from "../../services/i18n";
import {MailDataType} from "../../generated/graphql";

type TemplateCardProps = {
    title: string | React.ReactNode;
    onClick: () => void;
    style?: React.CSSProperties;
    isCustomTemplate?: boolean;
    mailDataType?: MailDataType;
    createTemplate?: boolean;
    noTemplate?: boolean;
};

const TemplateCard: FC<TemplateCardProps> = ({
    title,
    onClick,
    style,
    isCustomTemplate,
    mailDataType,
    createTemplate = false,
    noTemplate = false,
}) => {
    const badgeRender = () => {
        switch (mailDataType) {
            case MailDataType.Booker:
                return {color: orange[3], text: "Bucher"};
                break;
            case MailDataType.Attendee:
                return {color: green[3], text: "Teilnehmer"};
                break;
            case MailDataType.Booking:
                return {color: magenta[3], text: "Buchung"};
                break;
            case MailDataType.Course:
                return {color: blue[3], text: "Kurs"};
                break;
            case MailDataType.InvoicePdf:
                return {color: red[3], text: "PDF"};
                break;

            default:
                return {color: grey[3], text: "Nicht definiert"};
                break;
        }
    };

    const badgeText = (
        <div style={{fontSize: "0.8em", marginTop: 0}}>
            {badgeRender().text}
        </div>
    );

    const renderCardContent = (
        <Card
            style={style}
            // title={titleWithTemplateType}
            title={title}
            onClick={onClick}
            hoverable
            bordered
            className={
                noTemplate
                    ? classnames(css.root, css.noTemplate)
                    : classnames(css.root)
            }
            headStyle={{
                padding: "30px 12px 0 12px",
                fontWeight: 700,
                fontSize: "1.1em",
                lineHeight: "0.5em",
            }}
            bodyStyle={{
                padding: "12px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Skeleton
                title
                paragraph={{rows: isCustomTemplate || noTemplate ? 4 : 1}}
                className={classnames(css.skeleton)}
            />
            <div
                className={classnames(
                    isCustomTemplate || noTemplate
                        ? css.contentHidden
                        : css.content,
                )}
            >
                {i18n.containers.templates.EmailTemplates.courseDetails()}
            </div>
            <Skeleton
                title={false}
                paragraph={{
                    rows: isCustomTemplate || noTemplate ? 3 : 2,
                    width: "10%",
                }}
                className={classnames(css.skeleton)}
            />
            {isCustomTemplate ||
                (noTemplate && (
                    <Skeleton
                        title={false}
                        paragraph={{rows: 1, width: "80%"}}
                        className={classnames(css.skeleton)}
                    />
                ))}
            <Row gutter={[12, 0]} className={css.bottom}>
                <Row gutter={[12, 0]} style={{padding: "3px 6px"}}>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                </Row>
            </Row>
        </Card>
    );

    const renderCeateTemplateCard = (
        <Card
            style={style}
            // title={titleWithTemplateType}
            title={title}
            onClick={onClick}
            hoverable
            bordered
            className={classnames(css.root)}
            headStyle={{
                padding: "30px 12px 0 12px",
                fontWeight: 700,
                fontSize: "1.1em",
                lineHeight: "0.5em",
            }}
            bodyStyle={{
                padding: "12px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Skeleton
                title
                paragraph={{rows: isCustomTemplate || noTemplate ? 4 : 1}}
                className={classnames(css.skeleton)}
            />
            <PlusCircleTwoTone style={{fontSize: "5em"}} />
            {/* <div
                className={classnames(
                    isCustomTemplate ? css.contentHidden : css.content,
                )}
            >
                {i18n.containers.templates.EmailTemplates.courseDetails()}
            </div> */}
            <Skeleton
                title={false}
                paragraph={{
                    rows: isCustomTemplate || noTemplate ? 3 : 2,
                    width: "10%",
                }}
                className={classnames(css.skeleton)}
            />
            {isCustomTemplate ||
                (noTemplate && (
                    <Skeleton
                        title={false}
                        paragraph={{rows: 1, width: "80%"}}
                        className={classnames(css.skeleton)}
                    />
                ))}
            <Row gutter={[12, 0]} className={css.bottom}>
                <Row gutter={[12, 0]} style={{padding: "3px 6px"}}>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                    <Col span={6}>
                        <Skeleton
                            title={false}
                            paragraph={{rows: 1, width: "100%"}}
                            className={classnames(css.small)}
                        />
                    </Col>
                </Row>
            </Row>
        </Card>
    );

    if (createTemplate) {
        return renderCeateTemplateCard;
    }

    return (
        <div className={css.container}>
            <Badge.Ribbon
                className={css.badge}
                text={badgeText}
                color={badgeRender().color}
                placement="start"
            >
                {renderCardContent}
            </Badge.Ribbon>
        </div>
    );
};

export default memo(TemplateCard);
