import React, {FC, memo} from "react";
import DescriptionBox from "../../../views/DescriptionBox/DescriptionBox";

type DescriptionProps = {};

const Description: FC<DescriptionProps> = () => {
    return (
        <DescriptionBox
            headLine="Erstellen von Sepa-Xml Dateien"
            content={
                <>
                    <div>
                        {
                            "Um eine Sepa-XML-Datei für die Bank zu erstellen, kannst du hier einen Zeitraum wählen für den die offenen Posten exportiert werden sollen. Maßgeblich ist hier das Datum der Fälligkeit."
                        }
                    </div>
                    <div>
                        {`Bitte trage den Tag der Ausführung ein. 
                                 An diesem wird die Bank versuchen die Lastschriften einzuziehen.`}
                    </div>
                </>
            }
        />
    );
};

export default memo(Description);
