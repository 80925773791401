import {
    Button,
    DatePicker,
    Form,
    InputNumber,
    Modal,
    Space,
    Tooltip,
} from "antd";
import React, {FC, useEffect} from "react";
import {FormikProps} from "formik";
import moment from "moment";
import {isDefined} from "../../../helpers/typeScriptHelpers";
import {CreateSepaXmlByTimeRangeInput} from "../../../generated/graphql";
import {createInputHelpers} from "../../../helpers/createInputHelpers";
import MoneyInput from "../../../views/MoneyInput/MoneyInput";

type InputFieldsProps = {
    formikProps: FormikProps<CreateSepaXmlByTimeRangeInput>;
    setUpdating: (updating: boolean) => void;
    refetchResults: (params: {
        values: CreateSepaXmlByTimeRangeInput;
    }) => Promise<void>;
    style?: React.CSSProperties;
};

export const InputMaxAmount: FC<InputFieldsProps> = ({
    formikProps,
    setUpdating,
    refetchResults,
    style,
}) => {
    const {values} = formikProps;
    const {handleNumberInputChange} = createInputHelpers(formikProps);

    useEffect(() => {
        refetchResults({
            values: formikProps.values,
        }).then(() => {
            setUpdating(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.maxAmount]);

    return (
        <Form.Item
            style={{
                ...style,
                display: "flex",
                border: "1px solid lightgrey",
                borderRadius: "10px",
                padding: "7px",
            }}
            label="Anzahl begrenzen auf"
            name="maxAmount"
        >
            <Space>
                <InputNumber
                    style={{width: "100%"}}
                    value={
                        isDefined(values.maxAmount) ? values.maxAmount : null
                    }
                    onChange={(value) => {
                        handleNumberInputChange("maxAmount")(
                            value ?? undefined,
                        );
                    }}
                    onBlur={() => {
                        setUpdating(true);
                        refetchResults({
                            values: formikProps.values,
                        }).then(() => {
                            setUpdating(false);
                        });
                    }}
                />
                {/* <Button type="default" icon={<ReloadOutlined />} /> */}
            </Space>
        </Form.Item>
    );
};

export const InputMaxMoneyAmount: FC<InputFieldsProps> = ({
    formikProps,
    setUpdating,
    refetchResults,
    style,
}) => {
    const {values} = formikProps;
    const {handleNumberInputChange} = createInputHelpers(formikProps);

    useEffect(() => {
        refetchResults({
            values: formikProps.values,
        }).then(() => {
            setUpdating(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.maxMoneyAmount]);

    return (
        <Form.Item
            style={{
                ...style,
                display: "flex",
                border: "1px solid lightgrey",
                borderRadius: "10px",
                padding: "7px",
            }}
            label="Betrag begrenzen auf"
            name="maxMoneyAmount"
            help={
                !isDefined(values.maxMoneyAmount) ||
                values.maxMoneyAmount < 1 ? (
                    <span style={{fontSize: "0.8em"}}>
                        {"Betrag von 0 wird ignoriert!"}
                    </span>
                ) : (
                    undefined
                )
            }
        >
            <Space>
                <MoneyInput
                    style={{
                        width: "100%",
                    }}
                    value={
                        isDefined(values.maxMoneyAmount)
                            ? values.maxMoneyAmount
                            : null
                    }
                    onChange={(value) => {
                        handleNumberInputChange("maxMoneyAmount")(
                            value ?? undefined,
                        );
                    }}
                    onBlur={() => {
                        setUpdating(true);
                        refetchResults({
                            values: formikProps.values,
                        }).then(() => {
                            setUpdating(false);
                        });
                    }}
                />
            </Space>
        </Form.Item>
    );
};

export const InputCollectionDate: FC<InputFieldsProps> = ({
    formikProps,
    setUpdating,
    refetchResults,
    style,
}) => {
    const {values} = formikProps;
    const {handleDatePickerChange, handleDatePickerBlur} = createInputHelpers(
        formikProps,
    );

    return (
        <Form.Item
            label="Tag der Ausführung"
            style={{
                ...style,
                display: "flex",
                border: "1px solid lightgrey",
                borderRadius: "10px",
                padding: "7px",
            }}
        >
            <DatePicker
                style={{width: "150px"}}
                onChange={handleDatePickerChange("collectionDate")}
                value={
                    isDefined(values.collectionDate)
                        ? moment(values.collectionDate)
                        : undefined
                }
                format="DD.MM.YYYY"
                onOpenChange={(open) => {
                    handleDatePickerBlur("collectionDate");
                    if (open === false) {
                        console.log("open", open);
                        setUpdating(true);
                        refetchResults({
                            values: formikProps.values,
                        }).then(() => {
                            setUpdating(false);
                        });
                    }
                }}
                allowClear={true}
            />
        </Form.Item>
    );
};

export const InputStartEndRange: FC<InputFieldsProps> = ({
    formikProps,
    setUpdating,
    refetchResults,
    style,
}) => {
    const {values} = formikProps;
    const {handleDatePickerChange, handleDatePickerBlur} = createInputHelpers(
        formikProps,
    );

    return (
        <Form.Item
            label="Fälligkeitszeitraum"
            style={{
                ...style,
                display: "flex",
                border: "1px solid lightgrey",
                borderRadius: "10px",
                padding: "7px",
            }}
        >
            <Space size={5}>
                <Space>
                    <div>{"ab"}</div>
                    <DatePicker
                        style={{
                            width: "100%",
                        }}
                        onChange={handleDatePickerChange("startDate")}
                        value={
                            isDefined(values.startDate)
                                ? moment(values.startDate)
                                : undefined
                        }
                        format="DD.MM.YYYY"
                        onOpenChange={(open) => {
                            handleDatePickerBlur("startDate");
                            if (open === false) {
                                setUpdating(true);
                                refetchResults({
                                    values: formikProps.values,
                                }).then(() => {
                                    setUpdating(false);
                                });
                            }
                        }}
                        allowClear={true}
                    />
                    <div>{"bis"}</div>
                    <DatePicker
                        style={{
                            width: "100%",
                        }}
                        onChange={handleDatePickerChange("endDate")}
                        value={
                            isDefined(values.endDate)
                                ? moment(values.endDate)
                                : undefined
                        }
                        format="DD.MM.YYYY"
                        onOpenChange={(open) => {
                            handleDatePickerBlur("endDate");
                            if (open === false) {
                                console.log("open", open);
                                setUpdating(true);
                                refetchResults({
                                    values: formikProps.values,
                                }).then(() => {
                                    setUpdating(false);
                                });
                            }
                        }}
                        allowClear={true}
                    />
                </Space>
            </Space>
        </Form.Item>
    );
};

// SUBMIT BUTTON -----------------------------------------------------------------------------------------------------------------

type CreateButtonProps = {
    formikProps: FormikProps<CreateSepaXmlByTimeRangeInput>;
    noPayments: boolean;
    errors: Array<{errorTitle: string; errorMsg: string}> | undefined;
    saveAndDownload: () => void;
    loadXmlData: (params: {
        startDate: string;
        endDate: string;
        collectionDate: string;
        saveFile: boolean;
        maxAmount?: number;
        maxMoneyAmount?: number;
    }) => void;
    paymentCount?: number;
};

export const InputCreateButton: FC<CreateButtonProps> = ({
    formikProps,
    noPayments,
    errors,
    saveAndDownload,
    loadXmlData,
    paymentCount,
}) => {
    return (
        <Tooltip
            title={
                noPayments
                    ? "Es wurden keine exportierbaren Einträge gefunden"
                    : null
            }
        >
            <Button
                type="primary"
                block
                disabled={(errors && errors.length > 0) || noPayments}
                onClick={() => {
                    Modal.confirm({
                        style: {whiteSpace: "pre-line"},
                        title: `XML-Datei mit ${paymentCount} Posten`,
                        content: `Willst du diese jetzt erzeugen und die offenen Posten damit als bezahlt markieren?

                                Posten mit einem Wert von 0 werden nicht in die XML-Datei geschrieben, sondern nur als bezahlt markiert!
                            `,
                        okText: "Jetzt erzeugen",
                        icon: null,

                        onOk: () => {
                            saveAndDownload();
                            loadXmlData({
                                startDate: formikProps.values.startDate,
                                endDate: formikProps.values.endDate,
                                collectionDate:
                                    formikProps.values.collectionDate,
                                saveFile: false,
                                maxAmount: isDefined(
                                    formikProps.values.maxAmount,
                                )
                                    ? formikProps.values.maxAmount
                                    : undefined,
                                maxMoneyAmount: isDefined(
                                    formikProps.values.maxMoneyAmount,
                                )
                                    ? formikProps.values.maxMoneyAmount
                                    : undefined,
                            });
                        },
                    });
                }}
            >
                {"XML-Datei erstellen"}
            </Button>
        </Tooltip>
    );
};
