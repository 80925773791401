import React, {FC, memo, useState} from "react";
import {Button, Modal} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {useGetAppSettingsQuery} from "../../../generated/graphql";
import SwissQrCamtFIleUpload from "./SwissQrCamtFIleUpload";

type UploadSwissQrCamtModalProps = {refetch?: () => void};

const UploadSwissQrCamtModal: FC<UploadSwissQrCamtModalProps> = ({refetch}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    React.useEffect(() => {
        if (modalOpen) return;
        if (refetch) refetch();
    }, [modalOpen, refetch]);

    const {
        data: appSettingData,
        loading: appSettingLoading,
    } = useGetAppSettingsQuery();

    const loading = appSettingLoading;

    if (appSettingData?.settings.esr === false) return null;

    return (
        <>
            <Modal
                title="Neue Camt-Datei hochladen"
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                destroyOnClose={true}
                width={1200}
                okButtonProps={{style: {display: "none"}}}
                cancelButtonProps={{
                    icon: <CloseOutlined />,
                }}
                cancelText="Ansicht schliessen"
            >
                <SwissQrCamtFIleUpload
                    setModalOpen={(open: boolean) => setModalOpen(open)}
                />
            </Modal>
            <Button
                loading={loading}
                size="small"
                style={{marginLeft: 7}}
                onClick={() => setModalOpen(!modalOpen)}
            >
                {"camt.054 einlesen"}
            </Button>
        </>
    );
};

export default memo(UploadSwissQrCamtModal);
