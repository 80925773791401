import React, {FC, memo, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Space, Tag} from "antd";
import moment from "moment";
import {grey, red} from "@ant-design/colors";
import {Icon} from "@ant-design/compatible";
import Page from "../../views/Page/Page";
import PageContentWrapper from "../../views/PageContentWrapper/PageContentWrapper";
import LayoutStep from "../../views/LayoutStep/LayoutStep";
import i18n from "../../services/i18n";
import {
    GetBookingByIdQuery,
    useGetBookingByIdQuery,
} from "../../generated/graphql";
import PanelCardCourseData from "../../views/PanelCardCourseData/PanelCardCourseData";
import PanelCardPaymentData from "../../views/PanelCardPaymentData/PanelCardPaymentData";
import PanelCardBooker from "../../views/PanelCardBooker/PanelCardBooker";
import RenderInvoicePreview from "../../views/RenderInvoicePreview/RenderInvoicePreview";
import PanelCard from "../../views/PanelCard/PanelCard";
import {LocationState} from "../../types";
import PanelCardNotes from "../../views/PanelCardNotes/PanelCardNotes";
import PanelCardPayments from "../../views/PanelCardPayments/PanelCardPayments";
import SendMailMenu from "./SendMailMenu/SendMailMenu";
import {LeftMenuBookingIcon} from "../../assets/SVG-Icons";
import CorrectionInvoice from "./CorrectionInvoice/CorrectionInvoice";
import {getPaymentMethodType} from "../../helpers/getPaymentMethodType";

type SettlementDetailsProps = {
    bookingId?: string;
    renderWithoutPage?: boolean;
    modalStyle?: React.CSSProperties;
    setModalOpen?: (value: boolean) => void;
};

const SettlementDetails: FC<SettlementDetailsProps> = ({
    bookingId,
    renderWithoutPage,
    modalStyle,
    setModalOpen,
}) => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const [booking, setBooking] = useState<
        GetBookingByIdQuery["bookingById"] | undefined
    >(undefined);
    const [paymentMethodTyepIsEsr, setPaymentMethodTypeIsEsr] = useState<
        boolean
    >(false);

    const [timeStamp, setTimeStamp] = useState<number>(0);

    const {data, loading} = useGetBookingByIdQuery({
        variables: {id: bookingId ?? ""},
        fetchPolicy: "network-only",
    });

    const returnBack = (): void => {
        const backToUrl = location.state?.from?.pathname;

        if (backToUrl) {
            return history.push(backToUrl);
        }

        return history.goBack();
    };

    const bookingData: GetBookingByIdQuery["bookingById"] | undefined =
        data?.bookingById;

    useEffect(() => {
        setBooking(bookingData);
    }, [bookingData]);

    const paymentMethodType = booking?.paymentMethod
        ? getPaymentMethodType(booking.paymentMethod)
        : undefined;

    useEffect(() => {
        if (paymentMethodType === "esr") {
            setPaymentMethodTypeIsEsr(true);
        }
    }, [paymentMethodType]);

    const getInvoiceData = (booking: GetBookingByIdQuery["bookingById"]) => {
        if (booking.invoice === null) {
            return undefined;
        }
        if (booking.reversalInvoice) {
            const {
                invoiceNumberPrefix,
                invoiceNumber,
                id,
            } = booking.reversalInvoice;

            return {
                id,
                invoiceNumber,
                invoiceNumberPrefix,
                isReversalInvoice: true,
            };
        }

        const {invoiceNumberPrefix, invoiceNumber, id} = booking.invoice;

        return {
            id,
            invoiceNumber,
            invoiceNumberPrefix,
            isReversalInvoice: false,
        };
    };

    // COLLECTING DATA ---------------------------------------------------------

    const invoiceData = booking ? getInvoiceData(booking) : undefined;

    type BookingType = "PlaceReservation" | "Booking" | "Waitlist";
    const bookingType: BookingType = booking?.bookingType
        ? (booking.bookingType as BookingType)
        : "Booking";

    // RETURN ------------------------------------------------------------------

    const isNotSettled = booking?.invoice?.id === undefined;
    const isCanceled = booking?.reversalInvoice?.id !== undefined;
    const subTextSecondPart = isNotSettled
        ? ""
        : isCanceled
        ? `(${i18n.containers.invoice.InvoiceDetails.canceled()})`
        : `(${i18n.containers.invoice.InvoiceDetails.settled()})`;

    const InvoiceHeader = () => {
        const {correctionDate, createDate} = booking?.invoice ?? {
            correctionDate: undefined,
            createDate: undefined,
        };

        const subStyle: React.CSSProperties = {
            fontSize: "0.8rem",
            fontWeight: 400,
            color: grey[3],
            marginRight: -5,
        };

        if (correctionDate) {
            return (
                <Space size={20}>
                    <span>
                        {i18n.containers.invoice.InvoiceDetails.invoice()}
                    </span>
                    <Space size={10} style={subStyle}>
                        <span>{`Erstellt:`}</span>
                        <Tag color="green">
                            {moment(createDate).format("L")}
                        </Tag>
                        <span style={subStyle}>{`Korrektur:`}</span>
                        <Tag color="volcano">
                            {moment(correctionDate).format("L")}
                        </Tag>
                    </Space>
                </Space>
            );
        }

        return (
            <Space size={20}>
                <span>{i18n.containers.invoice.InvoiceDetails.invoice()}</span>
                <Space size={10} style={subStyle}>
                    <span>{`Erstellt`}</span>
                    <Tag color="green">{moment(createDate).format("L")}</Tag>
                </Space>
            </Space>
        );
    };

    const renderContent = () => (
        <>
            {booking ? (
                <>
                    <PanelCardCourseData
                        courseId={booking.courseLessons[0].course.id}
                        lessons
                        booking={booking}
                    />
                    <PanelCardBooker attendee={booking.attendee} />
                    <PanelCardPaymentData
                        booking={booking}
                        refetchScheduledPayments={async () =>
                            setTimeStamp(moment.unix(Date.now()).valueOf())
                        }
                        isCanceled={isCanceled}
                    />
                    <PanelCardPayments
                        bookingId={booking.id}
                        booking={booking}
                        scheduledPayments={booking.scheduledPayments}
                        timeStamp={timeStamp}
                        isCanceled={isCanceled}
                    />
                    <PanelCardNotes booking={booking} />
                    <PanelCard
                        title={<InvoiceHeader />}
                        columns={1}
                        gapAfter
                        extra={
                            <CorrectionInvoice
                                bookingId={booking.id}
                                invoiceId={invoiceData?.id}
                            />
                        }
                    >
                        {invoiceData === undefined ? (
                            <div>{"-"}</div>
                        ) : (
                            <RenderInvoicePreview invoiceData={invoiceData} />
                        )}
                    </PanelCard>
                </>
            ) : null}
        </>
    );

    if (renderWithoutPage) {
        return renderContent();
    }

    return (
        <Page
            content={
                <PageContentWrapper>
                    {loading ? null : (
                        <LayoutStep
                            columns={1}
                            cancel={false}
                            style={{
                                border: isCanceled
                                    ? `2px solid ${red[3]}`
                                    : "none",
                                backgroundColor: isCanceled
                                    ? "#F5F5F5"
                                    : "unset",
                                ...modalStyle,
                            }}
                            title={`${booking?.bookingNumberPrefix}-${booking?.bookingNumber}`}
                            subtitle={
                                <span
                                    style={{
                                        color: isCanceled ? red[4] : "unset",
                                        fontWeight: isCanceled ? 500 : "unset",
                                    }}
                                >{`${i18n.containers.common.BookingTypes[
                                    bookingType
                                ]()} ${subTextSecondPart}`}</span>
                            }
                            icon={<Icon component={LeftMenuBookingIcon} />}
                            button={
                                <Space>
                                    <SendMailMenu booking={booking} />
                                    <Button
                                        type="primary"
                                        size="small"
                                        // onClick={returnBack}
                                        onClick={() => {
                                            if (setModalOpen) {
                                                setModalOpen(false);
                                            } else {
                                                returnBack();
                                            }
                                        }}
                                    >
                                        {i18n.containers.courses.CourseDetails.close()}
                                    </Button>
                                </Space>
                            }
                        >
                            {renderContent()}
                        </LayoutStep>
                    )}
                </PageContentWrapper>
            }
        />
    );
};

export default memo(SettlementDetails);
