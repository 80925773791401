import {
    ConvertBookingToWaitlistComponentProps,
    ConvertWaitlistToBookingComponentProps,
    GetBookedAttendeesByCourseIdDocument,
    GetBookingsByCourseDocument,
    GetBookinsDocument,
    GetCourseByIdDocument,
    GetFreePlacesByCourseIdDocument,
    GetScheduledPaymentsByBookingIdDocument,
    GetSettledBookingsDocument,
    GetUnsettledBookingsDocument,
    GetWaitlistByCourseDocument,
    RemoveBookingsComponentProps,
    SendBookingConfirmationComponentProps,
} from "../../../../generated/graphql";

export const refetchQueriesForAll = ({
    bookingId,
    courseId,
}: {
    bookingId: string;
    courseId: string;
}):
    | RemoveBookingsComponentProps["refetchQueries"]
    | ConvertBookingToWaitlistComponentProps["refetchQueries"]
    | ConvertWaitlistToBookingComponentProps["refetchQueries"]
    | SendBookingConfirmationComponentProps["refetchQueries"] => {
    const refetchQueries = [
        "GetFreePlacesByCourseId",
        {
            query: GetFreePlacesByCourseIdDocument,
            variables: {id: courseId},
        },
        "GetBookingsByCourse",
        {
            query: GetBookingsByCourseDocument,
            variables: {id: courseId},
        },
        "GetBookins",
        {query: GetBookinsDocument},
        "GetUnsettledBookings",
        {query: GetUnsettledBookingsDocument},
        "GetSettledBookings",
        {query: GetSettledBookingsDocument},
        "GetBookedAttendeesByCourseId",
        {
            query: GetBookedAttendeesByCourseIdDocument,
            variables: {courseId},
        },
        "GetWaitlistByCourse",
        {
            query: GetWaitlistByCourseDocument,
            variables: {id: courseId},
        },
        "GetScheduledPaymentsByBookingId",
        {
            query: GetScheduledPaymentsByBookingIdDocument,
            variables: {bookingId},
        },
        "GetCourseById",
        {
            query: GetCourseByIdDocument,
            variables: {id: courseId},
        },
    ];

    return refetchQueries;
};
