import React from "react";
import {grey, green, orange, red} from "@ant-design/colors";
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    ClockCircleOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
} from "@ant-design/icons";

const iconStyle = (color: string) => {
    return {
        fontSize: "1.1em",
        color,
        paddingTop: 4,
    };
};

export type MailjetStatusOptions = {
    value: string;
    label: string;
    color: string;
    icon: JSX.Element;
    description: string;
};

export const mailjetStatusOptions: Array<MailjetStatusOptions> = [
    {
        value: "unknown",
        label: "Unbekannt",
        color: "red",
        icon: <QuestionCircleOutlined style={iconStyle(red[5])} />,
        description: `Wenn eine Email den Status "Unbekannt" hat, haben wir keine Informationen erhalten. In den aller meisten Fällen bedeutet dies jedoch, dass die Email normal zugestellt wurde.`,
    },
    {
        value: "queued",
        label: "In Warteschlange",
        color: "orange",
        icon: <ClockCircleOutlined style={iconStyle(orange[5])} />,
        description: `Hat eine Email den Status "In Warteschlange", haben wir diese erfolgreich an unseren Versanddienstleister übermittelt, wo sie auf den Versand vorbeitet wird.\n
Normalerweise wird eine E-Mail innerhalb von 5 Minuten nach dem Versand aus der Warteschlange entfernt und der Status wird auf "Zugestellt" geändert.\n
In seltenen Fällen kann es jedoch vorkommen, dass eine E-Mail länger als 5 Minuten in der Warteschlange bleibt. Dies kann z.B. passieren, wenn der Empfänger-Server nicht erreichbar ist oder sehr viele Anfragen abgearbeitet werden müssen.`,
    },
    {
        value: "sent",
        label: "Zugestellt",
        color: "green",
        icon: <CheckCircleOutlined style={iconStyle(green[5])} />,
        description: `Hat eine E-Mail den Status „Zugestellt“, wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert.\n
"Zugestellt" bedeutet nicht zwingend, dass die E-Mail dem „Posteingang des Empfängers zugestellt wird“. Nur der Empfänger allein kann wissen, ob die Nachricht seinem Posteingang oder Spam-Ordner zugestellt wurde.`,
    },
    {
        value: "opened",
        label: "Geöffnet",
        color: "green",
        icon: <CheckCircleFilled style={iconStyle(green[5])} />,
        description: `Hat eine Email den Status "Geöffnet", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert und vom Empfänger geöffnet.`,
    },
    {
        value: "clicked",
        label: "Angeklickt",
        color: "green",
        icon: <CheckCircleOutlined style={iconStyle(green[5])} />,
        description: `Hat eine Email den Status "Angeklickt", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert und vom Empfänger angeklickt.`,
    },
    {
        value: "bounce",
        label: "Rückläufer",
        color: "orange",
        icon: <ClockCircleOutlined style={iconStyle(orange[5])} />,
        description: `Hat eine Email den Status "Rückläufer (Soft Bounce)" wurde diese dem Empfänger nicht zugestellt. Es gibt 2 unterschiedliche Arten von Rückläufern:\n
        Soft Bounce:\n
        - Hierbei handelt es sich um ein vorübergehendes Problem, z.B. wenn der Posteingang des Empfängers voll ist oder ein Verbindungstimeout stattgefunden hat.\n
        Hard Bounce:\n
        - In diesem Fall liegt ein dauerhafter Zustellungsfehler vor, der durch eine ungültige E-Mail-Adresse verursacht wurde (z. B. falsch eingegebene E-Mail-Adresse, nicht vorhandener Zielserver).`,
    },
    {
        value: "spam",
        label: "Spam",
        color: "red",
        icon: <WarningOutlined style={iconStyle(red[5])} />,
        description: `Hat eine Email den Status "Spam", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert.\n
Das Melden einer EMail als Spam ist eine Aktion, die vom Empfänger nach Erhalt Ihrer E-Mail durchgeführt wird.\n
Spam-Beschwerden entstehen dann, wenn eine E-Mail laut Meinung des Empfängers unerwünscht ist.`,
    },
    {
        value: "unsub",
        label: "Abbestellt",
        color: "red",
        icon: <WarningOutlined style={iconStyle(red[5])} />,
        description: `Hat eine Email den Status "Abbestellt", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert und vom Empfänger über den Abmeldelink abbestellt.`,
    },
    {
        value: "blocked",
        label: "Geblockt",
        color: "red",
        icon: <WarningOutlined style={iconStyle(red[5])} />,
        description: `Hat eine Email den Status "Geblockt", wurde die Email von unserem Versanddienstleister Mailjet geblockt.\n
Um zu entscheiden, ob eine E-Mail blockiert werden soll, werden vom Mailjet-System mehrere Parameter überprüft, einschließlich Ihres Versandverlaufs. Beispiele für die Gründe einer Blockierung der E-Mail:\n
- Diese E-Mail-Adresse gibt es nicht. (Wahrscheinlichster Grund!)\n
- Ein Empfänger hat Ihre E-Mail als Spam gemeldet.\n
- Eine E-Mail-Adresse, bei der bereits ein Hard Bounce aufgetreten ist.\n
- Eine E-Mail, die aufgrund ihres Inhalts als Spam eingestuft würde. Bevor Ihre Kampagnen versendet werden, durchsucht unser System die Kampagne mithilfe einer Anti-Spam-Software, um diese auf potenzielle spambezogene Auslöser oder Probleme hin zu überprüfen (z. B. GROSSBUCHSTABEN, schlechtes Verhältnis von Text und Bildern, Spam-Wörter).`,
    },
    {
        value: "hardbounced",
        label: "Rückläufer (Hard Bounce)",
        color: "red",
        icon: <WarningOutlined style={iconStyle(red[5])} />,
        description: `Hat eine Email den Status "Rückläufer (Hard Bounce)" wurde diese dem Empfänger nicht zugestellt. Es gibt 2 unterschiedliche Arten von Rückläufern:\nSoft Bounce:\nHierbei handelt es sich um ein vorübergehendes Problem, z.B. wenn der Posteingang des Empfängers voll ist oder ein Verbindungstimeout stattgefunden hat.\nHard Bounce:\nIn diesem Fall liegt ein dauerhafter Zustellungsfehler vor, der durch eine ungültige E-Mail-Adresse verursacht wurde (z. B. falsch eingegebene E-Mail-Adresse, nicht vorhandener Zielserver).`,
    },
    {
        value: "softbounced",
        label: "Rückläufer (Soft Bounce)",
        color: "orange",
        icon: <ClockCircleOutlined style={iconStyle(orange[5])} />,
        description: `Hat eine Email den Status "Rückläufer (Soft Bounce)" wurde diese dem Empfänger nicht zugestellt. Es gibt 2 unterschiedliche Arten von Rückläufern:\n
Soft Bounce:\n
- Hierbei handelt es sich um ein vorübergehendes Problem, z.B. wenn der Posteingang des Empfängers voll ist oder ein Verbindungstimeout stattgefunden hat.\n
Hard Bounce:\n
- In diesem Fall liegt ein dauerhafter Zustellungsfehler vor, der durch eine ungültige E-Mail-Adresse verursacht wurde (z. B. falsch eingegebene E-Mail-Adresse, nicht vorhandener Zielserver).`,
    },
    {
        value: "deferred",
        label: "Erneuter Versuch",
        color: "orange",
        icon: <ClockCircleOutlined style={iconStyle(orange[5])} />,
        description: `Wenn eine E-Mail als „Wiederholungs-E-Mail“ eingestuft wird, bedeutet dies, dass wir versuchen, sie zuzustellen und auf eine Antwort des Zielservers (Gmail, Hotmail usw.) warten.\n
Diese Antwort kann ein paar Sekunden bis hin zu 24 Stunden auf sich warten lassen. Sobald 24 Stunden vergangen sind, wird die E-Mail als Soft Bounce markiert und nicht zugestellt.`,
    },
    {
        value: "bookingDeleted",
        label: "Buchung gelöscht",
        color: grey[3],
        icon: <QuestionCircleOutlined style={iconStyle(grey[5])} />,
        description: `Die zughörige Buchung wurde gelöscht.`,
    },
];
