import {Space} from "antd";
import React, {FC} from "react";
import Logo from "../../assets/logo.svg";
import i18n from "../../services/i18n";
import LogoTypographic from "../../assets/logo-typographic.svg";

type AlphaLoginTextProps = {size?: "small" | "large"};

export const AlphaLoginText: FC<AlphaLoginTextProps> = ({size = "large"}) => {
    const conditionalStyle: React.CSSProperties =
        size === "small" ? {width: 160} : {width: 295, marginTop: 5};

    return (
        <>
            <img
                src={LogoTypographic}
                style={conditionalStyle}
                alt="logo-typographic"
            />
        </>
    );

    // return (
    //     <div style={{margin: -10}}>
    //         <Space>
    //             <img src={Logo} alt="logo" style={{width: 50, height: 50}} />
    //             <h1
    //                 style={{
    //                     fontWeight: 700,
    //                     fontSize: "1.7em",
    //                     lineHeight: 1,
    //                     marginLeft: 7,
    //                     paddingTop: 18,
    //                 }}
    //             >
    //                 {"Login"}
    //             </h1>
    //         </Space>
    //     </div>
    // );
};
