import {SendOutlined} from "@ant-design/icons";
import {Button, Result} from "antd";
import React, {FC, memo} from "react";

type RenderResultProps = {
    convertTo: "booking" | "waitlist";
    attendeesName: string;
    prefixedBookingNumber: string;
    loading: boolean;
    triggerSendBookingConfirmation: () => void;
};

const RenderResult: FC<RenderResultProps> = ({
    convertTo,
    attendeesName,
    prefixedBookingNumber,
    loading: sendBookingConfirmationLoading,
    triggerSendBookingConfirmation,
}) => {
    if (convertTo === "booking") {
        return (
            <Result
                status="success"
                title={
                    <>
                        <div>{`${attendeesName}`}</div>
                        <div>{`wurde in den Kurs gebucht`}</div>
                    </>
                }
                subTitle={
                    <>
                        <div>{`Buchung ${prefixedBookingNumber}`}</div>
                        <div
                            style={{paddingTop: 5}}
                        >{`Eine Buchungsbestätigung wurde nicht automatisch versendet.`}</div>
                        <Button
                            type="link"
                            loading={sendBookingConfirmationLoading}
                            onClick={triggerSendBookingConfirmation}
                        >
                            {"Jetzt Buchungsbestätigung senden"}
                            {<SendOutlined />}
                        </Button>
                    </>
                }
            />
        );
    }

    return (
        <Result
            status="success"
            title={
                <>
                    <div>{`${attendeesName}`}</div>
                    <div>{`wurde auf die Warteliste gesetzt`}</div>
                </>
            }
            subTitle={`Wartelisteneintrag ${prefixedBookingNumber}`}
        />
    );
};

export default memo(RenderResult);
