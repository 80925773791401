import React, {FC, memo} from "react";
import classnames from "classnames";
import css from "./PanelCard.less";

type PanelCardProps = {
    children: React.ReactNode;
    columns?: number;
    style?: React.CSSProperties;
    gapAfter?: boolean;
    noBorder?: boolean;
    noPadding?: boolean;
    title?: string | React.ReactNode;
    smallTitle?: boolean;
    extra?: React.ReactNode;
    className?: string;
};

type PanelCardCSSProperties = React.CSSProperties & {
    "--column-count": number;
};

const PanelCard: FC<PanelCardProps> = ({
    children,
    columns = 6,
    style,
    gapAfter = false,
    noBorder = false,
    noPadding = false,
    title,
    smallTitle = false,
    extra,
    className,
}) => {
    const actualStyle: PanelCardCSSProperties = {
        "--column-count": columns,
        ...style,
    };

    return (
        <div id="PanelCard" className={className}>
            <div
                className={
                    noBorder
                        ? classnames(
                              css.noBorder,
                              css.noPadding,
                              css.root,
                              gapAfter ? css.gap : "",
                          )
                        : noPadding
                        ? classnames(
                              css.noPadding,
                              css.root,
                              gapAfter ? css.gap : css.root,
                          )
                        : gapAfter
                        ? classnames(css.gap, css.root, css.padding)
                        : classnames(css.root, css.padding)
                }
                style={actualStyle}
            >
                <div className={title ? css.panelHeader : undefined}>
                    <div>
                        {title ? (
                            smallTitle ? (
                                <div className={css.smallTitle}>{title}</div>
                            ) : (
                                <h3 className={css.title}>{title}</h3>
                            )
                        ) : null}
                    </div>
                    {extra ? <div className={css.extra}>{extra}</div> : null}
                </div>
                <div
                    className={css.inner}
                    style={{
                        padding: noBorder ? 0 : actualStyle.padding,
                        height: actualStyle.height,
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default memo(PanelCard);
