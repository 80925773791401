import {Translation} from "./types";

type TranslationModule = {
    default: Translation;
};

export const translations = {
    de: async () =>
        ((await import(
            /* webpackChunkName: "de" */ "./de"
        )) as TranslationModule).default,
};

export type Locale = keyof typeof translations;
export const locales = Object.keys(translations);

export default translations;
