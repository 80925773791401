import React, {FC, memo, ReactNode, useState} from "react";
import {Popover, Button, DatePicker} from "antd";
import moment, {Moment} from "moment";
import classnames from "classnames";
import css from "./CalendarPopover.less";

type CalendarPopoverProps = {
    title?: string;
    buttonLeftText?: string;
    buttonRightText?: string;
    buttonRightClick: (date: null | Moment) => void;
    children?: ReactNode;
    initialValue?: Date;
    customHeader?: ReactNode;
    disabled?: boolean;
};
const CalendarPopover: FC<CalendarPopoverProps> = ({
    title,
    buttonLeftText,
    buttonRightText,
    buttonRightClick,
    children,
    initialValue,
    customHeader,
    disabled = false,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [dateString, setDateString] = useState<null | Moment>(
        moment(initialValue) || moment(),
    );

    const handleVisibleChange = (visible: boolean) => {
        setVisible(visible);
    };

    const onDateChange = (date: Moment | null) => {
        setDateString(date);
    };

    return (
        <Popover
            trigger="click"
            placement="left"
            onVisibleChange={handleVisibleChange}
            visible={!disabled && visible}
            content={
                <div className={classnames(css.content)}>
                    {customHeader}
                    <h6>{title}</h6>
                    <DatePicker
                        onChange={onDateChange}
                        className={css.picker}
                        value={dateString}
                        format="DD.MM.YYYY"
                    />
                    <div>
                        <Button
                            onClick={() => {
                                setVisible(false);
                            }}
                            className={classnames(css.btn)}
                            size="small"
                            type="default"
                        >
                            {buttonLeftText}
                        </Button>
                        <Button
                            onClick={() => buttonRightClick(dateString)}
                            className={classnames([css.btn, css.btn__right])}
                            size="small"
                            type="primary"
                            disabled={!dateString}
                        >
                            {buttonRightText}
                        </Button>
                    </div>
                </div>
            }
        >
            {children}
        </Popover>
    );
};

export default memo(CalendarPopover);
