import React, {FC, memo} from "react";
import {Maybe} from "type-graphql";
import {Button, Checkbox} from "antd";
import {ArrayDataOptions} from "../../generated/graphql";

type FilterDropdownSelectMultipleProps = {
    dataIndex: string;
    options?: Maybe<ArrayDataOptions>;
    setOptions?: (options: ArrayDataOptions) => void;
    setDropdownOpen?: (dropdownOpen: string | null) => void;
    selectOptions?: Array<{
        label: string;
        value: string;
    }>;
    setNewFilterOption: (column: string, value?: string) => void;
    resetSelectedFilter: (column: string) => void;
};

const FilterDropdownSelectMultiple: FC<FilterDropdownSelectMultipleProps> = ({
    dataIndex,
    options,
    setOptions,
    setDropdownOpen,
    selectOptions,
    setNewFilterOption,
    resetSelectedFilter,
}) => {
    const getActiveFilterValues = () => {
        const currentFilter = options?.filter?.find(
            (filter) => filter.column === dataIndex,
        );

        if (currentFilter?.values) {
            return currentFilter.values[0].split(",");
        }

        return [];
    };

    const [selectedKeys, setSelectedKeys] = React.useState<Array<string>>(
        getActiveFilterValues(),
    );

    // const panelWidth = 168;
    const panelWidth = 188;

    const isChecked = (value: string) => {
        return selectedKeys.includes(value);
    };

    const handleCheck = (value: string, checked: boolean) => {
        if (checked) {
            setSelectedKeys([...selectedKeys, value]);
        } else {
            setSelectedKeys(selectedKeys.filter((key) => key !== value));
        }
    };

    const renderOptions = () => {
        return selectOptions?.length ? (
            selectOptions.map((option) => (
                <Checkbox
                    style={{marginLeft: 0}}
                    key={option.label}
                    value={option.value}
                    checked={isChecked(option.value)}
                    onChange={(e) =>
                        handleCheck(option.value, e.target.checked)
                    }
                >
                    {option.label}
                </Checkbox>
            ))
        ) : (
            <>{"-"}</>
        );
    };

    const currentFilteredValues =
        options?.filter?.find((filter) => filter.column === dataIndex)
            ?.values ?? [];

    const selectedKeysAsComaSeparatedString = selectedKeys.join(",");

    return (
        <div style={{padding: 8}}>
            <div
                style={{
                    width: panelWidth,
                    marginBottom: 8,
                    display: "block",
                }}
            >
                {renderOptions()}
            </div>
            <Button
                type="primary"
                // onClick={() => setNewFilterOption(dataIndex, selectedKeys)}
                onClick={() => {
                    setNewFilterOption(
                        dataIndex,
                        selectedKeysAsComaSeparatedString,
                    );
                }}
                size="small"
                style={{width: "100%"}}
                disabled={!selectedKeys.length}
            >
                {"Suchen"}
            </Button>
            <Button
                style={{width: "100%", marginTop: "5px"}}
                danger
                size="small"
                disabled={!currentFilteredValues.length}
                onClick={() => resetSelectedFilter(dataIndex)}
            >
                {"Filter entfernen"}
            </Button>
        </div>
    );
};

export default memo(FilterDropdownSelectMultiple);
