import React, {FC, memo, useEffect, useState} from "react";
import {Card, Space} from "antd";
import moment from "moment";
import {
    DashboardConfig,
    DesignColor,
} from "../DashboardOverview/DashboardOverview";
import {
    BookingType,
    GetBookinsQueryVariables,
    TablesEnum,
    useGetBookingsInStartedCoursesQuery,
} from "../../../generated/graphql";
import BookingList, {
    BookingListProps,
} from "../../bookings/BookingList/BookingList";
import {TableType} from "../../bookings/BookingList/BookingListColumns";
import {useLocalStorageOptions} from "../../../helpers/columnsLocalStorageHandler";
import CardBookingsStartedCoursesMenu from "./CardBookingsStartedCoursesMenu";

type CardBookingsStartedCoursesProps = {
    color: DesignColor;
    dashboardConfig: DashboardConfig;
    style?: React.CSSProperties;
};

const defaultEndDate = moment()
    .startOf("day")
    .toISOString();
const defaultStartDate = moment(defaultEndDate)
    .subtract(2, "weeks")
    .toISOString();
const defaultDays = moment(defaultEndDate).diff(
    moment(defaultStartDate),
    "days",
);

const pageSize = 5;
const tablesEnum = TablesEnum.BookingsInStartedCourses;
const CardBookingsStartedCourses: FC<CardBookingsStartedCoursesProps> = ({
    color,
    dashboardConfig,
    style,
}) => {
    const headStyle: React.CSSProperties = {
        backgroundColor: color[3],
    };
    const {cardHeight, innerCardHeight} = dashboardConfig;
    const [totalResults, setTotalResults] = useState<number>(0);
    const [days, setDays] = useState<number>(defaultDays);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName: tablesEnum,
        defaultOptions: {},
        totalResults,
    });

    React.useEffect(() => {
        const dates = options?.filter?.find((f) => f.column === "dateOfBooking")
            ?.values;
        const days = moment(dates?.[1]).diff(moment(dates?.[0]), "days");

        setDays(days);
    }, [options]);

    React.useEffect(() => {
        if (options?.filter) {
            const bookingTypes = options.filter.find(
                (f) => f.column === "bookingType",
            )?.values;

            if (!bookingTypes?.length) {
                setOptions({
                    ...options,
                    filter: [
                        ...options.filter,
                        {
                            column: "bookingType",
                            table: "bookings",
                            values: [BookingType.Booking, BookingType.Waitlist],
                        },
                    ],
                });
            }
        }
    }, [options, setOptions]);

    const initialOptions = options?.filter?.find(
        (f) => f.column === "dateOfBooking",
    )
        ? options
        : {
              ...options,
              filter: [
                  {
                      column: "dateOfBooking",
                      table: "bookings",
                      values: [defaultStartDate, defaultEndDate],
                  },
              ],
          };

    const {data, loading, refetch} = useGetBookingsInStartedCoursesQuery({
        variables: {
            options: initialOptions,
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        totalResults !== data?.bookingsInStartedCourses?.total &&
            setTotalResults(data?.bookingsInStartedCourses?.total ?? 0);
    }, [data, totalResults]);

    const bookingsInStartedCourses =
        data?.bookingsInStartedCourses?.items ?? [];

    const defaultColumns: Array<Extract<keyof TableType, string>> = [
        "bookingNumber",
        "prefixedCourseNumber",
        "attendeeFullName",
        "bookedBy",
        "dateOfBooking",
    ];

    const negativeSpacing = 15;

    const bookingListProps: BookingListProps = {
        tableData: {
            dataSource: bookingsInStartedCourses as Array<TableType>,
            existMore: data?.bookingsInStartedCourses?.existsMore ?? false,
            total: data?.bookingsInStartedCourses?.total ?? 0,
            loading,
        },
        setOptions: setOptions as React.Dispatch<
            React.SetStateAction<GetBookinsQueryVariables["options"]>
        >,
        options,
        refetch: () => {},
        tablesEnum,
        defaultColumns,
        hideHeader: true,
        minPadding: true,
        hideCreate: true,
        defaultPageSize: pageSize,
        notSelectable: true,
        style: {
            overflow: "hidden",
            marginTop: -negativeSpacing,
            marginBottom: -negativeSpacing,
            height: innerCardHeight ? innerCardHeight : "auto",
        },
        // innerCardHeight = 450
        scroll: {
            y: innerCardHeight ? innerCardHeight - 160 : "auto",
        },
        pagination: {
            current:
                options?.offset && options?.limit
                    ? options.offset / options.limit + 1
                    : 1,
            pageSize: options?.limit ?? pageSize,
            showSizeChanger: true,
            pageSizeOptions: [pageSize.toFixed(), "8", "10", "20"],
            defaultPageSize: pageSize,
            total: data?.bookingsInStartedCourses?.total ?? 0,
            hideOnSinglePage: true,
        },
    };

    return (
        <Card
            size="small"
            title={
                <Space size={5} style={{fontSize: "1.3em"}}>
                    {`Buchungen in bereits gestarteten Kursen (${days} Tage)`}
                </Space>
            }
            extra={
                <CardBookingsStartedCoursesMenu
                    // settings={settings}
                    // setSettings={setSettings}
                    options={options ?? {}}
                    setOptions={setOptions}
                    days={days}
                    refetch={refetch}
                />
            }
            hoverable
            headStyle={headStyle}
            bodyStyle={{
                height: innerCardHeight,
                overflow: "auto",
            }}
            style={{
                ...style,
                borderRadius: 15,
                overflow: "hidden",
                height: cardHeight,
            }}
        >
            <BookingList {...bookingListProps} />
        </Card>
    );
};

export default memo(CardBookingsStartedCourses);
