import {FormikProps} from "formik";
import {CascaderOptionType} from "antd/lib/cascader";
import {
    set,
    getYear,
    getMonth,
    getDate,
    getHours,
    getMinutes,
    getSeconds,
    add,
} from "date-fns";
import uuid from "uuid";
import {Moment} from "moment";
import {
    GetLocationsSortedByCityQuery,
    EditCourseInput,
    CourseCycle,
    NewCourseLessonInput,
    EditCourseLessonInput,
    NewCourseInput,
} from "../../generated/graphql";
import {PossibleFormikPropsType} from "./StepCourseData";
import {ISODateString, assertNever} from "../../helpers/typeScriptHelpers";
import {sortByStartDateTime} from "../../helpers/sortingFunctions";

export const prepareLocationsForCascader: (
    locationsData: GetLocationsSortedByCityQuery["locationsSortedByCity"],
) => Array<CascaderOptionType> = (locationsData) => {
    return locationsData.map((locationData) => ({
        value: locationData.city,
        label: locationData.city,
        children: locationData.locations.map((location) => ({
            value: location.id,
            label: location.name,
            children: location.venues.map((venue) => ({
                value: venue.id,
                label: venue.name,
            })),
        })),
    }));
};

export const setOnlyDate = (
    dateToChange: ISODateString,
    newDate: ISODateString,
): ISODateString => {
    const newDateAsDate = new Date(newDate);
    const year = getYear(newDateAsDate);
    const month = getMonth(newDateAsDate);
    const date = getDate(newDateAsDate);

    return set(new Date(dateToChange), {year, month, date}).toISOString();
};

export const setOnlyTime = (
    dateToChange: ISODateString,
    newTime: ISODateString,
): ISODateString => {
    const newTimeAsDate = new Date(newTime);
    const hours = getHours(newTimeAsDate);
    const minutes = getMinutes(newTimeAsDate);
    const seconds = getSeconds(newTimeAsDate);

    return set(new Date(dateToChange), {hours, minutes, seconds}).toISOString();
};

export const isSameDate = (
    dateA: ISODateString,
    dateB: ISODateString,
): boolean => {
    const a = new Date(dateA);
    const b = new Date(dateB);
    const sameYear = getYear(a) === getYear(b);
    const sameMonth = getMonth(a) === getMonth(b);
    const sameDate = getDate(a) === getDate(b);

    return sameYear && sameMonth && sameDate;
};

export const isSameTime = (
    dateA: ISODateString,
    dateB: ISODateString,
): boolean => {
    const a = new Date(dateA);
    const b = new Date(dateB);
    const sameHour = getHours(a) === getHours(b);
    const sameMinute = getMinutes(a) === getMinutes(b);
    const sameSecond = getSeconds(a) === getSeconds(b);

    return sameHour && sameMinute && sameSecond;
};

/**
 * Determines the date of the last course lesson and merges it with the default start time.
 * If no lessons are present, it returns the default startDateTime.
 */
const getReferenceStartDateTime = (
    formikProps: FormikProps<PossibleFormikPropsType>,
) => {
    const {lessons, startDateTime: defaultStartDateTime} = formikProps.values;

    const lastLesson:
        | NewCourseLessonInput
        | EditCourseLessonInput
        | undefined = lessons.slice().sort(sortByStartDateTime)[
        lessons.length - 1
    ];

    const newStartDateTime = lastLesson?.startDateTime ?? undefined;

    return newStartDateTime === undefined
        ? new Date(defaultStartDateTime).toISOString()
        : setOnlyDate(defaultStartDateTime, newStartDateTime);
};

type CalculateLessonDatesFromReferenceOptions = {
    formikProps: FormikProps<PossibleFormikPropsType>;
    stepsFromReference: number;
    referenceDate: ISODateString;
    cycle?: CourseCycle;
    offset?: number;
};

/**
 * Calculates `startDateTime` and `endDateTime` for lessons, following the reference point taking into account the
 * selected `cycle`
 */
const calculateLessonDatesFromReference = ({
    formikProps,
    stepsFromReference,
    referenceDate,
    cycle: customCycle,
    offset = 0,
}: CalculateLessonDatesFromReferenceOptions) => {
    const {
        startDateTime: defaultStartDateTime,
        endDateTime: defaultEndDateTime,
        cycle: defaultCycle,
    } = formikProps.values;

    const cycle = customCycle ?? defaultCycle ?? undefined;
    const reference = new Date(
        setOnlyTime(referenceDate, defaultStartDateTime),
    );
    const steps = stepsFromReference + offset;

    if (cycle === undefined) {
        throw new Error(
            '"Cycle" has not been defined in the formik props and is needed to calculate the lesson date.',
        );
    }

    const DAYS_PER_WEEK = 7;
    const DAYS_PER_FORTNIGHT = 14;

    if (cycle === CourseCycle.Weekly) {
        const startDateTime = add(reference, {
            days: DAYS_PER_WEEK * steps,
        }).toISOString();
        const endDateTime = setOnlyTime(startDateTime, defaultEndDateTime);

        return {startDateTime, endDateTime};
    }

    if (cycle === CourseCycle.EveryTwoWeeks) {
        const startDateTime = add(reference, {
            days: DAYS_PER_FORTNIGHT * steps,
        }).toISOString();

        const endDateTime = setOnlyTime(startDateTime, defaultEndDateTime);

        return {startDateTime, endDateTime};
    }

    if (cycle === CourseCycle.Monthly) {
        const startDateTime = add(reference, {months: steps}).toISOString();
        const endDateTime = setOnlyTime(startDateTime, defaultEndDateTime);

        return {startDateTime, endDateTime};
    }

    const startDateTime = add(reference, {days: steps}).toISOString();
    const endDateTime = setOnlyTime(startDateTime, defaultEndDateTime);

    return {startDateTime, endDateTime};
};

export const appliesToAllCourseLessons = (
    formikProps: FormikProps<PossibleFormikPropsType>,
    property: "startTime" | "endTime" | "date" | "cycle",
) => {
    const {lessons, startDateTime: referenceDate} = formikProps.values;

    if (lessons === undefined || lessons === null || lessons.length < 1) {
        return true;
    }

    return (lessons as Array<NewCourseInput | EditCourseInput>).every(
        (lesson, stepsFromReference) => {
            const {
                startDateTime: expectedStartDateTime,
                endDateTime: expectedEndDateTime,
            } = calculateLessonDatesFromReference({
                formikProps,
                referenceDate,
                stepsFromReference,
            });

            switch (property) {
                case "startTime":
                    return isSameTime(
                        lesson.startDateTime,
                        expectedStartDateTime,
                    );
                case "endTime":
                    return isSameTime(lesson.endDateTime, expectedEndDateTime);
                case "date":
                case "cycle":
                    return isSameDate(
                        lesson.startDateTime,
                        expectedStartDateTime,
                    );
                default:
                    return assertNever(property);
            }
        },
    );
};

export const generateLessons = (
    formikProps: FormikProps<PossibleFormikPropsType>,
) => {
    const {
        defaultLessonCount,
        startDateTime,
        instructors,
        locationId,
        venueId,
    } = formikProps.values;

    const newLessons = Array.from(
        {length: defaultLessonCount ?? 0},
        (_, index) => {
            return {
                instructors,
                locationId,
                venueId,
                localKey: uuid(),
                ...calculateLessonDatesFromReference({
                    formikProps,
                    referenceDate: startDateTime,
                    stepsFromReference: index,
                }),
            };
        },
    );

    formikProps.setFieldValue("lessons", newLessons);
};

export const addLesson = (
    formikProps: FormikProps<PossibleFormikPropsType>,
) => {
    const {instructors, locationId, venueId} = formikProps.values;

    const lessons = formikProps.values.lessons ?? [];
    const referenceDate = getReferenceStartDateTime(formikProps);

    const updatedLessons = [
        ...lessons,
        {
            instructors,
            locationId,
            venueId,
            localKey: uuid(),
            ...calculateLessonDatesFromReference({
                formikProps,
                referenceDate,
                stepsFromReference: 1,
            }),
        },
    ];

    formikProps.setFieldValue("lessons", updatedLessons);
};

export const handleDefaultDateChange = (
    formikProps: FormikProps<PossibleFormikPropsType>,
) => {
    return (value: Moment | null) => {
        const userInput = (value ?? new Date()).toISOString();
        const oldFirstLessonStartDate =
            formikProps.values.startDateTime ?? new Date().toISOString();
        const newFirstLessonStartDate = setOnlyDate(
            oldFirstLessonStartDate,
            userInput,
        );
        const oldFirstLessonEndDate =
            formikProps.values.endDateTime ?? new Date().toISOString();
        const newFirstLessonEndDate = setOnlyDate(
            oldFirstLessonEndDate,
            userInput,
        );

        formikProps.setValues({
            ...formikProps.values,
            startDateTime: newFirstLessonStartDate,
            endDateTime: newFirstLessonEndDate,
        });
    };
};

export const handleDefaultCourseCycleChange = (
    formikProps: FormikProps<PossibleFormikPropsType>,
) => {
    return (cycle: CourseCycle) => {
        formikProps.setFieldValue("cycle", cycle);
    };
};

export const handleDefaultTimeChange = (
    formikProps: FormikProps<PossibleFormikPropsType>,
    property: "startDateTime" | "endDateTime",
) => {
    return (value: Moment | null) => {
        const userInput = (value ?? new Date()).toISOString();
        const oldTime =
            formikProps.values[property] ?? new Date().toISOString();
        const newTime = setOnlyTime(oldTime, userInput);

        formikProps.setFieldValue(property, newTime);
    };
};

export const changeDefaultLessonCount = (
    formikProps: FormikProps<PossibleFormikPropsType>,
) => {
    return (value: number | undefined = 0) => {
        formikProps.setFieldValue("defaultLessonCount", value);
    };
};
