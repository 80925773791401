import {TableType as CourseTableType} from "../../containers/courses/CourseEdit/CourseListColumns";
import {
    csvArray,
    csvBoolean,
    csvCoursePrice,
    csvDate,
} from "./TableColumnExports";
import {CurrencyShort} from "../renderAmount";
import i18n from "../../services/i18n";

export const courseValues = (
    key: string,
    row: CourseTableType,
    index: number,
    currencyShort: CurrencyShort,
    notExportable: string,
    // eslint-disable-next-line max-params
): string => {
    // console.log("row", row);
    const courseRow: CourseTableType = row; // Type assertion

    const firstCourseLesson = courseRow.lessons[0].startDateTime;
    const lastCourseLesson =
        courseRow.lessons[courseRow.lessons.length - 1].startDateTime;

    switch (key) {
        case "rowIndex":
            return `${index + 1}`;
        case "prefixedCourseNumber":
            return `${courseRow.prefixedCourseNumber}`;
        case "courseType":
            return `${courseRow.courseType.name}`;
        case "firstCourseLesson":
            return csvDate(firstCourseLesson, true);
        case "lastCourseLesson":
            return csvDate(lastCourseLesson, true);
        case "location":
            return `${courseRow.location.name}`;
        case "venue":
            return `${courseRow.venue.name}`;
        case "freePlaces":
            return `${courseRow.freePlaces}`;
        case "maxAttendees":
            return `${courseRow.maxAttendees}`;
        case "instructors":
            return csvArray(
                courseRow.instructors.map(
                    (instructor) =>
                        `${instructor.firstname} ${instructor.lastname}`,
                ),
            );
        case "coursePrice":
            return csvCoursePrice(
                courseRow.coursePrice[0].grossPrice,
                currencyShort,
                courseRow.paymentInterval,
            );
        case "allowMonthlyPayment":
            return csvBoolean(Boolean(courseRow.allowMonthlyPayment));
        case "lessons":
            return `${courseRow.lessons.length}`;
        case "lessonCount":
            return `${courseRow.lessonCount}`;
        case "city":
            return `${courseRow.location.city}`;
        case "settledBookingCount":
            return `${
                courseRow.settledBookingCount
            } ${i18n.containers.invoice.Invoice.of()} ${
                courseRow.settledBookingCount
            }`;
        case "billedBookingCount":
            return `${courseRow.settledBookingCount}`;
        case "showInWeb":
            return csvBoolean(Boolean(courseRow.showInWeb));
        case "bookingsCount":
            return `${courseRow.bookingCount}`;
        case "waitlistCount":
            return `${courseRow.waitListCount}`;
        case "bookingCount":
            return `${courseRow.bookingCount}`;
        case "placeReservationsCount":
            return `${courseRow.placeReservationsCount}`;
        case "bookingOverview":
            return notExportable;
        case "isActive":
            return csvBoolean(Boolean(courseRow.isActive));
        case "annotation":
            return `${courseRow.annotation}`;
        default:
            return notExportable;
    }
};
