/* eslint-disable babel/new-cap */
import {EyeTwoTone} from "@ant-design/icons";
import {Button, Modal, Space} from "antd";
import jsPDF from "jspdf";
import React, {FC, memo, useState} from "react";
import {colorDarkGrey} from "../../../styles/colors";
import {CreateXmlModalPreviewProps} from "./CreateXmlModalPreview";

type Orientation = "landscape" | "portrait";

type AccompanyingDocumentPdfProps = {
    fileName: string;
    props: CreateXmlModalPreviewProps;
    previewWidth?: number;
};

const A4 = {width: 210, height: 297};

const AccompanyingDocumentPdf: FC<AccompanyingDocumentPdfProps> = ({
    fileName = "Begleitzettel.pdf",
    props,
    previewWidth = window.innerWidth * 0.9,
}) => {
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [pdfPreview, setPdfPreview] = useState<string | undefined>();
    const [orientation, setOrientation] = useState<Orientation>("portrait");

    const {documentInfo, creditorInfo, transactionInfo} = props;

    const getPageWidth = (orientation: Orientation) => {
        return orientation === "landscape" ? A4.height + 13 : A4.width + 13;
    };

    const generateDoc = (
        useCase: "DOWNLOAD" | "PREVIEW",
        orientation: Orientation,
        pageWidth: number,
    ) => {
        const pagePadding = 14;
        const innerPageWidth = pageWidth - 2 * pagePadding;
        // const pageBreak = A4.height - pagePadding;
        const pageBreak =
            orientation === "portrait"
                ? A4.height - pagePadding
                : A4.width - pagePadding;

        const doc = new jsPDF({
            orientation,
        });

        doc.compatAPI((doc) => {
            let currentPageNumber = 1;
            let currentY = 0;

            doc.setLineHeightFactor(1);

            currentY = currentY + 15;
            doc.setFontSize(16);
            doc.setTextColor(colorDarkGrey);
            doc.text("Begleitzettel", pagePadding + 1, currentY);

            doc.setFontSize(10);
            doc.text(fileName, innerPageWidth - 1, currentY, {
                align: "right",
            });

            currentY = currentY + 2;

            doc.setDrawColor(colorDarkGrey);
            doc.line(pagePadding, currentY, innerPageWidth, currentY);

            // HEADER INFO ---------------------------------------------------------------------------------------------
            currentY = currentY + 10;
            // doc.setFontSize(14);
            doc.setFontSize(14);
            doc.text("Dokumenten Informationen", pagePadding + 1, currentY);
            currentY = currentY + 2;
            doc.line(pagePadding, currentY, innerPageWidth, currentY);
            currentY = currentY + 5;

            // currentY = currentY + 10;
            documentInfo.forEach((entry, index) => {
                doc.setFontSize(10);
                doc.setTextColor(colorDarkGrey);
                doc.text(entry.label, pagePadding + 1, currentY + index * 5);

                doc.setFontSize(10);
                doc.setTextColor(colorDarkGrey);
                doc.text(
                    entry.value.toString(),
                    innerPageWidth - 1,
                    currentY + index * 5,
                    {
                        align: "right",
                    },
                );
            });

            // CREDITOR INFO -------------------------------------------------------------------------------------------

            currentY = currentY + 25;
            doc.setFontSize(14);
            doc.text("Kreditor Informationen", pagePadding + 1, currentY);
            currentY = currentY + 2;
            doc.line(pagePadding, currentY, innerPageWidth, currentY);
            currentY = currentY + 5;

            creditorInfo.forEach((entry, index) => {
                doc.setFontSize(10);
                doc.setTextColor(colorDarkGrey);
                doc.text(entry.label, pagePadding + 1, currentY + index * 5);

                doc.setFontSize(10);
                doc.setTextColor(colorDarkGrey);
                doc.text(
                    entry.value.toString(),
                    innerPageWidth - 1,
                    currentY + index * 5,
                    {
                        align: "right",
                    },
                );
            });

            // TRANSACTIONS --------------------------------------------------------------------------------------------

            currentY = currentY + 30;
            doc.setFontSize(14);
            doc.text("Transaktionen", pagePadding + 1, currentY);
            currentY = currentY + 2;
            doc.line(pagePadding, currentY, innerPageWidth, currentY);
            currentY = currentY + 5;
            const transactionHight = 40;

            transactionInfo.forEach((transaction, index) => {
                currentY = index === 0 ? currentY : currentY + transactionHight;
                transaction.forEach((entry, index) => {
                    // currentY = currentY + 5;
                    doc.setFontSize(10);
                    doc.setTextColor(colorDarkGrey);
                    doc.text(
                        entry.label,
                        pagePadding + 1,
                        currentY + index * 5,
                    );

                    doc.setFontSize(10);
                    doc.setTextColor(colorDarkGrey);
                    doc.text(
                        entry.value.toString(),
                        innerPageWidth - 1,
                        currentY + index * 5,
                        {
                            align: "right",
                        },
                    );
                });
                const yAtEndOfTransaction = currentY + transactionHight;

                if (yAtEndOfTransaction + transactionHight >= pageBreak) {
                    doc.line(pagePadding, pageBreak, innerPageWidth, pageBreak);
                    doc.text(
                        `Seite: ${currentPageNumber}`,
                        innerPageWidth - 1,
                        pageBreak + 5,
                        {
                            align: "right",
                        },
                    );
                    currentPageNumber = currentPageNumber + 1;
                    doc.addPage();
                    // currentY = pagePadding;
                    currentY = 0;
                }
            });

            doc.line(pagePadding, pageBreak, innerPageWidth, pageBreak);
            doc.text(
                `Seite: ${currentPageNumber}`,
                innerPageWidth - 1,
                pageBreak + 5,
                {
                    align: "right",
                },
            );
        });

        // //////////////////////////////////////////////////////////////////////////////

        if (useCase === "PREVIEW") {
            // return doc.output("dataurlnewwindow");
            setPdfPreview(doc.output("bloburl").toString());
            setPreviewModalOpen(true);

            // return doc.output("bloburl");
            return null;
        }

        return doc.save(fileName);
    };

    const previewSize = (width: number, layout: Orientation) => {
        const factor = 297 / 210;
        const height = layout === "landscape" ? width / factor : width * factor;

        const modalHeaderFooterHeight = 145;

        if (height > window.innerHeight - modalHeaderFooterHeight) {
            const newHeight = window.innerHeight - modalHeaderFooterHeight;

            return {
                width,
                height: newHeight,
                modalWidth: width + 50,
            };
        }

        return {
            width,
            height,
            modalWidth: width + 50,
        };
    };

    const changeOrientation = async () => {
        const changeTo = orientation === "landscape" ? "portrait" : "landscape";

        setOrientation(changeTo);

        generateDoc("PREVIEW", changeTo, getPageWidth(changeTo));
    };

    return (
        <div>
            {/* <Button
                size="small"
                type="default"
                onClick={() =>
                    generateDoc(
                        "DOWNLOAD",
                        orientation,
                        getPageWidth(orientation),
                    )
                }
            >
                {"Begleitzettel herunterladen"}
            </Button> */}
            <Button
                size="small"
                icon={<EyeTwoTone />}
                // danger
                onClick={() =>
                    generateDoc(
                        "PREVIEW",
                        orientation,
                        getPageWidth(orientation),
                    )
                }
            >
                {"Begleitzettel"}
            </Button>
            <Modal
                open={previewModalOpen}
                width={previewSize(previewWidth, orientation).modalWidth}
                onCancel={() => {
                    setPreviewModalOpen(false);
                }}
                style={{top: 20}}
                bodyStyle={{
                    height: previewSize(previewWidth, orientation).height,
                }}
                title={
                    <Space>
                        {fileName}
                        {/* <Button
                            size="small"
                            danger
                            onClick={() => {
                                generateDoc(
                                    "PREVIEW",
                                    orientation,
                                    getPageWidth(orientation),
                                );
                            }}
                        >
                            {"Update preview"}
                        </Button> */}
                    </Space>
                }
                footer={[
                    <Button key="orientation" onClick={changeOrientation}>
                        {orientation === "landscape"
                            ? "wechseln zu Hochformat"
                            : "wechseln zu Querformat"}
                    </Button>,
                ]}
            >
                <embed
                    width={previewSize(previewWidth, orientation).width}
                    height={previewSize(previewWidth, orientation).height}
                    src={pdfPreview}
                    // src={`${pdfPreview}#toolbar=0&view=FitH&scrollbar=0`}
                    // src={`${generateDoc(
                    //     "PREVIEW",
                    // )}#toolbar=0&view=FitH&scrollbar=0`}
                />
            </Modal>
        </div>
    );
};

export default memo(AccompanyingDocumentPdf);
