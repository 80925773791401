/* eslint-disable react/display-name */
/* eslint-disable max-params */
import {
    CloseCircleOutlined,
    FilterFilled,
    FilterOutlined,
} from "@ant-design/icons";
import {blue, orange} from "@ant-design/colors";
import React, {useEffect, useRef} from "react";
import {BaseOptionType, DefaultOptionType} from "antd/lib/select";
import {Card} from "antd";
import {ArrayDataOptions, FilterOption, Maybe} from "../generated/graphql";
import FilterDropdownDate from "./tableHelpers/FilterDropdownDate";
import FilterDropdownSelect from "./tableHelpers/FilterDropdownSelect";
import FilterDropdownNumber from "./tableHelpers/FilterDropdownNumber";
import FilterDropDownSearch from "./tableHelpers/FilterDropdownSearch";
import i18n from "../services/i18n";
import FilterDropdownTime from "./tableHelpers/FilterDropdownTime";
import FilterDropdownSelectMultiple from "./tableHelpers/FilterDropdownSelectMultiple";

export type InputType =
    | "date"
    | "time"
    | "search"
    | "select"
    | "selectMultiple"
    | "boolean"
    | "number"
    | "autoComplete"
    | undefined;

export type AutoCompleteOptions = Array<BaseOptionType | DefaultOptionType>;

type FilterDropdownProps = {
    dataIndex: string;
    options?: Maybe<ArrayDataOptions>;
    setOptions?: (options: ArrayDataOptions) => void;
    setDropdownOpen?: (dropdownOpen: string | null) => void;
};

const columnSortFilter = ({
    dataIndex,
    type,
    selectOptions,
    options,
    setOptions,
    dropdownOpen,
    setDropdownOpen,
    autoCompleteOptions,
}: {
    dataIndex: string;
    type: InputType;
    options: Maybe<ArrayDataOptions> | undefined;
    setOptions: (options: ArrayDataOptions) => void;
    selectOptions?: Array<{
        label: string;
        value: string;
    }>;
    dropdownOpen: string | null;
    setDropdownOpen: (dropdownOpen: string | null) => void;
    autoCompleteOptions?: AutoCompleteOptions;
}) => {
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: () => {
            const setNewFilterOption = (column: string, value?: string) => {
                if (!value) return;

                // if dataIndex already exists in filter replace it
                if (
                    options?.filter?.find(
                        (filter) => filter.column === dataIndex,
                    )
                ) {
                    const updatedFilters = options.filter.map((filter) => {
                        if (filter.column === dataIndex) {
                            return {
                                ...filter,
                                values: [value],
                            };
                        }

                        return filter;
                    });
                    const updatedOptions = {
                        ...options,
                        filter: updatedFilters,
                    };

                    setOptions(updatedOptions);
                    setDropdownOpen(null);

                    return;
                }

                const timestamp = new Date().getTime().toString();
                const newFilter: FilterOption = {
                    column,
                    values: [value, timestamp],
                    table: "bookings",
                };

                const updatedOptions = {
                    ...options,
                    filter: [...(options?.filter ?? []), newFilter],
                };

                setOptions(updatedOptions);
                setDropdownOpen(null);
            };

            const resetSelectedFilter = (column: string) => {
                const updatedFilters = options?.filter?.filter((el) => {
                    return el.column !== column;
                });
                const updatedOptions = {
                    ...options,
                    filter: updatedFilters,
                };

                setOptions(updatedOptions);
                setDropdownOpen(null);
            };

            const DropDownHandler = () => {
                // // HANDLE CLICK OUTSIDE ------------------------------------------------------------------------------------
                // const ref = useRef(null);

                // useEffect(() => {
                //     const handleClickOutside = (event) => {
                //         if (
                //             ref.current &&
                //             !ref.current.contains(event.target)
                //         ) {
                //             setDropdownOpen(null);
                //         }
                //     };

                //     // Use 'mouseup' event instead of 'mousedown'
                //     document.addEventListener("mouseup", handleClickOutside);

                //     return () => {
                //         document.removeEventListener(
                //             "mouseup",
                //             handleClickOutside,
                //         );
                //     };
                // }, [ref]);

                // const handleDropdownClick = (e) => {
                //     // Stop propagation for click events within the dropdown component
                //     // This prevents the document click handler from firing when the dropdown is clicked
                //     e.stopPropagation();
                // };

                // // HANDLE CLICK OUTSIDE ------------------------------------------------------------------------------------

                const renderDropdown = () => {
                    switch (type) {
                        case "date":
                            return (
                                <FilterDropdownDate
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                    // setNewFilterOption={setNewFilterOption}
                                    // resetSelectedFilter={resetSelectedFilter}
                                />
                            );
                        case "time":
                            return (
                                <FilterDropdownTime
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                />
                            );
                        case "select":
                            return (
                                <FilterDropdownSelect
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                    selectOptions={selectOptions}
                                    setNewFilterOption={setNewFilterOption}
                                    resetSelectedFilter={resetSelectedFilter}
                                />
                            );
                        case "selectMultiple":
                            return (
                                <FilterDropdownSelectMultiple
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                    selectOptions={selectOptions}
                                    setNewFilterOption={setNewFilterOption}
                                    resetSelectedFilter={resetSelectedFilter}
                                />
                            );
                        case "number":
                            return (
                                <FilterDropdownNumber
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                    selectOptions={selectOptions}
                                    // setNewFilterOption={setNewFilterOption}
                                    // resetSelectedFilter={resetSelectedFilter}
                                />
                            );
                        case "autoComplete":
                            return (
                                <FilterDropDownSearch
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                    setNewFilterOption={setNewFilterOption}
                                    resetSelectedFilter={resetSelectedFilter}
                                    autoCompleteOptions={autoCompleteOptions}
                                />
                            );
                        default:
                            return (
                                <FilterDropDownSearch
                                    dataIndex={dataIndex}
                                    options={options}
                                    setOptions={setOptions}
                                    setDropdownOpen={setDropdownOpen}
                                    setNewFilterOption={setNewFilterOption}
                                    resetSelectedFilter={resetSelectedFilter}
                                />
                            );
                    }
                };

                return (
                    <div /* ref={ref} onClick={handleDropdownClick} */>
                        {renderDropdown()}
                    </div>
                );
            };

            return (
                <Card
                    bodyStyle={{padding: 0}}
                    headStyle={{
                        padding: "0px 10px" /* , paddingLeft: "10px" */,
                    }}
                    size="small"
                    title={`${i18n.views.TableColumnTitles[dataIndex]}`}
                    extra={
                        <CloseCircleOutlined
                            onClick={() => setDropdownOpen(null)}
                        />
                    }
                >
                    <DropDownHandler />
                </Card>
            );
        },
        filterDropdownOpen: dropdownOpen === dataIndex,
        filterIcon: () => {
            const filtered = Boolean(
                options?.filter?.length &&
                    options.filter
                        .map((filter) => filter.column)
                        .includes(dataIndex),
            );

            if (filtered) {
                return (
                    <FilterFilled
                        style={{
                            color: "white",
                            fontSize: "0.8em",
                            backgroundColor: orange[5],
                            padding: 3,
                            borderRadius: 20,
                            border: `1px solid ${orange[5]}`,
                        }}
                        onClick={() => setDropdownOpen(dataIndex)}
                    />
                );
            }

            return (
                <FilterOutlined
                    style={{
                        color: blue[6],
                        fontSize: "1em",
                    }}
                    onClick={() => setDropdownOpen(dataIndex)}
                />
            );
        },
        sorter:
            dropdownOpen === dataIndex
                ? false
                : (a: any, b: any) => {
                      if (a[dataIndex] < b[dataIndex]) return -1;
                      if (a[dataIndex] > b[dataIndex]) return 1;
                  },
    });

    return getColumnSearchProps(dataIndex);
};

export default columnSortFilter;
