import gql from "graphql-tag";

export const CREATE_INVOICE = gql`
    mutation CreateInvoice($newInvoiceData: InvoiceInput!, $sendMail: Boolean) {
        createInvoice(newInvoiceData: $newInvoiceData, sendMail: $sendMail) {
            id
        }
    }
`;

export const CREATE_INVOICES_BY_COURSE_ID = gql`
    mutation CreateInvoicesByCourseId($courseId: String!, $sendMail: Boolean) {
        createInvoicesByCourseId(courseId: $courseId, sendMail: $sendMail) {
            id
        }
    }
`;

export const CREATE_INVOICES_BY_BOOKINGS_IDS = gql`
    mutation CreateInvoicesByBookingIds(
        $bookingIds: [ID!]!
        $sendMail: Boolean
    ) {
        createInvoicesByBookingIds(
            bookingIds: $bookingIds
            sendMail: $sendMail
        ) {
            id
            bookingId
        }
    }
`;

export const GET_INVOICE_BODY = gql`
    query GetInvoices($options: ArrayDataOptions) {
        invoices(options: $options) {
            existsMore
            total
            items {
                id
                invoiceBody
                invoiceFileName
            }
        }
    }
`;

export const GET_INVOICE_BODY_BY_ID = gql`
    query GetInvoiceBodyById($id: String!) {
        invoiceBodyById(id: $id) {
            id
            invoiceBody
            invoiceFileName
        }
    }
`;

export const GET_INVOICE_BODY_BY_BOOKING_ID = gql`
    query GetInvoiceBodyByBookingId($bookingId: String!) {
        invoiceBodyByBookingId(bookingId: $bookingId) {
            id
            invoiceBody
            invoiceFileName
        }
    }
`;

// export const GET_INVOICE_BY_ID = gql`
//     query GetInvoiceById($id: String!) {
//         invoice(id: $id) {
//             id
//             invoiceBody
//             invoiceFileName
//         }
//     }
// `;

export const GET_COURSES_DETAILS = gql`
    query GetCoursesDetails {
        courses {
            id
            settledBookings {
                id
                attendee {
                    id
                    firstname
                    lastname
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                    }
                }
                invoice {
                    id
                    billed
                    createDate
                    invoiceNumberPrefix
                    invoiceNumber
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                canceled
                bookingType
            }
            unSettledBookings {
                id
                attendee {
                    id
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                    }
                }
                bookingNumber
                bookingNumberPrefix
                invoice {
                    id
                    billed
                    createDate
                    invoiceNumberPrefix
                    invoiceNumber
                }
                canceled
                bookingType
            }
        }
    }
`;

export const GET_UNSETTLED_BOOKINGS = gql`
    query GetUnsettledBookings($options: ArrayDataOptions) {
        getUnSettledBookings(options: $options) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                noticeStaff
                coursePriceId
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                    id
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    startDateTime
                    endDateTime
                    id
                    locationId
                    course {
                        freePlaces
                        startDateTime
                        id
                        prefixedCourseNumber
                        allowMonthlyPayment
                        courseType {
                            id
                            name
                            color
                        }
                    }
                    location {
                        id
                        city
                        name
                    }
                }
                attendee {
                    id
                    gender
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        paymentMethods {
                            id
                            type
                            cash
                            esr
                            iban
                            bank
                            bic
                            sepaAccountholder
                            bankTransfer
                        }
                        credit
                    }
                    defaultPaymentMethod {
                        id
                        cash
                        esr
                        iban
                        bank
                        bic
                        sepaAccountholder
                    }
                }
                paymentMethodId
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                bookingType
                canceled
                createDate
                updateDate
                waitListCount
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
            }
        }
    }
`;

export const GET_SETTLED_BOOKINGS = gql`
    query GetSettledBookings($options: ArrayDataOptions) {
        getSettledBookings(options: $options) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                noticeStaff
                coursePriceId
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                    id
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    startDateTime
                    endDateTime
                    id
                    locationId
                    course {
                        freePlaces
                        startDateTime
                        id
                        prefixedCourseNumber
                        allowMonthlyPayment
                        courseType {
                            id
                            name
                            color
                        }
                    }
                    location {
                        id
                        city
                        name
                    }
                    #instructors {
                    #    lastname
                    #    firstname
                    #}
                }
                attendee {
                    id
                    gender
                    birthday
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        paymentMethods {
                            id
                            type
                            cash
                            esr
                            iban
                            bank
                            bic
                            sepaAccountholder
                            bankTransfer
                        }
                        credit
                    }
                    defaultPaymentMethod {
                        id
                        cash
                        esr
                        iban
                        bank
                        bic
                        sepaAccountholder
                    }
                }
                paymentMethodId
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                bookingType
                canceled
                payDate
                createDate
                updateDate
                waitListCount
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
            }
        }
    }
`;

export const GET_CANCELED_BOOKINGS = gql`
    query GetCanceledBookings($options: ArrayDataOptions) {
        getCanceledBookings(options: $options) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                noticeStaff
                coursePriceId
                grossPrice
                netPrice
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                    id
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    startDateTime
                    endDateTime
                    id
                    locationId
                    course {
                        freePlaces
                        startDateTime
                        id
                        prefixedCourseNumber
                        allowMonthlyPayment
                        courseType {
                            id
                            name
                            color
                        }
                    }
                    location {
                        id
                        city
                        name
                    }
                    #instructors {
                    #    lastname
                    #    firstname
                    #}
                }
                attendee {
                    id
                    gender
                    birthday
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        paymentMethods {
                            id
                            type
                            cash
                            esr
                            iban
                            bank
                            bic
                            sepaAccountholder
                            bankTransfer
                        }
                    }
                    defaultPaymentMethod {
                        id
                        cash
                        esr
                        iban
                        bank
                        bic
                        sepaAccountholder
                    }
                }
                paymentMethodId
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                bookingType
                canceled
                payDate
                createDate
                updateDate
                waitListCount
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
            }
        }
    }
`;

export const GET_BOOKERS_BY_COURSE_WITH_BOOKING_TYPE = gql`
    query GetBookersByCourseWithBookingType($courseId: String!) {
        getBookersByCourseWithBookingType(courseId: $courseId) {
            id
            customerNumberPrefix
            customerNumber
            firstname
            lastname
            email
            gender
            phoneNumber
            email
            attendees {
                id
                firstname
                lastname
                birthday
            }
        }
    }
`;

export const GET_INVOICE_PREVIEW = gql`
    query GetInvoicePreview($invoiceId: String!) {
        getInvoicePreview(invoiceId: $invoiceId)
    }
`;

export const GET_INVOICE_DUMMY_PREVIEW = gql`
    query GetInvoiceDummyPreview(
        $editTemplateInput: EditTemplateInput
        $reversal: Boolean
        $paymentType: PaymentType
        $monthlySchedule: MonthlySchedule
    ) {
        getInvoiceDummyPreview(
            editTemplateInput: $editTemplateInput
            reversal: $reversal
            paymentType: $paymentType
            monthlySchedule: $monthlySchedule
        )
    }
`;

export const SEND_BOOKING_INVOICE = gql`
    mutation SendBookingInvoice($bookingId: ID!, $reversal: Boolean) {
        sendBookingInvoice(bookingId: $bookingId, reversal: $reversal)
    }
`;

export const SEND_BOOKING_INVOICES_BY_BOOKING_IDS = gql`
    mutation SendBookingInvoicesByBookingIds(
        $bookingIds: [ID!]!
        $reversal: Boolean
    ) {
        sendBookingInvoicesByBookingIds(
            bookingIds: $bookingIds
            reversal: $reversal
        )
    }
`;

export const CREATE_CORRECTION_INVOICE = gql`
    mutation CreateCorrectionInvoice($bookingId: String!, $sendMail: Boolean) {
        createCorrectionInvoice(bookingId: $bookingId, sendMail: $sendMail) {
            id
            invoiceFileName
        }
    }
`;
