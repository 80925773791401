import React from "react";
import {BookingType, TransferBookingInput} from "../../../../generated/graphql";
import {ListType, TypeOfList} from "./TransferDragAndDrop";

export const useBookingsInput = ({
    waitlist,
    pReservations,
    bookings,
    originIds,
}: {
    waitlist: ListType;
    pReservations: ListType;
    bookings: ListType;
    originIds: Array<string>;
}): Array<TransferBookingInput> => {
    const [bookingInput, setBookingInput] = React.useState<
        Array<TransferBookingInput>
    >([]);

    React.useEffect(() => {
        const getBookingInput = (
            list: ListType,
            typeOfList: TypeOfList,
        ): Array<TransferBookingInput> => {
            const targetBookingType =
                typeOfList === "waitList"
                    ? BookingType.Waitlist
                    : typeOfList === "placeReservationList"
                    ? BookingType.PlaceReservation
                    : BookingType.Booking;

            const bookingsToTransfer: Array<TransferBookingInput> = [];

            list?.forEach((item) => {
                if (originIds.includes(item.id)) {
                    bookingsToTransfer.push({
                        bookingId: item.id,
                        originBookingType: item.bookingType as BookingType,
                        targetBookingType,
                        usePlaceReservation: false,
                        isSettled: item.invoice !== null,
                    });
                }
            });

            return bookingsToTransfer;
        };

        setBookingInput([
            ...getBookingInput(waitlist, "waitList"),
            ...getBookingInput(pReservations, "placeReservationList"),
            ...getBookingInput(bookings, "bookingList"),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitlist, pReservations, bookings /* originIds */]);

    return bookingInput;
};
