import React, {FC, memo, useEffect} from "react";
import {Maybe} from "type-graphql";
import moment, {Moment} from "moment";
import {Alert, Button, Card, DatePicker, Form, Space, TimePicker} from "antd";
import {
    PickerDateProps,
    PickerProps,
    PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";
import {ArrayDataOptions, FilterOption} from "../../generated/graphql";
import LayoutForm from "../../views/LayoutForm/LayoutForm";

type FilterDropdownDateProps = {
    dataIndex?: string;
    options?: Maybe<ArrayDataOptions>;
    setOptions?: (options: ArrayDataOptions) => void;
    setDropdownOpen?: (dropdownOpen: string | null) => void;
};

const FilterDropdownDate: FC<FilterDropdownDateProps> = ({
    dataIndex,
    options,
    setOptions,
    setDropdownOpen,
}) => {
    const [error, setError] = React.useState<string | null>(null);

    const [selectedKeys, setSelectedKeys] = React.useState<Array<string>>([]);

    useEffect(() => {
        const currentDateValues = options?.filter?.find(
            (filter) => filter.column === dataIndex,
        )?.values;

        setSelectedKeys(currentDateValues ?? []);
    }, [dataIndex, options]);

    const setNewFilterOption = (
        column: string,
        values?: Array<string | undefined>,
    ) => {
        if (!values || values.length === 0) return;

        // if dataIndex already exists in filter replace it
        if (options?.filter?.find((filter) => filter.column === dataIndex)) {
            const updatedFilters = options.filter.map((filter) => {
                if (filter.column === dataIndex) {
                    return {
                        ...filter,
                        values: values.map((value) => value ?? ""),
                    };
                }

                return filter;
            });

            const updatedOptions = {
                ...options,
                filter: updatedFilters,
            };

            // setTimeout(() => {
            setOptions?.(updatedOptions);
            setDropdownOpen?.(null);
            // }, 300);

            return;
        }

        const newFilter: FilterOption = {
            column,
            values: values.map((value) => value ?? ""),
            table: "bookings",
        };

        const updatedOptions = {
            ...options,
            filter: [...(options?.filter ?? []), newFilter],
        };

        setOptions?.(updatedOptions);
        setDropdownOpen?.(null);
    };

    const resetSelectedFilter = (column: string) => {
        const updatedFilters = options?.filter?.filter((el) => {
            return el.column !== column;
        });
        const updatedOptions = {
            ...options,
            filter: updatedFilters,
        };

        setOptions?.(updatedOptions);
        setDropdownOpen?.(null);
    };

    useEffect(() => {
        setError(null);
        if (moment(selectedKeys[0]).isAfter(moment(selectedKeys[1]))) {
            setError("Das Startdatum muss vor dem Enddatum liegen");

            return;
        }
        if (moment(selectedKeys[1]).isBefore(moment(selectedKeys[0]))) {
            setError("Das Enddatum muss nach dem Startdatum liegen");

            return;
        }
        setError(null);
    }, [selectedKeys]);

    const onChange = (
        dateType: "startDate" | "endDate",
        time: "startOfDay" | "endOfDay" | "time",
        date: Moment | null,
    ) => {
        const dateAndTime = () => {
            switch (time) {
                case "startOfDay":
                    // return date?.startOf("day").utc(true); // set to the start of the day in UTC
                    return date?.startOf("day") /* .utc(true) */; // commented out because of issues with the timezones
                case "endOfDay":
                    return date?.endOf("day") /* .utc(true) */; // commented out because of issues with the timezones
                case "time":
                    if (dateType === "startDate") {
                        return (
                            moment(selectedKeys[0])
                                .startOf("day")
                                // .utc(true) // Convert the selected date to UTC
                                // .utc(true) // commented out because of issues with the timezones
                                .add(date?.hours(), "hours")
                                .add(date?.minutes(), "minutes")
                        );
                    }

                    return (
                        moment(selectedKeys[1])
                            .startOf("day")
                            // .utc(true) // Convert the selected date to UTC
                            // .utc(true) // commented out because of issues with the timezones
                            .add(date?.hours(), "hours")
                            .add(date?.minutes(), "minutes")
                    );
                default:
                    return date?.utc(true); // Convert the provided date to UTC
            }
        };

        // if (date) {
        if (dateType === "startDate") {
            const updatedKeys = [...selectedKeys];

            if (selectedKeys.length > 0) {
                updatedKeys.splice(0, 1, dateAndTime()?.toISOString() ?? "");
            } else {
                updatedKeys.push(dateAndTime()?.toISOString() ?? "");
            }

            // make sure to update the end date even if it's not set
            if (updatedKeys.length === 1) {
                updatedKeys.push("");
            }

            setSelectedKeys(updatedKeys);
        } else {
            const updatedKeys = [...selectedKeys];

            if (selectedKeys.length === 2) {
                updatedKeys.splice(1, 1, dateAndTime()?.toISOString() ?? "");
            } else if (selectedKeys.length === 1) {
                updatedKeys.push(dateAndTime()?.toISOString() ?? "");
            } else {
                updatedKeys.push("");
                updatedKeys.push(dateAndTime()?.toISOString() ?? "");
            }
            setSelectedKeys(updatedKeys);
        }
    };

    const onClear = () => {
        setSelectedKeys([]);
        resetSelectedFilter(dataIndex ?? "");
    };

    type RangeFavProps = {
        dataIndex: string;
        title: string;
        startDate: Moment;
        endDate: Moment;
    };

    // FAVOURITES --------------------------------------------------------------------------------------------

    const FavouritsDateTab = () => {
        const rangeFavs: Array<RangeFavProps> = [
            {
                dataIndex: "today",
                title: "Heute",
                startDate: moment().startOf("day"),
                endDate: moment().endOf("day"),
            },
            {
                dataIndex: "thisWeek",
                title: "Diese Woche",
                startDate: moment().startOf("week"),
                endDate: moment().endOf("week"),
            },
            {
                dataIndex: "thisMonth",
                title: "Diesen Monat",
                startDate: moment().startOf("month"),
                endDate: moment().endOf("month"),
            },
            {
                dataIndex: "thisYear",
                title: "Dieses Jahr",
                startDate: moment().startOf("year"),
                endDate: moment().endOf("year"),
            },
        ];

        return (
            <div style={{margin: "10px -5px"}}>
                {rangeFavs.map((rangeFav, index) => {
                    return (
                        <Button
                            style={{margin: "0 5px"}}
                            size="small"
                            key={rangeFav.dataIndex}
                            onClick={() => {
                                setError(null);
                                setSelectedKeys([
                                    rangeFav.startDate.toISOString(),
                                    rangeFav.endDate.toISOString(),
                                ]);
                            }}
                        >
                            {rangeFav.title}
                        </Button>
                    );
                })}
            </div>
        );
    };

    // TIME & DATE TAB ----------------------------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------------------------------------------

    const renderContentDateTimeTab = () => {
        const pickerProps: PickerProps<Moment> = {
            size: "small",
            style: {minWidth: 190},
        };

        const datePickerProps: PickerDateProps<Moment> = {
            ...pickerProps,
            showTime: false,
            format: "DD.MM.YYYY",
            showToday: true,
        };

        const timePickerProps: PickerTimeProps<Moment> = {
            ...pickerProps,
            picker: "time",
            format: "HH:mm",
            minuteStep: 15,
        };

        return (
            <>
                <LayoutForm columns={1}>
                    {error !== null && (
                        <Alert
                            message="Eingabe Fehler"
                            description={error}
                            type="error"
                            style={{textAlign: "center"}}
                        />
                    )}
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginBottom: 8,
                        }}
                    >
                        <Form.Item label="Von" colon={false}>
                            <Space direction="vertical">
                                <DatePicker
                                    {...datePickerProps}
                                    value={
                                        selectedKeys[0]
                                            ? moment(selectedKeys[0])
                                            : undefined
                                    }
                                    onChange={(date) =>
                                        onChange(
                                            "startDate",
                                            "startOfDay",
                                            date,
                                        )
                                    }
                                />
                                <TimePicker
                                    {...timePickerProps}
                                    value={
                                        selectedKeys[0]
                                            ? moment(selectedKeys[0])
                                            : undefined
                                    }
                                    onChange={(date) =>
                                        onChange("startDate", "time", date)
                                    }
                                />
                            </Space>
                        </Form.Item>
                        <Form.Item label="Bis" colon={false}>
                            <Space direction="vertical">
                                <DatePicker
                                    {...datePickerProps}
                                    value={
                                        selectedKeys[1]
                                            ? moment(selectedKeys[1])
                                            : undefined
                                    }
                                    onChange={(date) =>
                                        onChange("endDate", "endOfDay", date)
                                    }
                                />
                                <TimePicker
                                    {...timePickerProps}
                                    value={
                                        selectedKeys[1]
                                            ? moment(selectedKeys[1])
                                            : undefined
                                    }
                                    onChange={(date) =>
                                        onChange("endDate", "time", date)
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Space>
                </LayoutForm>
                <FavouritsDateTab />
            </>
        );
    };

    // TABS ----------------------------------------------------------------------------------------------------------

    return (
        <Card /* title="Zeitraum der erste Kurseinheit wählen" */>
            {renderContentDateTimeTab()}
            <Space direction="vertical" style={{width: "100%"}}>
                <Button
                    size="small"
                    type="primary"
                    block
                    onClick={() =>
                        setNewFilterOption(dataIndex ?? "", selectedKeys)
                    }
                    disabled={error !== null}
                >
                    {"Übernehmen"}
                </Button>
                <Button size="small" block danger onClick={onClear}>
                    {"Filter entfernen"}
                </Button>
            </Space>
        </Card>
    );
};

export default memo(FilterDropdownDate);
