import React, {FC, memo} from "react";
import LayoutStandard from "../LayoutStandard/LayoutStandard";
import Spin from "../Spin/Spin";
import css from "./LoadingContent.less";

type LoadingContentProps = {
    content?: string | React.ReactNode;
    style?: React.CSSProperties;
};

const LoadingContent: FC<LoadingContentProps> = ({content, style}) => {
    if (content)
        return (
            <div className={css.root} style={style}>
                <Spin>{content}</Spin>
            </div>
        );

    return (
        <div className={css.root} style={style}>
            <Spin />
        </div>
    );
};

export const LoadingLoggedIn: FC<LoadingContentProps> = () => {
    return <LayoutStandard main={<LoadingContent />} />;
};

export default memo(LoadingContent);
