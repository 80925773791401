import {KnownError} from "../../../../errors/handleErrors";
import i18n from "../../../../services/i18n";

export const knownErrorsConvertToBooking: Array<KnownError> = [
    {
        key: "Not convertable: Booking is already a booking",
        title: "Nicht konvertierbar: Buchung ist bereits eine Buchung",
        msg: "Nicht konvertierbar: Buchung ist bereits eine Buchung",
    },
    {
        key: "Not convertable: Booking is a place reservation",
        title: "Nicht konvertierbar: Buchung ist eine Platzreservierung",
        msg: "Nicht konvertierbar: Buchung ist eine Platzreservierung",
    },
    {
        key: "Not convertable: Booking is already a waitlist",
        title: "Nicht konvertierbar: Buchung ist bereits eine Warteliste",
        msg: "Nicht konvertierbar: Buchung ist bereits eine Warteliste",
    },
    {
        key: "Not convertable: Booking is settled",
        title: "Nicht konvertierbar: Buchung ist bereits abgerechnet",
        msg: "Nicht konvertierbar: Buchung ist bereits abgerechnet",
    },
    {
        key: "Not convertable: Course finished",
        title: "Nicht konvertierbar: Kurs ist bereits beendet",
        msg: "Nicht konvertierbar: Kurs ist bereits beendet",
    },
    {
        key: "Not convertable: FirstCourseLesson in the past",
        title:
            "Nicht konvertierbar: Erste Kursstunde liegt in der Vergangenheit",
        msg: "Nicht konvertierbar: Erste Kursstunde liegt in der Vergangenheit",
    },
];

export const knownErrorsBookingConfirmation = (
    bookingOrWaitlistConfirmationText: string,
): Array<KnownError> => {
    const knownErrors: Array<KnownError> = [
        {
            key: "No booker email found",
            title: "Keine E-Mail-Adresse gefunden",
            msg:
                "Es konnte keine E-Mail-Adresse für den Bucher gefunden werden.",
        },
        {
            key: "default",
            title: "Fehler",
            msg: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.messages.errorText(
                {bookingOrWaitlistConfirmationText},
            ),
        },
    ];

    return knownErrors;
};
