import React, {memo, useEffect, useState} from "react";
import {Modal, Button, Tooltip, message, Tag, Space, Collapse} from "antd";
import {MessageOutlined} from "@ant-design/icons";
import {blue, red} from "@ant-design/colors";
import {Formik} from "formik";
import {
    MailDataType,
    SendSmsInput,
    useGetAppSettingsQuery,
    useSendSmsMutation,
} from "../../../generated/graphql";
import SmsModalContent from "./SmsModalContent";
import {SelectionType} from "../../templates/EmailRefactoring/EmailTemplatePicker";

const {Panel} = Collapse;

type SendSmsModalProps = {
    moreThanOneCourseSelected?: boolean;
    btnClassName?: string;
    // type?: BlankMailTypes;
    mailDataType: MailDataType;
    selection: Array<SelectionType>;
};

const SendSmsModal: React.FC<SendSmsModalProps> = ({
    moreThanOneCourseSelected,
    btnClassName,
    // type,
    mailDataType,
    selection,
}) => {
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [noApiKey, setNoApiKey] = useState(true);

    const {
        data: appSettingsData,
        loading: appSettingsLoading,
    } = useGetAppSettingsQuery();

    useEffect(() => {
        if (appSettingsData && !appSettingsLoading) {
            setNoApiKey(!appSettingsData.settings.sevenIoApiKey);
        }
    }, [appSettingsData, appSettingsLoading]);

    const initialValues: SendSmsInput = {
        text: "",
        to: "",
    };

    const [sendSms] = useSendSmsMutation();

    const handleSendSms = async (values: SendSmsInput) => {
        if (values.text === "" || values.to === "") return;
        try {
            setSending(true);
            const response = await sendSms({
                variables: {
                    smsParams: {
                        text: values.text,
                        to: values.to,
                    },
                },
            }).then((res) => {
                console.log("1 res", res);
                const {data} = res;

                if (data?.sendSms?.success === "100") {
                    const sentMessagesCount = values.to.split(",").length;

                    setSending(false);
                    message.success(
                        `${sentMessagesCount} SMS erfolgreich versendet`,
                    );
                }

                return res;
            });

            console.log("2 response:::", response);
        } catch (e) {
            console.error(e);
        } finally {
            console.log("3 finally");
            setSending(false);
            setOpen(false);
        }
    };

    const modalTitle = (mailDataType: MailDataType) => {
        const baseTitle = "SMS versenden an eine Auswahl von";

        switch (mailDataType) {
            case MailDataType.Course:
                return `${baseTitle} Kursen`;
            case MailDataType.Attendee:
                return `${baseTitle} Teilnehmern`;
            case MailDataType.Booker:
                return `${baseTitle} Buchern`;
            case MailDataType.Booking:
                return `${baseTitle} Buchungen`;
            default:
                return `SMS versenden an Auswahl`;
        }
    };

    const SelectedCourses = () => {
        if (mailDataType !== MailDataType.Course) {
            return null;
        }

        return (
            <Collapse>
                <Panel key={1} header="Gewählte Kurse">
                    {selection.map((course) => {
                        return (
                            <Tag
                                key={course.id}
                                color={course.courseType.color}
                                style={{borderRadius: 20}}
                            >
                                {course.prefixedCourseNumber}
                            </Tag>
                        );
                    })}
                </Panel>
            </Collapse>
        );
    };

    if (open) {
        return (
            <Formik<SendSmsInput>
                initialValues={initialValues}
                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {(formikProps) => {
                    const {values} = formikProps;
                    const receiverCount =
                        values.to === "" ? 0 : values.to.split(",").length;

                    return (
                        <Modal
                            open={open}
                            onCancel={() => setOpen(false)}
                            title={modalTitle(mailDataType)}
                            destroyOnClose
                            width={600}
                            footer={[
                                <Button
                                    key="cancel"
                                    onClick={() => setOpen(false)}
                                    disabled={sending}
                                >
                                    {"Abbrechen"}
                                </Button>,
                                <Button
                                    key="send"
                                    type="primary"
                                    onClick={async () => handleSendSms(values)}
                                    disabled={
                                        sending ||
                                        values.text === "" ||
                                        values.to === ""
                                    }
                                >
                                    {`Senden an ${receiverCount} Empfänger`}
                                </Button>,
                            ]}
                        >
                            <>
                                <SelectedCourses />
                                <SmsModalContent
                                    mailDataType={mailDataType}
                                    selection={selection}
                                    formikProps={formikProps}
                                    receiverCount={receiverCount}
                                />
                            </>
                        </Modal>
                    );
                }}
            </Formik>
        );
    }

    return (
        <Tooltip
            title={
                noApiKey ? (
                    <span style={{whiteSpace: "pre-line"}}>
                        {
                            "Um SMS versenden zu können, muss ein seven.io-Account verknüpft sein.\nDer API-Key dafür kann in den Einstellungen hinterlegt werden."
                        }
                    </span>
                ) : (
                    "SMS senden"
                )
            }
            color={moreThanOneCourseSelected ? red[3] : undefined}
            mouseEnterDelay={0.3}
        >
            <Button
                disabled={Boolean(moreThanOneCourseSelected) || noApiKey}
                size="small"
                type="link"
                danger
                icon={<MessageOutlined />}
                style={moreThanOneCourseSelected ? undefined : {color: blue[5]}}
                onClick={() => setOpen(!open)}
                shape="circle"
                className={btnClassName}
                loading={appSettingsLoading}
            />
        </Tooltip>
    );
};

export default memo(SendSmsModal);
