import {message, Modal} from "antd";
import {ApolloError} from "apollo-client";

export type KnownError = {
    key: string;
    title: string;
    msg: string;
    additionalInfo?: {[key: string]: string | number};
};
export type ErrorType = "modal" | "message" | "console";

export const handleError = async (
    context: string,
    error: unknown,
    type?: ErrorType,
    knownErrors?: Array<KnownError>,
) => {
    const errorAs = type ?? "modal";

    console.log("handleError: ", context, error);
    if (error instanceof ApolloError && knownErrors) {
        console.log(":::instanceof ApolloError:::");
        const knownErrorsArray = knownErrors.map((e) => e.key);
        const errorMessage = error.graphQLErrors[0].message;

        if (knownErrorsArray.some((e) => errorMessage.includes(e))) {
            const foundError = knownErrors.find((e) =>
                errorMessage.includes(e.key),
            );

            renderError(foundError, errorAs);
        } else if (
            knownErrorsArray.some((e) => errorMessage.includes("default"))
        ) {
            const foundError = knownErrors.find((e) =>
                errorMessage.includes("default"),
            );

            renderError(foundError, errorAs);
        } else {
            const fallBackError: KnownError = {
                key: "default",
                title: "Unbekannter Fehler",
                msg: "Es ist ein unbekannter Fehler aufgetreten.",
            };

            renderError(fallBackError, errorAs);
        }

        // // if knownErrorsArray includes substring errorMessage
        // if (knownErrorsArray.some((e) => errorMessage.includes(e))) {
        //     const foundError = knownErrors.find((e) =>
        //         errorMessage.includes(e.key),
        //     );

        //     console.log("msg: ", foundError?.msg);
        //     Modal.confirm({
        //         title: foundError?.msg,
        //         content: foundError?.msg,
        //         cancelButtonProps: {style: {display: "none"}},
        //         okButtonProps: {block: true},
        //     });
        // } else {
        //     Modal.confirm({
        //         title: "Unbekannter Fehler",
        //         content: "Es ist ein unbekannter Fehler aufgetreten.",
        //         cancelButtonProps: {style: {display: "none"}},
        //         okButtonProps: {block: true},
        //     });
        // }
    }
};

const renderError = (error: KnownError | undefined, errorAs: ErrorType) => {
    if (error) {
        switch (errorAs) {
            case "modal":
                Modal.confirm({
                    title: error.title,
                    content: error.msg,
                    cancelButtonProps: {style: {display: "none"}},
                    okButtonProps: {block: true},
                });
                break;
            case "message":
                // message.error(error.msg);
                message.error(`${error.title}: ${error.msg}`);
                break;
            case "console":
                console.log(error.msg);
                break;
            default:
                break;
        }
    } else {
        console.log("renderError: error is undefined");
    }
};
