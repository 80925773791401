import {Space} from "antd";
import React, {FC, memo} from "react";
import moment from "moment";
import {red} from "@ant-design/colors";
import {FormikProps} from "formik";
import AlertCard from "../../../views/AlertCard/AlertCard";
import LayoutForm from "../../../views/LayoutForm/LayoutForm";
import {
    InputCollectionDate,
    InputCreateButton,
    InputMaxAmount,
    InputMaxMoneyAmount,
    InputStartEndRange,
} from "./InputFields";
import {CreateSepaXmlByTimeRangeInput} from "../../../generated/graphql";
import {isDefined} from "../../../helpers/typeScriptHelpers";
import {XmlResponse} from "./CreateXmlModal";

type InputFormProps = {
    formikProps: FormikProps<CreateSepaXmlByTimeRangeInput>;
    currentDateIsBeforeEndDate: boolean;
    createSepaXmlByTimeRangeInput: CreateSepaXmlByTimeRangeInput;
    setUpdating: (updating: boolean) => void;
    refetchResults: (params: {
        values: CreateSepaXmlByTimeRangeInput;
    }) => Promise<void>;
    setCreateSepaXmlByTimeRangeInput: (
        value: CreateSepaXmlByTimeRangeInput,
    ) => void;
    setXmlData: React.Dispatch<React.SetStateAction<XmlResponse | undefined>>;
    loadXmlData: (
        updatedDates?: CreateSepaXmlByTimeRangeInput,
    ) => Promise<void>;
    noPayments: boolean;
    paymentCount?: number;
    errors: Array<{errorTitle: string; errorMsg: string}> | undefined;
};

const InputForm: FC<InputFormProps> = ({
    formikProps,
    currentDateIsBeforeEndDate,
    createSepaXmlByTimeRangeInput,
    setUpdating,
    refetchResults,
    setCreateSepaXmlByTimeRangeInput,
    setXmlData,
    loadXmlData,
    noPayments,
    paymentCount,
    errors,
}) => {
    const {values} = formikProps;

    const maxAmount: number | undefined = isDefined(values.maxAmount)
        ? values.maxAmount
        : undefined;

    const saveAndDownload = () => {
        const updatedDates: CreateSepaXmlByTimeRangeInput = {
            startDate: values.startDate
                ? moment(values.startDate).format("YYYY-MM-DD")
                : undefined,
            endDate: values.endDate
                ? moment(values.endDate).format("YYYY-MM-DD")
                : undefined,
            collectionDate: values.collectionDate
                ? moment(values.collectionDate).format("YYYY-MM-DD")
                : undefined,
        };

        setCreateSepaXmlByTimeRangeInput({
            ...updatedDates,
            saveFile: true,
            maxAmount: values.maxAmount,
            maxMoneyAmount: values.maxMoneyAmount,
        });

        loadXmlData({
            ...updatedDates,
            maxAmount,
            saveFile: true,
            maxMoneyAmount: values.maxMoneyAmount,
        });

        setXmlData(undefined);
        setCreateSepaXmlByTimeRangeInput({
            collectionDate: undefined,
            endDate: undefined,
            startDate: undefined,
            saveFile: false,
            maxAmount: 2000,
        });
        // setOpen(false);
    };

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            {currentDateIsBeforeEndDate && (
                <AlertCard
                    title={`Achtung! Der Fälligkeitszeitraum liegt in der Zukunft. (${moment(
                        createSepaXmlByTimeRangeInput.endDate,
                    ).format(
                        "DD.MM.YYYY",
                    )}) Bitte überprüfe, ob das gewünscht ist.`}
                    type="WARNING"
                    style={{
                        border: `2px solid ${red[4]}`,
                        width: "100%",
                        backgroundColor: red[1],
                    }}
                    showIcon={false}
                />
            )}
            <LayoutForm
                layout="vertical"
                size="small"
                style={{
                    display: "block",
                    alignItems: "baseline",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(5, 1fr)",
                        gridGap: "10px",
                    }}
                >
                    <InputStartEndRange
                        formikProps={formikProps}
                        setUpdating={setUpdating}
                        refetchResults={refetchResults}
                        style={{gridColumn: "span 2"}}
                    />
                    <InputCollectionDate
                        formikProps={formikProps}
                        setUpdating={setUpdating}
                        refetchResults={refetchResults}
                    />
                    <InputMaxAmount
                        formikProps={formikProps}
                        setUpdating={setUpdating}
                        refetchResults={refetchResults}
                    />
                    <InputMaxMoneyAmount
                        formikProps={formikProps}
                        setUpdating={setUpdating}
                        refetchResults={refetchResults}
                    />
                </div>
                <InputCreateButton
                    formikProps={formikProps}
                    noPayments={noPayments}
                    errors={errors}
                    saveAndDownload={saveAndDownload}
                    loadXmlData={loadXmlData}
                    paymentCount={paymentCount}
                />
            </LayoutForm>
        </Space>
    );
};

export default memo(InputForm);
