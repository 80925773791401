import {Form, Input, message} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {blue} from "@ant-design/colors";
import {
    GetCourseByIdDocument,
    useGetCourseAnnotationQuery,
    useUpdateCourseAnnotationMutation,
} from "../../generated/graphql";
import LoadingContent from "../LoadingContent/LoadingContent";
import Button from "../Button/Button";

type AnnotationTextFieldProps = {
    courseId: string;
    courseLessonCount?: number;
    placeholder?: string;
    style?: React.CSSProperties;
};

export const AnnotationTextField: FC<AnnotationTextFieldProps> = ({
    courseId,
    courseLessonCount,
    placeholder,
    style,
}) => {
    const [annotation, setAnnotation] = useState<string>("");
    const [submitting, setSubmitting] = useState(false);
    const [textChanged, setTextChanged] = useState(false);
    const {data, loading} = useGetCourseAnnotationQuery({
        variables: {id: courseId},
    });

    useEffect(() => {
        if (data?.courseById?.annotation) {
            setAnnotation(data.courseById.annotation);
        }
    }, [data]);

    const [updateCourseAnnotation] = useUpdateCourseAnnotationMutation({
        variables: {id: courseId, annotation},
        refetchQueries: [
            "GetCourseById",
            {query: GetCourseByIdDocument, variables: {id: courseId}},
        ],
    });

    if (loading) {
        return <LoadingContent />;
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAnnotation(e.target.value);
        setTextChanged(true);
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await updateCourseAnnotation();
            await message.success("Anmerkung gespeichert");
        } catch (error) {
            console.error(error);
            await message.error("Fehler beim Speichern der Anmerkung");
        } finally {
            setSubmitting(false);
        }
    };

    const color = (intencity: number) => {
        if (textChanged) {
            return undefined;
        }

        return blue[intencity];
    };

    return (
        <Form>
            <Form.Item style={{...style, margin: 0, padding: 0}}>
                <Input.TextArea
                    placeholder={placeholder}
                    autoSize={{
                        minRows: courseLessonCount ?? 10,
                        maxRows: courseLessonCount ?? 50,
                    }}
                    onChange={handleChange}
                    value={annotation}
                    style={{
                        backgroundColor: textChanged ? color(0) : "white",
                    }}
                />
            </Form.Item>
            <Button
                loading={submitting}
                block
                onClick={handleSubmit}
                style={{color: color(5), borderColor: color(3)}}
                type={textChanged ? "primary" : "default"}
            >
                {"speichern"}
            </Button>
        </Form>
    );
};

export default memo(AnnotationTextField);
