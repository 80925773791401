import React, {FC, memo} from "react";
import {Space, Spin} from "antd";
import {
    ArrayDataOptions,
    Currency,
    Maybe,
    ScheduledPaymentSummary,
    useGetScheduledPaymentsSummaryQuery,
} from "../../generated/graphql";
import {useCurrency} from "../../helpers/currencyHelper";
import {renderAmount} from "../../helpers/renderAmount";

type PaymentsFooterProps = {
    contentType: "openPayment" | "donePayment";
    options: Maybe<ArrayDataOptions> | undefined;
};

const PaymentsFooter: FC<PaymentsFooterProps> = ({options, contentType}) => {
    const currency = useCurrency();

    const {
        data: summaryData,
        loading: loadingSummaryData,
        error: errorSummaryData,
    } = useGetScheduledPaymentsSummaryQuery({
        variables: {
            paymentDone: contentType === "donePayment",
            options,
        },
        // fetchPolicy: "network-only",
        pollInterval: 1000 * 60 * 10,
        // pollInterval: 1000 * 10,
    });

    if (errorSummaryData) {
        console.log("errorSummaryData:::", errorSummaryData);
    }

    const summary: ScheduledPaymentSummary | undefined =
        summaryData?.scheduledPaymentSummary;

    const valueStyle: React.CSSProperties = {
        fontWeight: "bold",
    };

    const itemType = contentType === "donePayment" ? "Umsätze" : "Posten";

    const FilteredSummary = () => {
        return (
            <Space size={3}>
                {`Gefilterte ${itemType}:`}
                <div style={valueStyle}>
                    {renderAmount({
                        amount: summary?.filtered.sum as number,
                        currency: currency.short as Currency,
                    })}
                </div>
            </Space>
        );
    };

    const TotalSummary = () => {
        return (
            <Space size={4}>
                {`Alle ${itemType}:`}
                <div style={valueStyle}>
                    {renderAmount({
                        amount: summary?.total.sum as number,
                        currency: currency.short as Currency,
                    })}
                </div>
            </Space>
        );
    };

    if (loadingSummaryData) {
        return (
            <div>
                <Spin />
            </div>
        );
    }

    return (
        <Space>
            <FilteredSummary />
            <div>{"|"}</div>
            <TotalSummary />
        </Space>
    );
};

export default memo(PaymentsFooter);
