import {Checkbox, Space, Tooltip, message} from "antd";
import React, {FC, memo} from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {blue} from "@ant-design/colors";
import {SendMailToSelectionOfCourseInput} from "../../../../generated/graphql";
import {SelectionType} from "../EmailTemplatePicker";
import {marginXs} from "../../../../styles/layout";

export type NotifySelection =
    | "notifyBookings"
    | "notifyWaitlist"
    | "notifyCancelations";

type CourseBookingTypeSelectorProps = {
    selection: Array<SelectionType>;
    dbTemplateId?: string;
    setNotifySelection: (bookingTypes: Array<NotifySelection>) => void;
};

const CourseBookingTypeSelector: FC<CourseBookingTypeSelectorProps> = ({
    dbTemplateId,
    selection,
    setNotifySelection,
}) => {
    const [
        sendMailToSelectionOfCourseInput,
        setSendMailToSelectionOfCourse,
    ] = React.useState<SendMailToSelectionOfCourseInput>({
        courseId: selection[0].id,
        templateId: dbTemplateId ?? "",
        notifyBookings: true,
        notifyWaitlist: false,
        notifyCancelations: false,
    });

    const options = [
        {label: "Buchungen", value: "notifyBookings"},
        {label: "Warteliste", value: "notifyWaitlist"},
        {label: "Stornierungen", value: "notifyCancelations"},
    ];

    const toNotify = (): Array<NotifySelection> => {
        const result: Array<NotifySelection> = [];

        if (sendMailToSelectionOfCourseInput.notifyBookings) {
            result.push("notifyBookings");
        }
        if (sendMailToSelectionOfCourseInput.notifyWaitlist) {
            result.push("notifyWaitlist");
        }
        if (sendMailToSelectionOfCourseInput.notifyCancelations) {
            result.push("notifyCancelations");
        }

        setNotifySelection(result);

        return result;
    };

    const onChange = (value: NotifySelection, checked: boolean) => {
        // Check if unchecking would result in no checkboxes being checked
        if (!checked) {
            const isLastChecked =
                (value === "notifyBookings" &&
                    !sendMailToSelectionOfCourseInput.notifyWaitlist &&
                    !sendMailToSelectionOfCourseInput.notifyCancelations) ||
                (value === "notifyWaitlist" &&
                    !sendMailToSelectionOfCourseInput.notifyBookings &&
                    !sendMailToSelectionOfCourseInput.notifyCancelations) ||
                (value === "notifyCancelations" &&
                    !sendMailToSelectionOfCourseInput.notifyBookings &&
                    !sendMailToSelectionOfCourseInput.notifyWaitlist);

            if (isLastChecked) {
                message.error({
                    content: "Es muss mindestens eine Option ausgewählt sein!",
                });

                return; // Do not allow unchecking the last checked option
            }
        }

        setSendMailToSelectionOfCourse({
            ...sendMailToSelectionOfCourseInput,
            notifyBookings:
                value === "notifyBookings"
                    ? checked
                    : sendMailToSelectionOfCourseInput.notifyBookings,
            notifyWaitlist:
                value === "notifyWaitlist"
                    ? checked
                    : sendMailToSelectionOfCourseInput.notifyWaitlist,
            notifyCancelations:
                value === "notifyCancelations"
                    ? checked
                    : sendMailToSelectionOfCourseInput.notifyCancelations,
        });
    };

    const Content = () => {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                    marginTop: `calc(${marginXs} * -1)`,
                    borderBottom: "1px solid lightgrey",
                    marginBottom: 5,
                    // backgroundColor: orange[3],
                }}
            >
                <Space
                    direction="horizontal"
                    style={{
                        borderRadius: 5,
                        paddingTop: 2,
                        paddingLeft: 6,
                        paddingRight: 6,
                    }}
                >
                    <Checkbox.Group value={toNotify()}>
                        {options.map((option) => {
                            return (
                                <Checkbox
                                    key={option.label}
                                    value={option.value}
                                    checked={toNotify().includes(
                                        option.value as NotifySelection,
                                    )}
                                    onChange={(e) => {
                                        onChange(
                                            e.target.value,
                                            e.target.checked,
                                        );
                                    }}
                                >
                                    {option.label}
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                    <Tooltip
                        title="Hier kannst einstellen, welche Buchungen benachrichtigt werden sollen."
                        trigger={["click"]}
                        placement="right"
                    >
                        <InfoCircleOutlined style={{color: blue[5]}} />
                    </Tooltip>
                </Space>
            </div>
        );
    };

    return (
        <div>
            <Content />
        </div>
    );
};

export default memo(CourseBookingTypeSelector);
