import React, {FC, memo, useState} from "react";
import {Button, Card, Modal, Space, Tooltip, message} from "antd";
import {SwapOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import classNames from "classnames";
import {
    GetBookingsByCourseDocument,
    GetBookingsByCourseQuery,
    GetCourseByIdDocument,
    TablesEnum,
    TransferBookingInput,
    TransferMultipleBookingsInput,
    useGetBookingsByCourseQuery,
    useTransferMultipleBookingsMutation,
} from "../../../generated/graphql";
import {marginL, panelWidth} from "../../../styles/layout";
import TransferCourseSelector from "../../invoice/MoreMenuCourseDetails/TransferMultipleAttendees/TransferCourseSelector";
import {TableType} from "../../courses/CourseEdit/CourseListColumns";
import {TableType as BookingTableType} from "../../bookings/BookingList/BookingListColumns";
import css from "./TransferMultipleBookings.less";
import {useBookingsInput} from "../../invoice/MoreMenuCourseDetails/TransferMultipleAttendees/useTransferMultipleBookingsInput";
import TransferDragAndDropDescription from "../../invoice/MoreMenuCourseDetails/TransferMultipleAttendees/TransferDragAndDropDescription";
import i18n from "../../../services/i18n";
import BookingList from "../../bookings/BookingList/BookingList";
import {KnownError, handleError} from "../../../errors/handleErrors";

type TransferMultipleBookingsProps = {
    currentCourseId: string;
    key: number;
    selectedBookings: GetBookingsByCourseQuery["getBookingsByCourse"]["items"];
    btnClassName?: string;
    onFinished: () => void;
};

const TransferMultipleBookings: FC<TransferMultipleBookingsProps> = ({
    currentCourseId,
    selectedBookings,
    key,
    btnClassName,
    onFinished,
}) => {
    const [open, setOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState<TableType | null>(
        null,
    );
    const [transfering, setTransfering] = useState(false);
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [transferableBookings, setTransferableBookings] = useState<
        GetBookingsByCourseQuery["getBookingsByCourse"]["items"]
    >(selectedBookings);
    const [
        selectedBookingsWithDuplicates,
        setSelectedBookingsWithDuplicates,
    ] = useState<GetBookingsByCourseQuery["getBookingsByCourse"]["items"]>(
        selectedBookings,
    );

    const {
        data: bookingsOfTargetCourseData,
        loading: loadingBookingsOfTargetCourse,
    } = useGetBookingsByCourseQuery({
        skip: !selectedCourse,
        variables: {id: selectedCourse?.id ?? ""},
    });

    React.useEffect(() => {
        const attendeeIdsOfTargetCourse = bookingsOfTargetCourseData?.getBookingsByCourse?.items
            .filter((booking) => booking.canceled === null)
            .map((booking) => booking.attendee?.id);
        const bookingIdsOfDuplicateAttendees = selectedBookings
            .filter((booking) =>
                attendeeIdsOfTargetCourse?.includes(booking.attendee?.id),
            )
            .map((booking) => booking.id);
        const selectedBookingsWithoutDuplicates = selectedBookings.filter(
            (booking) => !bookingIdsOfDuplicateAttendees.includes(booking.id),
        );
        const selectedBookingsWithDuplicates = selectedBookings.filter(
            (booking) => bookingIdsOfDuplicateAttendees.includes(booking.id),
        );

        const transferableBookings =
            selectedCourse && !loadingBookingsOfTargetCourse
                ? selectedBookingsWithoutDuplicates
                : selectedBookings;

        setSelectedBookingsWithDuplicates(selectedBookingsWithDuplicates);
        setTransferableBookings(transferableBookings);
    }, [
        selectedCourse,
        selectedBookings,
        bookingsOfTargetCourseData,
        loadingBookingsOfTargetCourse,
    ]);
    const transferableBookingIds = transferableBookings.map(
        (booking) => booking.id,
    );
    const transferableBookingsCount = transferableBookings.length;

    const bookingInput: Array<TransferBookingInput> = useBookingsInput({
        bookings: transferableBookings,
        waitlist: undefined,
        originIds: transferableBookingIds,
        pReservations: undefined,
    });

    // const bookingInput: Array<TransferBookingInput> = useBookingsInput({
    //     bookings: selectedBookings,
    //     waitlist: undefined,
    //     originIds: selectedBookingIds,
    //     pReservations: undefined,
    // });
    const transferMultipleBookingsInput: TransferMultipleBookingsInput = {
        originCourseId: currentCourseId,
        targetCourseId: selectedCourse?.id ?? "",
        notifyBooker: false,
        copyBookings: false,
        bookings: bookingInput,
    };

    const knownErrors: Array<KnownError> = [
        {
            key: "Already enrolled",
            title: "Doppelbuchung",
            msg: `
                Mindestens einer der gewählten Teilnehmer:innen ist bereits in diesem Kurs gebucht, oder steht auf dessen Warteliste.
                Die Umbuchung kann deshalb nicht durchgeführt werden.
                `,
        },
        {
            key: "default",
            title: "Fehler beim Verschieben der Buchungen",
            msg:
                "Sollte das Problem weiterhin bestehen, kontaktiere bitte den Support.",
        },
    ];

    const [transferMultipleBookings] = useTransferMultipleBookingsMutation({
        refetchQueries: [
            "GetBookingsByCourseDocument",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: currentCourseId},
            },
            "GetBookingsByCourseDocument",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: selectedCourse?.id},
            },
            "GetCourseByIdDocument",
            {
                query: GetCourseByIdDocument,
                variables: {id: selectedCourse?.id},
            },
        ],
    });
    const handleTransferMultipleBookings = async ({
        action,
    }: {
        action: "copy" | "move";
    }) => {
        setTransfering(true);
        message.loading({
            content: "Buchungen werden verschoben...",
            key: "transferMultipleBookings",
        });
        transferMultipleBookingsInput.copyBookings = action === "copy";

        try {
            await transferMultipleBookings({
                variables: {transferMultipleBookingsInput},
            });
            await message.success({
                content: "Buchungen wurden erfolgreich verschoben",
                key: "transferMultipleBookings",
                duration: 3,
            });
        } catch (error) {
            handleError(
                "transferAttendeeToCourseSimple",
                error,
                "modal",
                knownErrors,
            );
            await message.error({
                content: "Fehler beim Verschieben der Buchungen",
                key: "transferMultipleBookings",
            });
            console.error("Error transferring bookings", error);
        } finally {
            setTransfering(false);
            // resetAll();
            message.destroy("transferMultipleBookings");
            onFinished();
            setOpen(false);
        }
    };

    const Footer = () => {
        // const bookingCount = selectedBookings.length;
        const bookingCount = transferableBookings.length;
        const disabled =
            !currentCourseId || !selectedCourse || bookingCount === 0;

        const tooltipText = disabled
            ? bookingCount === 0
                ? "Es gibt keine übertragbaren Teilnehmer"
                : "Bitte wähle zuerst den Zielkurs"
            : undefined;

        return (
            <Space className={css.footer}>
                <Tooltip title={tooltipText}>
                    <Button
                        loading={transfering || loadingBookingsOfTargetCourse}
                        disabled={disabled || transfering}
                        type="default"
                        onClick={async () =>
                            handleTransferMultipleBookings({action: "move"})
                        }
                    >
                        {`${transferableBookingsCount} Buchungen verschieben`}
                    </Button>
                </Tooltip>
                <Tooltip title={tooltipText}>
                    <Button
                        loading={transfering || loadingBookingsOfTargetCourse}
                        disabled={disabled || transfering}
                        type="primary"
                        onClick={async () =>
                            handleTransferMultipleBookings({action: "copy"})
                        }
                    >
                        {`${transferableBookingsCount} Buchungen kopieren`}
                    </Button>
                </Tooltip>
            </Space>
        );
    };

    const renderTable = (
        bookings: Array<BookingTableType>,
        duplicateTable?: boolean,
    ) => {
        const additionalStyle = duplicateTable
            ? {
                  marginTop: -10,
                  border: `4px solid ${red[2]}`,
                  padding: 0,
                  borderRadius: 7,
                  overFlow: "hidden",
                  opacity: 0.8,
              }
            : undefined;

        return (
            <BookingList
                hideColumnSelector={duplicateTable}
                tablesEnum={TablesEnum.TransferSimpleCourseSelector}
                tableData={{
                    dataSource: selectedCourse
                        ? bookings
                        : (selectedBookings as Array<BookingTableType>),
                    existMore: false,
                    total: bookings.length,
                    loading: false,
                }}
                refetch={() => console.log("refetch")}
                setOptions={() => console.log("setOptions")}
                toolBarButtons={[]}
                hideCreate
                hideHeader
                hidePagination
                hideFiltersAndSorters
                notSelectable
                minPadding
                defaultColumns={[
                    "bookingNumber",
                    "attendeeFullName",
                    "age",
                    "bookerFullName",
                    "email",
                    "bookedBy",
                    "billed",
                ]}
                hiddenColumns={["actions"]}
                actionColumn={() => null}
                style={{
                    marginTop: -25,
                    ...additionalStyle,
                }}
            />
        );
    };

    if (open) {
        return (
            <Modal
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            {
                                "Verschieben oder Kopieren der ausgewählten Teilnehmern"
                            }
                        </div>
                        <Space>
                            <TransferDragAndDropDescription
                                setDescriptionOpen={setDescriptionOpen}
                                descriptionOpen={descriptionOpen}
                                descritpion="simple"
                            />
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: red[3],
                                    borderColor: red[3],
                                    fontWeight: 450,
                                }}
                                onClick={() => setOpen(false)}
                            >
                                {i18n.containers.customers.BookerDetails.modal.abortShort()}
                            </Button>
                        </Space>
                    </div>
                }
                open={open}
                onCancel={() => setOpen(false)}
                width={panelWidth}
                closable={false}
                destroyOnClose
                bodyStyle={{
                    padding: 0,
                    overflow: "auto",
                    maxHeight: `calc(100vh - 220px`,
                }}
                style={{top: marginL}}
                footer={<Footer />}
            >
                <div className={css.selectedCourseDetails}>
                    <TransferCourseSelector
                        currentCourseId={currentCourseId}
                        selectedCourse={selectedCourse}
                        setSelectedCourse={setSelectedCourse}
                    />
                    <Card
                        title={
                            <div className={css.title}>
                                {"Zu übertragende Buchungen"}
                            </div>
                        }
                        className={css.transferCourseSelector}
                    >
                        {transferableBookingsCount > 0 ? (
                            <>
                                {renderTable(
                                    transferableBookings as Array<
                                        BookingTableType
                                    >,
                                )}
                            </>
                        ) : (
                            <h3
                                style={{
                                    margin: 15,
                                    width: "100%",
                                    textAlign: "center",
                                    color: red[3],
                                }}
                            >
                                {`Es gibt keine übertragbaren Teilnehmer`}
                            </h3>
                        )}
                        {selectedBookingsWithDuplicates.length > 0 &&
                            selectedCourse && (
                                <div style={{opacity: 0.9}}>
                                    <h5 style={{margin: 15, marginTop: 30}}>
                                        {
                                            "Teilnehmer die nicht übertragbar sind, da diese bereits in den Zielkurs eingebucht sind"
                                        }
                                    </h5>
                                    {renderTable(
                                        selectedBookingsWithDuplicates as Array<
                                            BookingTableType
                                        >,
                                        true,
                                    )}
                                </div>
                            )}
                    </Card>
                </div>
            </Modal>
        );
    }

    return (
        <Tooltip title="Teilnehmer umbuchen">
            <Button
                key={key}
                onClick={() => setOpen(true)}
                size="small"
                type="link"
                style={{
                    fontSize: 15,
                }}
                shape="circle"
                className={btnClassName}
            >
                <SwapOutlined /* className={css.shimeringSmallItems} */ />
            </Button>
        </Tooltip>
    );
};

export default memo(TransferMultipleBookings);
