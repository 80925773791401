import {
    GetTemplateByTypeQuery,
    TemplateType,
} from "../../client/generated/graphql";
import i18n from "../../client/services/i18n";

export const setupTemplateVars = async (variables: any, template: any) => {
    // OUTDATED VERSION WITHOUT TYPES
    for (const [key, value] of Object.entries(variables)) {
        template.headerText = template.headerText?.replace(
            `{{ ${key} }}`,
            value,
        );
        template.footerText = template.footerText?.replace(
            `{{ ${key} }}`,
            value,
        );
        template.signature = template.signature?.replace(`{{ ${key} }}`, value);
        template.subject = template.subject?.replace(`{{ ${key} }}`, value);
    }
};

// .replace(/\n/g, "<br />")

export const replaceTemplateTextBlockVariables = <T = object>({
    variables,
    textBlocks,
}: {
    variables: T;
    // textBlocks?: TemplateTextBlocks;
    textBlocks?: GetTemplateByTypeQuery["templateByType"];
}) => {
    if (textBlocks) {
        for (const [key, value] of Object.entries(variables)) {
            textBlocks.headerText = textBlocks.headerText?.replace(
                `{{ ${key} }}`,
                value,
            );
            textBlocks.footerText = textBlocks.footerText?.replace(
                `{{ ${key} }}`,
                value,
            );
            textBlocks.signature = textBlocks.signature?.replace(
                `{{ ${key} }}`,
                value,
            );
            textBlocks.subject = textBlocks.subject?.replace(
                `{{ ${key} }}`,
                value,
            );
        }
    }
};

export const getMailjetTemplateId = (templateType: TemplateType) => {
    switch (templateType) {
        case TemplateType.BookingConfirmation:
            // return EmailTemplates.BookingConfirmation;
            return "2046014";
            break;
        case TemplateType.BookingCancellation:
            // return EmailTemplates.BookingCancellation;
            return "1291189";
            break;
        case TemplateType.SettlementConfirmation:
            // return EmailTemplates.InvoiceSettlement;
            return "2123003";
            break;

        case TemplateType.WaitingListConfirmation:
            // return EmailTemplates.WaitingListConfirmation;
            return "2473686";
            break;

        default:
            // return EmailTemplates.Blank;
            return "2288419";
            break;
    }
};

export const renderTitle = (type: TemplateType | string | undefined) => {
    switch (type) {
        case "Warning":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.warning();
        case "BookingConfirmation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.bookingConfirmation();
        case "WaitingListConfirmation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.waitingListConfirmation();
        case "SettlementConfirmation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.invoice();
        case "Reminder":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.courseMemory();
        case "SettledBookingCancellation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.cancelInvoice();
        case "NoTemplate":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.noTemplate();
        case "Invoice":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.invoice();
        case "InvoiceMonthly":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.invoiceMonthly();
        case "InvoiceMonthlyConsistent":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.invoiceMonthlyConsistent();
        case "InvoiceReversal":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.invoiceReversal();
        case "InvoiceReversalMonthly":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.invoiceReversalMonthly();
        case "InvoiceReversalMonthlyConsistent":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.invoiceReversalMonthlyConsistent();
        default:
            return type;
    }
};

export const renderTitleDescription = (
    type: TemplateType | string | undefined,
) => {
    switch (type) {
        case "InvoiceMonthly":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.monthlyDescription();
        case "InvoiceMonthlyConsistent":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.monthlyDescriptionConsistent();
        case "InvoiceReversalMonthly":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.monthlyDescription();
        case "InvoiceReversalMonthlyConsistent":
            return i18n.containers.templates.InvoiceTemplates.defaultTemplates.monthlyDescriptionConsistent();
        default:
            return type;
    }
};
