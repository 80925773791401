import React, {useState, useEffect} from "react";
import {Button} from "antd";
import css from "./CookieBanner.less";

const CookieBanner: React.FC = () => {
    const [showBanner, setShowBanner] = useState(false);

    // Überprüfen, ob der Benutzer bereits Cookies akzeptiert hat
    useEffect(() => {
        const cookiesAccepted = document.cookie
            .split(";")
            .some((item) => item.trim().startsWith("cookiesAccepted="));

        if (!cookiesAccepted) {
            setShowBanner(true);
        }
    }, []);

    // Funktion zum Akzeptieren der Cookies und Setzen eines Cookies
    const acceptCookies = () => {
        console.log("acceptCookies function called");

        const redirectUrl = new URLSearchParams(window.location.search).get(
            "redirect",
        );

        console.log("Redirect URL:", redirectUrl);
        const date = new Date();

        date.setFullYear(date.getFullYear() + 1); // Cookie für 1 Jahr speichern
        document.cookie =
            "cookiesAccepted=true; expires=" + date.toUTCString() + "; path=/";
        console.log("Cookie set:", document.cookie);
        setShowBanner(false);
        if (redirectUrl) {
            console.log("Redirecting to:", redirectUrl);
            window.location.assign(redirectUrl);
        } else {
            console.log("Reloading current page");
            window.location.reload();
        }
    };

    if (!showBanner) return null;

    return (
        <div className={css.cookieBanner}>
            <p>
                {
                    "Diese Website verwendet Cookies, um Ihnen das bestmögliche Erlebnis zu bieten."
                }{" "}
                <a
                    href={`/datenschutz?redirect=${encodeURIComponent(
                        window.location.pathname,
                    )}`}
                    className={css.cookieBannerLink}
                >
                    {"Mehr erfahren"}
                </a>
            </p>
            <Button onClick={acceptCookies} className={css.cookieBannerButton}>
                {"OK"}
            </Button>
        </div>
    );
};

export default CookieBanner;
