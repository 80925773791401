import React, {FC, memo} from "react";
import {Layout as AntdLayout, Alert} from "antd";
import i18n from "../../services/i18n";
import css from "./LayoutNotFound.less";

type LayoutNotFoundProps = {};

const LayoutNotFound: FC<LayoutNotFoundProps> = () => {
    return (
        <AntdLayout className={css.root}>
            <AntdLayout.Content>
                <Alert
                    message={i18n.views.LayoutNotFound.message()}
                    description={i18n.views.LayoutNotFound.description()}
                    type="error"
                    showIcon
                />
            </AntdLayout.Content>
        </AntdLayout>
    );
};

export default memo(LayoutNotFound);
