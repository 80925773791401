import {WarningOutlined} from "@ant-design/icons";
import {InputStatus} from "antd/lib/_util/statusUtils";
import React, {FC, memo} from "react";
import {red} from "@ant-design/colors";
import {Alert, Form, Select, Space, Tag, Tooltip} from "antd";
import {Receiver} from "../ReceiversBar";
import {useGetBookersByIdQuery} from "../../../../generated/graphql";
import {SelectionType} from "../EmailTemplatePicker";
import css from "../Email.less";
import {useTestSubscription} from "../emailHelpers";
import LoadingContent from "../../../../views/LoadingContent/LoadingContent";
import ReceiversWithNoMailModal from "./ReceiversWithNoMailModal";

type GetReceiversBookerProps = {
    bookers: Array<SelectionType>;
    setDisabledSendButton?: (disabled: boolean) => void;
    setReceiverCount?: (count: number) => void;
};

const GetReceiversBooker: FC<GetReceiversBookerProps> = ({
    bookers,
    setDisabledSendButton,
    setReceiverCount,
}) => {
    const isTestSubscription = useTestSubscription().isTestSubscription;
    const bookerIds = bookers.map((b) => b.id);
    const {data: bookersData, loading: bookersLoading} = useGetBookersByIdQuery(
        {
            skip: !bookerIds.length,
            variables: {
                bookerIds,
            },
            fetchPolicy: "network-only",
        },
    );

    const getReceivers = (): Array<Receiver> => {
        if (!bookersData?.bookersById) return [];

        const receivers: Array<Receiver> = [];

        bookersData.bookersById.forEach((booker) => {
            receivers.push({
                id: booker.id,
                email: booker.email ?? undefined,
                firstname: booker.firstname,
                lastname: booker.lastname,
            });
        });

        return receivers;
    };

    const receivers: Array<Receiver> = getReceivers();
    const receiverWithMailCount = receivers.filter((receiver) => receiver.email)
        .length;

    setReceiverCount?.(receiverWithMailCount);

    const noReceiverHasEmail = receivers.every(
        (receiver) => !receiver.email || receiver.email === "",
    );

    React.useEffect(() => {
        if (setDisabledSendButton) {
            if (!receivers.length || noReceiverHasEmail) {
                setDisabledSendButton(true);
            } else if (receivers.length > maxReceivers) {
                setDisabledSendButton(true);
            } else {
                setDisabledSendButton(false);
            }
        }
    }, [noReceiverHasEmail, receivers, setDisabledSendButton]);

    const Warning = ({missingEmail}: {missingEmail: boolean}) => {
        if (!missingEmail) return null;

        return <WarningOutlined style={{color: "white", marginRight: 7}} />;
    };

    const getValue = () => {
        if (!receivers.length) return undefined;

        return receivers.map((receiver) => {
            return {
                value: receiver.email,
                label: `${receiver.firstname} ${receiver.lastname}`,
                fieldNames: {"data-booking-id": receiver.id},
                key: `${receiver.id}`,
            };
        });
    };

    const inputStatus: InputStatus =
        receivers.length && !noReceiverHasEmail
            ? ("success" as InputStatus)
            : ("error" as InputStatus);

    const maxReceivers = 25;

    const alertStyle: React.CSSProperties = {
        border: `2px solid ${red[2]}`,
        textAlign: "center",
        marginLeft: -2,
        marginRight: -2,
    };

    const NoReceiversWarning = () => {
        return (
            <Alert
                style={alertStyle}
                message="Keine Empfänger mit Email-Adresse gefunden"
                description="In der aktuellen Auswahl sind keine Empfänger mit Email-Adresse enthalten."
                type="error"
            />
        );
    };

    const MaxReceiversWarning = () => {
        return (
            <Alert
                style={alertStyle}
                message="Zu viele Empfänger"
                description={`Es können derzeit maximal ${maxReceivers} Empfänger ausgewählt werden.`}
                type="error"
            />
        );
    };

    const DemoWarning = () => {
        return (
            <Alert
                style={{
                    ...alertStyle,
                    fontSize: "0.9em",
                    padding: 3,
                    lineHeight: "1.1em",
                }}
                message="Demoversion!"
                description="Mails werden nicht an den Empfänger gesendet, sondern an die E-Mail-Adresse, die in den Einstellungen hinterlegt ist."
                type="warning"
            />
        );
    };

    const RenderContent = ({receivers}: {receivers: Array<Receiver>}) => {
        return (
            <Form.Item
                label={
                    receivers.length &&
                    !noReceiverHasEmail &&
                    receivers.length < 8 &&
                    receivers.length < 25 &&
                    !isTestSubscription
                        ? "An"
                        : undefined
                }
                className={css.receiversBar}
            >
                {receivers.length > 25 && <MaxReceiversWarning />}
                {isTestSubscription && <DemoWarning />}
                {(!receivers.length || noReceiverHasEmail) && (
                    <NoReceiversWarning />
                )}
                <Select
                    style={{width: `100%`}}
                    open={false}
                    mode="tags"
                    labelInValue={true}
                    value={getValue()}
                    status={inputStatus}
                    defaultValue={getValue()}
                    maxTagCount={50}
                    optionFilterProp="label"
                    loading={bookersLoading}
                    tagRender={(props) => {
                        const missingEmail = !props.value || props.value === "";
                        const color = (num: number) =>
                            missingEmail ? red[num] : undefined;
                        const maxLength = receivers.length > 4 ? 10 : 30;
                        const style = {
                            marginRight: 2,
                            color: missingEmail ? "white" : undefined,
                            borderColor: color(2),
                            fontWeight: missingEmail ? 500 : undefined,
                            backgroundColor: color(2),
                        };

                        const tooltipTitle = missingEmail ? (
                            <Space size={2} direction="vertical">
                                {`Für Bucher:in ${props.label} ist keine Email-Adresse hinterlegt. Bucher:in wird deshalb nicht benachrichtigt.`}
                            </Space>
                        ) : (
                            <Space size={2} direction="vertical">
                                {props.label}
                                {props.value}
                            </Space>
                        );

                        return (props.label as string).length > maxLength ? (
                            <Tooltip
                                title={tooltipTitle}
                                overlayStyle={{
                                    minWidth: 350,
                                }}
                                placement="bottom"
                                mouseEnterDelay={0.3}
                            >
                                <Tag style={style}>
                                    <Warning missingEmail={missingEmail} />
                                    {props.label}
                                    {"..."}
                                </Tag>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={tooltipTitle}
                                overlayStyle={{
                                    minWidth: 350,
                                }}
                            >
                                <Tag style={style}>
                                    <Warning missingEmail={missingEmail} />
                                    {props.label}
                                </Tag>
                            </Tooltip>
                        );
                    }}
                >
                    {receivers.map((receiver) => {
                        const receiverName = `${receiver.firstname} ${receiver.lastname}`;
                        const receiverEmail = receiver.email;
                        const key = `${receiver.id}`;

                        return (
                            <Select.Option
                                key={key}
                                value={receiverEmail}
                                disabled
                            >
                                <Tooltip title={receiverEmail}>
                                    {receiverName}
                                </Tooltip>
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    if (bookersLoading) {
        return <LoadingContent content="Empfängerdaten laden" />;
    }

    const receiversWithNoEmail = receivers.filter(
        (receiver) => !receiver.email,
    );

    return (
        <>
            {<ReceiversWithNoMailModal receivers={receiversWithNoEmail} />}
            <RenderContent receivers={receivers} />
        </>
    );
};

export default memo(GetReceiversBooker);
