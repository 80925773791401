// import mjml2html from "mjml";
import mjml2html from "mjml-browser";
import {compile} from "handlebars";
import {
    BookerEmailNotificationCourseLesson,
    BookerEmailNotificationVariables,
} from "../../../../shared/models/booker";
import {Template} from "../../../generated/graphql";

type CourseLessonType = {
    dow: string;
    date: string;
    time: string;
    location: string;
    city: string;
};

const dummyLessons: Array<CourseLessonType> = [
    {
        dow: "Montag",
        date: "4. November 2052",
        time: "11:11 - 12:12",
        location: "Musterbad",
        city: "Musterstadt",
    },
    {
        dow: "Montag",
        date: "11. November 2052",
        time: "11:11 - 12:12",
        location: "Musterbad",
        city: "Musterstadt",
    },
    {
        dow: "Montag",
        date: "18. November 2052",
        time: "11:11 - 12:12",
        location: "Musterbad",
        city: "Musterstadt",
    },
    {
        dow: "Montag",
        date: "25. November 2052",
        time: "11:11 - 12:12",
        location: "Musterbad",
        city: "Musterstadt",
    },
];

const fontFamily = "Ubuntu, Helvetica, Arial, sans-serif";
const style1 = `font-family:${fontFamily};font-size:12px;font-weight:500;line-height:16px;text-align:left;color:rgba(0, 0, 0, 0.7);`;
const styleWeekDay = `font-family:${fontFamily};font-size:13px;font-weight:450;line-height:18px;text-align:center; color:white;`;
const style2 = `font-family:${fontFamily}; font-size:13px;font-weight:800;line-height:18px;text-align:left;color:rgba(0, 0, 0, 0.5);`;
// const placesStyle = `font-family:${fontFamily};font-size:12px;font-weight:500;line-height:16px;text-align:center; color:rgba(0, 0, 0, 0.7); background: lightgreen; border-radius: 20px; padding: 4px; min-height: 10px; min-width: 15px;`;

const renderCourseLessonTable = (
    courseLessons: Array<
        BookerEmailNotificationVariables["course"]["courseLessons"][0]
    >,
) => {
    let entries = "";

    // const sortedLessonsDate = orderBy(courseLessons, ["date"], ["asc"]);
    // const sortedLessons = orderBy(sortedLessonsDate, ["time"], ["asc"]);

    courseLessons.forEach((lesson, index) => {
        entries =
            entries +
            `
            <tr style="height: 10px;"></tr>
            <tr>
                <td style="padding: 0; color: rgba(0, 98, 255, 0.5">
                    <div style="${style2} min-width: 15px;">
                        ${index + 1}
                    </div>
                </td>
                <td style="min-width: 65px; height: auto; padding: 5px;  color: white; border-radius: 5px; text-align: center; background-color: #1A90FF">
                    <div style="${styleWeekDay}">
                        ${lesson.dow}
                    </div>
                </td>
                <td style="padding: 0 0 0 10px; text-align: center;">
                    <div style="${style1}">${lesson.date}</div>
                </td>
                <td style="padding: 0 0 0 10px; text-align: center;">
                    <div style="${style1}">${lesson.time}</div>
                </td>
                <td style="padding: 0 0 0 10px; text-align: center;">
                    <div style="${style1}">${lesson.location} | ${
                lesson.city
            }</div>
            </tr>
            <tr style="height: 10px; border-bottom: 1px solid lightgrey; padding-bottom: 5px;"></tr>
        `;
    });

    return entries;
};

export const toMjml = ({
    mjmlTemplate,
    variables,
    courseLessons,
}: {
    mjmlTemplate: string;
    variables: Partial<Template>;
    courseLessons?: Array<BookerEmailNotificationCourseLesson>;
}) => {
    const template = mjml2html(mjmlTemplate);

    const courseLessonTable = renderCourseLessonTable(
        courseLessons ?? dummyLessons,
    );

    const regEx1 = /((?:var)+:)/g; // var:
    // eslint-disable-next-line optimize-regex/optimize-regex
    const regEx2 = /:"[A-Za-zÄÖÜäöüß -]+"/g; // :"subject"

    // Table loop of mailjet needs to be replaced here
    const regExTable3 = /<div *?class="courseLessonsStart"(.|\n)*?<\/div>(\*?(.|\n)*?\*?)<div *?class="courseLessonsEnd"(.|\n)*?<\/div>/g;

    const replacement3 = `
    <div style="margin:0px auto;max-width:568px; padding: 10px 0px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
            <tr>
            <td style="direction:ltr;font-size:0px;padding:0;text-align:center;">
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                        <tbody>
                        ${courseLessonTable}
                        </tbody>
                    </table>
                </div>
            </td>
            </tr>
        </tbody>
        </table>
    </div>`;

    const replace = (input: string) => {
        let output = input.replace(regEx1, "");

        output = input.replace(regEx1, "");
        output = output.replace(regEx2, "");
        output = output.replace(regExTable3, replacement3);

        return output;
    };

    // REPLACE {{ variables }} with {{{ variables }}} so that handlebars accepts html -----------

    const reEx4 = /\n/g;
    const replaceWith4 = "<br>";

    const variblesWithLineBreaks = {
        ...variables,
        headerText: variables.headerText?.replace(reEx4, replaceWith4),
        footerText: variables.footerText?.replace(reEx4, replaceWith4),
        signature: variables.signature?.replace(reEx4, replaceWith4),
    };

    let replacedInHtml = template.html.replace(
        `{{var:headerText:"Kopftext"}}`,
        `{{{var:headerText:"Kopftext"}}}`,
    );

    replacedInHtml = replacedInHtml.replace(
        `{{var:footerText:"Fußtext"}}`,
        `{{{var:footerText:"Fußtext"}}}`,
    );

    replacedInHtml = replacedInHtml.replace(
        `{{var:signature:"Signatur"}}`,
        `{{{var:signature:"Signatur"}}}`,
    );

    // REPLACE {{ variables }} with {{{ variables }}} so that handlebars accepts html -----------

    const mjml = compile(replace(replacedInHtml))(variblesWithLineBreaks);

    return mjml;
};
