import React, {FC, memo, useEffect} from "react";
import {Button, Modal, Space, Spin, Table, Tooltip, message} from "antd";
import moment from "moment";
import {
    Booking,
    GenerateScheduledPaymentsForSettledBookingMutation,
    GetBookingByIdDocument,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    useGenerateScheduledPaymentsForSettledBookingMutation,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {
    renderAmount,
    renderDate,
} from "../TableColumnRenderers/TableColumnRenderers";

type GenerateSchedulePaymentsButtonProps = {
    bookingId: Booking["id"];
};

const GenerateSchedulePaymentsButton: FC<GenerateSchedulePaymentsButtonProps> = ({
    bookingId,
}) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [scheduledPayments, setScheduledPayments] = React.useState<
        GenerateScheduledPaymentsForSettledBookingMutation["generateScheduledPaymentsForSettledBooking"]
    >();
    const [
        generateSchedulePayments,
        {loading},
    ] = useGenerateScheduledPaymentsForSettledBookingMutation({
        refetchQueries: [
            "GetUnpaidScheduledPayments",
            {query: GetPaidScheduledPaymentsDocument},
            "GetUnpaidScheduledPayments",
            {
                query: GetUnpaidScheduledPaymentsDocument /* , variables: {options} */,
            },
            "GetBookingById",
            {query: GetBookingByIdDocument, variables: {id: bookingId}},
        ],
    });

    const generateNewSchedulePayments = async ({
        savePayments,
    }: {
        savePayments: boolean;
    }) => {
        try {
            generateSchedulePayments({
                variables: {bookingId, savePayments},
            }).then((res) => {
                const result =
                    res.data?.generateScheduledPaymentsForSettledBooking;

                if (result) {
                    if (savePayments && !loading) {
                        message.success(
                            "Posten erfolgreich generiert und gespeichert",
                        );
                        // window.location.reload();
                        setModalOpen(false);
                    } else {
                        setScheduledPayments(result);
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const ModalContent = () => {
        useEffect(() => {
            if (!scheduledPayments && !loading) {
                generateNewSchedulePayments({savePayments: false});
            }
        }, []);

        if (loading) {
            return <Spin />;
        }

        const newBookingPrice = scheduledPayments?.reduce(
            (acc, payment) => acc + payment.paymentAmount,
            0,
        );

        return (
            <Space direction="vertical" style={{width: "100%"}}>
                <Table
                    columns={[
                        {
                            dataIndex: "lessonNumber",
                            title: i18n.views.TableColumnTitles.index(),
                            align: "center",
                        },
                        {
                            dataIndex: "paymentDue",
                            title: i18n.views.TableColumnTitles.paymentDue(),
                            align: "center",
                        },
                        {
                            dataIndex: "paymentAmount",
                            title: i18n.views.TableColumnTitles.paymentAmount(),
                            align: "right",
                        },
                    ]}
                    dataSource={scheduledPayments?.map((payment, index) => ({
                        key: payment.id,
                        lessonNumber: index + 1,
                        paymentDue: payment?.paymentDue ? (
                            <Space>
                                <span>
                                    {moment(payment.paymentDue).format("MMM")}
                                </span>
                                <span>{renderDate(payment.paymentDue)}</span>
                            </Space>
                        ) : (
                            ""
                        ),
                        paymentAmount: renderAmount(
                            Number(payment.paymentAmount ?? 0),
                        ),
                    }))}
                    pagination={false}
                />
                <Space style={{display: "flex", justifyContent: "flex-end"}}>
                    <span style={{fontSize: "1.2rem"}}>
                        {i18n.containers.invoice.InvoiceDetails.sumAmount()}
                    </span>
                    {renderAmount(newBookingPrice ?? 0, {
                        fontSize: "1.2rem",
                    })}
                </Space>
            </Space>
        );
    };

    if (modalOpen) {
        return (
            <Modal
                title="Vorschau der zu generierenden Posten"
                open={modalOpen}
                destroyOnClose
                onCancel={() => {
                    setModalOpen(false);
                    setScheduledPayments(undefined);
                }}
                okText="Jetzt generieren"
                onOk={async () =>
                    generateNewSchedulePayments({savePayments: true})
                }
            >
                <ModalContent />
            </Modal>
        );
    }

    return (
        <Button
            style={{marginTop: 2}}
            danger
            size="small"
            // type="text"
            onClick={() => setModalOpen(!modalOpen)}
        >
            {"Posten neu generieren"}
        </Button>
    );
};

export default memo(GenerateSchedulePaymentsButton);
