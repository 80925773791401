// @ts-nocheck
import {
    EditOutlined,
    InfoCircleOutlined,
    SendOutlined,
} from "@ant-design/icons";
import {blue} from "@ant-design/colors";
import {message} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import React, {FC, memo, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {TableType} from "../../containers/bookings/BookingList/BookingListColumns";
import ReverseBookingModal from "../../containers/bookings/BookingList/ReverseBookingModal";
import {GetCoursesQuery} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {QueryItem} from "../../types";
import Button from "../Button/Button";
import DeleteButton from "../DeleteButton/DeleteButton";
import MoreMenu from "../../containers/bookings/BookingList/MoreMenu/MoreMenu";
import css from "./ActionButtonGroup.less";
import Roles from "../../../shared/models/roles";

type GetCoursesQueryItem = QueryItem<GetCoursesQuery["courses"]>;
type GetBookinsQueryItem = QueryItem<TableType>;

// export type TableType = GetBookinsQuery["bookings"]["items"][0] & ExtraColumns;

type DeleteMessageParams = {
    loading: boolean | undefined;
    data: Record<string, unknown> | undefined;
    error: Error | undefined;
};

export const deleteMessageStateFromApolloQueryResult = ({
    loading,
    data,
    error,
}: DeleteMessageParams) =>
    (Boolean(error) && "error") ||
    (Boolean(data) && "success") ||
    (loading && "loading") ||
    "initial";

type UseDeleteMessageConfig = {
    state: "loading" | "error" | "success" | "initial";
    loadingMessage: string;
    successMessage: string;
    errorMessage: string;
};

export const useDeleteMessage = ({
    state,
    loadingMessage,
    successMessage,
    errorMessage,
}: UseDeleteMessageConfig) => {
    useEffect(() => {
        if (state === "loading") {
            message.loading(
                {
                    content: loadingMessage,
                    key: "deleting",
                },
                0,
            );
        }

        if (state === "success") {
            message.success({
                content: successMessage,
                key: "deleting",
            });
        }

        if (state === "error") {
            message.error({
                content: errorMessage,
                key: "deleting",
            });
        }
    }, [state, loadingMessage, successMessage, errorMessage]);
};

type CustomIconProps = "edit" | "send";

type ActionButtonGroupProps = {
    editUrl?: string;
    customIcon?: CustomIconProps;
    deleteDisabled?: boolean;
    deleteConfirmText?: string;
    deleteDisabledText?: string;
    deleteOnConfirm?: () => void;
    deleteTestId?: string;
    editTestId?: string;
    detailsUrl?: string;
    detailsState: GetCoursesQueryItem | GetBookinsQueryItem;
    editParams?: GetBookinsQueryItem;
    detailsTestId?: string;
    isSettled?: boolean;
    moreMenuItems?: MenuProps["items"];
    highestRole?: Roles;
};

const ActionButtonGroup: FC<ActionButtonGroupProps> = ({
    deleteTestId = "DeleteButton",
    editTestId = "EditButton",
    detailsTestId = "DetailsButton",
    editUrl = "/",
    customIcon,
    deleteDisabled = false,
    deleteConfirmText = "confirm",
    deleteDisabledText = "disabled",
    deleteOnConfirm = () => {
        console.log("confirmed");
    },
    detailsUrl = "/",
    detailsState,
    editParams,
    isSettled,
    moreMenuItems,
    highestRole,
}) => {
    const [
        reverseBookingModalVisible,
        setReverseBookingModalVisible,
    ] = useState(false);

    const history = useHistory();

    const getCustomIcon = (customIcon?: CustomIconProps) => {
        switch (customIcon) {
            case "edit":
                return <EditOutlined style={{color: "rgb(7, 96, 255)"}} />;
                break;
            case "send":
                return <SendOutlined style={{color: "rgb(7, 96, 255)"}} />;
            default:
                return <EditOutlined style={{color: "rgb(7, 96, 255)"}} />;
                break;
        }
    };

    if (isSettled) {
        return (
            <>
                <ReverseBookingModal
                    visible={reverseBookingModalVisible}
                    closeModal={() => setReverseBookingModalVisible(false)}
                    refetch={refetch}
                />
                <ButtonGroup className={css.root}>
                    <Button
                        data-testid={editTestId}
                        size="small"
                        type="danger"
                        style={{border: "1px solid", borderColor: blue[5]}}
                    >
                        <Link
                            to={{
                                pathname: editUrl,
                                // editParams,
                                state: {
                                    ...editParams,
                                    from: {pathname: history.location.pathname},
                                },
                            }}
                        >
                            {i18n.containers.bookings.BookingList.reverseBooking()}
                        </Link>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <ButtonGroup className={css.root}>
            {highestRole !== Roles.Instructor &&
                (editParams ? (
                    <Button
                        data-testid={editTestId}
                        size="small"
                        style={{border: "1px solid", borderColor: blue[5]}}
                    >
                        <Link
                            to={{
                                pathname: editUrl,
                                // editParams,
                                state: {
                                    ...editParams,
                                    from: {pathname: history.location.pathname},
                                },
                            }}
                        >
                            <EditOutlined style={{color: "rgb(7, 96, 255)"}} />
                        </Link>
                    </Button>
                ) : (
                    <Button
                        data-testid={editTestId}
                        size="small"
                        href={editUrl}
                    >
                        {getCustomIcon(customIcon)}
                        {/* <EditOutlined style={{color: "rgb(7, 96, 255)"}} /> */}
                    </Button>
                ))}
            {highestRole !== Roles.Instructor && (
                <DeleteButton
                    data-testid={deleteTestId}
                    size="small"
                    confirmText={deleteConfirmText}
                    disabled={deleteDisabled}
                    onConfirm={deleteOnConfirm}
                    disabledText={deleteDisabledText}
                    style={{border: "1px solid", borderColor: blue[5]}}
                />
            )}
            {detailsUrl === "/" ? null : (
                <Button data-testid={detailsTestId} size="small">
                    <Link
                        to={{
                            pathname: detailsUrl,
                            state: {
                                detailsState,
                                from: {pathname: history.location.pathname},
                            },
                        }}
                    >
                        <InfoCircleOutlined
                            style={{color: "rgb(7, 96, 255)"}}
                        />
                    </Link>
                </Button>
            )}
            {moreMenuItems && <MoreMenu moreMenuItems={moreMenuItems} />}
        </ButtonGroup>
    );
};

export default memo(ActionButtonGroup);
