import React, {FC, memo} from "react";
import LoadingContent from "../LoadingContent/LoadingContent";

export type MjMlOutputType = {
    html: string;
    json?: JSON;
    errors?: Array<unknown>;
};

type MjmlPreviewType = {
    mjmlOutput?: MjMlOutputType;
    loading?: boolean;
    style?: React.CSSProperties;
};

const MjmlPreview: FC<MjmlPreviewType> = ({
    mjmlOutput,
    loading = false,
    style,
}) => {
    const {html, error} = mjmlOutput;

    if (error) {
        console.log("error on MjmlPreview: ", error);
    }

    const actualStyle: React.CSSProperties = {
        border: "none",
        maxHeight: "calc(100vh - 25px)",
        // height: "calc(100vh - 250px)",
        height: "calc(100vh - 310px)",
        ...style,
    };

    return (
        <>
            {mjmlOutput || loading === false ? (
                <iframe
                    className="preview"
                    id="previewIframe"
                    // loading="lazy"
                    srcDoc={html}
                    title="MJML Preview"
                    allowFullScreen
                    style={{
                        ...actualStyle,
                    }}
                    width={800}
                    // height={867}
                    // height={787}
                />
            ) : (
                <LoadingContent />
            )}
        </>
    );
};

export default memo(MjmlPreview);
