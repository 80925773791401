import React, {FC, memo} from "react";
import {Alert} from "antd";
import {Redirect} from "react-router-dom";
import {useAuthenticateQuery} from "../../generated/graphql";
import LoadingContent from "../../views/LoadingContent/LoadingContent";
import Button from "../../views/Button/Button";
import i18n from "../../services/i18n";
import {URL_BOOKINGS, URL_COURSES, URL_LOGIN} from "../../models/url";
import LayoutWithoutNavigation from "../../views/LayoutWithoutNavigation/LayoutWithoutNavigation";
import LoginHeader from "../../views/LoginHeader/LoginHeader";
import PageContentWrapper from "../../views/PageContentWrapper/PageContentWrapper";
import TopLevelHero from "../../views/TopLevelHero/TopLevelHero";
import FeatureWrapper from "../../views/FeatureWrapper/FeatureWrapper";
import WelcomeTabsWrapper from "../../views/WelcomeTabsWrapper/WelcomeTabsWrapper";
import LoginFooter from "../../views/LoginFooter/LoginFooter";
import {featureTabs} from "./featureTabs.json";
import useHighestRole from "../../helpers/useHighestRole";
import Roles from "../../../shared/models/roles";

const buttonStyle = {
    width: 200,
    margin: "auto",
    marginTop: -24,
    bottom: -12,
    boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.25)",
};

type NextStepButtonProps = {
    userHasProfile: boolean;
};

const NextStepButton: FC<NextStepButtonProps> = ({userHasProfile}) => {
    const highestRole = useHighestRole();

    const isInstructor = highestRole === Roles.Instructor;

    // DEAKTIVATED PROFILE SETUP
    return (
        <Button
            href={isInstructor ? URL_COURSES : URL_BOOKINGS}
            data-testid="WelcomePageStartButton"
            type="primary"
            shape="round"
            size="small"
            style={buttonStyle}
        >
            {i18n.containers.welcomePage.WelcomePage.startKursOrganizer()}
        </Button>
    );
};

type WelcomePageProps = {};

const WelcomePage: FC<WelcomePageProps> = () => {
    const {data, error, loading} = useAuthenticateQuery({
        fetchPolicy: "network-only",
    });

    if (loading) {
        return <LoadingContent />;
    }

    if (!data?.authenticate) {
        return <Redirect to={URL_LOGIN} />;
    }

    if (error) {
        console.log("error", error);

        return (
            <Alert
                type="error"
                message="Could not get user data from the server."
            />
        );
    }

    return (
        <LayoutWithoutNavigation
            main={
                <>
                    <LoginHeader
                        link={{
                            href: i18n.containers.welcomePage.WelcomePage.link(),
                            label: i18n.containers.welcomePage.WelcomePage.title(),
                        }}
                    />
                    <PageContentWrapper style={{margin: 0}}>
                        <TopLevelHero
                            headLine={i18n.containers.welcomePage.WelcomePage.headLine()}
                            subHeadLine={i18n.containers.welcomePage.WelcomePage.subHeadLine()}
                            slogan={i18n.containers.welcomePage.WelcomePage.slogan()}
                            crossAlign="right"
                        />
                        <NextStepButton
                            userHasProfile={Boolean(
                                data.authenticate.hasProfile,
                            )}
                        />
                        <FeatureWrapper
                            // title="Was es zu entdecken gibt"
                            paddingTop={60}
                        >
                            <WelcomeTabsWrapper content={featureTabs} />
                        </FeatureWrapper>
                    </PageContentWrapper>
                    <LoginFooter />
                </>
            }
            data-testid="WelcomePage"
        />
    );
};

export default memo(WelcomePage);
