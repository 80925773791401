import {useState, useEffect} from "react";
import {
    useGetTemplateByIdLazyQuery,
    useGetMjmlTemplateByIdLazyQuery,
    GetTemplateByIdQuery,
    GetMjmlTemplateByIdQuery,
    TemplateType,
} from "../../../generated/graphql";
import {getMailjetTemplateId} from "../../../../shared/templates/handleTemplateVariables";
import {BookingDataProps} from "./MjmlLoader";

const useTemplateData = ({
    bookingData,
    bookingId,
    dbTemplateId,
    variables,
    renderWithoutCourseDetails,
}: {
    bookingData?: BookingDataProps;
    bookingId?: string;
    dbTemplateId: string;
    variables: {};
    renderWithoutCourseDetails?: boolean;
}) => {
    const [userTemplateTextBlocks, setUserTemplateTextBlocks] = useState<
        GetTemplateByIdQuery["template"]
    >();
    const [mailjetTemplateData, setMailjetTemplateData] = useState<
        GetMjmlTemplateByIdQuery["getEmailTemplateById"]["Data"]
    >();
    const [loadingAll, setLoadingAll] = useState(true);

    const [
        getUserTemplateFromDb,
        {data: userTemplateData, loading: loadingUserTemplateFromDb},
    ] = useGetTemplateByIdLazyQuery({fetchPolicy: "network-only"});
    const [
        getMailjetTemplate,
        {data: mailjetTemplate, loading: loadingMailjetTemplate},
    ] = useGetMjmlTemplateByIdLazyQuery();

    useEffect(() => {
        const templateType =
            bookingId || bookingData
                ? TemplateType.BookingConfirmation
                : TemplateType.FreeText;
        const templateId = renderWithoutCourseDetails
            ? "5879362"
            : getMailjetTemplateId(templateType);

        if (dbTemplateId) {
            getUserTemplateFromDb({variables: {id: dbTemplateId}});
        }
        if (bookingId || bookingData) {
            // Assume getMailjetTemplateId is a function to retrieve template ID based on conditions
            getMailjetTemplate({variables: {templateId}});

            getMailjetTemplate({variables: {templateId}});
        }
    }, [
        dbTemplateId,
        bookingData,
        bookingId,
        getUserTemplateFromDb,
        getMailjetTemplate,
        renderWithoutCourseDetails,
    ]);

    useEffect(() => {
        // Transform and set the user template data
        if (userTemplateData) {
            // setUserTemplateTextBlocks(userTemplateData.template);
            const template: GetTemplateByIdQuery["template"] =
                userTemplateData.template;

            setUserTemplateTextBlocks(template);
        }
        if (mailjetTemplate) {
            setMailjetTemplateData(mailjetTemplate.getEmailTemplateById.Data);
        }
        setLoadingAll(loadingUserTemplateFromDb || loadingMailjetTemplate);
    }, [
        userTemplateData,
        mailjetTemplate,
        loadingUserTemplateFromDb,
        loadingMailjetTemplate,
    ]);

    return {
        // allVariables,
        userTemplateTextBlocks,
        mailjetTemplateData,
        loadingAll,
    };
};

export default useTemplateData;
