import React, {FC, memo} from "react";
import {Select, Space, Tag, Tooltip} from "antd";
import {WarningOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import {SmsReceiver} from "../../../generated/graphql";
import LoadingContent from "../../../views/LoadingContent/LoadingContent";
import Spin from "../../../views/Spin/Spin";

type SmsReceiverBarProps = {
    receivers?: Array<SmsReceiver>;
    loading?: boolean;
};

const SmsReceiverBar: FC<SmsReceiverBarProps> = ({receivers, loading}) => {
    const RenderContent = ({receivers}: {receivers: Array<SmsReceiver>}) => {
        const Warning = ({missingNumber}: {missingNumber: boolean}) => {
            if (!missingNumber) return null;

            return <WarningOutlined style={{color: "white", marginRight: 7}} />;
        };

        const getValue = () => {
            if (!receivers.length) return undefined;

            return receivers.map((receiver) => {
                return {
                    value: receiver.mobileNumber,
                    label: `${receiver.fullName} | ${receiver.prefixedBookingNumber} | ${receiver.valid}`,
                    fieldNames: {"data-booking-id": receiver.bookerId},
                    key: `${receiver.bookerId}`,
                };
            });
        };

        return (
            <Select
                style={{
                    width: `calc(100% + 30px)`,
                    margin: `-15px`,
                }}
                open={false}
                mode="tags"
                labelInValue={true}
                value={getValue()}
                // status={inputStatus}
                defaultValue={getValue()}
                maxTagCount={100}
                optionFilterProp="label"
                loading={loading}
                bordered={false}
                tagRender={(props) => {
                    const missingNumber = !props.value || props.value === "";
                    const firstLabelPart = (props.label as string).split(
                        "|",
                    )[0];
                    const secondLabelPart = (props.label as string).split(
                        "|",
                    )[1];
                    const thirdLabelPart = (props.label as string).split(
                        "|",
                    )[2];
                    const isValid = thirdLabelPart.trim() === "true";
                    const color = (num: number) =>
                        missingNumber || !isValid ? red[num] : undefined;
                    const maxLength = receivers.length > 4 ? 10 : 30;
                    const style = {
                        marginRight: 2,
                        color: missingNumber || !isValid ? "white" : undefined,
                        borderColor: color(2),
                        fontWeight: missingNumber || !isValid ? 500 : undefined,
                        backgroundColor: color(2),
                    };

                    const tooltipTitle = missingNumber ? (
                        <Space
                            size={2}
                            direction="vertical"
                            style={{
                                whiteSpace: "pre-wrap",
                                textAlign: "center",
                            }}
                        >
                            {`Nummer fehlt!\n\nFür den Kursbucher ${firstLabelPart} mit der Buchungsnummer ${secondLabelPart} ist keine Mobil-Nummer hinterlegt.\n\nDer Kursbucher wird nicht benachrichtigt.`}
                        </Space>
                    ) : isValid ? (
                        <Space
                            size={2}
                            direction="vertical"
                            style={{
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {secondLabelPart}
                            {firstLabelPart}
                            {props.value}
                        </Space>
                    ) : (
                        <Space
                            size={2}
                            direction="vertical"
                            style={{
                                whiteSpace: "pre-wrap",
                                textAlign: "center",
                            }}
                        >
                            {`Ungültige Nummer:\n${props.value}\n\nFür den Kursbucher ${firstLabelPart} mit der Buchungsnummer ${secondLabelPart} ist keine gültige Mobil-Nummer hinterlegt.\n\nDer Kursbucher wird nicht benachrichtigt.`}
                        </Space>
                    );

                    return (props.label as string).length > maxLength ? (
                        <Tooltip
                            title={tooltipTitle}
                            overlayStyle={{
                                minWidth: 350,
                            }}
                            placement="bottom"
                            mouseEnterDelay={0.3}
                        >
                            <Tag style={style}>
                                <Warning
                                    missingNumber={missingNumber || !isValid}
                                />
                                {firstLabelPart.slice(0, maxLength)}
                                {"..."}
                            </Tag>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={tooltipTitle}
                            overlayStyle={{
                                minWidth: 350,
                            }}
                        >
                            <Tag style={style}>
                                <Warning
                                    missingNumber={missingNumber || !isValid}
                                />
                                {firstLabelPart}
                            </Tag>
                        </Tooltip>
                    );
                }}
            >
                {receivers.map((receiver) => {
                    const receiverName = `${receiver.fullName}`;
                    const receiverMobile = receiver.mobileNumber ?? "";
                    const key = `${receiver.bookerId}-${receiver.prefixedBookingNumber}`;

                    return (
                        <Select.Option key={key} value={receiverMobile}>
                            <Tooltip title={receiverMobile}>
                                {receiverName}
                            </Tooltip>
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };

    if (loading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                <Spin />
                <div style={{marginTop: 5}}>
                    {"Empfängerinfos werden gesammelt"}
                </div>
            </div>
        );
    }

    if (!receivers) {
        return <div>{"No receivers found"}</div>;
    }

    return (
        <div>
            <RenderContent receivers={receivers} />
        </div>
    );
};

export default memo(SmsReceiverBar);
