import React, {FC, memo} from "react";
import {Badge} from "antd";
import {blue, green, grey, magenta, orange, red} from "@ant-design/colors";
import {MailDataType} from "../../../generated/graphql";

// export const mailDataType = MailDataType.Course;
export const badgeRender = (mailDataType: MailDataType) => {
    switch (mailDataType) {
        case MailDataType.Booker:
            return {color: orange[3], text: "Bucher"};
            break;
        case MailDataType.Attendee:
            return {color: green[3], text: "Teilnehmer"};
            break;
        case MailDataType.Booking:
            return {color: magenta[3], text: "Buchung"};
            break;
        case MailDataType.Course:
            return {color: blue[3], text: "Kurs"};
            break;
        case MailDataType.InvoicePdf:
            return {color: red[3], text: "PDF"};
            break;

        default:
            return {color: grey[3], text: "Nicht definiert"};
            break;
    }
};

export const renderBadgeText = (mailDataType: MailDataType) => {
    return (
        <div style={{fontSize: "0.8em", marginTop: 0}}>
            {badgeRender(mailDataType).text}
        </div>
    );
};

type MailDataTypeBadgeProps = {
    mailDataType: MailDataType;
    ribbon?: boolean;
};

const MailDataTypeBadge: FC<MailDataTypeBadgeProps> = ({
    mailDataType,
    ribbon,
}) => {
    if (ribbon) {
        return (
            <Badge.Ribbon
                color={badgeRender(mailDataType).color}
                text={renderBadgeText(mailDataType)}
            />
        );
    }

    return (
        <div
            style={{
                backgroundColor: badgeRender(mailDataType).color,
                color: "#fff",
                fontSize: "1.3em",
                padding: "3px 7px",
                width: "fit-content",
                // borderRadius: "5px",
            }}
            // count={renderBadgeText(mailDataType)}
            // color={badgeRender(mailDataType).color}
            // text={renderBadgeText(mailDataType)}
        >
            {renderBadgeText(mailDataType)}
        </div>
    );
};

export default memo(MailDataTypeBadge);
