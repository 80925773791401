import React, {FC, memo} from "react";
import {Formik} from "formik";
import {TableType} from "./PaymentsListColumns";
import {ModifyScheduledPaymentInput} from "../../generated/graphql";
import EditScheduledPaymentForm from "./EditScheduledPaymentForm";

type EditScheduledPaymentProps = {
    record?: TableType;
    setEditPaymentOpen: (value: boolean) => void;
    refetch?: () => void;
};

const EditScheduledPayment: FC<EditScheduledPaymentProps> = ({
    record,
    setEditPaymentOpen,
    refetch,
}) => {
    console.log("EditScheduledPayment", record);

    if (!record) {
        return null;
    }

    return (
        <div>
            <Formik<ModifyScheduledPaymentInput>
                initialValues={{
                    id: record.id,
                    paymentAmount: record.paymentAmount,
                    paymentDue: record.paymentDue,
                    paymentDone: record.paymentDone,
                    paymentMethodId: record.paymentMethodId,
                    sepaClearance: Boolean(record.sepaClearance),
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                }}
                enableReinitialize={true}
                render={(formikProps) => {
                    return (
                        <EditScheduledPaymentForm
                            formikProps={formikProps}
                            setEditPaymentOpen={setEditPaymentOpen}
                            bookingId={record.booking.id}
                            refetch={refetch}
                        />
                    );
                }}
            />
        </div>
    );
};

export default memo(EditScheduledPayment);
