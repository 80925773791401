import {green, orange, red} from "@ant-design/colors";
import {Progress, ProgressProps} from "antd";
import React, {FC, memo} from "react";

type ProgressElementProps = {
    breakPoints?: {
        green: number;
        orange: number;
        red: number;
    };
    percent: number;
    unit?: string;
    multiplier?: number;
    progressProps?: ProgressProps;
    reverse?: boolean;
};

const ProgressElement: FC<ProgressElementProps> = ({
    breakPoints = {green: 100, orange: 75, red: 10},
    percent,
    unit = "%",
    multiplier = 1,
    progressProps,
    reverse = false,
}) => {
    const value = percent * multiplier;
    const strokeColor = (value: number) => {
        if (reverse) {
            if (value <= breakPoints.green) {
                return green[3];
            } else if (value <= breakPoints.orange) {
                return orange[3];
            }

            return red[3];
        }

        if (value >= breakPoints.green) {
            return green[3];
        } else if (value >= breakPoints.orange) {
            return orange[3];
        }

        return red[3];
    };

    const lengthOfUnit = unit.length;

    return (
        <Progress
            percent={value}
            strokeColor={strokeColor(value)}
            strokeWidth={10}
            status="active"
            {...progressProps}
            format={(percent) => {
                const percentVal = percent ?? 0;

                return (
                    <div
                        style={
                            lengthOfUnit > 1
                                ? {
                                      marginLeft: -5,
                                      fontSize: "0.8em",
                                      fontWeight: 500,
                                  }
                                : {
                                      marginLeft: -3,
                                      fontSize: "0.9em",
                                      fontWeight: 500,
                                  }
                        }
                    >{`${percentVal / multiplier}${unit}`}</div>
                );
            }}
        />
    );
};

export default memo(ProgressElement);
