import React, {FC, memo, ReactNode, useEffect, useState} from "react";
import Icon, {
    CloseOutlined,
    MenuOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import {Layout, Tooltip} from "antd";
import {Link, NavLink} from "react-router-dom";
import classnames from "classnames";
// import env from "../../const";
import {
    URL_CUSTOMERS,
    URL_BOOKINGS,
    URL_COURSES,
    URL_LOCATIONS,
    URL_STAFF,
    URL_SETTINGS,
    URL_INVOICE,
    URL_TEMPLATES,
    URL_PAYMENTS,
    URL_NOTIFICATIONS,
    URL_CHANGELOG,
    URL_BADGES,
    URL_MY_BOOKINGS,
    URL_LESSONS_TODAY,
    URL_DASHBOARD,
    URL_STAFF_LOGIN,
    URL_LESSONS_TODAY_CALENDAR_WEEKVIEW,
} from "../../models/url";
import i18n from "../../services/i18n";
import css from "./LayoutStandard.less";
import Logout from "../../containers/logout/Logout";
import {
    LeftMenuBookingIcon,
    VorlagenSvg,
    TransactionsSvg,
    LocationsSvg,
    StaffSvg,
    CoursesSvg,
    AttendeesSvg,
    InvoicesSvg,
    NotificationsSvg,
    AbzeichenSvg,
    WeekPlannerSvg,
    DashboardSvg,
    StaffLoginSvg,
} from "../../assets/SVG-Icons";
import LogoTypographic from "../../assets/logo-typographic.svg";
import packageJson from "../../../../package.json";
import {getSubDomain} from "../../helpers/getFirstPathnameSegment";
import {
    GetCompanyInfoQuery,
    useGetCompanyInfoQuery,
    useGetUserRolesQuery,
    useIsInstructorQuery,
} from "../../generated/graphql";
import Roles from "../../../shared/models/roles";
import {routePermissions} from "../../containers/common/AuthenticateSetup";
import {menuWidth} from "../../styles/layout";

const SUBDOMAIN = getSubDomain();

type LayoutStandardProps = {
    navigationIsOpen?: boolean;
    secondLevelNavigation?: ReactNode;
    main?: ReactNode;
    pageTitle?: (navigationOpen: boolean) => string | JSX.Element;
    // companyInfo?: GetCompanyInfoQuery["companyInfo"];
};

const LayoutStandard: FC<LayoutStandardProps> = ({
    navigationIsOpen = true,
    secondLevelNavigation = null,
    main = null,
    pageTitle,
    // companyInfo,
}) => {
    const [_navigationIsOpen, toggleNavigation] = useState(navigationIsOpen);
    const contentAreaClasses = [css.contentArea];
    const menuToggleClasses = [css.mainMenuToggle];
    const menuTextClasses = [css.mainMenuItemTextClosed];

    if (_navigationIsOpen) {
        menuToggleClasses.push(css.mainMenuIsOpen);
        contentAreaClasses.push(css.withMenuOpen);
        menuTextClasses.push(css.mainMenuItemTextOpen);
    }

    const subDomainShort = () => {
        // console.log("SUBDOMAIN: ", SUBDOMAIN);
        if (SUBDOMAIN === "stage") {
            return "stage";
        }
        if (SUBDOMAIN === "localhost") {
            return "dev";
        }
        if (SUBDOMAIN === "app") {
            return "";
        }

        return SUBDOMAIN;
    };

    const pollIntervallInMinutes = 5;
    const pollIntervallInMilliseconds = pollIntervallInMinutes * 60 * 1000;

    const [companyInfo, setCompanyInfo] = useState<
        GetCompanyInfoQuery["companyInfo"] | null
    >(null);

    const {data: companyInfoData} = useGetCompanyInfoQuery();

    useEffect(() => {
        if (companyInfoData?.companyInfo) {
            setCompanyInfo(companyInfoData.companyInfo);
        }
    }, [companyInfoData]);

    const [highestRole, setHighestRole] = useState<Roles>(Roles.Instructor);

    // ---------------------------------------------------------------------
    const [rolesOfCurrent, setRolesOfCurrent] = useState<Array<Roles>>([
        Roles.Instructor,
    ]);
    const {data: rolesData} = useGetUserRolesQuery({
        // fetchPolicy: "no-cache",
        fetchPolicy: "cache-and-network",
        pollInterval: pollIntervallInMilliseconds,
    });

    const {data: isInstructorData} = useIsInstructorQuery();
    const isInstructor = isInstructorData?.isInstructor;

    useEffect(() => {
        if (rolesData?.getUserRoles?.rolesOfCurrent) {
            setRolesOfCurrent(rolesData.getUserRoles.rolesOfCurrent);
        }
    }, [rolesData]);

    const getHighestRole = (roles: Array<Roles>) => {
        if (roles.includes(Roles.Superuser)) {
            return Roles.Superuser;
        } else if (roles.includes(Roles.BetaTester)) {
            return Roles.BetaTester;
        } else if (roles.includes(Roles.Admin)) {
            return Roles.Admin;
        } else if (roles.includes(Roles.Instructor)) {
            return Roles.Instructor;
        } else if (roles.includes(Roles.Staff)) {
            return Roles.Staff;
        }

        return Roles.EndUser;
    };

    useEffect(() => {
        setHighestRole(getHighestRole(rolesOfCurrent));
    }, [rolesOfCurrent]);

    return (
        <>
            <Layout className={css.root}>
                <Layout.Header
                    className={
                        subDomainShort() === "stage" ? css.stage : css.header
                    }
                >
                    <button
                        type="button"
                        className={menuToggleClasses.join(" ")}
                        onClick={() => toggleNavigation(!_navigationIsOpen)}
                    >
                        {_navigationIsOpen ? (
                            <CloseOutlined style={{fontSize: "0.95em"}} />
                        ) : (
                            <MenuOutlined />
                        )}
                        {_navigationIsOpen ? "Menu" : ""}
                    </button>
                    {secondLevelNavigation !== null && (
                        <div className={css.secondLevelNavigation}>
                            {pageTitle !== undefined && (
                                <h2 className={css.pageTitle}>
                                    {pageTitle(_navigationIsOpen)}
                                </h2>
                            )}
                            {secondLevelNavigation}
                        </div>
                    )}
                    <div className={css.secondLevelNavigationLogo}>
                        <img src={LogoTypographic} alt="logo-typographic" />
                        <Link
                            to={{
                                pathname: URL_CHANGELOG.replace(
                                    ":highestRole",
                                    highestRole,
                                ),
                            }}
                        >
                            <div className={css.versionNumber}>{`V ${
                                packageJson.version
                            } ${subDomainShort()}`}</div>
                        </Link>
                    </div>
                </Layout.Header>
                <Layout className={css.contentAreaWrapper}>
                    <Layout.Sider
                        collapsed={!_navigationIsOpen}
                        // collapsedWidth={0}
                        collapsedWidth={38}
                        breakpoint="xxl"
                        onBreakpoint={(isClosed) => {
                            toggleNavigation(!isClosed);
                        }}
                        // hidden={!_navigationIsOpen}
                        width={menuWidth}
                        trigger={null}
                    >
                        <MainMenu
                            className={
                                subDomainShort() === "stage"
                                    ? css.stage
                                    : undefined
                            }
                            highestRole={highestRole}
                            rolesOfCurrent={rolesOfCurrent}
                            isInstructor={isInstructor}
                            menuTextClasses={menuTextClasses}
                            closed={!_navigationIsOpen}
                            companyInfo={companyInfo}
                        />
                    </Layout.Sider>
                    <Layout className={contentAreaClasses.join(" ")}>
                        <Layout id="modalmount">{main}</Layout>
                    </Layout>
                </Layout>
            </Layout>
            {/* {main} */}
        </>
    );
};

export default memo(LayoutStandard);

type MainMenuProps = {
    className?: string;
    highestRole: Roles;
    rolesOfCurrent: Array<Roles>;
    isInstructor?: boolean;
    menuTextClasses?: Array<string>;
    closed?: boolean;
    companyInfo?: GetCompanyInfoQuery["companyInfo"];
};

const MainMenu: FC<MainMenuProps> = ({
    className,
    highestRole,
    rolesOfCurrent,
    isInstructor = false,
    menuTextClasses,
    closed,
    companyInfo,
}) => {
    const mainMenuClasses = [css.mainMenu, className];
    const featureStaffLogin = companyInfo?.subscription?.featureStaffLogin;
    const featureWeekPlaner = companyInfo?.subscription?.featureWeekPlaner;

    if (closed) {
        mainMenuClasses.push(css.mainMenuClosed);
    }

    return (
        <nav className={classnames(mainMenuClasses)}>
            <ul>
                {routePermissions[URL_DASHBOARD].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.dashboard()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_DASHBOARD}>
                                <Icon component={DashboardSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.dashboard()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_CUSTOMERS].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.customers()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_CUSTOMERS}>
                                <Icon component={AttendeesSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.customers()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_COURSES].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.courses()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_COURSES}>
                                <Icon component={CoursesSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.courses()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_LOCATIONS].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.locations()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_LOCATIONS}>
                                <Icon component={LocationsSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.locations()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_BOOKINGS].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.bookings()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_BOOKINGS}>
                                <Icon component={LeftMenuBookingIcon} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.bookings()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_LESSONS_TODAY].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed &&
                                i18n.views.LayoutStandard.lessonsToday()
                            }
                            placement="right"
                        >
                            {featureWeekPlaner ? (
                                <NavLink
                                    to={URL_LESSONS_TODAY_CALENDAR_WEEKVIEW}
                                >
                                    <Icon component={WeekPlannerSvg} />
                                    <span
                                        className={classnames(menuTextClasses)}
                                    >
                                        {i18n.views.LayoutStandard.lessonsToday()}
                                    </span>
                                </NavLink>
                            ) : (
                                <NavLink to={URL_LESSONS_TODAY}>
                                    <Icon component={WeekPlannerSvg} />
                                    <span
                                        className={classnames(menuTextClasses)}
                                    >
                                        {i18n.views.LayoutStandard.lessonsToday()}
                                    </span>
                                </NavLink>
                            )}
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_MY_BOOKINGS].includes(highestRole) &&
                isInstructor ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.myBookings()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_MY_BOOKINGS}>
                                <Icon component={LeftMenuBookingIcon} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.myBookings()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_INVOICE].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.invoice()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_INVOICE}>
                                <Icon component={InvoicesSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.invoice()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_PAYMENTS].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.payments()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_PAYMENTS}>
                                <Icon component={TransactionsSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.payments()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_STAFF].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={closed && i18n.views.LayoutStandard.staff()}
                            placement="right"
                        >
                            <NavLink to={URL_STAFF}>
                                <Icon component={StaffSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.staff()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_STAFF_LOGIN].includes(highestRole) &&
                featureStaffLogin ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.staffLogin()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_STAFF_LOGIN}>
                                <Icon component={StaffLoginSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.staffLogin()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_TEMPLATES].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.templates()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_TEMPLATES}>
                                <Icon component={VorlagenSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.templates()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_NOTIFICATIONS].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed &&
                                i18n.views.LayoutStandard.notifications()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_NOTIFICATIONS}>
                                <Icon component={NotificationsSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.notifications()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_BADGES].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={closed && i18n.views.LayoutStandard.badges()}
                            placement="right"
                        >
                            <NavLink to={URL_BADGES}>
                                <Icon component={AbzeichenSvg} />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.badges()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
                {routePermissions[URL_SETTINGS].includes(highestRole) ? (
                    <li>
                        <Tooltip
                            title={
                                closed && i18n.views.LayoutStandard.settings()
                            }
                            placement="right"
                        >
                            <NavLink to={URL_SETTINGS}>
                                <SettingOutlined />
                                <span className={classnames(menuTextClasses)}>
                                    {i18n.views.LayoutStandard.settings()}
                                </span>
                            </NavLink>
                        </Tooltip>
                    </li>
                ) : null}
            </ul>

            <div className={css.logout}>
                <Logout highestRole={highestRole} menuClosed={closed} />
            </div>
        </nav>
    );
};
