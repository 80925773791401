import gql from "graphql-tag";

export const GET_BOOKINS = gql`
    query GetBookins($options: ArrayDataOptions, $onlyCurrentUser: Boolean) {
        bookings(options: $options, onlyCurrentUser: $onlyCurrentUser) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                updateDate
                createDate
                startDateTime
                courseId
                noticeStaff
                attendanceListNote
                noticeBooker
                coursePriceId
                payDate
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    id
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    id
                    startDateTime
                    endDateTime
                    locationId
                    course {
                        id
                        freePlaces
                        startDateTime
                        paymentInterval
                        minAttendees
                        maxAttendees
                        prefixedCourseNumber
                        allowMonthlyPayment
                        paymentInterval
                        courseType {
                            id
                            name
                            color
                        }
                        instructors {
                            id
                            firstname
                            lastname
                        }
                        lessonCount
                    }
                    location {
                        id
                        city
                        name
                    }
                }
                attendee {
                    id
                    gender
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        gender
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        gender
                        nonDisclosureNotice
                        credit
                    }
                }
                bookedBy
                canceled
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                waitListCount
                additionalInfo
            }
        }
    }
`;

export const CREATE_BOOKING = gql`
    mutation CreateBooking(
        $newBookingData: NewBookingInput!
        $sendMail: Boolean!
    ) {
        createBooking(newBookingData: $newBookingData, sendMail: $sendMail) {
            id
            bookingType
        }
    }
`;

export const REMOVE_BOOKING = gql`
    mutation RemoveBooking(
        $id: ID!
        $bookingDeleteAction: BookingDeleteAction
        $notifyBooker: Boolean
    ) {
        removeBooking(
            id: $id
            bookingDeleteAction: $bookingDeleteAction
            notifyBooker: $notifyBooker
        )
    }
`;

export const REMOVE_BOOKINGS = gql`
    mutation RemoveBookings($ids: [ID!]!) {
        removeBookings(ids: $ids)
    }
`;

export const UPDATE_BOOKING_BY_ID = gql`
    mutation UpdateBooking($editBookingData: EditBookingInput!, $id: String!) {
        updateBooking(editBookingData: $editBookingData, id: $id) {
            id
            bookingType
        }
    }
`;

export const UPDATE_NOTICE_STAFF = gql`
    mutation UpdateNoticeStaff($id: String!, $noticeStaff: String!) {
        updateNoticeStaff(id: $id, noticeStaff: $noticeStaff) {
            id
            noticeStaff
        }
    }
`;

export const GET_MONTHLY_PAYMENT_PREVIEW = gql`
    query getMonthlyPaymentPreview(
        $monthlyPaymentPreviewOptions: ScheduledPaymentPreviewInput!
    ) {
        getMonthlyPaymentPreview(
            monthlyPaymentPreviewOptions: $monthlyPaymentPreviewOptions
        )
    }
`;

export const GET_BOOKING_BY_ID = gql`
    query getBookingById($id: ID!) {
        bookingById(id: $id) {
            id
            bookingNumber
            bookingNumberPrefix
            bookingType
            updateDate
            createDate
            noticeStaff
            noticeBooker
            coursePriceId
            payDate
            grossPrice
            netPrice
            vat
            memberDiscount
            isSpecial
            startDateTime
            courseId
            coursePrice {
                id
                grossPrice
                netPrice
                vat
            }
            invoice {
                billed
                invoiceNumber
                invoiceNumberPrefix
                createDate
                correctionDate
                createDate
                id
            }
            reversalInvoice {
                id
                invoiceNumber
                invoiceNumberPrefix
                createDate
            }
            courseLessons {
                startDateTime
                endDateTime
                id
                locationId
                course {
                    id
                    freePlaces
                    bookingCount
                    startDateTime
                    paymentInterval
                    minAttendees
                    maxAttendees
                    prefixedCourseNumber
                    allowMonthlyPayment
                    allowUpFrontPayment
                    monthlySchedule
                    courseType {
                        id
                        name
                        color
                    }
                    installmentRates {
                        id
                        installmentAmount
                        installmentDue
                    }
                    paymentType
                    lessons {
                        id
                        startDateTime
                    }
                }
                location {
                    id
                    city
                    name
                }
            }
            attendee {
                id
                gender
                firstname
                lastname
                birthday
                customerNumberPrefix
                customerNumber
                member
                booker {
                    id
                    firstname
                    lastname
                    customerNumberPrefix
                    customerNumber
                    street
                    houseNumber
                    postCode
                    city
                    phoneNumber
                    mobileNumber
                    email
                    gender
                    paymentMethods {
                        id
                        type
                        cash
                        esr
                        iban
                        bank
                        bic
                        sepaAccountholder
                        bankTransfer
                    }
                    nonDisclosureNotice
                    credit
                }
                defaultPaymentMethod {
                    id
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                }
            }
            paymentType
            paymentMethod {
                id
                cash
                esr
                creditcardNumber
                creditCardAccountholder
                expirationDate
                sepaAccountholder
                iban
                bic
                bank
                paypalAccountholder
                email
                bankTransfer
            }
            paymentMethodId
            bookedBy
            canceled
            scheduledPayments {
                id
                paymentAmount
                paymentDue
                paymentDone
                type
            }
            monthlySchedule
            paymentType
            creditedBalance
            waitListCount
            additionalInfo
        }
    }
`;

export const GET_BOOKINGS_BY_COURSE = gql`
    query GetBookingsByCourse($options: ArrayDataOptions, $id: ID!) {
        getBookingsByCourse(options: $options, id: $id) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                updateDate
                createDate
                noticeStaff
                noticeBooker
                attendanceListNote
                coursePriceId
                courseId
                noticeBooker
                payDate
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    id
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    id
                    startDateTime
                    endDateTime
                    locationId
                    course {
                        id
                        freePlaces
                        startDateTime
                        paymentInterval
                        minAttendees
                        maxAttendees
                        prefixedCourseNumber
                        allowMonthlyPayment
                        courseType {
                            id
                            name
                            color
                        }
                    }
                    location {
                        id
                        city
                        name
                    }
                    #courseLessonBookings {
                    #    id
                    #    bookingId
                    #    attendance
                    #    courseLessonId
                    #}
                }
                attendee {
                    id
                    gender
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        gender
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        gender
                        nonDisclosureNotice
                        credit
                    }
                }
                bookedBy
                canceled
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                waitListCount
            }
        }
    }
`;

export const GET_WAITLIST_BY_COURSE = gql`
    query GetWaitlistByCourse($options: ArrayDataOptions, $id: ID!) {
        getWaitlistByCourse(options: $options, id: $id) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                coursePriceId
                courseId
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                courseLessons {
                    id
                    course {
                        id
                        paymentInterval
                    }
                }
                paymentType
                attendee {
                    id
                    gender
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        gender
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        gender
                        nonDisclosureNotice
                    }
                }
                canceled
                waitListCount
            }
        }
    }
`;

export const GET_WAITLIST_COUNT_OF_COURSE = gql`
    query GetWaitlistCountOfCourse($id: ID!) {
        getWaitlistCountOfCourse(id: $id)
    }
`;

export const GET_BOOKINGS_BY_COURSE_MINIMAL = gql`
    query GetBookingsByCourseMinimal($options: ArrayDataOptions, $id: ID!) {
        getBookingsByCourse(options: $options, id: $id) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                attendee {
                    id
                    gender
                    birthday
                    firstname
                    lastname
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        gender
                        customerNumberPrefix
                        customerNumber
                        email
                        nonDisclosureNotice
                    }
                }
                canceled
                waitListCount
            }
        }
    }
`;

export const GET_BOOKINGS_BY_COURSE_FOR_TRANSFER = gql`
    query GetBookingsByCourseForTransfer($options: ArrayDataOptions, $id: ID!) {
        getBookingsByCourse(options: $options, id: $id) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                attendee {
                    id
                    gender
                    birthday
                    firstname
                    lastname
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        firstname
                        lastname
                        gender
                        customerNumberPrefix
                        customerNumber
                        email
                        nonDisclosureNotice
                    }
                }
                canceled
                waitListCount
                invoice {
                    id
                }
            }
        }
    }
`;

export const GET_BOOKINGS_COUNT_BY_COURSE = gql`
    query GetBookingsCountByCourse($options: ArrayDataOptions, $id: ID!) {
        getBookingsByCourse(options: $options, id: $id) {
            existsMore
            total
            items {
                id
                bookingNumberPrefix
                bookingType
                canceled
                waitListCount
            }
        }
    }
`;

export const REMOVE_ALL_BOOKINGS = gql`
    mutation RemoveAllBookings {
        removeAllBookings
    }
`;

export const SEND_BOOKING_CONFIRMATION = gql`
    mutation SendBookingConfirmation($bookingId: ID!) {
        sendBookingConfirmation(bookingId: $bookingId)
    }
`;

export const GET_VARIABLES_FOR_BOOKING_WITH_COURSE = gql`
    query getVariablesForBookingWithCourse($id: ID!) {
        bookingById(id: $id) {
            id
            attendee {
                id
                firstname
                lastname
                birthday
                booker {
                    id
                    firstname
                    lastname
                }
            }
            courseLessons {
                id
                startDateTime
                endDateTime
                course {
                    id
                    prefixedCourseNumber
                    courseType {
                        id
                        name
                        description
                    }
                }
                location {
                    id
                    city
                    name
                }
            }
            creditedBalance
            memberDiscount
        }
    }
`;

export const CONVERT_BOOKING_TO_WAITLIST = gql`
    mutation convertBookingToWaitlist($bookingId: ID!) {
        convertBookingToWaitlist(bookingId: $bookingId) {
            id
            bookingType
            bookingNumberPrefix
            bookingNumber
            attendee {
                id
                firstname
                lastname
            }
        }
    }
`;

export const CONVERT_WAITLIST_TO_BOOKING = gql`
    mutation convertWaitlistToBooking($bookingId: ID!) {
        convertWaitlistToBooking(bookingId: $bookingId) {
            id
            bookingType
            bookingNumberPrefix
            bookingNumber
            attendee {
                id
                firstname
                lastname
            }
        }
    }
`;

export const TRANSFER_ATTENDEE_TO_COURSE = gql`
    mutation transferAttendeeToCourse(
        $bookingId: ID!
        $targetCourseId: ID!
        $bookingDeleteAction: BookingDeleteAction
        $usePlaceReservation: Boolean
    ) {
        transferAttendeeToCourse(
            bookingId: $bookingId
            targetCourseId: $targetCourseId
            bookingDeleteAction: $bookingDeleteAction
            usePlaceReservation: $usePlaceReservation
        ) {
            id
            bookingType
            bookingNumberPrefix
            bookingType
            attendee {
                id
                firstname
                lastname
            }
            courseLessons {
                id
                course {
                    id
                    prefixedCourseNumber
                    courseType {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const COPY_ATTENDEE_TO_COURSE = gql`
    mutation copyAttendeeToCourse(
        $bookingId: ID!
        $targetCourseId: ID!
        $usePlaceReservation: Boolean
    ) {
        copyAttendeeToCourse(
            bookingId: $bookingId
            targetCourseId: $targetCourseId
            usePlaceReservation: $usePlaceReservation
        ) {
            id
            bookingType
            bookingNumberPrefix
            bookingType
            attendee {
                id
                firstname
                lastname
            }
            courseLessons {
                id
                course {
                    id
                    prefixedCourseNumber
                    courseType {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_BOOKINGS_BY_IDS = gql`
    query GetBookingsByIds($ids: [String!]!) {
        getBookingsByIds(ids: $ids) {
            id
            bookingNumberPrefix
            bookingNumber
            bookingType
            courseId
            attendee {
                id
                booker {
                    id
                    firstname
                    lastname
                    email
                    mobileNumber
                }
            }
            canceled
            waitListCount
        }
    }
`;

export const GET_BOOKINGS_BY_SCHEDULEDPAYMENT_IDS = gql`
    query GetBookingsByScheduledPaymentIds($scheduledPaymentIds: [String!]!) {
        getBookingsByScheduledPaymentIds(
            scheduledPaymentIds: $scheduledPaymentIds
        ) {
            id
            bookingNumberPrefix
            bookingNumber
            bookingType
            attendee {
                id
                booker {
                    id
                    firstname
                    lastname
                    email
                }
            }
            canceled
        }
    }
`;

export const GET_BOOKINGS_BY_INVOICE_IDS = gql`
    query getBookingsByInvoiceIds($invoiceIds: [String!]!) {
        getBookingsByInvoiceIds(invoiceIds: $invoiceIds) {
            id
            bookingNumberPrefix
            bookingNumber
            bookingType
            #attendee {
            #    id
            #    firstname
            #    lastname
            #    booker {
            #        id
            #        firstname
            #        lastname
            #        email
            #}
        }
    }
`;

export const GET_BOOKINGS_BY_ATTENDEE_WITH_OPTIONS = gql`
    query GetBookingsByAttendeeIdWithOptions(
        $id: ID!
        $startDateTime: DateTime
        $endDateTime: DateTime
        $canceledBookings: Boolean
    ) {
        getBookingsByAttendeeIdWithOptions(
            id: $id
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            canceledBookings: $canceledBookings
        ) {
            id
            bookingNumber
            bookingNumberPrefix
            bookingType
            updateDate
            createDate
            startDateTime
            courseId
            noticeStaff
            attendanceListNote
            noticeBooker
            coursePriceId
            payDate
            grossPrice
            netPrice
            vat
            isSpecial
            coursePrice {
                id
                grossPrice
                netPrice
                vat
            }
            invoice {
                id
                billed
                invoiceNumber
                invoiceNumberPrefix
                createDate
            }
            reversalInvoice {
                id
                invoiceNumber
                invoiceNumberPrefix
                createDate
            }
            paymentType
            courseLessons {
                id
                startDateTime
                endDateTime
                locationId
                course {
                    id
                    freePlaces
                    startDateTime
                    paymentInterval
                    minAttendees
                    maxAttendees
                    prefixedCourseNumber
                    allowMonthlyPayment
                    paymentInterval
                    courseType {
                        id
                        name
                        color
                    }
                    instructors {
                        id
                        firstname
                        lastname
                    }
                    lessonCount
                }
                location {
                    id
                    city
                    name
                }
            }
            attendee {
                id
                gender
                firstname
                lastname
                birthday
                customerNumberPrefix
                customerNumber
                booker {
                    id
                    gender
                    firstname
                    lastname
                    customerNumberPrefix
                    customerNumber
                    street
                    houseNumber
                    postCode
                    city
                    phoneNumber
                    mobileNumber
                    email
                    gender
                    nonDisclosureNotice
                    credit
                }
            }
            bookedBy
            canceled
            emailNotifications {
                id
                templateType
                subject
                createDate
                bookingId
                email
            }
            bookingConfirmationSent
            waitingListConfirmationSent
            cancelationSent
            invoiceSent
            paymentMethod {
                id
                type
                cash
                esr
                iban
                bank
                bic
                sepaAccountholder
                bankTransfer
            }
            waitListCount
        }
    }
`;

export const UPDATE_ATTENDANCE_LIST_NOTE = gql`
    mutation UpdateAttendanceListNote(
        $bookingId: ID!
        $attendanceListNote: String
    ) {
        updateAttendanceListNote(
            bookingId: $bookingId
            attendanceListNote: $attendanceListNote
        ) {
            id
            attendanceListNote
        }
    }
`;

export const UPDATE_BOOKING_PAYMENT_METHOD = gql`
    mutation UpdateBookingPaymentMethod(
        $bookingId: ID!
        $paymentMethodId: ID!
    ) {
        updateBookingPaymentMethod(
            bookingId: $bookingId
            paymentMethodId: $paymentMethodId
        ) {
            id
            paymentMethodId
        }
    }
`;

export const ADD_MULTIPLE_PLACE_RESERVATIONS = gql`
    mutation AddMultiplePlaceReservations($courseId: ID!, $count: Float!) {
        addMultiplePlaceReservations(courseId: $courseId, count: $count) {
            id
        }
    }
`;

export const DELETE_ALL_PLACE_RESERVATIONS_BY_COURSE_ID = gql`
    mutation DeleteAllPlaceReservationsByCourseId($courseId: ID!) {
        deleteAllPlaceReservationsByCourseId(courseId: $courseId)
    }
`;

export const DELETE_SELECTION_OF_WAITLIST = gql`
    mutation DeleteSelectionOfWaitlist($bookingIds: [ID!]!) {
        deleteSelectionOfWaitlist(bookingIds: $bookingIds)
    }
`;

export const GET_BOOKINGS_BY_COURSE_REFACTORED = gql`
    query GetBookingsByCourseRefactored($options: ArrayDataOptions, $id: ID!) {
        getBookingsByCourse(options: $options, id: $id) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                updateDate
                createDate
                noticeStaff
                noticeBooker
                attendanceListNote
                coursePriceId
                courseId
                noticeBooker
                payDate
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    id
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    id
                    startDateTime
                    endDateTime
                    locationId
                    course {
                        id
                        freePlaces
                        startDateTime
                        paymentInterval
                        minAttendees
                        maxAttendees
                        prefixedCourseNumber
                        allowMonthlyPayment
                        courseType {
                            id
                            name
                            color
                        }
                    }
                    location {
                        id
                        city
                        name
                    }
                    #courseLessonBookings {
                    #    id
                    #    bookingId
                    #    attendance
                    #    courseLessonId
                    #}
                }
                attendee {
                    id
                    gender
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        gender
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        gender
                        nonDisclosureNotice
                        credit
                    }
                }
                bookedBy
                canceled
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                waitListCount
            }
        }
    }
`;

export const TRANSFER_MULTIPLE_BOOKINGS = gql`
    mutation TransferMultipleBookings(
        $transferMultipleBookingsInput: TransferMultipleBookingsInput!
    ) {
        transferMultipleBookings(
            transferMultipleBookingsInput: $transferMultipleBookingsInput
        ) {
            id
            bookingNumberPrefix
            bookingNumber
            grossPrice
            courseId
            scheduledPayments {
                id
                paymentDue
                paymentAmount
                type
            }
            attendee {
                id
                firstname
                lastname
            }
        }
    }
`;
// bookingsInStartedCourses
export const GET_BOOKINGS_IN_STARTED_COURSES = gql`
    query GetBookingsInStartedCourses($options: ArrayDataOptions) {
        bookingsInStartedCourses(options: $options) {
            existsMore
            total
            items {
                id
                bookingNumber
                bookingNumberPrefix
                bookingType
                updateDate
                createDate
                startDateTime
                courseId
                noticeStaff
                attendanceListNote
                noticeBooker
                coursePriceId
                payDate
                grossPrice
                netPrice
                vat
                isSpecial
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                }
                invoice {
                    id
                    billed
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                reversalInvoice {
                    id
                    invoiceNumber
                    invoiceNumberPrefix
                    createDate
                }
                paymentType
                courseLessons {
                    id
                    startDateTime
                    endDateTime
                    locationId
                    course {
                        id
                        freePlaces
                        startDateTime
                        paymentInterval
                        minAttendees
                        maxAttendees
                        prefixedCourseNumber
                        allowMonthlyPayment
                        paymentInterval
                        courseType {
                            id
                            name
                            color
                        }
                        instructors {
                            id
                            firstname
                            lastname
                        }
                        lessonCount
                    }
                    location {
                        id
                        city
                        name
                    }
                }
                attendee {
                    id
                    gender
                    firstname
                    lastname
                    birthday
                    customerNumberPrefix
                    customerNumber
                    booker {
                        id
                        gender
                        firstname
                        lastname
                        customerNumberPrefix
                        customerNumber
                        street
                        houseNumber
                        postCode
                        city
                        phoneNumber
                        mobileNumber
                        email
                        gender
                        nonDisclosureNotice
                        credit
                    }
                }
                bookedBy
                canceled
                emailNotifications {
                    id
                    templateType
                    subject
                    createDate
                    bookingId
                    email
                }
                bookingConfirmationSent
                waitingListConfirmationSent
                cancelationSent
                invoiceSent
                paymentMethod {
                    id
                    type
                    cash
                    esr
                    iban
                    bank
                    bic
                    sepaAccountholder
                    bankTransfer
                }
                waitListCount
                additionalInfo
            }
        }
    }
`;
