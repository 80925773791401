import {useState, useEffect} from "react";
import {Maybe} from "type-graphql";
import {ArrayDataOptions} from "../generated/graphql";
import {Button, ButtonProps, Modal, Tooltip} from "antd";
import React from "react";
import {MoreOutlined} from "@ant-design/icons";
import moment from "moment";

export const useLocalStorageOptions = ({
    localStorageName,
    defaultOptions,
    totalResults,
}: {
    localStorageName: string;
    defaultOptions: ArrayDataOptions;
    totalResults: number;
}): [
    Maybe<ArrayDataOptions>,
    React.Dispatch<React.SetStateAction<ArrayDataOptions>>,
] => {
    const [options, setOptions] = useState<ArrayDataOptions>(defaultOptions);

    useEffect(() => {
        const savedOptions = localStorage.getItem(localStorageName);
        const savedOptionsParsed = savedOptions && JSON.parse(savedOptions);

        if (savedOptions) {
            setOptions(savedOptionsParsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorage.setItem(localStorageName, JSON.stringify(options));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    useEffect(() => {
        // if the current page would't show results, set offset to 0
        if (totalResults && options?.offset && totalResults < options.offset) {
            setOptions({
                ...options,
                offset: 0,
            });
        }
    }, [options, totalResults]);

    return [options, setOptions];
};

export const ResetLocalStorage = ({
    refetch,
    btnContent,
    style,
    buttonProps,
}: {
    refetch?: () => void;
    btnContent?: string | React.ReactNode;
    style?: React.CSSProperties;
    buttonProps?: ButtonProps;
}) => {
    const clearAppLocalStorage = (refetch?: () => void) => {
        Object.keys(localStorage).forEach((key) => {
            localStorage.removeItem(key);
            console.log("==> removed:", key);
        });

        refetch?.();
    };

    return (
        <Button
            size="small"
            type="text"
            style={{padding: 0, margin: 0, ...style}}
            onClick={() =>
                Modal.confirm({
                    title: "App Daten zurücksetzen",
                    content:
                        "Falls es Probleme mit der Darstellung der Inhalte von Listen gibt, können hier alle Filter in der App zurückgesetzt werden.\n Daten gehen dabei nicht verloren.",
                    okText: "Jetzt Zurücksetzen",
                    onOk: () => clearAppLocalStorage(refetch),
                    icon: null,
                })
            }
            {...buttonProps}
        >
            <Tooltip title="App Daten zurücksetzen" placement="left">
                {btnContent ?? <MoreOutlined />}
            </Tooltip>
        </Button>
    );
};

export type WeekViewCalendarLocalStorage = {
    currentWeek: number;
    year: number;
    timeRange: Array<number>;
    locationId: string | undefined;
};

export const useWeekViewCalendarLocalStorage = ({
    localStorageName,
    currentWeek,
    year,
    timeRange,
    locationId,
}: {
    localStorageName: string;
    currentWeek: number;
    year: number;
    timeRange: Array<number>;
    locationId: string | undefined;
}): [
    WeekViewCalendarLocalStorage,
    React.Dispatch<React.SetStateAction<WeekViewCalendarLocalStorage>>,
] => {
    const [localStorageSettings, setLocalStorageSettings] = useState<
        WeekViewCalendarLocalStorage
    >({
        currentWeek: currentWeek ?? moment().week(),
        year,
        timeRange: timeRange ?? [7, 19],
        locationId,
    });

    useEffect(() => {
        const savedOptions = localStorage.getItem(localStorageName);
        const savedOptionsParsed = savedOptions && JSON.parse(savedOptions);

        if (savedOptions) {
            setLocalStorageSettings(savedOptionsParsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorage.setItem(
            localStorageName,
            JSON.stringify(localStorageSettings),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorageSettings]);

    return [localStorageSettings, setLocalStorageSettings];
};
