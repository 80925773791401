import React, {FC, memo} from "react";
import {Tooltip, Popconfirm} from "antd";
import {ButtonProps} from "antd/lib/button";
import {DeleteOutlined} from "@ant-design/icons";
import Button from "../Button/Button";
import i18n from "../../services/i18n";

type DeleteButtonButtonProps = Omit<ButtonProps, "icon" | "ghost" | "type"> & {
    filled?: boolean;
    hideIcon?: boolean;
};

const DeleteButtonButton: FC<DeleteButtonButtonProps> = ({
    filled = false,
    hideIcon,
    className,
    ...restProps
}) => (
    <Button
        type="ghost"
        danger
        ghost={filled === false}
        icon={hideIcon ? undefined : <DeleteOutlined />}
        {...restProps}
    />
);

// Workaround for the tooltip not showing for disabled buttons, because of missing mouse events
// More info: https://github.com/ant-design/ant-design/issues/9581#issuecomment-374113785
(DeleteButtonButton as any).__ANT_BUTTON = true;

type DeleteButtonProps = Omit<DeleteButtonButtonProps, "onClick"> & {
    confirmText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    disabledText?: string;
    disableConfirm?: boolean;
    tooltipDisabled?: boolean;
    style?: React.CSSProperties;
};

const DeleteButton: FC<DeleteButtonProps> = ({
    disabledText = "",
    confirmText = "",
    onConfirm,
    onCancel,
    tooltipDisabled = false,
    disableConfirm = false,
    ...buttonProps
}) => {
    const {disabled} = buttonProps;

    if (disabled && tooltipDisabled === false) {
        return (
            <Tooltip title={disabledText} placement="top">
                <DeleteButtonButton {...buttonProps} />
            </Tooltip>
        );
    }

    if (disabled === false && disableConfirm === false) {
        return (
            <Popconfirm
                title={confirmText}
                onConfirm={onConfirm}
                onCancel={onCancel}
                okText={i18n.views.DeleteButton.ok()}
                cancelText={i18n.views.DeleteButton.cancel()}
                placement="left"
            >
                <DeleteButtonButton {...buttonProps} />
            </Popconfirm>
        );
    }

    return <DeleteButtonButton onClick={onConfirm} {...buttonProps} />;
};

export default memo(DeleteButton);
