import {blue, red} from "@ant-design/colors";
import {
    CloseCircleTwoTone,
    MoreOutlined,
    PlusCircleTwoTone,
} from "@ant-design/icons";
import {Button, ButtonProps, Dropdown} from "antd";
import {sortBy} from "lodash";
import {ItemType} from "rc-menu/lib/interface";
import React, {FC, memo, useEffect, useState} from "react";
import {
    GetColumnsForTableDocument,
    TablesEnum,
    useUpdateTableColumnSettingsMutation,
} from "../../generated/graphql";
import css from "./ColumnConfigurator.less";

type ColumnConfiguratorProps = {
    columnsToShow: Array<string>;
    allColumns: {[key: string]: string};
    // setSelectedColumns: Function;
    setSelectedColumns: (e: Array<string>) => void;
    setColumnSelectorVisible: Function;
    mandatoryColumns?: Array<string>;
    hiddenColumns?: Array<string>;
    tablesEnum?: TablesEnum;
    style?: React.CSSProperties;
};

const ColumnConfigurator: FC<ColumnConfiguratorProps> = ({
    columnsToShow,
    allColumns,
    setSelectedColumns,
    setColumnSelectorVisible,
    mandatoryColumns = [],
    hiddenColumns = [],
    tablesEnum,
    style,
}) => {
    // console.group("ColumnConfigurator");
    // console.log("columnsToShowCount", columnsToShow.length);
    // console.log("columnsToShow", columnsToShow);
    // // console.log("setSelectedColumns", setSelectedColumns);
    // console.log("tablesEnum", tablesEnum);
    // console.log("allColumns", allColumns);
    // console.log("mandatoryColumns", mandatoryColumns);
    // console.log("hiddenColumns", hiddenColumns);
    // console.groupEnd();

    const [currentSelection, setCurrentSelection] = useState<
        Array<{[key: string]: string}>
    >([]);

    // CLEANUP FUNCTION // Commented out because on 2023-03-05
    // useEffect(() => {
    //     return () => {
    //         setCurrentSelection([]);
    //     };
    // }, []);

    const [updateUserColumns] = useUpdateTableColumnSettingsMutation();

    useEffect(() => {
        const updatedSelection: Array<{[key: string]: string}> = [];

        columnsToShow.forEach((column) => {
            updatedSelection.push({[column]: allColumns[column]});
        });

        setCurrentSelection(updatedSelection);
    }, [allColumns, columnsToShow]);

    const handleRemove = (currentIndex: number) => {
        const updatedSelection = [...currentSelection];

        updatedSelection.splice(currentIndex, 1);

        const arrayOfDataIndexes: Array<string> = updatedSelection.map(
            (col) => {
                return Object.keys(col)[0];
            },
        );

        if (tablesEnum) {
            updateUserColumns({
                variables: {
                    updateTableColumnSettingsInput: {
                        table: tablesEnum,
                        columns: arrayOfDataIndexes,
                    },
                },
                refetchQueries: [
                    "GetColumnsForTable",
                    {
                        query: GetColumnsForTableDocument,
                        variables: {table: tablesEnum},
                    },
                ],
            });
        }

        setSelectedColumns(arrayOfDataIndexes);
        setCurrentSelection(updatedSelection);
    };

    const handleAdd = (currentIndex: number, newColumn: string) => {
        const updatedSelection = [...currentSelection];

        updatedSelection.splice(currentIndex + 1, 0, {
            [newColumn]: allColumns[newColumn],
        });

        const arrayOfDataIndexes: Array<string> = updatedSelection.map(
            (col) => {
                return Object.keys(col)[0];
            },
        );

        if (tablesEnum) {
            updateUserColumns({
                variables: {
                    updateTableColumnSettingsInput: {
                        table: tablesEnum,
                        columns: arrayOfDataIndexes,
                    },
                },
            });
        }

        setSelectedColumns(arrayOfDataIndexes);
        setCurrentSelection(updatedSelection);
    };

    const ColumnBox = ({
        dataIndex,
        title,
        currentIndex,
    }: {
        dataIndex: string;
        title: string;
        currentIndex: number;
    }) => {
        const [visible, setVisible] = useState<boolean>(true);

        const handleChange = (
            currentIndex: number,
            direction: "left" | "right",
        ) => {
            let newIndex =
                direction === "left" ? currentIndex - 1 : currentIndex + 1;

            const isHidden =
                newIndex < currentSelection.length &&
                newIndex > 0 &&
                hiddenColumns.includes(
                    Object.keys(currentSelection[newIndex])[0],
                );

            // Skip hidden columns
            if (isHidden) {
                newIndex = direction === "left" ? newIndex - 1 : newIndex + 1;
            }

            // check if start or end of array
            const isStartOfArray = newIndex < 0;
            const isEndOfArray = newIndex > currentSelection.length - 1;

            if (!isStartOfArray && !isEndOfArray) {
                const updatedSelection = [...currentSelection];

                const movingItem = updatedSelection[currentIndex];
                const movedItem = updatedSelection[newIndex];

                updatedSelection.splice(currentIndex, 1, movedItem);
                updatedSelection.splice(newIndex, 1, movingItem);

                const arrayOfDataIndexes: Array<string> = updatedSelection.map(
                    (col) => {
                        return Object.keys(col)[0];
                    },
                );

                if (tablesEnum) {
                    updateUserColumns({
                        variables: {
                            updateTableColumnSettingsInput: {
                                // table: TablesEnum.Bookers,
                                table: tablesEnum,
                                columns: arrayOfDataIndexes,
                            },
                        },
                    });
                }

                setSelectedColumns(arrayOfDataIndexes);
                setCurrentSelection(updatedSelection);
            }
        };

        const buttonConfig: ButtonProps = {
            size: "small",
            type: "ghost",
        };

        return (
            <div
                className={css.columnBox}
                key={dataIndex}
                // onMouseEnter={() => setVisible(true)}
                // onMouseLeave={() => setTimeout(() => setVisible(false), 3000)}
            >
                <Button
                    // style={{display: visible ? "block" : "none"}}
                    {...buttonConfig}
                    className={css.sortButtonBack}
                    onClick={() => handleChange(currentIndex, "left")}
                >
                    {"<"}
                </Button>
                <div className={css.columnText}>{title}</div>
                <CloseCircleTwoTone
                    twoToneColor={red[3]}
                    style={{
                        marginRight: 3,
                        display: visible ? "block" : "none",
                    }}
                    onClick={() => handleRemove(currentIndex)}
                />
                <Button
                    // style={{display: visible ? "block" : "none"}}
                    {...buttonConfig}
                    className={css.sortButtonForward}
                    onClick={() => handleChange(currentIndex, "right")}
                >
                    {">"}
                </Button>
            </div>
        );
    };

    const AddColumn = ({currentIndex}: {currentIndex: number}) => {
        const [visible, setVisible] = useState<boolean>(false);

        // CLEANUP FUNCTION
        useEffect(() => {
            return () => {
                setVisible(false);
            };
        }, []);

        const arrayOfDataIndexes: Array<string> = currentSelection.map(
            (col) => {
                return Object.keys(col)[0];
            },
        );

        const items: Array<ItemType> = [];

        Object.entries(allColumns).forEach(([key, value]) => {
            if (
                !arrayOfDataIndexes.includes(key) &&
                !hiddenColumns.includes(key)
            ) {
                items.push({
                    label: value,
                    key,
                    onClick: () => handleAdd(currentIndex, key),
                });
            }
        });

        if (currentIndex >= 0 && currentIndex < currentSelection.length - 1) {
            const sortedItems: Array<ItemType> = [];

            sortBy(items, "label").forEach((item) => {
                sortedItems.push(item);
            });

            return (
                <div
                    onMouseEnter={() => setVisible(true)}
                    onMouseLeave={() => setVisible(false)}
                >
                    {visible ? (
                        <Dropdown
                            menu={{items: sortedItems}}
                            trigger={["click"]}
                        >
                            <Button
                                type="text"
                                size="small"
                                icon={<PlusCircleTwoTone />}
                            />
                        </Dropdown>
                    ) : (
                        <MoreOutlined />
                    )}
                </div>
            );
        }

        return null;
    };

    return (
        <div className={css.root} style={style}>
            <div className={css.columnContainer}>
                {currentSelection.map((column, currentIndex) => {
                    const dataIndex = Object.keys(column)[0];
                    const title = Object.values(column)[0];

                    if (!hiddenColumns.includes(dataIndex)) {
                        return (
                            <div
                                key={dataIndex}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ColumnBox
                                    dataIndex={dataIndex}
                                    title={title}
                                    currentIndex={currentIndex}
                                />
                                <AddColumn currentIndex={currentIndex} />
                            </div>
                        );
                    }

                    // return <div key={dataIndex}>{"!"}</div>;
                })}
            </div>
        </div>
    );
};

export default memo(ColumnConfigurator);
