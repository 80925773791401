import {DeleteFilled} from "@ant-design/icons";
import {Button, Modal, Tooltip, message} from "antd";
import React, {FC, memo, useState} from "react";
import {red} from "@ant-design/colors";
import {
    GetBookingsByCourseDocument,
    GetBookingsByCourseQuery,
    GetCourseByIdDocument,
    useDeleteSelectionOfWaitlistMutation,
} from "../../../generated/graphql";

type BulkDeleteWaitlistProps = {
    currentCourseId: string;
    key: number;
    selectedBookings: GetBookingsByCourseQuery["getBookingsByCourse"]["items"];
    btnClassName?: string;
    onFinished: () => void;
};

const BulkDeleteWaitlist: FC<BulkDeleteWaitlistProps> = ({
    currentCourseId,
    key,
    selectedBookings,
    btnClassName,
    onFinished,
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteSelectionOfWaitlist] = useDeleteSelectionOfWaitlistMutation({
        refetchQueries: [
            "GetBookingsByCourseDocument",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: currentCourseId},
            },
            "GetCourseByIdDocument",
            {
                query: GetCourseByIdDocument,
                variables: {id: currentCourseId},
            },
        ],
    });

    const handleDelete = async () => {
        try {
            setLoading(true);
            message.loading({
                key: "deleteWaitlistMsg",
                content: "Lösche Wartelistenplätze...",
            });
            await deleteSelectionOfWaitlist({
                variables: {
                    bookingIds: selectedBookings.map((booking) => booking.id),
                },
            }).then((res) => {
                if (res.data?.deleteSelectionOfWaitlist) {
                    const count = res.data.deleteSelectionOfWaitlist;

                    message.success({
                        key: "deleteWaitlistMsg",
                        content:
                            count > 1
                                ? `${count} Wartelistenplätze erfolgreich gelöscht`
                                : `Wartelistenplatz erfolgreich gelöscht`,
                    });

                    setLoading(false);
                    setOpen(false);
                }
            });
        } catch (error) {
            await message.error({
                key: "deleteWaitlistMsg",
                content: "Fehler beim Löschen der Wartelistenplätze",
            });
            console.error(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
                message.destroy("deleteWaitlistMsg");
                onFinished();
                setOpen(false);
            }, 2000);
        }
    };

    const count = selectedBookings.length > 1 ? selectedBookings.length : "";
    const articlePlural = selectedBookings.length > 1 ? "die" : "der";
    const waitlistPlural =
        selectedBookings.length > 1 ? "Wartelistenplätze" : "Wartelistenplatz";
    const modalText = `Sollen ${articlePlural} ausgewählten ${count} ${waitlistPlural} dauerhaft gelöscht werden?`;

    if (open) {
        return (
            <Modal
                title="Wartelistenplätze löschen"
                open={open}
                onCancel={() => setOpen(false)}
                okText={loading ? "löschen..." : "Jetzt löschen"}
                okType="danger"
                okButtonProps={{
                    loading,
                    block: loading,
                    style: {margin: "auto"},
                }}
                cancelButtonProps={{
                    disabled: loading,
                    hidden: loading,
                    style: {marginRight: 7},
                }}
                bodyStyle={{textAlign: "center"}}
                onOk={handleDelete}
                destroyOnClose
            >
                {modalText}
            </Modal>
        );
    }

    return (
        <Tooltip title="Wartelistenplätze löschen">
            <Button
                key={key}
                onClick={() => setOpen(true)}
                size="small"
                type="link"
                style={{color: red[3]}}
                shape="circle"
                className={btnClassName}
            >
                <DeleteFilled />
            </Button>
        </Tooltip>
    );
};

export default memo(BulkDeleteWaitlist);
