import {ConfigProps} from "antd/lib/notification";
import {Locale} from "../translations";
import {
    getFirstPathnameSegment,
    getSubDomain,
} from "./helpers/getFirstPathnameSegment";

// Typographic constants
export const THIN_SPACE = "\u2009";
export const NON_BREAKING_SPACE = "\u00A0";

export const DEFAULT_LOCALE: Locale = "de";
export const NOTIFICATION_DEFAULT_OPTIONS: ConfigProps = {
    duration: 0,
};

export const ORGANIZATION_ID = getFirstPathnameSegment();
export const SUBDOMAIN = getSubDomain();
// export const STAGE = getSubDomain() === "stage"; // testing

// The env object contains constants that are only known during execution.
// Using an object so that it can be easily mocked from a test.
const env = {};

export default env;
