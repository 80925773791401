import React, {FC, memo, useEffect, useRef} from "react";
import {AutoComplete, Button, Input} from "antd";
import {BaseOptionType, DefaultOptionType} from "antd/lib/select";
import {ArrayDataOptions, FilterOption, Maybe} from "../../generated/graphql";
import i18n from "../../services/i18n";

const {Search} = Input;

export type InputType =
    | "date"
    | "search"
    | "select"
    | "boolean"
    | "number"
    | "autoComplete"
    | undefined;

export type AutoCompleteOptions = Array<BaseOptionType | DefaultOptionType>;

type FilterDropdownSearchProps = {
    dataIndex: string;
    options?: Maybe<ArrayDataOptions>;
    setOptions?: (options: ArrayDataOptions) => void;
    setDropdownOpen?: (dropdownOpen: string | null) => void;
    setNewFilterOption: (column: string, value?: string) => void;
    resetSelectedFilter: (column: string) => void;
    autoCompleteOptions?: AutoCompleteOptions;
};

const FilterDropdownSearch: FC<FilterDropdownSearchProps> = ({
    dataIndex,
    options,
    setOptions,
    setDropdownOpen,
    setNewFilterOption,
    resetSelectedFilter,
    autoCompleteOptions,
}) => {
    const [selectedKeys, setSelectedKeys] = React.useState<Array<string>>([]);

    // const panelWidth = 180;
    const panelWidth = 220;

    const placeholder = (dataIndex: string) => {
        return `${i18n.views.TableColumnTitles[dataIndex]} suchen`;
    };

    const currentFilteredValue = options?.filter?.find(
        (filter) => filter.column === dataIndex,
    )?.values[0];

    // console.log("currentFilteredValue", currentFilteredValue);

    const selectedValue = ({
        value,
        selectedKey,
        currentValue,
    }: {
        value?: string;
        selectedKey?: string;
        currentValue?: string;
    }) => {
        if (selectedKey === undefined && currentValue !== undefined) {
            return currentValue;
        }

        return selectedKey ?? value;
    };

    return (
        <div /* ref={ref} */ style={{padding: 8, width: panelWidth}}>
            {/* OLD VARIANT */}
            {/* <div
                style={{
                    marginBottom: 8,
                    display: "block",
                }}
            >
                {autoCompleteOptions === undefined ? (
                    (() => {
                        return (
                            <Input
                                // style={{width: `${panelWidth}px - 8px}`}}
                                allowClear
                                autoFocus
                                placeholder={placeholder(dataIndex)}
                                onPressEnter={() => {
                                    setNewFilterOption(
                                        dataIndex,
                                        selectedKeys[0],
                                    );
                                }}
                                onChange={(e) =>
                                    setSelectedKeys(
                                        e.target.value
                                            ? [e.target.value]
                                            : [""],
                                    )
                                }
                                // value={currentFilteredValue ?? undefined}
                                value={selectedValue({
                                    // value: currentFilteredValue,
                                    selectedKey: selectedKeys[0],
                                    currentValue: currentFilteredValue,
                                })}
                            />
                        );
                    })()
                ) : (
                    <AutoComplete
                        style={{width: 168}}
                        allowClear
                        autoFocus
                        placeholder={placeholder(dataIndex)}
                        options={autoCompleteOptions}
                        onChange={(e) => setSelectedKeys(e ? [e] : [""])}
                        value={selectedValue({
                            // value: e?.toString(),
                            selectedKey: selectedKeys[0],
                            currentValue: currentFilteredValue,
                        })}
                    />
                )}
            </div> */}
            {/* OLD VARIANT */}
            <div
                style={{
                    marginBottom: 8,
                    display: "block",
                }}
            >
                {autoCompleteOptions === undefined ? (
                    (() => {
                        return (
                            <Input
                                allowClear
                                autoFocus
                                placeholder={placeholder(dataIndex)}
                                onPressEnter={() => {
                                    setNewFilterOption(
                                        dataIndex,
                                        selectedKeys[0],
                                    );
                                }}
                                onChange={(e) =>
                                    setSelectedKeys(
                                        e.target.value
                                            ? [e.target.value]
                                            : [""],
                                    )
                                }
                                value={selectedValue({
                                    selectedKey: selectedKeys[0],
                                    currentValue: currentFilteredValue,
                                })}
                            />
                        );
                    })()
                ) : (
                    <AutoComplete
                        style={{width: panelWidth - 15}}
                        options={autoCompleteOptions}
                        onChange={(e) => setSelectedKeys(e ? [e] : [""])}
                        value={selectedValue({
                            // value: e?.toString(),
                            selectedKey: selectedKeys[0],
                            currentValue: currentFilteredValue,
                        })}
                        filterOption={(inputValue, option) =>
                            option !== undefined &&
                            option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect={(e) => {
                            setSelectedKeys([e.toString()]);
                            setNewFilterOption(dataIndex, e.toString());
                        }}
                        showSearch
                    >
                        <Input.Search
                            placeholder={placeholder(dataIndex)}
                            enterButton
                            allowClear
                            onSearch={(e) => {
                                console.log("onSearch", e);
                                setNewFilterOption(dataIndex, e);
                            }}
                        />
                    </AutoComplete>
                )}
            </div>
            <Button
                type="primary"
                onClick={() => {
                    setNewFilterOption(dataIndex, selectedKeys[0]);
                }}
                size="small"
                style={{width: "100%"}}
            >
                {"Suchen"}
            </Button>
            <Button
                style={{width: "100%", marginTop: "5px"}}
                danger
                size="small"
                // disabled={!selectedKeys.length}
                disabled={!currentFilteredValue}
                onClick={() => {
                    resetSelectedFilter(dataIndex);
                }}
            >
                {"Filter entfernen"}
            </Button>
        </div>
    );
};

export default memo(FilterDropdownSearch);
