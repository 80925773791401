import {
    GetCompanyInfoQuery,
    GetOwnerEmailQuery,
    GetReceiversByCourseIdsQuery,
    GetTemplatesQuery,
    MailDataType,
    SubscriptionType,
    TemplateType,
} from "../../../generated/graphql";
import {Receiver} from "./ReceiversBar";

type TemplatesWorkingType = GetTemplatesQuery["templates"];
type Filter = "custom" | "default" | "noTemplate";

export const filterTemplates = (
    templates: TemplatesWorkingType,
    mailDataType: MailDataType,
    filter: Filter,
): TemplatesWorkingType => {
    const bookingCompatible = [
        MailDataType.Course,
        MailDataType.Booking,
        MailDataType.ScheduledPayment,
    ];

    const filteredTemplates = templates.filter((template) => {
        return (
            (template.mailDataType === mailDataType ||
                bookingCompatible.includes(mailDataType)) &&
            template.templateType !== TemplateType.NoTemplate
        );
    });

    const foundTemplates = filteredTemplates.filter((template) => {
        if (filter === "noTemplate") {
            return template.templateType === TemplateType.NoTemplate;
        }
        if (filter === "custom") {
            return template.isDefault === false;
        }

        return template.isDefault === true;
    });

    if (filter === "custom" && foundTemplates) {
        const noTemplate = templates.find((template) => {
            return template.templateType === TemplateType.NoTemplate;
        });

        if (noTemplate) {
            foundTemplates.unshift(noTemplate);
        }
    }

    return foundTemplates;
};

const replaceEmailWithOwnerEmail = (
    uniqueReceivers: Array<Receiver>,
    ownerMailData?: GetOwnerEmailQuery,
) => {
    const ownerMail: GetOwnerEmailQuery["ownerEmail"] | string =
        ownerMailData?.ownerEmail.ownerEmail ?? "";

    return uniqueReceivers.map((receiver) => {
        if (receiver.email === ownerMail) {
            return {
                ...receiver,
                disabled: true,
            };
        }

        return receiver;
    });
};

export const getUniqueReceiversWithMail = ({
    receiversOfCourseData,
    companyInfoData,
    ownerMailData,
}: {
    // receivers?: GetReceiversByCourseIdsQuery["getBookersByCourseIds"];
    receiversOfCourseData?: GetReceiversByCourseIdsQuery;
    companyInfoData?: GetCompanyInfoQuery;
    ownerMailData?: GetOwnerEmailQuery;
}) => {
    const uniqueReceivers: Array<Receiver> = [];
    const receivers = receiversOfCourseData?.getBookersByCourseIds;

    receivers?.forEach((receiver) => {
        if (receiver.email) {
            uniqueReceivers.push({
                id: receiver.id,
                email: receiver.email,
                firstname: receiver.firstname,
                lastname: receiver.lastname,
            });
        }
    });

    const companyInfo: GetCompanyInfoQuery["companyInfo"] | undefined =
        companyInfoData?.companyInfo;

    const subscriptionType =
        SubscriptionType[
            companyInfo?.subscriptionType ?? SubscriptionType.TestSubscription
        ];

    const isDemoAccount =
        subscriptionType === SubscriptionType.TestSubscription;

    if (isDemoAccount) {
        const ownerMailReceivers = replaceEmailWithOwnerEmail(
            uniqueReceivers,
            ownerMailData,
        );

        return ownerMailReceivers;
    }

    return uniqueReceivers;
};
