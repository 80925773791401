import {Checkbox, Space, Tooltip} from "antd";
import React, {memo, FC} from "react";
import {blue} from "@ant-design/colors";
import {InfoCircleOutlined} from "@ant-design/icons";
import {marginXs} from "../../../styles/layout";

export type BookingTypeOptions = {
    notifyBookings: boolean;
    notifyWaitlist: boolean;
    notifyCancelations: boolean;
};

type NotifySelection =
    | "notifyBookings"
    | "notifyWaitlist"
    | "notifyCancelations";

type SmsCourseBookingTypeSelectorProps = {
    bookingTypeOptions: BookingTypeOptions;
    setBookingTypeOptions: (options: BookingTypeOptions) => void;
    style?: React.CSSProperties;
};

const SmsCourseBookingTypeSelector: FC<SmsCourseBookingTypeSelectorProps> = ({
    bookingTypeOptions,
    setBookingTypeOptions,
    style,
}) => {
    const options = [
        {label: "Buchungen", value: "notifyBookings"},
        {label: "Warteliste", value: "notifyWaitlist"},
        {label: "Stornierungen", value: "notifyCancelations"},
    ];

    const toNotify = (): Array<NotifySelection> => {
        const result: Array<NotifySelection> = [];

        if (bookingTypeOptions.notifyBookings) {
            result.push("notifyBookings");
        }
        if (bookingTypeOptions.notifyWaitlist) {
            result.push("notifyWaitlist");
        }
        if (bookingTypeOptions.notifyCancelations) {
            result.push("notifyCancelations");
        }

        return result;
    };

    const handleBookingTypeChange = (type: keyof BookingTypeOptions) => {
        setBookingTypeOptions({
            ...bookingTypeOptions,
            [type]: !bookingTypeOptions[type],
        });
    };

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: 5,
                marginTop: `calc(${marginXs} * -1)`,
                borderBottom: "1px solid lightgrey",
                marginBottom: 5,
                ...style,
            }}
        >
            <Space
                direction="horizontal"
                style={{
                    borderRadius: 5,
                    paddingTop: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                }}
            >
                <Checkbox.Group value={toNotify()}>
                    {options.map((option) => {
                        return (
                            <Checkbox
                                key={option.label}
                                value={option.value}
                                checked={toNotify().includes(
                                    option.value as NotifySelection,
                                )}
                                onChange={() => {
                                    handleBookingTypeChange(
                                        option.value as keyof BookingTypeOptions,
                                    );
                                }}
                                // onChange={(e) => {
                                //     onChange(e.target.value, e.target.checked);
                                // }}
                            >
                                {option.label}
                            </Checkbox>
                        );
                    })}
                </Checkbox.Group>
                <Tooltip
                    title="Hier kannst einstellen, welche Buchungen benachrichtigt werden sollen."
                    trigger={["click"]}
                    placement="right"
                >
                    <InfoCircleOutlined style={{color: blue[5]}} />
                </Tooltip>
            </Space>
        </div>
    );
};

export default memo(SmsCourseBookingTypeSelector);
