import {DownloadOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {ColumnProps} from "antd/lib/table";
import moment from "moment";
import React from "react";
import {GetExportedSepaXmlsQuery} from "../../generated/graphql";
import {
    renderDate,
    RenderUserName,
} from "../../views/TableColumnRenderers/TableColumnRenderers";
import AccompanyingDocumentPdf from "../payments/CreateXmlModal/AccompanyingDocumentPdf";
import {convertXmlData} from "../payments/CreateXmlModal/convertXmlData";
import {XmlResponse} from "../payments/CreateXmlModal/CreateXmlModal";
import {CreateXmlModalPreviewProps} from "../payments/CreateXmlModal/CreateXmlModalPreview";

export type TableType = GetExportedSepaXmlsQuery["exportedSepaXmls"]["items"][0];

const downLoadXmlFile = ({xmlData}: {xmlData?: XmlResponse}) => {
    if (!xmlData?.xml) {
        return;
    }

    const blob = new Blob([xmlData.xml], {
        type: "application/xml; charset=utf-8",
    });

    // ----------------------------------------------------------------------------------------------

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    const today = moment().format("YYYY-MM-DD_HHmm");

    link.href = url;
    link.setAttribute("download", `SEPA_${today}.xml`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
};

export const exportedSpaXmlColumns: Array<ColumnProps<TableType>> = [
    // {
    //     title: "ID",
    //     dataIndex: "id",
    //     key: "id",
    // },
    {
        title: "Erstellungsdatum",
        dataIndex: "createDate",
        key: "createDate",
        render: (text, record) => renderDate(record.createDate, true),
    },
    {
        title: "Erstellt von",
        dataIndex: "createdByUserId",
        key: "createdByUserId",
        // eslint-disable-next-line react/display-name
        render: (_, record) => {
            if (!record.createdByUserId) {
                return "no user found";
            }

            return <RenderUserName userId={record.createdByUserId} />;
        },
    },
    {
        title: "Dateiname",
        dataIndex: "fileName",
        key: "fileName",
    },
    {
        title: "Begleitzettel",
        dataIndex: "previewAccompnying",
        key: "previewAccompnying",
        // eslint-disable-next-line react/display-name
        render: (_, record) => {
            if (!record.object || !record.xml) {
                return null;
            }

            const fileName = record.fileName
                ? `${record.fileName}_Begleitzettel.pdf`
                : "Begleitzettel.pdf";

            const xmlData: XmlResponse = {
                object: record.object,
                xml: record.xml,
            };

            const convertedXmlData: CreateXmlModalPreviewProps = convertXmlData(
                {xmlData},
            );

            return (
                <AccompanyingDocumentPdf
                    fileName={fileName}
                    props={convertedXmlData}
                />
            );
        },
    },
    {
        title: "Aktion",
        dataIndex: "action",
        key: "action",
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
            const {xml, object} = record;

            if (!xml || !object) {
                console.log("No xml or object found");
                console.log("xml", xml);
                console.log("object", object);

                return null;
            }

            const xmlData: XmlResponse = {xml, object};

            return (
                <Button
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => downLoadXmlFile({xmlData})}
                >
                    {"XML-Datei"}
                </Button>
            );
        },
    },
];
