import React, {FC, memo} from "react";
import {Card} from "antd";
import classnames from "classnames";
import {InfoCircleOutlined} from "@ant-design/icons";
import css from "./AlertCard.less";
import {colorDarkGrey} from "../../styles/colors";

type AlertCardProps = {
    title?: string;
    description?: string;
    type?: "INFO" | "DANGER" | "WARNING";
    transparent?: boolean;
    style?: React.CSSProperties;
    showIcon?: boolean;
    titleStyle?: React.CSSProperties;
};

const AlertCard: FC<AlertCardProps> = ({
    title,
    description = "",
    type,
    transparent = true,
    style,
    showIcon = true,
    titleStyle,
}) => {
    const fontStyle = {color: colorDarkGrey, ...titleStyle};
    const actualStyle = {...fontStyle, ...style};

    switch (type) {
        case "INFO":
            return (
                <Card
                    style={actualStyle}
                    className={
                        transparent
                            ? classnames(css.wrapperTransparent, css.infoCard)
                            : classnames(css.wrapperCard, css.infoCard)
                    }
                >
                    {showIcon && (
                        <InfoCircleOutlined className={classnames(css.icon)} />
                    )}
                    <h3 style={fontStyle}>{title}</h3>
                    <p>{description}</p>
                </Card>
            );
        case "DANGER":
            return (
                <Card
                    style={actualStyle}
                    className={
                        transparent
                            ? classnames(css.wrapperTransparent, css.dangerCard)
                            : classnames(css.wrapperCard, css.dangerCard)
                    }
                >
                    {showIcon && (
                        <InfoCircleOutlined className={classnames(css.icon)} />
                    )}
                    <h3 style={fontStyle}>{title}</h3>
                    <p>{description}</p>
                </Card>
            );
        case "WARNING":
            // TODO this is a placeholder
            return (
                <Card style={actualStyle}>
                    {showIcon && (
                        <InfoCircleOutlined
                            style={{
                                fontSize: "1.5em",
                                color: "#52c419",
                            }}
                        />
                    )}
                    {title}
                </Card>
            );
        default:
            return (
                <Card style={actualStyle}>
                    {showIcon && (
                        <InfoCircleOutlined
                            style={{
                                fontSize: "1.5em",
                                color: "#52c419",
                            }}
                        />
                    )}
                    {title}
                </Card>
            );
    }
};

export default memo(AlertCard);
