import React, {FC, ReactNode, memo} from "react";
import {Layout} from "antd";
import css from "./LayoutWithoutNavigation.less";

type LayoutWithoutNavigationProps = {
    main?: ReactNode;
    "data-testId"?: string;
};

const LayoutWithoutNavigation: FC<LayoutWithoutNavigationProps> = ({
    main,
    ...props
}) => {
    return (
        <Layout className={css.root} {...props}>
            <Layout className={css.contentArea}>{main}</Layout>
        </Layout>
    );
};

export default memo(LayoutWithoutNavigation);
