import React, {memo, useState} from "react";
import {Button, message, Modal, Space} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import moment, {Moment} from "moment";
import {renderAmount} from "../../helpers/renderAmount";
import {useCurrency} from "../../helpers/currencyHelper";
import {
    Currency,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    TablesEnum,
    useGetUnpaidScheduledPaymentsZeroValuesQuery,
    useSetMultipleScheduledPaymentsPaidMutation,
    // useUpdateOneByOneByIdsMutation,
} from "../../generated/graphql";
import CalendarPopover from "../../views/CalendarPopover/CalendarPopover";
import i18n from "../../services/i18n";
import PaymentsList from "./PaymentsList";
import {TableType} from "./PaymentsListColumns";

type SubmenuProps = {refetch: () => void};

const Submenu: React.FC<SubmenuProps> = ({refetch}) => {
    const currency = useCurrency();
    const [selection, setSelection] = useState<Array<string>>([]);
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const numWithCurrency = renderAmount({
        amount: 0,
        currency: currency.short as Currency,
    });

    const {
        data,
        loading,
        error,
        refetch: refetchZeroValues,
    } = useGetUnpaidScheduledPaymentsZeroValuesQuery({
        skip: !submenuOpen,
        fetchPolicy: "network-only",
    });

    const foundPayments = data?.unpaidScheduledPaymentsZeroValues ?? [];

    React.useEffect(() => {
        if (modalOpen) {
            refetchZeroValues();
        }
    }, [modalOpen, refetchZeroValues]);

    React.useEffect(() => {
        const foundPaymentIds = foundPayments.map((payment) => payment.id);

        console.log("foundPaymentIds: ", foundPaymentIds);
        if (foundPayments.length > 0) {
            setSelection(foundPayments.map((payment) => payment.id));
        }
    }, [foundPayments]);

    const [
        updateManyScheduledPaymentMutation,
    ] = useSetMultipleScheduledPaymentsPaidMutation();

    const setAsPaid = async (date: Moment | null) => {
        const hide = message.loading(
            i18n.containers.payments.OpenPaymentsList.operate(),
        );

        try {
            await updateManyScheduledPaymentMutation({
                variables: {
                    paymentIds: selection,
                    paymentDone: date
                        ? date.toISOString()
                        : moment().toISOString(),
                },
                refetchQueries: [
                    "GetPaidScheduledPayments",
                    {query: GetPaidScheduledPaymentsDocument},
                    "GetUnpaidScheduledPayments",
                    {query: GetUnpaidScheduledPaymentsDocument},
                ],
            });

            message.success(
                i18n.containers.payments.OpenPaymentsList.markedAsPaidFinished(),
                2,
            );
        } catch (error) {
            console.log("updateManyScheduledPaymentMutation: ", error);

            message.error("Fehler beim Setzen der Posten auf bezahlt", 2);
        } finally {
            refetch();
            hide();
            setSelection([]);
        }
    };

    const renderContent = () => {
        if (loading) {
            return <div>{"Loading..."}</div>;
        }

        if (error) {
            return <div>{"Error..."}</div>;
        }

        if (foundPayments.length === 0) {
            return (
                <>
                    <div>{`Es wurden keine offene Posten mit ${numWithCurrency} gefunden`}</div>
                </>
            );
        }

        const tableData = {
            dataSource: foundPayments as TableType,
        };

        const renderTable = () => {
            return (
                <PaymentsList
                    tablesEnum={TablesEnum.ZeroValuePayments}
                    tableData={tableData}
                    columns={["id"]}
                    toolBarButtons={[]}
                    tableTitle={`Alle Posten mit ${numWithCurrency}`}
                    tableDefaultPageSize={foundPayments.length}
                    defaultColumns={[
                        "bookingNumber",
                        "courseType",
                        "courseNumber",
                        "booker",
                        "attendee",
                        "invoice",
                    ]}
                    hiddenColumns={["id", "setPaidAction", "setUnPaidAction"]}
                    setOptions={() => {}}
                    notSelectable
                />
            );
        };

        return <>{renderTable()}</>;
    };

    const footer = () => {
        const onOk = (date: Moment | null) => {
            setAsPaid(date)
                .catch((err) => console.log(err))
                .then(() => setSelection([]))
                .then(() => setModalOpen(false));
        };

        return (
            <Space>
                <Button
                    type="default"
                    danger
                    onClick={() => {
                        setModalOpen(false);
                    }}
                >
                    {"Abbrechen"}
                </Button>

                <CalendarPopover
                    title={i18n.containers.payments.OpenPaymentsList.paymentDate()}
                    buttonLeftText={i18n.containers.payments.OpenPaymentsList.changePaymentPopover.cancel()}
                    buttonRightClick={(date) => onOk(date)}
                    buttonRightText={i18n.containers.payments.OpenPaymentsList.accept()}
                >
                    <Button type="primary">
                        {`Alle ${foundPayments.length} Posten auf bezahlt setzen`}
                    </Button>
                </CalendarPopover>
            </Space>
        );
    };

    if (submenuOpen) {
        return (
            <Space>
                <Modal
                    open={modalOpen}
                    onCancel={() => {
                        setSelection([]);
                        setModalOpen(false);
                    }}
                    width={1000}
                    footer={footer()}
                    style={{top: 20}}
                    destroyOnClose
                >
                    <>{renderContent()}</>
                </Modal>
                <Button
                    size="small"
                    key="test"
                    onClick={() => setModalOpen(true)}
                >
                    {`Alle ${numWithCurrency} Posten auf bezahlt setzen`}
                </Button>
                <MoreOutlined onClick={() => setSubmenuOpen(!submenuOpen)} />
            </Space>
        );
    }

    return <MoreOutlined onClick={() => setSubmenuOpen(!submenuOpen)} />;
};

export default memo(Submenu);
