import {blue} from "@ant-design/colors";
import {MenuOutlined} from "@ant-design/icons";
import {Button, Dropdown, MenuProps} from "antd";
import React, {FC, memo} from "react";
import {SendBlankMail, SendBookingConfirmation, SendInvoice} from "./MenuItems";
import {GetBookingByIdQuery} from "../../../generated/graphql";

type SendMailMenuProps = {booking?: GetBookingByIdQuery["bookingById"]};

const SendMailMenu: FC<SendMailMenuProps> = ({booking}) => {
    if (!booking) return null;

    const booker = `${booking?.attendee?.booker?.firstname} ${booking?.attendee?.booker?.lastname} (${booking?.attendee?.booker?.email})`;
    const isReversal = Boolean(booking?.reversalInvoice?.id);

    const menu: MenuProps = {
        items: [
            {
                label: <SendInvoice booking={booking} booker={booker} />,
                key: 0,
            },
            {
                label: (
                    <SendInvoice
                        booking={booking}
                        booker={booker}
                        isReversal={isReversal}
                    />
                ),
                key: 1,
            },
            {
                label: (
                    <SendBookingConfirmation
                        booking={booking}
                        booker={booker}
                    />
                ),
                key: 2,
            },
            {
                label: <SendBlankMail booking={booking} booker={booker} />,
                key: 3,
            },
        ],
    };

    if (!isReversal) menu?.items?.splice(1, 1);

    return (
        <Dropdown menu={{items: menu.items}} trigger={["click"]}>
            <Button size="small" style={{color: blue[5]}}>
                {"Bucher benachrichtigen"}
                <MenuOutlined />
            </Button>
        </Dropdown>
    );
};

export default memo(SendMailMenu);
