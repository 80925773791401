import React, {FC, memo} from "react";
import {Maybe} from "type-graphql";
import {Button, Card, Form, InputNumber, Space} from "antd";
import {ArrayDataOptions, FilterOption} from "../../generated/graphql";
import i18n from "../../services/i18n";
import LayoutForm from "../../views/LayoutForm/LayoutForm";

type FilterDropdownNumberProps = {
    dataIndex: string;
    options?: Maybe<ArrayDataOptions>;
    setOptions?: (options: ArrayDataOptions) => void;
    setDropdownOpen?: (dropdownOpen: string | null) => void;
    selectOptions?: Array<{
        label: string;
        value: string;
    }>;
};

const FilterDropdownNumber: FC<FilterDropdownNumberProps> = ({
    dataIndex,
    options,
    setOptions,
    setDropdownOpen,
    selectOptions,
}) => {
    const [selectedKeys, setSelectedKeys] = React.useState<Array<string>>([]);
    const [selectedPreset, setSelectedPreset] = React.useState<string>();

    const panelWidth = 330;

    // DEFINE CASES ------------------------------------------------------------
    const cases = [
        {
            index: 0,
            dataIndex: "indexOfLessonInCourse",
            title: i18n.views.TableColumnTitles.indexOfLessonInCourseLong(),
            presets: [
                {
                    key: "0",
                    dataIndex: "all",
                    text: "Alle",
                    values: ["0", "100"],
                    onClick: () => setSelectedKeys(["0", "100"]),
                },
                {
                    key: "1",
                    dataIndex: "firstCourseLesson",
                    text: "Erste Kurseinheit",
                    values: ["0", "0"],
                    onClick: () => setSelectedKeys(["0", "0"]),
                },
            ],
        },
    ];

    const caseDataIndexes = cases.map((el) => el.dataIndex);

    // console.log("casesDataIndexes", casesDataIndexes);

    // -------------------------------------------------------------------------

    if (dataIndex === "credit") {
        const presets = [
            {
                key: "0",
                text: "alle ohne Gutschrift",
                onClick: () => setSelectedKeys(["0", "0"]),
            },
            {
                key: "1",
                text: "alle mit Gutschrift",
                onClick: () => setSelectedKeys(["1", "10000"]),
            },
        ];

        const setNewFilterOption = (column: string, values?: Array<string>) => {
            if (!values || values.length === 0) return;

            // if dataIndex already exists in filter replace it
            if (
                options?.filter?.find((filter) => filter.column === dataIndex)
            ) {
                const updatedFilters = options.filter.map((filter) => {
                    if (filter.column === dataIndex) {
                        console.log("values", values);

                        return {
                            ...filter,
                            values,
                        };
                    }

                    return filter;
                });
                const updatedOptions = {
                    ...options,
                    filter: updatedFilters,
                };

                console.log("updatedOptions", updatedOptions);

                setOptions?.(updatedOptions);
                setDropdownOpen?.(null);

                return;
            }

            const newFilter: FilterOption = {
                column,
                values: [...values /* , timestamp */],
                table: "bookings",
            };

            const updatedOptions = {
                ...options,
                filter: [...(options?.filter ?? []), newFilter],
            };

            setOptions?.(updatedOptions);
            setDropdownOpen?.(null);
        };

        const resetSelectedFilter = (column: string) => {
            const updatedFilters = options?.filter?.filter((el) => {
                return el.column !== column;
            });
            const updatedOptions = {
                ...options,
                filter: updatedFilters,
            };

            setOptions?.(updatedOptions);
            setDropdownOpen?.(null);
        };

        const handleChange = (selectedKey: React.Key | null, field: 0 | 1) => {
            console.log("selectedKey:", selectedKey, "field:", field);

            const updatedSelectedKeys = [...selectedKeys];

            if (field === 0) {
                if (selectedKey === null) {
                    setTimeout(() => {
                        updatedSelectedKeys[0] = "0";
                    }, 5);
                } else {
                    updatedSelectedKeys[0] = selectedKey.toString();
                }
            }

            if (field === 1) {
                if (selectedKey === null) {
                    setTimeout(() => {
                        updatedSelectedKeys[1] = "1000";
                    }, 5);
                } else {
                    updatedSelectedKeys[1] = selectedKey.toString();
                }
            }
            setSelectedKeys(updatedSelectedKeys);
        };

        const renderNumberPicker = () => {
            return (
                <LayoutForm columns={1}>
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginBottom: 8,
                        }}
                    >
                        <Form.Item label="von" style={{marginBottom: 0}}>
                            <InputNumber
                                size="small"
                                style={{
                                    width: "100%",
                                }}
                                value={Number(selectedKeys[0])}
                                max={Number(selectedKeys[1])}
                                onChange={(e) =>
                                    handleChange((e as unknown) as React.Key, 0)
                                }
                            />
                        </Form.Item>
                        <Form.Item label="bis" style={{marginBottom: 0}}>
                            <InputNumber
                                size="small"
                                style={{
                                    width: "100%",
                                }}
                                value={Number(selectedKeys[1])}
                                min={Number(selectedKeys[0])}
                                onChange={(e) =>
                                    handleChange((e as unknown) as React.Key, 1)
                                }
                            />
                        </Form.Item>
                    </Space>
                </LayoutForm>
            );
        };

        return (
            <Card title="Gutschriften filtern" style={{width: panelWidth}}>
                {renderNumberPicker()}
                <div
                    style={{
                        width: `calc(${panelWidth}px - 20px)`,
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "10px",
                        marginBottom: "10px",
                    }}
                >
                    {presets.map((preset) => (
                        <Button
                            size="small"
                            key={preset.key}
                            type={
                                (preset.key === "0" &&
                                    selectedKeys[0] === "0" &&
                                    selectedKeys[1] === "0") ||
                                (preset.key === "1" &&
                                    selectedKeys[0] === "1" &&
                                    selectedKeys[1] === "10000")
                                    ? "primary"
                                    : "default"
                            }
                            onClick={() => {
                                if (preset.key === "0") {
                                    setSelectedKeys(["0", "0"]);
                                    // confirm();
                                } else {
                                    setSelectedKeys(["1", "10000"]);
                                    // confirm();
                                }
                            }}
                            style={{gridColumn: "span 1"}}
                        >
                            {preset.text}
                        </Button>
                    ))}
                </div>
                <Space direction="vertical" style={{width: "100%"}}>
                    <Button
                        size="small"
                        type="primary"
                        block
                        onClick={() => {
                            setNewFilterOption(dataIndex, selectedKeys);
                        }}
                    >
                        {"Übernehmen"}
                    </Button>
                    <Button
                        size="small"
                        block
                        danger
                        onClick={() => {
                            resetSelectedFilter(dataIndex);
                        }}
                    >
                        {"Filter entfernen"}
                    </Button>
                </Space>
            </Card>
        );
    } else if (caseDataIndexes.includes(dataIndex)) {
        const presets = [
            {
                key: "0",
                text: "alle",
                onClick: () => setSelectedKeys(["0", "0"]),
            },
        ];

        const setNewFilterOption = (column: string, values?: Array<string>) => {
            if (!values || values.length === 0) return;

            // if dataIndex already exists in filter replace it
            if (
                options?.filter?.find((filter) => filter.column === dataIndex)
            ) {
                const updatedFilters = options.filter.map((filter) => {
                    if (filter.column === dataIndex) {
                        console.log("values", values);

                        return {
                            ...filter,
                            values,
                        };
                    }

                    return filter;
                });
                const updatedOptions = {
                    ...options,
                    filter: updatedFilters,
                };

                console.log("updatedOptions", updatedOptions);

                setOptions?.(updatedOptions);
                setDropdownOpen?.(null);

                return;
            }

            const newFilter: FilterOption = {
                column,
                values: [...values /* , timestamp */],
                table: "bookings",
            };

            const updatedOptions = {
                ...options,
                filter: [...(options?.filter ?? []), newFilter],
            };

            setOptions?.(updatedOptions);
            setDropdownOpen?.(null);
        };

        const resetSelectedFilter = (column: string) => {
            const updatedFilters = options?.filter?.filter((el) => {
                return el.column !== column;
            });
            const updatedOptions = {
                ...options,
                filter: updatedFilters,
            };

            setOptions?.(updatedOptions);
            setDropdownOpen?.(null);
        };

        const handleChange = (selectedKey: React.Key | null, field: 0 | 1) => {
            console.log("selectedKey:", selectedKey, "field:", field);

            const updatedSelectedKeys = [...selectedKeys];

            if (field === 0) {
                if (selectedKey === null) {
                    setTimeout(() => {
                        updatedSelectedKeys[0] = "0";
                    }, 5);
                } else {
                    updatedSelectedKeys[0] = selectedKey.toString();
                }
            }

            if (field === 1) {
                if (selectedKey === null) {
                    setTimeout(() => {
                        updatedSelectedKeys[1] = "100000";
                    }, 5);
                } else {
                    updatedSelectedKeys[1] = selectedKey.toString();
                }
            }
            setSelectedKeys(updatedSelectedKeys);
        };

        const renderNumberPicker = () => {
            return (
                <LayoutForm columns={1}>
                    {dataIndex === "indexOfLessonInCourse" ? (
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginBottom: 8,
                            }}
                        >
                            <Form.Item label="von" style={{marginBottom: 0}}>
                                <InputNumber
                                    size="small"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={Number(selectedKeys[0] + 1)}
                                    max={Number(selectedKeys[1] + 1)}
                                    onChange={(e) =>
                                        handleChange(
                                            (e as unknown) as React.Key,
                                            0,
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="bis" style={{marginBottom: 0}}>
                                <InputNumber
                                    size="small"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={Number(selectedKeys[1] + 1)}
                                    min={Number(selectedKeys[0] + 1)}
                                    onChange={(e) =>
                                        handleChange(
                                            (e as unknown) as React.Key,
                                            1,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Space>
                    ) : (
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginBottom: 8,
                            }}
                        >
                            <Form.Item label="von" style={{marginBottom: 0}}>
                                <InputNumber
                                    size="small"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={Number(selectedKeys[0])}
                                    max={Number(selectedKeys[1])}
                                    onChange={(e) =>
                                        handleChange(
                                            (e as unknown) as React.Key,
                                            0,
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="bis" style={{marginBottom: 0}}>
                                <InputNumber
                                    size="small"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={Number(selectedKeys[1])}
                                    min={Number(selectedKeys[0])}
                                    onChange={(e) =>
                                        handleChange(
                                            (e as unknown) as React.Key,
                                            1,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Space>
                    )}
                </LayoutForm>
            );
        };

        const currentCase = cases.find((el) => el.dataIndex === dataIndex);

        // console.log("currentCase", currentCase);

        return (
            <Card
                title={`${currentCase?.title} filtern`}
                style={{width: panelWidth}}
            >
                {renderNumberPicker()}
                <div
                    style={{
                        width: `calc(${panelWidth}px - 20px)`,
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "10px",
                        marginBottom: "10px",
                    }}
                >
                    {currentCase?.presets.map((preset) => (
                        <Button
                            size="small"
                            key={preset.key}
                            type={
                                selectedPreset === preset.dataIndex
                                    ? "primary"
                                    : "default"
                            }
                            onClick={() => {
                                setSelectedKeys(preset.values);
                                setSelectedPreset(preset.dataIndex);
                            }}
                            style={{gridColumn: "span 1"}}
                        >
                            {preset.text}
                        </Button>
                    ))}
                </div>
                <Space direction="vertical" style={{width: "100%"}}>
                    <Button
                        size="small"
                        type="primary"
                        block
                        onClick={() => {
                            setNewFilterOption(dataIndex, selectedKeys);
                        }}
                    >
                        {"Übernehmen"}
                    </Button>
                    <Button
                        size="small"
                        block
                        danger
                        onClick={() => {
                            resetSelectedFilter(dataIndex);
                        }}
                    >
                        {"Filter entfernen"}
                    </Button>
                </Space>
            </Card>
        );
    }

    return <div>{"Noch nicht implementiert"}</div>;
};

export default memo(FilterDropdownNumber);
