import {ApolloProvider} from "@apollo/react-components";
import {ApolloProvider as ApolloHooksProvider} from "@apollo/react-hooks";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import React, {FC, ReactNode} from "react";
import fetch from "unfetch";
import LocalResolvers from "./LocalResolver";
import LocalSchema from "./LocalSchema.graphql";
import env from "../../../shared/const";
import {ORGANIZATION_ID, SUBDOMAIN} from "../../const";

type ApolloWrapperProps = {
    children: ReactNode;
};

let uri = "http://localhost:3000/graphql";

if (SUBDOMAIN === "stage") {
    uri = "https://api.stage.kursorganizer.com/graphql";
} else if (env.IS_PRODUCTION) {
    uri = "https://api.kursorganizer.com/graphql";
} else if (env.IS_DEVELOPMENT) {
    uri = "http://localhost:3000/graphql";
} else if (env.IS_STAGING) {
    uri = "https://api.stage.kursorganizer.com/graphql";
} else if (env.IS_TESTING) {
    uri = "http://localhost:3000/graphql";
}

const cache = new InMemoryCache({
    // Apollo automatically generates keys for the cache by concatenating `__typename` and `id`.
    // If we disable the typenames, Apollo can not generate proper cache keys, which results in failed automatic
    // cache updates. This can be fixed in our case, since we use UUIDs as our ids, which means we won’t have the
    // same id for different types, which is why we can use the id as the cache key for an entity.
    //
    // The future of __typename is still under discussion at the time of writing. See the issue for more info:
    // https://github.com/apollographql/apollo-feature-requests/issues/6
    addTypename: false,
    // dataIdFromObject: ({id}) => (id === undefined ? null : id),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dataIdFromObject: ({id, __typename}) =>
        __typename && id ? `${__typename}:${id}` : null,
});

// inital data
cache.writeData({
    data: {
        LocalState: {
            createCourse: {
                locationId: null,
            },
            bookAttendees: {
                selectedAttendee: {
                    id: null,
                    firstname: null,
                    bookedCourseIds: null,
                },
                selectedCourseId: null,
            },
        },
    },
});

export const apolloClient = new ApolloClient({
    link: createHttpLink({
        fetch,
        uri,
        headers: {
            "x-ko-organization-id": ORGANIZATION_ID,
            // "CSRF-Token": document
            //     .querySelector('meta[name="csrf-token"]')
            //     ?.getAttribute("content"),
        },
        credentials: "include",
    }),
    cache,
    typeDefs: LocalSchema,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolvers: LocalResolvers as any,
});

export const URIContext = React.createContext("");

const ApolloWrapper: FC<ApolloWrapperProps> = ({children}) => {
    return (
        <URIContext.Provider value={uri}>
            <ApolloProvider client={apolloClient}>
                <ApolloHooksProvider client={apolloClient}>
                    {children}
                </ApolloHooksProvider>
            </ApolloProvider>
        </URIContext.Provider>
    );
};

export default ApolloWrapper;
