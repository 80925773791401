// import {PaginationConfig} from "antd/lib/pagination";
import {TablePaginationConfig} from "antd/lib/table";

export const getPagination = ({
    pagination,
    defaultSize,
}: {
    pagination: TablePaginationConfig;
    defaultSize: number;
}) => {
    const {current, pageSize} = pagination;

    if (current && pageSize) {
        const offset = (current - 1) * pageSize;
        const limit = pageSize;

        return {limit, offset};
    }

    return {limit: defaultSize, offset: 0};
};
