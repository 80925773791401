import classNames from "classnames";
import React, {FC, memo, useState} from "react";
import {Button, Modal} from "antd";
import {blue} from "@ant-design/colors";
import {
    GetBookingByIdDocument,
    GetBookingByIdQuery,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    PaymentMethod,
    useUpdateBookingPaymentMethodMutation,
} from "../../generated/graphql";
import {getPaymentMethodType} from "../../helpers/getPaymentMethodType";
import i18n from "../../services/i18n";
import PanelCard from "../PanelCard/PanelCard";
import PaymentMethodBadge from "../PaymentMethodBadge/PaymentMethodBadge";
import {
    RenderAmount,
    renderPaymentType,
} from "../TableColumnRenderers/TableColumnRenderers";
import css from "./PanelCardPaymentData.less";
import PaymentMethodTable from "../PaymentMethodTable/PaymentMethodTable";
import BookingAddPaymentMethod from "../BookingAddPaymentMethod/BookingAddPaymentMethod";
import {KnownError, handleError} from "../../errors/handleErrors";

type PanelCardPaymentDataProps = {
    booking: GetBookingByIdQuery["bookingById"];
    refetchScheduledPayments: () => void;
    isCanceled: boolean;
};

const PanelCardPaymentData: FC<PanelCardPaymentDataProps> = ({
    booking,
    refetchScheduledPayments,
    isCanceled,
}) => {
    const paymentMethodType = getPaymentMethodType(booking.paymentMethod ?? {});

    const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false);
    const [addPaymentMethodModalOpen, setAddPaymentMethodModalOpen] = useState(
        false,
    );

    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        // ...style,
    };

    const EditPaymentModal = () => {
        const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
            booking.paymentMethod,
        );

        const [updatePaymentMethod] = useUpdateBookingPaymentMethodMutation({
            variables: {
                bookingId: booking.id,
                paymentMethodId: selectedPaymentMethod?.id ?? "",
            },
            refetchQueries: [
                "GetBookingById",
                {query: GetBookingByIdDocument, variables: {id: booking.id}},
                "GetPaidScheduledPayments",
                {
                    query: GetPaidScheduledPaymentsDocument,
                    variables: {bookingId: booking.id},
                },
                "GetUnpaidScheduledPayments",
                {
                    query: GetUnpaidScheduledPaymentsDocument,
                    variables: {bookingId: booking.id},
                },
            ],
        });

        const paymentMethodsOfBooker =
            booking?.attendee?.booker?.paymentMethods;

        return (
            <Modal
                title="Bezahlmethode bearbeiten"
                open={editPaymentModalOpen}
                onCancel={() => {
                    setEditPaymentModalOpen(false);
                    setAddPaymentMethodModalOpen(false);
                }}
                width={800}
                onOk={async () => {
                    const knownErrors: Array<KnownError> = [
                        {
                            key: "Booking is billed",
                            title: "Alles bezahlt 😎",
                            msg:
                                "Wenn alle Posten einer Buchung bezahlt sind, kann deren Bezahlmethode nicht mehr geändert werden.",
                        },
                    ];

                    try {
                        await updatePaymentMethod().then(() => {
                            refetchScheduledPayments();
                            setAddPaymentMethodModalOpen(false);
                            setEditPaymentModalOpen(false);
                        });
                    } catch (error) {
                        handleError(
                            "transferOrCopyAttendeeToAnotherCoursePlacereservation",
                            error,
                            "modal",
                            knownErrors,
                        );
                    }
                }}
                okText="Bezahlmethode speichern"
                afterClose={() => {
                    console.log("after close");
                }}
                footer={addPaymentMethodModalOpen ? null : undefined}
            >
                {addPaymentMethodModalOpen && booking?.courseId ? (
                    <BookingAddPaymentMethod
                        bookerId={booking.attendee?.booker?.id}
                        courseId={booking.courseId}
                        setSelectedPayment={(selectedMethod: PaymentMethod) =>
                            setSelectedPaymentMethod(selectedMethod)
                        }
                        setModalOpen={(open: boolean) =>
                            setAddPaymentMethodModalOpen(open)
                        }
                        bookingId={booking.id}
                        okButtonText="Bezahlmethode für Bucher anlegen"
                    />
                ) : (
                    <>
                        <PaymentMethodTable
                            paymentMethodId={selectedPaymentMethod?.id}
                            paymentMethods={paymentMethodsOfBooker ?? []}
                            setSelectedPayment={setSelectedPaymentMethod}
                        />

                        <Button
                            size="small"
                            style={{
                                color: blue[5],
                                marginTop: 10,
                            }}
                            block
                            onClick={() => {
                                setAddPaymentMethodModalOpen(
                                    !addPaymentMethodModalOpen,
                                );
                            }}
                        >
                            {i18n.containers.bookings.BookingSummary.buttons.morePaymentMethods()}
                        </Button>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <PanelCard
            title={i18n.containers.invoice.InvoiceDetails.titlePayment()}
            columns={4}
            gapAfter
            extra={
                !isCanceled && (
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => setEditPaymentModalOpen(true)}
                    >
                        {"Bezahlmethode bearbeiten"}
                    </Button>
                )
            }
        >
            <EditPaymentModal />
            <div
                className={classNames(css.withDivider, css.container)}
                style={actualStyle}
            >
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.paymentMethod()}
                </div>
                <div className={css.entry}>
                    {<PaymentMethodBadge type={paymentMethodType} />}
                </div>
            </div>
            {paymentMethodType === "sepaDebit" ? (
                <div
                    className={classNames(css.withDivider, css.container)}
                    style={actualStyle}
                >
                    <div className={css.label}>
                        {i18n.containers.invoice.InvoiceDetails.sepaAccountholder()}
                    </div>
                    <div className={css.entry}>
                        {booking.paymentMethod?.sepaAccountholder}
                    </div>

                    <div className={css.label}>
                        {i18n.containers.invoice.InvoiceDetails.iban()}
                    </div>
                    <div className={css.entry}>
                        {booking.paymentMethod?.iban}
                    </div>
                </div>
            ) : null}
            <div
                className={classNames(css.withDivider, css.container)}
                style={actualStyle}
            >
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.turnus()}
                </div>
                <div className={css.entry}>
                    {renderPaymentType(booking.paymentType)}
                </div>
            </div>
            <div
                className={classNames(css.withDivider, css.container)}
                style={actualStyle}
            >
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.bookingPrice()}
                </div>
                <div className={css.entry}>
                    <RenderAmount
                        amount={booking.grossPrice}
                        style={{fontWeight: 550}}
                    />
                </div>
            </div>
            <div className={classNames(css.container)} style={actualStyle}>
                <div className={css.label}>
                    {i18n.containers.invoice.InvoiceDetails.creditedBalance()}
                </div>
                <div className={css.entry}>
                    <RenderAmount
                        amount={booking.creditedBalance ?? 0}
                        style={{fontWeight: 550}}
                    />
                </div>
            </div>
        </PanelCard>
    );
};

export default memo(PanelCardPaymentData);
