import * as Yup from "yup";
import {
    AddNamedScheduledPaymentInput,
    ScheduledPaymentType,
} from "../../generated/graphql";

const {string, object, number} = Yup;

export const AddNamedScheduledPaymentValidation = object<
    AddNamedScheduledPaymentInput
>().shape({
    paymentAmount: number()
        .required("Betrag ist ein Pflichtfeld")
        // .lessThan(1000000, "Betrag muss kleiner als 1.000.000 sein")
        .positive("Betrag muss positiv sein"),
    // .negative("Betrag muss positiv sein"),
    paymentDue: string().required("Fälligkeitsdatum ist ein Pflichtfeld"),
    title: string()
        .when("type", {
            is: (type) => type === ScheduledPaymentType.Custom,
            then: string().required("Bezeichnung ist ein Pflichtfeld"),
        })
        .notRequired()
        .nullable(),
});
