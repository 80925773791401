import React, {FC, memo} from "react";
import {
    ArrayDataOptions,
    GetTemplatesQuery,
    MailDataType,
    Maybe,
    TablesEnum,
    TemplateType,
} from "../../../generated/graphql";
import css from "./RenderTemplates.less";
import {renderTitle} from "../../../helpers/renderTemplateTitle";
import TemplateCard from "../../../views/TemplateCard/TemplateCard";
import TemplateList from "../MailTemplateEditor/TemplateList";
import {ViewType} from "../EmailTemplates";

type RenderTemplatesProps = {
    mailDataType: MailDataType;
    templates?: GetTemplatesQuery["templates"];
    setSelectedTemplate: (template: GetTemplatesQuery["templates"][0]) => void;
    tablesEnum: TablesEnum;
    options?: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    refetch: () => void;
    viewType: ViewType;
};

const RenderTemplates: FC<RenderTemplatesProps> = ({
    mailDataType,
    templates,
    setSelectedTemplate,
    tablesEnum,
    options,
    setOptions,
    refetch,
    viewType,
}) => {
    // const [viewType, setViewType] = useState<ViewType>("GRID");
    const columns = 6;

    type LayoutFormCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: LayoutFormCSSProperties = {
        "--column-count": columns,
    };

    if (viewType === "LIST") {
        return (
            <TemplateList
                tablesEnum={tablesEnum}
                templates={templates}
                mailDataType={mailDataType}
                options={options}
                setOptions={setOptions}
                refetch={refetch}
                onClick={(template) => setSelectedTemplate(template)}
            />
        );
    }

    return (
        <>
            <div className={css.root} style={actualStyle}>
                <div className={css.gridContainer}>
                    {templates?.map((template) => {
                        return (
                            <TemplateCard
                                key={template.id}
                                style={actualStyle}
                                onClick={() => setSelectedTemplate(template)}
                                title={
                                    template.templateType ===
                                    TemplateType.NoTemplate
                                        ? "Ohne Vorlage"
                                        : template.templateType ===
                                          TemplateType.FreeText
                                        ? template.name ?? "Kein Name"
                                        : renderTitle(
                                              template.templateType as TemplateType,
                                          )
                                }
                                isCustomTemplate={
                                    template.templateType === "FreeText"
                                }
                                mailDataType={
                                    template.mailDataType ?? MailDataType.Booker
                                }
                                noTemplate={
                                    template.templateType ===
                                    TemplateType.NoTemplate
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default memo(RenderTemplates);
