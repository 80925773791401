import {blue} from "@ant-design/colors";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Button, MenuProps, message} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
import React, {FC, memo, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {SentMailsResult} from "../../../../server/mailjet.types";
import {
    TemplateType,
    useSendBookingInvoicesByBookingIdsMutation,
} from "../../../generated/graphql";
import {
    URL_INSTRUCTOR_INVOICE_SETTLEMENT_DETAILS,
    URL_INVOICE_SETTLEMENT_DETAILS,
} from "../../../models/url";
import i18n from "../../../services/i18n";
import DeleteButton from "../../../views/DeleteButton/DeleteButton";
import {
    GET_SETTLED_BOOKINGS,
    GET_UNSETTLED_BOOKINGS,
} from "../../invoice/invoice.graphql";
import MailTemplateEditor from "../../templates/MailTemplateEditor/MailTemplateEditor";
import {TableType} from "../BookingList/BookingListColumns";
import ReverseBookingModal from "../BookingList/ReverseBookingModal";
import {
    GET_BOOKINGS_BY_ATTENDEE_WITH_OPTIONS,
    GET_BOOKINS,
} from "../bookings.graphql";
import css from "./CancelSettledBookingAction.less";
import MoreMenu from "../BookingList/MoreMenu/MoreMenu";
import useHighestRole from "../../../helpers/useHighestRole";
import Roles from "../../../../shared/models/roles";

type CancelSettledBookingActionProps = {
    record: TableType;
    refetch?: () => void;
    style?: React.CSSProperties;
    moreMenuItems?: MenuProps["items"];
};

const CancelSettledBookingAction: FC<CancelSettledBookingActionProps> = ({
    record,
    refetch,
    style,
    // setShowEmailEditor,
    // showEmailEditor,
    moreMenuItems,
}) => {
    const [showEmailEditor, setShowEmailEditor] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<TableType>();

    const highestRole = useHighestRole();
    const isInstructor = highestRole === Roles.Instructor;

    // THIS NEEDS TO BE PROBABLY IN THE COMPONENT ABOVE
    const [
        reverseBookingModalVisible,
        setReverseBookingModalVisible,
    ] = useState(false);

    const history = useHistory();

    const [
        sendMultipleIncoiceMails,
    ] = useSendBookingInvoicesByBookingIdsMutation();

    const showEmailEditorModal = (confirmed: boolean) => {
        setShowEmailEditor(confirmed);
        setReverseBookingModalVisible(false);
    };

    const handleSelection = (record: TableType) => {
        setSelectedRecord(record);
        setReverseBookingModalVisible(true);
    };

    const attendeeId = record?.attendee?.id;

    const refetchQueries = async () => [
        "GetBookinsQuery",
        {query: GET_BOOKINS},
        "GetUnsettledBookings",
        {query: GET_UNSETTLED_BOOKINGS},
        "GetSettledBookings",
        {query: GET_SETTLED_BOOKINGS},
        "GetBookingsByAttendeeIdWithOptions",
        {
            query: GET_BOOKINGS_BY_ATTENDEE_WITH_OPTIONS,
            skip: !record.courseId,
            // variables: {id: record.courseId},
            variables: {id: attendeeId},
        },
    ];

    const handleSendingMultipleMails = async (send: boolean) => {
        if (send) {
            if (!selectedRecord?.id)
                throw Error("Missing booking Ids when sending mails");
            // setSendingMessageState("loading");
            try {
                await sendMultipleIncoiceMails({
                    variables: {
                        bookingIds: [selectedRecord.id],
                        reversal: true,
                    },
                    refetchQueries: await refetchQueries(),
                }).then((res) => {
                    // console.log("sendTemplateMail res::: ", res);
                    const sentMailResult: SentMailsResult =
                        res.data?.sendBookingInvoicesByBookingIds;

                    if (sentMailResult) {
                        const mailCount = sentMailResult.sentMailCount;
                        const emailText =
                            mailCount === 1 ? "E-Mail" : "E-Mails";
                        const receivers = sentMailResult.sentMessages.map(
                            (message) => message.To[0].Email,
                        );
                        const receiverString = receivers.join(", ");

                        message.success({
                            key: "sendMail",
                            content: `${mailCount} ${emailText} erfolgreich versendet. Empfänger: ${receiverString}`,
                        });
                    }
                });
            } catch (error) {
                // setSendingMessageState("error");
                throw new Error(
                    `Error useSendBookingInvoiceMutation: ${error}`,
                );
            }
        }
        setSelectedRecord(undefined);
        setShowEmailEditor(false);
        refetch?.();
    };

    const isCanceled = record.canceled !== null;
    // const isSettled = isDefined(record.invoice) === true;
    const cancelDate = moment(record.canceled).format("DD.MM.YYYY");
    const detailsLink = isInstructor
        ? URL_INSTRUCTOR_INVOICE_SETTLEMENT_DETAILS
        : URL_INVOICE_SETTLEMENT_DETAILS;
    const detailsUrl = detailsLink
        ? detailsLink.replace(":id", record.id)
        : undefined;
    const detailsTestId = "SettledBookingsDetail";
    const detailsState = record;

    return (
        <>
            {selectedRecord && (
                <>
                    <ReverseBookingModal
                        visible={reverseBookingModalVisible}
                        // closeModal={() => setReverseBookingModalVisible(false)}
                        closeModal={() => setReverseBookingModalVisible(false)}
                        confirmAction={(confirmed) => {
                            showEmailEditorModal(confirmed);
                        }}
                        record={selectedRecord}
                        refetch={refetch}
                    />
                    {/* )} */}
                    {/* THIS GETS RERENDERED A FAR TO OFTEN */}
                    {/* <MailTemplateEditor
                        asModal
                        visible={showEmailEditor}
                        setVisibility={(e: boolean) => setShowEmailEditor(e)}
                        templateType={TemplateType.SettledBookingCancellation}
                        useCase="SENDMAIL"
                        confirmAction={async (e) =>
                            handleSendingMultipleMails(e)
                        }
                        receivers={[
                            selectedRecord?.attendee?.booker?.email ?? "",
                        ]}
                    /> */}
                </>
            )}

            <ButtonGroup style={style}>
                {detailsUrl === "/" ? null : (
                    <Button
                        data-testid={detailsTestId}
                        size="small"
                        // style={style}
                        style={{
                            borderColor: blue[5],
                        }}
                    >
                        <Link
                            to={{
                                pathname: detailsUrl,
                                state: {
                                    ...detailsState,
                                    from: {pathname: history.location.pathname},
                                },
                            }}
                        >
                            <InfoCircleOutlined
                                style={{
                                    color: blue[5],
                                }}
                            />
                        </Link>
                    </Button>
                )}
                <DeleteButton
                    size="small"
                    hideIcon={true}
                    data-testid="ReverseBookingButton"
                    disableConfirm
                    disabled={isCanceled}
                    disabledText={i18n.containers.bookings.BookingList.bookingReversal.disabledText(
                        {cancelDate},
                    )}
                    onClickCapture={() => handleSelection(record)}
                    style={style}
                >
                    {i18n.containers.bookings.BookingList.reverseBooking()}
                </DeleteButton>
                {moreMenuItems?.length && (
                    <MoreMenu moreMenuItems={moreMenuItems} />
                )}
            </ButtonGroup>
        </>
    );
};

export default memo(CancelSettledBookingAction);
