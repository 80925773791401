import {ColumnProps} from "antd/lib/table";
import React, {FC, memo, useEffect, useState} from "react";
import {Button, message, Space, Table, Tooltip} from "antd";
import {ExpandableConfig} from "antd/lib/table/interface";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import {
    Booker,
    Booking,
    GetAppSettingsDocument,
    GetAppSettingsQuery,
    GetBookedAttendeesByCourseIdDocument,
    GetBookingByIdDocument,
    GetPaymentMethodsByBookerIdDocument,
    GetPaymentMethodsByBookerIdQuery,
    SepaDebitIntput,
    useAddPaymentMethodMutation,
    useGetAppSettingsQuery,
    useGetPaymentMethodsByBookerIdQuery,
} from "../../generated/graphql";
import {
    getPaymentMethodType,
    PaymentMethodType,
} from "../../helpers/getPaymentMethodType";
import PaymentMethodBadge from "../PaymentMethodBadge/PaymentMethodBadge";
import SepaDebitPaymentMethodModal from "../../containers/customers/paymentMethodForms/SepaDebitPaymentMethodModal";
import i18n from "../../services/i18n";

type BookingAddPaymentMethodProps = {
    bookerId?: Booker["id"];
    courseId?: string;
    setSelectedPayment: Function;
    setModalOpen: Function;
    bookingId?: Booking["id"];
    okButtonText?: string;
};

const BookingAddPaymentMethod: FC<BookingAddPaymentMethodProps> = ({
    bookerId,
    courseId,
    setSelectedPayment,
    setModalOpen,
    bookingId,
    okButtonText,
}) => {
    // GET PAYMENTMETHODS OF COMPANY -----------------------------------------------------------------------------
    type CompanySettings = GetAppSettingsQuery["settings"];
    type CompanyMethods = Pick<
        CompanySettings,
        "cash" | "esr" | "bankTransfer" | "sepaDebit"
    >;

    const [companyPaymentMethods, setCompanyPaymentMethods] = useState<
        CompanyMethods
    >();

    const {data: companySettingsData} = useGetAppSettingsQuery();

    useEffect(() => {
        if (companySettingsData) {
            // setCompanySettings(companySettingsData.settings)

            const companyPamentMethods = {
                cash: companySettingsData.settings.cash,
                esr: companySettingsData.settings.esr,
                bankTransfer: companySettingsData.settings.bankTransfer,
                sepaDebit: companySettingsData.settings.sepaDebit,
            };

            setCompanyPaymentMethods(companyPamentMethods);
        }
    }, [companySettingsData]);

    // GET PAYMENTMETHODS OF BOOKER ------------------------------------------------------------------------------

    const {data: bookerData} = useGetPaymentMethodsByBookerIdQuery({
        skip: !bookerId,
        variables: {bookerId: bookerId ?? ""},
        fetchPolicy: "network-only",
    });

    const bookerPaymentMethods:
        | GetPaymentMethodsByBookerIdQuery["getPaymentMethodsByBookerId"]
        | undefined = bookerData?.getPaymentMethodsByBookerId;

    const bookerPaymentMethodTypes = bookerPaymentMethods?.map(
        (bookerMethod) => {
            return getPaymentMethodType(bookerMethod);
        },
    );

    // get all company paymentMethods that are not in bookers paymentMethods
    const companyPaymentMethodsNotInBooker = companyPaymentMethods
        ? Object.keys(companyPaymentMethods).filter(
              (key) =>
                  //   companyPaymentMethods[key as keyof CompanyMethods] &&
                  !bookerPaymentMethodTypes?.includes(key as PaymentMethodType),
          )
        : [];

    const AvailablePaymentMethodsTable = () => {
        const [selectedRowKeys, setSelectedRowKeys] = useState<
            Array<PaymentMethodType>
        >([]);

        const [sepaDebitData, setSepaDebitData] = useState<SepaDebitIntput>();

        const [sepaDebit, setSepaDebit] = useState(false);

        useEffect(() => {
            if (selectedRowKeys.includes("sepaDebit")) {
                setSepaDebit(true);
            } else {
                setSepaDebit(false);
            }
        }, [selectedRowKeys]);

        // ADD NEW PAYMENTMETHOD TO BOOKER ---------------------------------------------------------------------------

        const [addPaymentMethodMutation] = useAddPaymentMethodMutation();

        const addPaymentMethod = async ({
            bookerId,
            selectedRowKeys,
            sepaDebitInput,
        }: {
            bookerId?: Booker["id"];
            selectedRowKeys?: Array<PaymentMethodType>;
            sepaDebitInput?: SepaDebitIntput;
        }) => {
            if (!bookerId || !selectedRowKeys || !courseId) {
                return;
            }

            const refetchQueries = [
                "GetAppSettings",
                {query: GetAppSettingsDocument},
                "GetBookedAttendeesByCourseId",
                {
                    query: GetBookedAttendeesByCourseIdDocument,
                    variables: {courseId},
                },
                "GetPaymentMethodsByBookerId",
                {
                    query: GetPaymentMethodsByBookerIdDocument,
                    variables: {bookerId},
                },
            ];

            if (bookingId) {
                refetchQueries.push({
                    query: GetBookingByIdDocument,
                    variables: {id: bookingId},
                });
            }

            try {
                const result = await addPaymentMethodMutation({
                    variables: {
                        bookerId,
                        paymentMethodType: selectedRowKeys[0],
                        sepaDebitInput,
                    },
                    refetchQueries,
                });

                if (result.data?.addPaymentMethod) {
                    message.success("Bezahlmethode wurde hinzugefügt");
                    setSelectedPayment(selectedRowKeys[0]);
                    setModalOpen(false);
                }
            } catch (error) {
                console.log("error", error);
                message.error("Bezahlmethode konnte nicht hinzugefügt werden");
            }
        };

        // ------------------------------------------------------------------------------------------------------------

        const renderBadge = (paymentMethod: string) => {
            return (
                <PaymentMethodBadge type={paymentMethod as PaymentMethodType} />
            );
        };

        type TableType = {key: string; title: string; dataIndex: string};

        // Hint if is not company method -------------------------------------------------------------------------------
        const isCompanyMethod = (record: TableType) => {
            if (!companyPaymentMethods) return null;
            if (
                companyPaymentMethods[record.dataIndex as keyof CompanyMethods]
            ) {
                return null;
            }

            return (
                <Tooltip
                    title={i18n.views.PaymentMethodTable.notCompanyMethod()}
                >
                    <ExclamationCircleOutlined
                        style={{color: red[3], fontSize: "1.5em"}}
                    />
                </Tooltip>
            );
        };

        const columns: Array<ColumnProps<{
            title: string;
            dataIndex: string;
        }>> = [
            {
                key: "companyPaymentMethod",
                title: "",
                dataIndex: "companyPaymentMethod",
                render: (text, record: any) => {
                    return isCompanyMethod(record);
                },
                width: 10,
            },
            {
                title: "Verfügbare Bezahlmethoden",
                dataIndex: "dataIndex",
                render: (text, record: any) => renderBadge(text),
            },
        ];

        const dataSource = Object.values(companyPaymentMethodsNotInBooker).map(
            (method) => {
                return {
                    key: method,
                    title: method,
                    dataIndex: method,
                };
            },
        );

        const onSelectChange = (selectedRowKeys: Array<PaymentMethodType>) => {
            setSelectedRowKeys(selectedRowKeys);
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };

        const expandedRowRender = (record: TableType) => {
            if (record.dataIndex === "sepaDebit" && sepaDebit) {
                return (
                    <SepaDebitPaymentMethodModal
                        asModal={false}
                        onSubmit={(data) => {
                            setSepaDebitData(data as SepaDebitIntput);
                            addPaymentMethod({
                                bookerId,
                                selectedRowKeys,
                                sepaDebitInput: data as SepaDebitIntput,
                            });
                        }}
                    />
                );
            }
        };

        const expandable: ExpandableConfig<TableType> = {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            rowExpandable(record) {
                return record.dataIndex === "sepaDebit";
            },
            expandedRowRender,
            expandedRowKeys: sepaDebit ? ["sepaDebit"] : [],
            showExpandColumn: false,
        };

        return (
            <Space
                direction="vertical"
                style={{width: "100%", paddingTop: 0, marginTop: 0}}
            >
                <Table<TableType>
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    rowSelection={{type: "radio", ...rowSelection}}
                    expandable={expandable}
                    showHeader={false}
                />
                {!sepaDebit && (
                    <div
                        style={{
                            // width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            // border: "2px dotted red",
                        }}
                    >
                        <Button
                            style={{width: "49%"}}
                            danger
                            onClick={() => setModalOpen(false)}
                        >
                            {"Abbrechen"}
                        </Button>
                        <Button
                            style={{width: "49%"}}
                            type="primary"
                            disabled={selectedRowKeys.length === 0}
                            onClick={() => {
                                addPaymentMethod({bookerId, selectedRowKeys});
                            }}
                        >
                            {okButtonText ?? "Bezahlmethoden hinzufügen"}
                        </Button>
                    </div>
                )}
            </Space>
        );
    };

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <AvailablePaymentMethodsTable />
        </Space>
    );
};

export default memo(BookingAddPaymentMethod);
