import React from "react";
import css from "./CardGrid.less";
import {DashboardConfig} from "./DashboardOverview";

type DropZoneProps = {
    dashboardConfig: DashboardConfig;
};

const DropZone: React.FC<DropZoneProps> = ({dashboardConfig}) => {
    type DropZoneCSSProperties = React.CSSProperties & {
        "--columnCount": number;
    };

    const actualStyle: DropZoneCSSProperties = {
        "--columnCount": dashboardConfig.dashColumns,
    };

    return (
        <div
            className={css.dropZone}
            style={{...actualStyle, height: dashboardConfig.cardHeight}}
        />
    );
};

export default DropZone;
