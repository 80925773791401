import {green} from "@ant-design/colors";
import {CheckCircleFilled, InfoCircleOutlined} from "@ant-design/icons";
import {Button, message, Modal, Popconfirm, Result, Space, Tooltip} from "antd";
import {ExecutionResult} from "graphql";
import React, {memo, FC, useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {handleError, KnownError} from "../../errors/handleErrors";
import {
    ArrayDataOptions,
    BookingType,
    CopyAttendeeToCourseMutation,
    GetBookingsByCourseDocument,
    GetBookingsByCourseMinimalDocument,
    GetBookinsDocument,
    GetCoursesWithPaginationQuery,
    GetSettledBookingsDocument,
    GetUnsettledBookingsDocument,
    TablesEnum,
    TransferAttendeeToCourseMutation,
    useCopyAttendeeToCourseMutation,
    useGetBookingsByCourseMinimalQuery,
    useGetCoursesWithPaginationQuery,
    useTransferAttendeeToCourseMutation,
} from "../../generated/graphql";
import {
    URL_BOOKING_EDIT_ID,
    URL_COURSES_DETAILS_ID,
    URL_INSTRUCTOR_BOOKING_EDIT_ID,
} from "../../models/url";
import CourseList from "../courses/CourseEdit/CourseList";
import {TableType} from "../courses/CourseEdit/CourseListColumns";
import css from "./TransferAttendeeToAnotherCourse.less";
import {useLocalStorageOptions} from "../../helpers/columnsLocalStorageHandler";
import {marginS} from "../../styles/layout";
import useHighestRole from "../../helpers/useHighestRole";
import Roles from "../../../shared/models/roles";

type TransferAttendeeToAnotherCourseProps = {
    transformAction: "transfer" | "copy";
    bookingId: string;
    currentCourseId: string;
    modalOpen: boolean;
    setModalOpen: Function;
    onCancel?: Function;
    attendeeFullName?: string;
    isSettled?: boolean;
};

const TransferAttendeeToAnotherCourse: FC<TransferAttendeeToAnotherCourseProps> = ({
    transformAction,
    bookingId,
    currentCourseId,
    modalOpen,
    setModalOpen,
    attendeeFullName,
    isSettled,
}) => {
    const history = useHistory();
    const highestRole = useHighestRole();
    const isInstructor = highestRole === Roles.Instructor;
    const [selectedCourse, setSelectedCourse] = useState<TableType | undefined>(
        undefined,
    );
    // const [options, setOptions] = useState<ArrayDataOptions>({});
    const localStorageName = "transferAttendeeToCourse";
    const [totalResults, setTotalResults] = useState<number>(0);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName,
        // defaultOptions: standadOptions,
        defaultOptions: {},
        totalResults,
    });

    // COURSELIST DATA -------------------------------------------------------------------------------------
    // const emptyOptions = Object.keys(options ?? {}).length === 0;
    const {
        data: coursesData,
        loading: loadingCourses,
        refetch: refetchCourses,
    } = useGetCoursesWithPaginationQuery({
        variables: {options: options as ArrayDataOptions},
    });

    useEffect(() => {
        totalResults !== coursesData?.coursesWithPagination?.total &&
            setTotalResults(coursesData?.coursesWithPagination?.total ?? 0);
    }, [coursesData, totalResults]);

    const courses: GetCoursesWithPaginationQuery["coursesWithPagination"]["items"] =
        coursesData?.coursesWithPagination?.items ?? [];

    const coursesWithoutCurrentCourse = courses.filter(
        (course) => course.id !== currentCourseId,
    );

    const tableData = {
        dataSource: coursesWithoutCurrentCourse as Array<TableType>,
        loading: loadingCourses,
        total: coursesData?.coursesWithPagination?.total ?? 0,
    };

    // CHECKING FOR PLACE RESERVATIONa ----------------------------------------------------------------------
    const {
        data: bookingsByCourseData,
        loading: bookingsByCourseLoading,
    } = useGetBookingsByCourseMinimalQuery({
        skip: !selectedCourse?.id,
        variables: {id: selectedCourse?.id ?? ""},
    });

    const bookingsIncludingCanceled =
        bookingsByCourseData?.getBookingsByCourse["items"];
    const allBookings = bookingsIncludingCanceled?.filter((booking) => {
        return booking.canceled === null;
    });

    const placervationsEntries =
        allBookings?.filter((booking) => {
            return booking.bookingNumberPrefix === "P";
        }) ?? [];

    // ACTION --------------------------------------------------------------------------------------

    const knownErrors: Array<KnownError> = [
        {
            key: "Booking has no attendee",
            title: "Buchung ohne Teilnehmer",
            msg: "Diese Buchung hat keinen Teilnehmer.",
        },
        {
            key: "Booking is of type PlaceReservation",
            title: "Buchung ist vom Typ Platzreservierung",
            msg: "Platzreservierurngen können nicht verschoben werden.",
        },
        {
            key: "Booking is settled",
            title: "Buchung ist abgerechnet",
            msg: "Abgerechnete Buchungen können nicht verschoben werden.",
        },
        {
            key: "Target course not found",
            title: "Zielkurs nicht gefunden",
            msg: "Der Zielkurs konnte nicht gefunden werden.",
        },
        {
            key: "Already enrolled",
            title: "Doppelbuchung",
            msg:
                "Dieser Teilnehmer ist bereits in diesem Kurs gebucht, oder steht auf dessen Warteliste.",
        },
    ];

    const [transferAttendee] = useTransferAttendeeToCourseMutation({
        refetchQueries: [
            "GetBookingsByCourseMinimal",
            {
                query: GetBookingsByCourseMinimalDocument,
                variables: {id: selectedCourse?.id},
            },
            "GetBookingsByCourseMinimal",
            {
                query: GetBookingsByCourseMinimalDocument,
                variables: {id: currentCourseId},
            },
            // "GetCourseLessonsByCourseId",
            "GetBookingsByCourse",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: selectedCourse?.id},
            },
            "GetBookingsByCourse",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: currentCourseId},
            },
            "GetSettledBookings",
            {query: GetSettledBookingsDocument},
            "GetUnsettledBookings",
            {query: GetUnsettledBookingsDocument},
            "GetBookins",
            {query: GetBookinsDocument},
        ],
    });

    const [copyBooking] = useCopyAttendeeToCourseMutation({
        refetchQueries: [
            "GetBookingsByCourseMinimal",
            {
                query: GetBookingsByCourseMinimalDocument,
                variables: {id: selectedCourse?.id},
            },
            "GetBookingsByCourseMinimal",
            {
                query: GetBookingsByCourseMinimalDocument,
                variables: {id: currentCourseId},
            },
            // "GetCourseLessonsByCourseId",
            "GetBookingsByCourse",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: selectedCourse?.id},
            },
            "GetBookingsByCourse",
            {
                query: GetBookingsByCourseDocument,
                variables: {id: currentCourseId},
            },
            "GetSettledBookings",
            {query: GetSettledBookingsDocument},
            "GetUnsettledBookings",
            {query: GetUnsettledBookingsDocument},
            "GetBookins",
            {query: GetBookinsDocument},
        ],
    });

    type ResultModalProps = {
        attendeeFullName: string;
        course: any;
        bookingType?: BookingType;
        newBookingId?: string;
        transformAction?: "transfer" | "copy";
    };

    const ResultModal: FC<ResultModalProps> = ({
        attendeeFullName,
        course,
        bookingType,
        newBookingId,
        transformAction,
    }) => {
        const linkToCourse = course.id
            ? URL_COURSES_DETAILS_ID.replace(":id", course.id)
            : undefined;
        const linkToNewBooking = newBookingId
            ? isInstructor
                ? URL_INSTRUCTOR_BOOKING_EDIT_ID.replace(":id", newBookingId)
                : URL_BOOKING_EDIT_ID.replace(":id", newBookingId)
            : undefined;

        const ResultTextCopy = () => {
            if (bookingType === BookingType.Waitlist) {
                return (
                    <div className={css.resultTitle}>
                        <div
                            className={css.resultTitleHighlighted}
                        >{`${attendeeFullName}`}</div>
                        <div>{`wurde auf die Warteliste des Kurses`}</div>
                        <div
                            className={css.resultTitleHighlighted}
                        >{`${course.prefixedCourseNumber}`}</div>
                        <div>{`kopiert.`}</div>
                    </div>
                );
            }

            return (
                <div className={css.resultTitle}>
                    <div
                        className={css.resultTitleHighlighted}
                    >{`${attendeeFullName}`}</div>
                    <div>{`wurde erfolgreich in den Kurs`}</div>
                    <div
                        className={css.resultTitleHighlighted}
                    >{`${course.prefixedCourseNumber}`}</div>
                    <div>{`kopiert.`}</div>
                </div>
            );
        };

        const ResultTextTransfer = () => {
            if (bookingType === BookingType.Waitlist) {
                return (
                    <div className={css.resultTitle}>
                        <div
                            className={css.resultTitleHighlighted}
                        >{`${attendeeFullName}`}</div>
                        <div>{`wurde auf die Warteliste des Kurses`}</div>
                        <div
                            className={css.resultTitleHighlighted}
                        >{`${course.prefixedCourseNumber}`}</div>
                        <div>{`verschoben.`}</div>
                    </div>
                );
            }

            return (
                <div className={css.resultTitle}>
                    <div
                        className={css.resultTitleHighlighted}
                    >{`${attendeeFullName}`}</div>
                    <div>{`wurde erfolgreich in den Kurs`}</div>
                    <div
                        className={css.resultTitleHighlighted}
                    >{`${course.prefixedCourseNumber}`}</div>
                    <div>{`verschoben.`}</div>
                </div>
            );
        };

        const handleGoToLink = async (link: string) => {
            setSelectedCourse(undefined);
            setModalOpen(false);
            history.push(link);
            Modal.destroyAll();
        };

        return (
            <Result
                icon={
                    <CheckCircleFilled
                        style={{fontSize: 50, color: green[3]}}
                    />
                }
                status="success"
                style={{width: "100%", padding: 0}}
                // style={{padding: 30}}
                title={
                    transformAction === "copy" ? (
                        <ResultTextCopy />
                    ) : (
                        <ResultTextTransfer />
                    )
                }
                subTitle={
                    <div className={css.resultSubTitle}>
                        {transformAction === "copy" ? (
                            `Der Teilnehmer ist jetzt in beiden Kursen eingetragen.`
                        ) : (
                            <span>
                                <p>{`Der Platz im bisherigen Kurs ist damit verfügbar und kann neu vergeben werden.`}</p>
                                {isSettled && (
                                    <p
                                        style={{fontWeight: 500}}
                                    >{`Die bisherige Buchung wurde storniert und es wurde eine Stornorechnung generiert.`}</p>
                                )}
                            </span>
                        )}
                    </div>
                }
                extra={
                    <Space
                        style={{width: "100%", justifyContent: "space-around"}}
                    >
                        {linkToCourse && (
                            <Button
                                type="link"
                                style={{minWidth: "40%"}}
                                onClick={async () =>
                                    handleGoToLink(linkToCourse)
                                }
                            >
                                {"Zum Kurs"}
                            </Button>
                        )}
                        {linkToNewBooking && (
                            <Button
                                type="link"
                                style={{minWidth: "40%"}}
                                onClick={async () =>
                                    handleGoToLink(linkToNewBooking)
                                }
                            >
                                {"Zur neuen Buchung"}
                            </Button>
                        )}
                    </Space>
                }
            />
        );
    };

    type ResultType =
        | ExecutionResult<CopyAttendeeToCourseMutation>
        | ExecutionResult<TransferAttendeeToCourseMutation>;

    const isCopyAttendeeToCourse = (
        result: ResultType["data"],
    ): result is CopyAttendeeToCourseMutation => {
        return (
            result !== null &&
            result !== undefined &&
            "copyAttendeeToCourse" in result
        );
    };

    const handleSuccess = (
        res: ResultType,
        selectedCourse: TableType,
        transformAction: "transfer" | "copy",
    ) => {
        // const result = res.data;
        const resultData = res.data;

        if (!resultData) return;

        const result = isCopyAttendeeToCourse(resultData)
            ? resultData.copyAttendeeToCourse
            : resultData.transferAttendeeToCourse;

        const {prefixedCourseNumber} = selectedCourse;
        const attendeeFullName = `${result?.attendee?.firstname} ${result?.attendee?.lastname}`;

        if (result.bookingType === "Booking") {
            message.success(
                `${attendeeFullName} wurde erfolgreich in den Kurs ${prefixedCourseNumber} gebucht.`,
            );
        } else {
            message.success(
                `${attendeeFullName} wurde erfolgreich auf die Warteliste des Kurses ${prefixedCourseNumber} gesetzt.`,
            );
        }

        Modal.confirm({
            icon: null,
            content: (
                <ResultModal
                    attendeeFullName={attendeeFullName}
                    course={result.courseLessons[0].course}
                    bookingType={result.bookingType as BookingType}
                    newBookingId={result.id}
                    transformAction={transformAction}
                />
            ),
            onOk: () => {
                setSelectedCourse(undefined);
                setModalOpen(false);
            },
            okButtonProps: {
                block: true,
                style: {margin: 0, paddingLeft: 0},
            },
            cancelButtonProps: {style: {display: "none"}},
            maskClosable: true,
        });
    };

    const transferOrCopyAttendeeToAnotherCourse = async () => {
        const selectedCourseId = selectedCourse?.id;

        if (!selectedCourseId) {
            return;
        }

        try {
            if (transformAction === "copy") {
                await copyBooking({
                    variables: {
                        bookingId,
                        targetCourseId: selectedCourseId,
                    },
                }).then((res) => {
                    handleSuccess(res, selectedCourse, transformAction);
                });
            } else {
                await transferAttendee({
                    variables: {
                        bookingId,
                        targetCourseId: selectedCourseId,
                    },
                }).then((res) => {
                    handleSuccess(res, selectedCourse, transformAction);
                });
            }
        } catch (error) {
            console.log("error:::", error);
            handleError(
                "transferAttendeeToCourse",
                error,
                "modal",
                knownErrors,
            );
        }
    };

    const transferOrCopyAttendeeToAnotherCoursePlacereservation = async (
        usePlaceReservation?: boolean,
    ) => {
        const selectedCourseId = selectedCourse?.id;

        if (!selectedCourseId) {
            return;
        }

        console.log("usePlaceReservation:::", usePlaceReservation);

        try {
            if (transformAction === "copy") {
                await copyBooking({
                    variables: {
                        bookingId,
                        targetCourseId: selectedCourseId,
                        usePlaceReservation,
                    },
                }).then((res) => {
                    handleSuccess(res, selectedCourse, transformAction);
                });
            } else {
                await transferAttendee({
                    variables: {
                        bookingId,
                        targetCourseId: selectedCourseId,
                        usePlaceReservation,
                    },
                }).then((res) => {
                    handleSuccess(res, selectedCourse, transformAction);
                });
            }
        } catch (error) {
            console.log("error:::", error);
            handleError(
                "transferOrCopyAttendeeToAnotherCoursePlacereservation",
                error,
                "modal",
                knownErrors,
            );
        }
    };

    // TEXT -----------------------------------------------------------------------------------------------------

    const modalTitle =
        transformAction === "copy" ? (
            <span>
                <span
                    className={css.modalTitlePrimary}
                >{`Weitere Buchung anlegen für `}</span>
                <span
                    className={css.modalTitleSecondary}
                >{`${attendeeFullName}`}</span>
            </span>
        ) : isSettled ? (
            <span>
                <span
                    className={css.modalTitlePrimary}
                >{`Buchung stornieren und Teilnehmer in anderen Kurs verschieben `}</span>
                <span
                    className={css.modalTitleSecondary}
                >{`${attendeeFullName}`}</span>
            </span>
        ) : (
            <span>
                <span
                    className={css.modalTitlePrimary}
                >{`Teilnehmer in anderen Kurs verschieben `}</span>
                <span
                    className={css.modalTitleSecondary}
                >{`${attendeeFullName}`}</span>
            </span>
        );

    const confirmButtonText = (freePlaces: number) => {
        if (transformAction === "copy") {
            if (freePlaces <= 0) {
                return "Kopieren und auf die Warteliste des Kurses setzen";
            }

            return "Kopieren und in diesen Kurs buchen";
        } else if (freePlaces <= 0) {
            return `Teilnehmer auf die Warteliste des Kuses verschieben`;
        }

        if (isSettled) {
            return `Buchung stornieren und Teilnehmer in diesen Kurs verschieben`;
        }

        return `Teilnehmer in diesen Kurs verschieben`;
    };

    const placeReservationTexts = (): {
        buttonText: string;
        modalTitle: React.ReactNode | string;
        modalContent: React.ReactNode | string;
        action: () => void;
    } => {
        if (transformAction === "copy") {
            return {
                buttonText: "Kopieren und Platzreservierung belegen",
                modalTitle: "Weitere Buchung anlegen (Weitermeldung)",
                modalContent: (
                    <span style={{whiteSpace: "pre-line"}}>
                        <div style={{fontWeight: 500}}>
                            {
                                "Bei dieser Aktion wird der Teilnehmer in einen anderen Kurs kopiert"
                            }
                        </div>
                        <div>
                            {`- Der ausgewählte Kurs hat mindestens eine Platzreservierung, diese wird verwendet.\n- Die aktuelle Buchung bleibt unverändert.`}
                        </div>
                    </span>
                ),
                action: async () =>
                    transferOrCopyAttendeeToAnotherCoursePlacereservation(true),
            };
        } else if (isSettled) {
            return {
                buttonText: "Buchung stornieren und Platzreservierung belegen",
                modalTitle: "Buchung stornieren und Teilnehmer verschieben",
                modalContent: (
                    <span style={{whiteSpace: "pre-line"}}>
                        <div style={{fontWeight: 500}}>
                            {
                                "Bei dieser Aktion wird der Teilnehmer in einen anderen Kurs verschoben"
                            }
                        </div>
                        <div>
                            {`- Der ausgewählte Kurs hat mindestens eine Platzreservierung, diese wird verwendet.\n- Die aktuelle Buchung wird storniert.`}
                        </div>
                    </span>
                ),
                action: async () =>
                    transferOrCopyAttendeeToAnotherCoursePlacereservation(true),
            };
        }

        return {
            buttonText: "Verschieben und Platzreservierung belegen",
            modalTitle: "Teilnehmer verschieben",
            modalContent: (
                <span style={{whiteSpace: "pre-line"}}>
                    <div style={{fontWeight: 500}}>
                        {
                            "Bei dieser Aktion wird der Teilnehmer in einen anderen Kurs verschoben"
                        }
                    </div>
                    <div>
                        {`- Der ausgewählte Kurs hat mindestens eine Platzreservierung, diese wird verwendet.`}
                    </div>
                </span>
            ),
            action: async () =>
                transferOrCopyAttendeeToAnotherCoursePlacereservation(true),
        };
    };

    const ModalFooter = ({freePlaces}: {freePlaces: number}) => {
        return (
            <div>
                <Button danger onClick={() => setModalOpen(false)}>
                    {"Abbrechen"}
                </Button>
                {placervationsEntries.length > 0 && (
                    <Button
                        onClick={() => {
                            Modal.confirm({
                                width: 600,
                                icon: null,
                                bodyStyle: {padding: marginS},
                                title: placeReservationTexts().modalTitle,
                                content: placeReservationTexts().modalContent,
                                onCancel: () => {},
                                okText: placeReservationTexts().buttonText,
                                onOk: placeReservationTexts().action,
                                closable: true,
                            });
                        }}
                        disabled={!selectedCourse}
                    >
                        {placeReservationTexts().buttonText}
                    </Button>
                )}

                <Button
                    type="primary"
                    // onClick={transferOrCopyAttendeeToAnotherCourse}
                    onClick={
                        isSettled // here
                            ? transformAction === "copy"
                                ? () => {
                                      Modal.confirm({
                                          icon: null,
                                          title:
                                              "Teilnehmer in anderen Kurs kopieren",
                                          content:
                                              "Bei dieser Aktion wird der Teilnehmer in einen anderen Kurs kopiert. Die aktuelle Buchung bleibt unverändert.",
                                          onCancel: () => {},
                                          okText: "Teilnehmer kopieren",
                                          onOk: transferOrCopyAttendeeToAnotherCourse,
                                      });
                                  }
                                : () => {
                                      Modal.confirm({
                                          icon: null,
                                          title: "Stornieren und umbuchen",
                                          content:
                                              "Bei dieser Aktion wird die aktuelle Buchung storniert und eine Stornorechnung erstellt. Der Teilnehmer wird in den ausgewählten Kurs übertragen.",
                                          onCancel: () => {},
                                          okText: "Stornieren und umbuchen",
                                          onOk: transferOrCopyAttendeeToAnotherCourse,
                                      });
                                  }
                            : transferOrCopyAttendeeToAnotherCourse
                    }
                    disabled={!selectedCourse}
                >
                    {confirmButtonText(freePlaces)}
                </Button>
            </div>
        );
    };

    // MODAL -----------------------------------------------------------------------------------------------------

    return (
        <Modal
            title={modalTitle}
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            width="calc(100% - 120px)"
            style={{
                top: 30,
                padding: 0,
            }}
            footer={
                <ModalFooter freePlaces={selectedCourse?.freePlaces ?? 0} />
            }
            destroyOnClose
        >
            <CourseList
                tableData={tableData}
                options={options}
                setOptions={setOptions}
                optionsCallBack={() =>
                    console.log("optionsCallBack should be removed")
                }
                refetch={refetchCourses}
                defaultPageSize={10}
                bookingCourse
                toolBarButtons={["columnSelector"]}
                hiddenColumns={["actions"]}
                defaultColumns={[
                    "prefixedCourseNumber",
                    "courseType",
                    "firstCourseLesson",
                    "location",
                    "freePlaces",
                    "placeReservationsCount",
                ]}
                handleCourseSelection={(selectedCourses: Array<TableType>) => {
                    setSelectedCourse(selectedCourses[0]);
                }}
                tablesEnum={TablesEnum.CourseTransferAttendeeToCourse}
            />
        </Modal>
    );
};

export default memo(TransferAttendeeToAnotherCourse);
