import {
    DeleteOutlined,
    PaperClipOutlined,
    PlusCircleFilled,
} from "@ant-design/icons";
import {Button, Tooltip, Upload, UploadProps, message} from "antd";
import React, {FC, memo, useState} from "react";
import uuid from "uuid";
import {grey, red, yellow} from "@ant-design/colors";
import css from "./Email.less";
import {Attachment, Maybe} from "../../../generated/graphql";
import {getBase64} from "./uploadHelpers";
import {renderFileSize} from "./emailHelpers";

type UploadComponentProps = {
    setAttachments: (attachments: Array<Attachment>) => void;
    attachments?: Maybe<Array<Attachment>>;
    receiverCount: number;
    setDisabledSendButton?: (disabled: boolean) => void;
};

const UploadComponent: FC<UploadComponentProps> = ({
    setAttachments,
    attachments,
    receiverCount,
    setDisabledSendButton,
}) => {
    const [fileCount, setFileCount] = useState(0);
    const fileTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
    ];
    const fileTypeNames = ["jpeg", "jpg", "png", "pdf"];

    const maxFileSizeInMB = 5;
    const maxFileSize = maxFileSizeInMB * 1024 * 1024; // 10 MB in bytes
    const maxCount = 2;
    const maxTotalFileSize = 14 * 1024 * 1024; // 14 MB in bytes Mailjet erlaubt 15 MB pro Mail-Badge
    const fileSizeSum = attachments?.reduce((acc, attachment) => {
        return acc + (attachment.Base64Content.length ?? 0);
    }, 0);

    const totalFileSize = fileSizeSum ? fileSizeSum * receiverCount : 0;

    const attachmentsToLarge = totalFileSize > maxTotalFileSize;

    React.useEffect(() => {
        if (setDisabledSendButton) {
            if (attachmentsToLarge) {
                setDisabledSendButton(true);
            } else {
                setDisabledSendButton(false);
            }
        }
    }, [attachmentsToLarge, setDisabledSendButton]);

    const RenderFileSizeSum = () => {
        if (attachments?.length === 0) return null;

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "0.8em",
                    paddingRight: 5,
                    paddingTop: 0,
                    fontWeight: attachmentsToLarge ? 600 : 400,
                    color: attachmentsToLarge ? "white" : grey[3],
                    background: attachmentsToLarge ? red[2] : undefined,
                    width: "100%",
                }}
            >
                <span>{`Gesamtgröße aller Anhänge: ${renderFileSize(
                    totalFileSize,
                )}`}</span>
                <span
                    style={{
                        marginLeft: 10,
                        color: attachmentsToLarge ? red[7] : undefined,
                        fontWeight: 600,
                    }}
                >{`(Maximale Größe: ${renderFileSize(
                    maxTotalFileSize,
                )})`}</span>
            </div>
        );
    };

    const props: UploadProps = {
        // name: "file",
        // name: attachments?.[0]?.Filename ?? uuid.v4(),
        disabled: fileCount >= maxCount,
        className: css.uploadListInline,
        maxCount,
        customRequest: async (options) => {},
        multiple: true,
        // eslint-disable-next-line react/display-name
        itemRender: (originNode, file, fileList, actions) => {
            // Remove or disable the preview action
            actions.preview = () => {};

            return (
                <div className={css.uploadListItem}>
                    {/* <Tooltip title={`${sizeToShow} ${unitToShow}`}>*/}
                    <Tooltip title={renderFileSize(file.size ?? 0)}>
                        <PaperClipOutlined style={{marginRight: 3}} />
                        <span>{file.name}</span>
                    </Tooltip>
                    <Button
                        size="small"
                        onClick={() => actions.remove(file)}
                        type="text"
                    >
                        <DeleteOutlined style={{color: red[4]}} />
                    </Button>
                    {/* Add other actions if needed */}
                </div>
            );
        },
        beforeUpload: (file, fileList) => {
            if (!fileTypes.includes(file.type)) {
                message.error({
                    content: (
                        <span style={{whiteSpace: "pre-line"}}>
                            <span>{"Die Datei "}</span>
                            <span
                                style={{fontWeight: 600, background: yellow[1]}}
                            >
                                {file.name}
                            </span>
                            <span>
                                {
                                    " kann nicht als Anhang verwendet werden.\nBitte wähle eine Datei mit einem der folgenden Formate aus: "
                                }
                            </span>
                            <span>
                                {fileTypeNames.join(", ")}
                                {"."}
                            </span>
                        </span>
                    ),
                    closable: true,
                });

                return Upload.LIST_IGNORE; // Reject this file

                // return false; // Reject this file
            }

            if (file.size > maxFileSize) {
                message.error({
                    content: (
                        <span style={{whiteSpace: "pre-line"}}>
                            <span>{"Die Datei "}</span>
                            <span
                                style={{fontWeight: 600, background: yellow[1]}}
                            >
                                {file.name}
                            </span>
                            <span>{` ist zu groß. Die maximale Dateigröße beträgt ${maxFileSizeInMB} MB.`}</span>
                        </span>
                    ),
                    closable: true,
                });

                return Upload.LIST_IGNORE; // Reject this file
            }

            const indexOfCurrentFile = fileList.findIndex(
                (fileX) => fileX.name === file.name,
            );

            if (indexOfCurrentFile > maxCount - 1) {
                message.error({
                    content: (
                        <span style={{whiteSpace: "pre-line"}}>
                            <span>
                                {`Es können maximal ${maxCount} Dateien angehängt werden. Die Datei `}
                            </span>
                            <span
                                style={{
                                    fontWeight: 600,
                                    background: yellow[1],
                                }}
                            >
                                {file.name}
                            </span>
                            <span>{` wurde nicht angehängt.`}</span>
                        </span>
                    ),
                });
            }

            return true; // Accept this file
        },
        onChange: async (info) => {
            const fileList = info.fileList;
            // Convert all valid files to Base64
            const attachmentsPromises = fileList.map(async (file) => {
                const base64 = await getBase64(file.originFileObj as File);

                return {
                    ContentType: file.type ?? "", // Provide a default value if undefined
                    Filename: file.name || uuid.v4(), // Provide a default value if undefined
                    Base64Content: base64,
                };
            });

            const attachments = await Promise.all(attachmentsPromises);

            setAttachments(attachments);
            setFileCount(fileList.length);
        },
    };

    return (
        <>
            <Upload {...props}>
                <Tooltip
                    title={
                        fileCount >= maxCount
                            ? `Es können maximal ${maxCount} Anhänge an eine Mail gehängt werden.`
                            : undefined
                    }
                >
                    <Button
                        className={css.uploadButton}
                        type="link"
                        disabled={fileCount >= maxCount}
                    >
                        {fileCount === 0 ? (
                            <div style={{display: "flex"}}>
                                <PaperClipOutlined
                                    style={{paddingTop: 4, marginRight: 3}}
                                />
                                <span>{"Anhang hinzufügen"}</span>
                            </div>
                        ) : (
                            <div style={{display: "flex"}}>
                                <span>
                                    {"Anhänge"}
                                    <PlusCircleFilled
                                        style={{paddingTop: 4, marginLeft: 3}}
                                    />
                                </span>
                            </div>
                        )}
                    </Button>
                </Tooltip>
            </Upload>
            <RenderFileSizeSum />
        </>
    );
};

export default memo(UploadComponent);
