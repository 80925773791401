import {useEffect, useState} from "react";
import Roles from "../../shared/models/roles";
import {useGetUserRolesQuery, useIsInstructorQuery} from "../generated/graphql";

// const pollIntervallInMilliseconds = 5000;
const pollIntervallInMilliseconds = undefined;

const useHighestRole = (): Roles => {
    const [highestRole, setHighestRole] = useState<Roles>(Roles.Instructor);

    // ---------------------------------------------------------------------
    const [rolesOfCurrent, setRolesOfCurrent] = useState<Array<Roles>>([
        Roles.Instructor,
    ]);
    const {data: rolesData, loading: loadingRoles} = useGetUserRolesQuery({
        // fetchPolicy: "no-cache",
        // fetchPolicy: "cache-and-network",
        // fetchPolicy: "network-only",
        pollInterval: pollIntervallInMilliseconds,
    });

    const {data: isInstructorData} = useIsInstructorQuery();
    const isInstructor = isInstructorData?.isInstructor;

    useEffect(() => {
        if (rolesData?.getUserRoles?.rolesOfCurrent && !loadingRoles) {
            setRolesOfCurrent(rolesData.getUserRoles.rolesOfCurrent);
        }
    }, [rolesData, loadingRoles]);

    const getHighestRole = (roles: Array<Roles>) => {
        if (roles.includes(Roles.Superuser)) {
            return Roles.Superuser;
        } else if (roles.includes(Roles.BetaTester)) {
            return Roles.BetaTester;
        } else if (roles.includes(Roles.SuperAdmin)) {
            return Roles.SuperAdmin;
        } else if (roles.includes(Roles.Admin)) {
            return Roles.Admin;
        } else if (roles.includes(Roles.Instructor)) {
            return Roles.Instructor;
        } else if (roles.includes(Roles.Staff)) {
            return Roles.Staff;
        }

        return Roles.EndUser;
    };

    useEffect(() => {
        setHighestRole(getHighestRole(rolesOfCurrent));
    }, [rolesOfCurrent]);

    // return {highestRole, isInstructor};
    return highestRole;
};

export default useHighestRole;
