import {
    BookingType,
    GetBookingsByCourseMinimalQuery,
    GetCompanyInfoQuery,
    MailDataType,
    SubscriptionType,
    useGetBookingsByCourseMinimalQuery,
    useGetCompanyInfoQuery,
} from "../../../generated/graphql";
import {NotifySelection} from "./SendMailHandlers/CourseBookingTypeSelector";

export type ReceiverByNotifySelection = {
    id: string;
    email: string;
    firstname: string | undefined;
    lastname: string | undefined;
    bookingType: BookingType;
    bookingNumberPrefix: string;
    bookingNumber: string;
    canceled: string | null;
};

export const getReceiversFilteredByNotifySelection = ({
    notifySelection,
    bookingsByCourse,
}: {
    notifySelection: Array<NotifySelection>;
    bookingsByCourse: GetBookingsByCourseMinimalQuery | undefined;
}): Array<ReceiverByNotifySelection> => {
    const allReceiversByBookingsOfCourse:
        | Array<ReceiverByNotifySelection>
        | undefined = bookingsByCourse?.getBookingsByCourse?.items?.map(
        (booking) => {
            const booker = booking?.attendee?.booker;

            return {
                id: booking.id,
                email: booker?.email ?? "",
                firstname: booker?.firstname,
                lastname: booker?.lastname,
                bookingType: booking.bookingType as BookingType,
                bookingNumberPrefix: booking.bookingNumberPrefix,
                bookingNumber: booking.bookingNumber.toString(),
                canceled: booking.canceled,
            };
        },
    );

    if (!allReceiversByBookingsOfCourse) return [];

    // Return all receivers if no option is selected
    if (notifySelection.length === 0) {
        return allReceiversByBookingsOfCourse;
    }

    const receiversWithNotCanceledBookings = allReceiversByBookingsOfCourse.filter(
        (receiver) => receiver.canceled === null,
    );

    // Combine all receivers into one array
    let combinedReceivers: Array<ReceiverByNotifySelection> = [];

    if (notifySelection.includes("notifyBookings")) {
        combinedReceivers = combinedReceivers.concat(
            receiversWithNotCanceledBookings.filter(
                (receiver) => receiver.bookingType === BookingType.Booking,
            ),
        );
    }
    // ...similar for notifyWaitlist and notifyCancelations...
    if (notifySelection.includes("notifyWaitlist")) {
        combinedReceivers = combinedReceivers.concat(
            receiversWithNotCanceledBookings.filter(
                (receiver) => receiver.bookingType === BookingType.Waitlist,
            ),
        );
    }

    if (notifySelection.includes("notifyCancelations")) {
        combinedReceivers = combinedReceivers.concat(
            allReceiversByBookingsOfCourse.filter(
                (receiver) => receiver.canceled !== null,
            ),
        );
    }

    // Remove duplicates based on id and bookingNumber
    const uniqueReceivers = Array.from(
        new Map(
            combinedReceivers.map((receiver) => [
                receiver.id + "-" + receiver.bookingNumber,
                receiver,
            ]),
        ).values(),
    );

    return uniqueReceivers;
};

export const useReceiversByBookingsOfCourse = ({
    courseId,
    notifySelection,
}: {
    courseId: string;
    notifySelection: Array<NotifySelection>;
}): {receivers: Array<ReceiverByNotifySelection>; loading: boolean} => {
    const bookingsByCourse = useGetBookingsByCourseMinimalQuery({
        skip: !courseId,
        variables: {
            id: courseId,
        },
        fetchPolicy: "network-only",
    });

    const receivers = getReceiversFilteredByNotifySelection({
        notifySelection,
        bookingsByCourse: bookingsByCourse.data,
    });

    return {
        receivers,
        loading: bookingsByCourse.loading,
    };
};

export const translatedMailDataType = ({
    mailDataType,
}: {
    mailDataType: MailDataType;
}) => {
    switch (mailDataType) {
        case MailDataType.Booking:
            return "Buchung";
        case MailDataType.Course:
            return "Kurs";
        case MailDataType.Booker:
            return "Bucher";
        case MailDataType.Attendee:
            return "Teilnehmer";
        case MailDataType.Invoice:
            return "Rechnungnugsempfänger";
        case MailDataType.AttendanceList:
            return "Anwesenheitsliste";
        case MailDataType.InvoicePdf:
            return "Rechnungnugsempfänger (PDF)";
        case MailDataType.ScheduledPayment:
            return "Zahlung";
        default:
            return "";
    }
};

export const useTestSubscription = (): {isTestSubscription: boolean} => {
    const {data: companyInfoData} = useGetCompanyInfoQuery({
        fetchPolicy: "network-only",
    });
    const companyInfo: GetCompanyInfoQuery["companyInfo"] | undefined =
        companyInfoData?.companyInfo;

    if (!companyInfo) return {isTestSubscription: false};

    const subscriptionType = SubscriptionType[companyInfo.subscriptionType];
    const isTestSubscription =
        subscriptionType === SubscriptionType.TestSubscription;

    return {isTestSubscription};
};

export const renderFileSize = (fileSizeBytes: number) => {
    const fileSizeInKiloBites = fileSizeBytes / 1024;
    const fileSizeInMB = fileSizeBytes / 1048576;

    const sizeToShow =
        fileSizeBytes > 1024
            ? fileSizeInKiloBites > 1024
                ? fileSizeInMB.toFixed(1)
                : fileSizeInKiloBites.toFixed(0)
            : fileSizeBytes.toFixed(0);
    const unitToShow =
        fileSizeBytes > 1024
            ? fileSizeInKiloBites > 1024
                ? "MB"
                : "KB"
            : "Bytes";

    return `${sizeToShow} ${unitToShow}`;
};
