import {ApolloError} from "apollo-client";
import React, {FC, useEffect, useState} from "react";
import {Redirect, Route, RouteProps, useHistory} from "react-router";
import {Button, Result} from "antd";
import Roles from "../../../shared/models/roles";
import {URL_LOGIN, URL_PERMISSION_DENIED} from "../../models/url";
import {
    useAuthenticateLazyQuery,
    useGetUserRolesQuery,
    useLogoutMutation,
} from "../../generated/graphql";
import LoadingContent from "../../views/LoadingContent/LoadingContent";
import KoLogo from "../../assets/topLevelPage/styled-KoLogo.svg";

type AllowedRoles = {roles: Array<Roles>};

type AuthenticatedRouteProps = RouteProps & AllowedRoles;

export type AuthenticationRedirectState = {
    authorized?: boolean;
    error: ApolloError | undefined;
};

const AuthenticatedRoute: FC<AuthenticatedRouteProps> = (props) => {
    const [authenticate, {data, error, loading}] = useAuthenticateLazyQuery();
    const history = useHistory();
    // const {data, error, loading} = useAuthenticateQuery();

    const [logout, {client, loading: loggingOut}] = useLogoutMutation({
        onCompleted: () => {
            client?.cache.reset().then(() => {
                history.push(URL_LOGIN);
            });
        },
    });

    const {
        data: rolesData,
        error: rolesDataError,
        loading: loadingUserRoles,
    } = useGetUserRolesQuery();

    if (rolesDataError) {
        console.log("rolesDataError", rolesDataError);
    }

    const [rolesOfCurrentUser, setRolesOfCurrent] = useState(
        rolesData?.getUserRoles?.rolesOfCurrent,
    );

    useEffect(() => {
        setRolesOfCurrent(rolesData?.getUserRoles?.rolesOfCurrent);
    }, [rolesData]);

    useEffect(() => {
        console.log("AUTHENTICATE");
        authenticate();
        console.log("AUTHENTICATE DONE");
    }, [authenticate]);

    // console.log("AuthenticationData:::", data);

    if (loading || loadingUserRoles) {
        return <LoadingContent />;
    }

    if (!data?.authenticate || !rolesOfCurrentUser) {
        return (
            <Result
                title="Du wurdest ausgeloggt"
                subTitle="Um fortzufahren, melde dich bitte erneut an."
                icon={<img src={KoLogo} alt="ko-logo" width={130} />}
                extra={
                    <Button
                        loading={loggingOut}
                        type="primary"
                        // onClick={() => history.push(URL_LOGIN)}
                        onClick={() => {
                            logout();
                        }}
                    >
                        {"Zurück zum Login"}
                    </Button>
                }
                style={{
                    backgroundColor: "#F5F5F5",
                    height: "33vh",
                    paddingTop: 30,
                }}
            />
        );
    }

    const user = data.authenticate;

    const hasRequiredRole = (userRoles: Array<Roles>) => {
        if (userRoles === undefined) {
            return false;
        }

        return props.roles.some((role) => userRoles.includes(role));
    };

    if (
        (user !== undefined &&
            Array.isArray(rolesOfCurrentUser) &&
            hasRequiredRole(rolesOfCurrentUser)) ||
        props.roles.length === 0
    ) {
        return <Route {...props} />;
    }

    if (
        rolesOfCurrentUser &&
        !loadingUserRoles &&
        !hasRequiredRole(rolesOfCurrentUser) &&
        props.roles.length > 0
    ) {
        const state: AuthenticationRedirectState = {authorized: false, error};

        return <Redirect to={{pathname: URL_PERMISSION_DENIED, state}} />;
    }

    if (user !== undefined) {
        const state: AuthenticationRedirectState = {authorized: false, error};

        return <Redirect to={{pathname: URL_LOGIN, state}} />;
    }

    if (error !== undefined) {
        const state: AuthenticationRedirectState = {
            authorized: undefined,
            error,
        };

        return <Redirect to={{pathname: URL_LOGIN, state}} />;
    }

    return null;
};

export default AuthenticatedRoute;
