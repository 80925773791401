import React, {FC, memo} from "react";
import {Space} from "antd";
import css from "./PanelCardPayments.less";
import PanelCard from "../PanelCard/PanelCard";
import i18n from "../../services/i18n";
import OpenPaymentsList from "../../containers/payments/OpenPaymentsList";
import DonePaymentsList from "../../containers/payments/DonePaymentsList";
import {GetBookingByIdQuery, TablesEnum} from "../../generated/graphql";
import {margin, marginXs} from "../../styles/layout";
import GenerateSchedulePaymentsButton from "./GenerateSchedulePaymentsButton";
import {RenderAmount} from "../TableColumnRenderers/TableColumnRenderers";
import AddNamedScheduledPayment from "./AddNamedScheduledPayment";
import MemberDiscount from "./MemberDiscount";

type PanelCardPaymentsProps = {
    bookingId?: string;
    booking?: GetBookingByIdQuery["bookingById"];
    scheduledPayments?: GetBookingByIdQuery["bookingById"]["scheduledPayments"];
    timeStamp: number;
    isCanceled: boolean;
};

// const pollInterval = 6000;
const pollInterval = undefined;

const PanelCardPayments: FC<PanelCardPaymentsProps> = ({
    bookingId,
    booking,
    scheduledPayments,
    timeStamp,
    isCanceled,
}) => {
    // amount of all payments that payments that have not been done yet
    const openAmount = scheduledPayments?.reduce(
        (acc, payment) =>
            payment?.paymentDone === null
                ? acc + payment.paymentAmount
                : acc + 0,
        0,
    );

    const doneAmount = scheduledPayments?.reduce(
        (acc, payment) =>
            // eslint-disable-next-line no-negated-condition
            payment?.paymentDone !== null
                ? acc + payment.paymentAmount
                : acc + 0,
        0,
    );

    const sumAmount = scheduledPayments?.reduce(
        (acc, payment) => acc + payment.paymentAmount,
        0,
    );

    const SumAmount = () => {
        return (
            <div>
                <div className={css.sumAmount}>
                    <div className={css.sumAmountText}>
                        {i18n.containers.invoice.InvoiceDetails.sumAmount()}
                    </div>
                    <div className={css.sumAmountValue}>
                        <RenderAmount
                            amount={sumAmount ?? 0}
                            style={{fontSize: "unset", fontWeight: "unset"}}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <PanelCard
            columns={1}
            title={i18n.containers.invoice.InvoiceDetails.payments()}
            gapAfter
            extra={
                <Space>
                    {!scheduledPayments?.length &&
                        booking?.canceled === null &&
                        // highestRole === roles.Superuser &&
                        bookingId && (
                            <GenerateSchedulePaymentsButton
                                bookingId={bookingId}
                            />
                        )}
                    <SumAmount />
                </Space>
            }
        >
            {!isCanceled && bookingId && (
                <>
                    <OpenPaymentsList
                        tablesEnum={TablesEnum.SettledBookingOpenPaymentsList}
                        hideTitle
                        mandatoryColumns={["paymentDue", "title"]}
                        customColumns={[
                            "paymentDue",
                            "paymentMethod",
                            "paymentAmount",
                            "setPaidAction",
                        ]}
                        // hidePagination
                        hideXmlBtn
                        style={{
                            marginBottom: marginXs,
                            marginTop: "30px",
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: -10,
                            marginRight: -10,
                            width: "calc(100% + 10px)",
                        }}
                        bookingId={bookingId}
                        hiddenFilters={["courseNumber"]}
                        minPadding
                        pollInterval={pollInterval}
                        tableTitle={
                            <Space>
                                {"Offene Posten"}
                                <RenderAmount
                                    amount={openAmount ?? 0}
                                    style={{
                                        fontSize: "unset",
                                        fontWeight: "unset",
                                    }}
                                />
                            </Space>
                        }
                        hideSpecialFunctions
                        timeStamp={timeStamp}
                        hideFooter
                    />
                    <MemberDiscount booking={booking} />
                    <AddNamedScheduledPayment
                        bookingId={bookingId}
                        monthlySchedule={booking?.monthlySchedule}
                    />
                </>
            )}
            <DonePaymentsList
                tablesEnum={TablesEnum.SettledBookingDonePaymentsList}
                hideTitle
                customColumns={[
                    "paymentDone",
                    "paymentMethod",
                    "paymentAmount",
                    "setUnPaidAction",
                ]}
                // hidePagination
                hideXmlBtn
                style={{
                    marginBottom: marginXs,
                    marginTop: margin,
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: -10,
                    marginRight: -10,
                    width: "calc(100% + 10px)",
                }}
                bookingId={bookingId}
                hiddenFilters={["courseNumber"]}
                minPadding
                pollInterval={pollInterval}
                tableTitle={
                    <Space>
                        {"Bezahlte Posten"}{" "}
                        <RenderAmount
                            amount={doneAmount ?? 0}
                            style={{fontSize: "unset", fontWeight: "unset"}}
                        />
                    </Space>
                }
                hideSpecialFunctions
                timeStamp={timeStamp}
                hideFooter
            />
        </PanelCard>
    );
};

export default memo(PanelCardPayments);
