import {blue, red, volcano} from "@ant-design/colors";
import React from "react";

export const LeftMenuBookingIcon = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path
            d="M18,2 L6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,4 C20,2.9 19.1,2 18,2 Z M6,4 L11,4 L11,12 L8.5,10.5 L6,12 L6,4 Z"
            id="Shape"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const AbosSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            fill="currentColor"
            d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z"
        />
    </svg>
);

export const AbzeichenSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 19 22">
        <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Tablet" transform="translate(-128.000000, -50.000000)">
                <g
                    id="Certificate"
                    transform="translate(124.000000, 49.000000)"
                >
                    <path
                        d="M17,1 C18.6568542,1 20,2.34314575 20,4 L20,16.6553033 C20,18.3121576 18.6568542,19.6553033 17,19.6553033 L14.6623884,19.6566392 C14.5843913,19.8749864 14.479822,20.0808822 14.3525327,20.2705088 L14.3529412,21.9201578 C14.3529412,22.4724426 13.9052259,22.9201578 13.3529412,22.9201578 C13.0892797,22.9201578 12.8362871,22.8160295 12.6490154,22.6304314 L11.9990588,21.9865382 L11.3509846,22.6304314 C10.9587114,23.0191988 10.3255528,23.0163569 9.9367853,22.6240836 C9.7511872,22.4368119 9.64705882,22.1838193 9.64705882,21.9201578 L9.64746734,20.2705088 C9.52017798,20.0808822 9.41560868,19.8749864 9.33761158,19.6566392 L7,19.6553033 C5.34314575,19.6553033 4,18.3121576 4,16.6553033 L4,4 C4,2.34314575 5.34314575,1 7,1 L17,1 Z M12.9411765,18.7225382 C12.9411765,18.2073862 12.5197974,17.789773 12,17.789773 C11.4802026,17.789773 11.0588235,18.2073862 11.0588235,18.7225382 C11.0588235,19.2008936 11.4221555,19.5951466 11.8902389,19.6490279 L12,19.6553033 L12,19.6553033 C12.5197974,19.6553033 12.9411765,19.2376901 12.9411765,18.7225382 Z M17.1176471,2.86553033 L6.88235294,2.86553033 C6.3695171,2.86553033 5.94684578,3.25157052 5.88908067,3.74890921 L5.88235294,3.86553033 L5.88235294,16.789773 C5.88235294,17.3026088 6.26839313,17.7252801 6.76573182,17.7830453 L6.88235294,17.789773 L9.33750891,17.7887246 C9.72541817,16.7024214 10.7710129,15.9242427 12,15.9242427 C13.2289871,15.9242427 14.2745818,16.7024214 14.6624911,17.7887246 L17.1176471,17.789773 C17.6304829,17.789773 18.0531542,17.4037328 18.1109193,16.9063941 L18.1176471,16.789773 L18.1176471,3.86553033 C18.1176471,3.31324558 17.6699318,2.86553033 17.1176471,2.86553033 Z"
                        id="Combined-Shape"
                        fill="currentColor"
                    />
                    <path
                        d="M8,5 L16,5 C16.5522847,5 17,5.44771525 17,6 C17,6.55228475 16.5522847,7 16,7 L8,7 C7.44771525,7 7,6.55228475 7,6 C7,5.44771525 7.44771525,5 8,5 Z"
                        id="Rectangle-Copy-6"
                        fill="currentColor"
                    />
                    <rect
                        id="Rectangle-Copy-7"
                        fill="currentColor"
                        x="8"
                        y="9"
                        width="8"
                        height="2"
                        rx="1"
                    />
                    <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                </g>
            </g>
        </g>
    </svg>
);

export const AccountSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const AttendeesSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h20v20H0V0z" />
        <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const CoursesSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const DashboardSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path
            d="M13 13v8h8v-8h-8zM3 21h8v-8H3v8zM3 3v8h8V3H3zm13.66-1.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65z"
            fill="currentColor"
        />
    </svg>
);

export const LocationsSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            fill="currentColor"
            d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const MenuSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 18 18">
        <path
            d="M 2 13.875 L 16 13.875 C 16.481 13.875 16.875 13.481 16.875 13 C 16.875 12.519 16.481 12.125 16 12.125 L 2 12.125 C 1.519 12.125 1.125 12.519 1.125 13 C 1.125 13.481 1.519 13.875 2 13.875 Z M 2 9.5 L 16 9.5 C 16.481 9.5 16.875 9.106 16.875 8.625 C 16.875 8.144 16.481 7.75 16 7.75 L 2 7.75 C 1.519 7.75 1.125 8.144 1.125 8.625 C 1.125 9.106 1.519 9.5 2 9.5 Z M 1.125 4.25 C 1.125 4.731 1.519 5.125 2 5.125 L 16 5.125 C 16.481 5.125 16.875 4.731 16.875 4.25 C 16.875 3.769 16.481 3.375 16 3.375 L 2 3.375 C 1.519 3.375 1.125 3.769 1.125 4.25 Z"
            fill="currentColor"
        />
    </svg>
);

export const PostBoxSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path fill="transparent" d="M0 0h24v24H0V0z" />
        <path
            fill="currentColor"
            d="M19 3H5c-1.1 0-2 .9-2 2v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 6h-4c0 1.62-1.38 3-3 3s-3-1.38-3-3H5V5h14v4zm-4 7h6v3c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3z"
        />
    </svg>
);

export const ReportsSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
            fill="currentColor"
            d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"
        />
    </svg>
);

export const SettingsSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h20v20H0V0z" />
        <path
            fill="currentColor"
            d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
        />
    </svg>
);

export const StaffSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            fill="currentColor"
            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
        />
    </svg>
);

export const StaffLoginSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width="1.35em"
        height="1.35em"
        fill="currentColor"
    >
        <path d="M440-280h320v-22q0-45-44-71.5T600-400q-72 0-116 26.5T440-302v22Zm160-160q33 0 56.5-23.5T680-520q0-33-23.5-56.5T600-600q-33 0-56.5 23.5T520-520q0 33 23.5 56.5T600-440ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z" />
    </svg>
);

export const TransactionsSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            fill="currentColor"
            d="M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z"
        />
    </svg>
);

export const InvoicesSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M10,17H5v-2h5V17z M10,13H5v-2h5V13z M10,9H5V7h5V9z M14.82,15L12,12.16l1.41-1.41l1.41,1.42L17.99,9 l1.42,1.42L14.82,15z"
            fill="currentColor"
        />
    </svg>
);

export const VorlagenSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path
            d="M10.9813324,5.51381163e-12 L10.9813324,7 L17.969453,7 L17.969453,22 C17.969453,23.1045695 17.0740225,24 15.969453,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,5.4003277e-12 2,5.40012479e-12 L10.9813324,5.51381163e-12 Z M10.9813324,18 L2.99490883,18 L2.99490883,20 L10.9813324,20 L10.9813324,18 Z M14.9745441,14 L2.99490883,14 L2.99490883,16 L14.9745441,16 L14.9745441,14 Z M14.9745441,10 L2.99490883,10 L2.99490883,12 L14.9745441,12 L14.9745441,10 Z M6.9881206,4 L2.99490883,4 L2.99490883,8 L6.9881206,8 L6.9881206,4 Z M12.0101823,0.0379231771 L18,6.03792318 L12.0101823,6.03792318 L12.0101823,0.0379231771 Z"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const NotificationsSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="2 0 24 24">
        <path
            // d="M10.9813324,5.51381163e-12 L10.9813324,7 L17.969453,7 L17.969453,22 C17.969453,23.1045695 17.0740225,24 15.969453,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,5.4003277e-12 2,5.40012479e-12 L10.9813324,5.51381163e-12 Z M10.9813324,18 L2.99490883,18 L2.99490883,20 L10.9813324,20 L10.9813324,18 Z M14.9745441,14 L2.99490883,14 L2.99490883,16 L14.9745441,16 L14.9745441,14 Z M14.9745441,10 L2.99490883,10 L2.99490883,12 L14.9745441,12 L14.9745441,10 Z M6.9881206,4 L2.99490883,4 L2.99490883,8 L6.9881206,8 L6.9881206,4 Z M12.0101823,0.0379231771 L18,6.03792318 L12.0101823,6.03792318 L12.0101823,0.0379231771 Z"
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const WaitingListsSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const WeekPlannerSvg = () => (
    <svg width="1.3em" height="1.3em" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            fill="currentColor"
            d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z"
        />
    </svg>
);

export const MemberIconSvg = ({
    size = 24,
    color,
    style,
}: {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    // aspect ratio: 125/100 = 1.25
    <svg
        // viewBox="0 0 100 125"
        viewBox="0 0 100 100"
        width={size / 1.25}
        height={size}
        style={{...style, marginBottom: -size / 3}}
    >
        <path
            d="m14.789 50c0-19.445 15.766-35.211 35.211-35.211s35.211 15.766 35.211 35.211c0 9.2734-3.582 17.703-9.4375 23.992-1.1758 1.2617-1.1094 3.2422 0.15625 4.418 1.2617 1.1758 3.2383 1.1055 4.4141-0.15625 6.8945-7.4023 11.117-17.34 11.117-28.254 0-22.898-18.562-41.461-41.461-41.461s-41.461 18.562-41.461 41.461c0 10.914 4.2227 20.852 11.117 28.254 1.1758 1.2617 3.1523 1.332 4.4141 0.15625 1.2656-1.1758 1.332-3.1562 0.15625-4.418-5.8594-6.2891-9.4375-14.719-9.4375-23.992zm25.383-4.9961c0-5.4258 4.4023-9.8281 9.8281-9.8281s9.8281 4.4023 9.8281 9.8281c0 5.418-4.3867 9.8125-9.8047 9.8242h-0.046876c-5.418-0.011719-9.8047-4.4062-9.8047-9.8242zm25.906 0c0 4.6055-1.9375 8.7578-5.0391 11.688 4.5 1.5703 8.5664 4.0703 11.961 7.2695 1.2539 1.1875 1.3125 3.1641 0.12891 4.418-1.1836 1.2578-3.1641 1.3164-4.418 0.12891-4.8789-4.6016-11.449-7.4219-18.684-7.4297l-0.027344 0.003906-0.027344-0.003906c-7.2344 0.007813-13.805 2.8281-18.684 7.4297-1.2539 1.1875-3.2344 1.1289-4.418-0.12891-1.1836-1.2539-1.125-3.2305 0.12891-4.418 3.3945-3.1992 7.4609-5.6992 11.961-7.2695-3.1016-2.9297-5.0391-7.082-5.0391-11.688 0-8.8789 7.1992-16.078 16.078-16.078s16.078 7.1992 16.078 16.078zm-2.2539 29.043c1.2188-1.2188 1.2188-3.1992 0-4.418-1.2227-1.2227-3.1992-1.2227-4.4219 0l-10.961 10.961-5.0195-5.0156c-1.2188-1.2188-3.1992-1.2188-4.418 0-1.2188 1.2227-1.2188 3.1992 0 4.418l7.2266 7.2266c0.58594 0.58984 1.3828 0.91797 2.2109 0.91797s1.6211-0.32812 2.207-0.91797z"
            fill={color ?? "currentColor"}
        />
    </svg>
);

export const PrerequisiteCoursesIconSvg = ({
    size = 24,
    color,
    style,
}: {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg
        width={size}
        height={size}
        viewBox="10 30 90 65"
        fill={color}
        style={style}
    >
        <path
            d="M84.375,18.75L15.625,18.75C10.457,18.75 6.25,22.957 6.25,28.125L6.25,71.875C6.25,77.043 10.457,81.25 15.625,81.25L84.375,81.25C89.543,81.25 93.75,77.043 93.75,71.875L93.75,28.125C93.75,22.957 89.543,18.75 84.375,18.75ZM87.5,71.875C87.5,73.598 86.098,75 84.375,75L15.625,75C13.902,75 12.5,73.598 12.5,71.875L12.5,28.125C12.5,26.402 13.902,25 15.625,25L84.375,25C86.098,25 87.5,26.402 87.5,28.125L87.5,71.875Z"
            transform="translate(5.47844,10.0172)"
        />
        <path
            d="M49.246,43.438L41.414,41.34L37,34.536C35.848,32.766 32.91,32.766 31.758,34.536L27.344,41.34L19.512,43.438C18.488,43.711 17.676,44.485 17.348,45.489C17.019,46.497 17.226,47.602 17.89,48.422L22.996,54.727L22.57,62.825C22.516,63.883 23,64.895 23.855,65.516C24.715,66.137 25.824,66.286 26.812,65.907L34.375,63.001L41.945,65.907C42.308,66.047 42.687,66.114 43.066,66.114C43.715,66.114 44.359,65.911 44.902,65.516C45.758,64.895 46.242,63.883 46.187,62.825L45.762,54.727L50.867,48.422C51.531,47.602 51.738,46.497 51.41,45.489C51.082,44.485 50.269,43.715 49.246,43.438ZM40.148,51.727C39.664,52.325 39.414,53.087 39.457,53.856L39.695,58.344L35.496,56.735C35.137,56.598 34.754,56.528 34.375,56.528C33.996,56.528 33.613,56.598 33.254,56.735L29.055,58.344L29.293,53.856C29.332,53.083 29.086,52.325 28.601,51.727L25.769,48.231L30.113,47.067C30.859,46.868 31.504,46.399 31.926,45.751L34.375,41.981L36.824,45.754C37.246,46.403 37.891,46.872 38.637,47.071L42.98,48.235L40.148,51.727Z"
            transform="translate(5.47844,10.0172)"
        />
        <path
            d="M70.312,53.125L60.937,53.125C59.21,53.125 57.812,54.523 57.812,56.25C57.812,57.977 59.21,59.375 60.937,59.375L70.312,59.375C72.039,59.375 73.437,57.977 73.437,56.25C73.437,54.523 72.039,53.125 70.312,53.125Z"
            transform="translate(5.47844,10.0172)"
        />
        <path
            d="M79.688,40.625L60.938,40.625C59.211,40.625 57.813,42.023 57.813,43.75C57.813,45.477 59.211,46.875 60.938,46.875L79.688,46.875C81.415,46.875 82.813,45.477 82.813,43.75C82.813,42.023 81.415,40.625 79.688,40.625Z"
            transform="translate(5.47844,10.0172)"
        />
    </svg>
);

// NOT IN USE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HidenCourseSettingsIconSvg = ({
    size = 24,
    color,
    style,
}: {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <circle cx="12" cy="12" r="10" fill="#FF4B55" />
        <path
            fill="white"
            d="M15.9,14.6l-0.7-0.4c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.1-0.6l0.7-0.4c0.2-0.1,0.2-0.3,0.1-0.5l-0.7-1.2
        c-0.1-0.2-0.3-0.2-0.5-0.1l-0.7,0.4c-0.3-0.2-0.6-0.4-0.9-0.5l-0.1-0.8c0-0.2-0.2-0.3-0.4-0.3h-1.3c-0.2,0-0.4,0.2-0.4,0.3l-0.1,0.8
        c-0.3,0.1-0.6,0.3-0.9,0.5l-0.7-0.4c-0.2-0.1-0.4-0.1-0.5,0.1L8,11.1c-0.1,0.2-0.1,0.4,0.1,0.5l0.7,0.4c-0.1,0.2-0.1,0.4-0.1,0.6
        s0.1,0.4,0.1,0.6l-0.7,0.4c-0.2,0.1-0.2,0.3-0.1,0.5l0.7,1.2c0.1,0.2,0.3,0.2,0.5,0.1l0.7-0.4c0.3,0.2,0.6,0.4,0.9,0.5l0.1,0.8
        c0,0.2,0.2,0.3,0.4,0.3h1.3c0.2,0,0.4-0.2,0.4-0.3l0.1-0.8c0.3-0.1,0.6-0.3,0.9-0.5l0.7,0.4c0.2,0.1,0.4,0.1,0.5-0.1l0.7-1.2
        C16.1,14.9,16.1,14.7,15.9,14.6z"
        />
    </svg>
);

export const HidenCourseTypeSettingsIconSvg = ({
    size = 24,
    color,
    style,
}: {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <circle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        />
        <rect x="8" y="8" width="8" height="8" fill="currentColor" />
        <line
            x1="5"
            y1="19"
            x2="19"
            y2="5"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export const HidenLocationSettingsIconSvg = ({
    size = 24,
    color,
    style,
}: {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
    >
        <circle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8z" fill="currentColor" />
        <line
            x1="5"
            y1="19"
            x2="19"
            y2="5"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export const RedCrossSvg = ({
    style,
    color,
    size = 26,
    padding = 3,
}: {
    color?: string;
    style?: React.CSSProperties;
    size?: number;
    padding?: number;
}) => {
    const circleSize = size - padding;
    const crossFactor = circleSize / 4.5;
    const crossSize = circleSize - crossFactor;

    console.log("crossSize", crossSize);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${size} ${size}`}
            width={size}
            height={size}
            style={{...style}}
        >
            <circle
                cx={size / 2}
                cy={size / 2}
                r={circleSize / 2}
                fill={color ?? "black"}
            />
            <path
                d={`
            M${size - crossSize} ${size - crossSize} 
            L${size - (size - crossSize)} ${size - (size - crossSize)}
            M${size - crossSize} ${size - (size - crossSize)}
            L${size - (size - crossSize)} ${size - crossSize}
            `}
                stroke="white"
                strokeWidth={Math.max(1, 1.5)}
            />
        </svg>
    );
};

export const CoursesSvgSizable = ({
    size = 24,
    style,
}: {
    size?: number;
    style?: React.CSSProperties;
}) => (
    <svg
        // width="1.3em"
        // height="1.3em"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        // viewBox={`0 0 ${size} ${size}`}
        style={{...style}}
    >
        <path
            fill="currentColor"
            d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const LocationsSvgSizable = ({
    size = 24,
    style,
}: {
    size?: number;
    style?: React.CSSProperties;
}) => (
    <svg
        // width="1.3em"
        // height="1.3em"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        // viewBox={`0 0 ${size} ${size}`}
        style={{...style}}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            fill="currentColor"
            d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const CoursesTestSvg = ({
    style,
    color,
    size = 26,
    padding = 3,
}: {
    color?: string;
    style?: React.CSSProperties;
    size?: number;
    padding?: number;
}) => {
    const circleSize = size - padding;
    const crossFactor = circleSize / 4.5;
    const crossSize = circleSize - crossFactor;
    const position = "relative";
    const smallIconSize = 12;

    return (
        <div
            style={{
                height: `${size}`,
                width: `${size}`,
                marginTop: -smallIconSize + padding,
            }}
        >
            <CoursesSvgSizable
                size={smallIconSize}
                style={{
                    // position: "absolute",
                    top: 6,
                    left: 14,
                    color,
                    marginLeft: -10,
                    position,
                }}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${size} ${size}`}
                width={size}
                height={size}
                style={{
                    ...style,
                    position,
                    marginTop: -size * 2,
                }}
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={circleSize / 2}
                    fill={color ?? "black"}
                />
                <path
                    d={`
                        M${size - crossSize} ${size - crossSize} 
                        L${size - (size - crossSize)} ${size -
                        (size - crossSize)}
                        M${size - crossSize} ${size - (size - crossSize)}
                        L${size - (size - crossSize)} ${size - crossSize}
                    `}
                    stroke="white"
                    strokeWidth={Math.max(1, 1.5)}
                />
            </svg>
        </div>
    );
};

export const CourseTypeTestSvg = ({
    style,
    color,
    size = 26,
    padding = 3,
}: {
    color?: string;
    style?: React.CSSProperties;
    size?: number;
    padding?: number;
}) => {
    const circleSize = size - padding;
    const crossFactor = circleSize / 4.5;
    const crossSize = circleSize - crossFactor;
    const position = "relative";
    const smallIconSize = 12;

    return (
        <div
            style={{
                height: `${size}`,
                width: `${size}`,
                marginTop: -smallIconSize + padding,
            }}
        >
            <CoursesSvgSizable
                size={smallIconSize}
                style={{
                    top: 6,
                    left: 14,
                    color,
                    marginLeft: -10,
                    position,
                }}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${size} ${size}`}
                width={size}
                height={size}
                style={{
                    ...style,
                    position,
                    marginTop: -size * 2,
                }}
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={circleSize / 2}
                    fill={color ?? "black"}
                />
                <path
                    d={`
                        M${size - crossSize} ${size - crossSize} 
                        L${size - (size - crossSize)} ${size -
                        (size - crossSize)}
                        M${size - crossSize} ${size - (size - crossSize)}
                        L${size - (size - crossSize)} ${size - crossSize}
                    `}
                    stroke="white"
                    strokeWidth={Math.max(1, 1.5)}
                />
            </svg>
        </div>
    );
};

export const LocationTestSvg = ({
    style,
    color,
    size = 26,
    padding = 3,
}: {
    color?: string;
    style?: React.CSSProperties;
    size?: number;
    padding?: number;
}) => {
    const circleSize = size - padding;
    const crossFactor = circleSize / 4.5;
    const crossSize = circleSize - crossFactor;
    const position = "relative";
    const smallIconSize = 12;

    return (
        <div
            style={{
                height: `${size}`,
                width: `${size}`,
                marginTop: -smallIconSize + padding,
            }}
        >
            <LocationsSvgSizable
                size={smallIconSize}
                style={{
                    // position: "absolute",
                    top: 6,
                    left: 14,
                    color,
                    marginLeft: -10,
                    position,
                }}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${size} ${size}`}
                width={size}
                height={size}
                style={{
                    ...style,
                    position,
                    marginTop: -size * 2,
                }}
            >
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={circleSize / 2}
                    fill={color ?? "black"}
                />
                <path
                    d={`
                        M${size - crossSize} ${size - crossSize} 
                        L${size - (size - crossSize)} ${size -
                        (size - crossSize)}
                        M${size - crossSize} ${size - (size - crossSize)}
                        L${size - (size - crossSize)} ${size - crossSize}
                    `}
                    stroke="white"
                    strokeWidth={Math.max(1, 1.5)}
                />
            </svg>
        </div>
    );
};

export const IconMarkdownSvg = ({
    style,
    color,
    size = 24,
    padding = 2,
}: {
    color?: string;
    style?: React.CSSProperties;
    size?: number;
    padding?: number;
}) => {
    const position = "relative";
    const ratio = 1.5;

    return (
        <svg
            fill={color ?? "black"}
            width={size}
            height={size}
            viewBox="0 0 24.00 24.00"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#000000"
            strokeWidth="0.00024000000000000003"
            style={{
                marginBottom: -5,
                ...style,
            }}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#CCCCCC"
                strokeWidth="0.72"
            />
            <g id="SVGRepo_iconCarrier">
                <path d="M22.269 19.385H1.731a1.73 1.73 0 0 1-1.73-1.73V6.345a1.73 1.73 0 0 1 1.73-1.73h20.538a1.73 1.73 0 0 1 1.73 1.73v11.308a1.73 1.73 0 0 1-1.73 1.731zm-16.5-3.462v-4.5l2.308 2.885 2.307-2.885v4.5h2.308V8.078h-2.308l-2.307 2.885-2.308-2.885H3.461v7.847zM21.231 12h-2.308V8.077h-2.307V12h-2.308l3.461 4.039z" />
            </g>
        </svg>
    );
};
