/* eslint-disable react/no-array-index-key */
/* eslint-disable no-negated-condition */
import React, {FC, memo} from "react";
import {Tabs} from "antd";
import css from "./WelcomeTabsWrapper.less";
import TextBlock from "../TextBlock/TextBlock";
import {formattedText} from "../../helpers/formati18nText";

const {TabPane} = Tabs;

type WelcomeTabsWrapperProps = {
    content: Array<any>;
};

const WelcomeTabsWrapper: FC<WelcomeTabsWrapperProps> = ({
    content = [
        {
            key: "1",
            title: "Tab1",
            headline: "Headline",
            text: ["Paragraph 1", "Paragraph2"],
            features: [],
            image: "",
        },
        {
            key: "2",
            title: "Tab2",
            headline: "Headline",
            text: ["Paragraph 1", "Paragraph2"],
            features: [],
            image: "",
        },
    ],
}) => {
    return (
        <div className={css.root}>
            <Tabs size="large" className={css.tabBar}>
                {content.map((tab) => {
                    return (
                        <TabPane tab={tab.title} key={tab.key}>
                            {/* {tab.image !== "" ? (
                                <div className={css.imgContainer}>
                                    <img
                                        className={css.image}
                                        src={tab.image}
                                        alt={tab.title}
                                    />
                                </div>
                            ) : null} */}
                            <TextBlock
                                title={tab.headLine}
                                text={tab.text}
                                width={700}
                                alignment={tab.alignment}
                                paddingTop={15}
                            >
                                <ul>
                                    {tab.features.map(
                                        (feature: string, index: number) => {
                                            console.log("feature", feature);

                                            return (
                                                <li
                                                    key={index}
                                                    // eslint-disable-next-line react/no-danger
                                                    dangerouslySetInnerHTML={{
                                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                                        __html: formattedText(
                                                            feature,
                                                        ),
                                                    }}
                                                />
                                            );
                                        },
                                    )}
                                </ul>
                            </TextBlock>
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default memo(WelcomeTabsWrapper);
