import React, {memo, FC, useState, useRef} from "react";
import {useDrop} from "react-dnd";
import css from "./CardGrid.less";
import DashBoardCard from "../DashBoardCard/DashBoardCard";
import {ItemTypes} from "../DragTypes";
import DropZone from "./DropZone";
import {DashboardConfig, DraggableCard} from "./DashboardOverview";

type CardGridProps = {
    cards: Array<DraggableCard>;
    setCards: (cards: Array<DraggableCard>) => void;
    dashboardConfig: DashboardConfig;
};

const CardGridComponent: FC<CardGridProps> = ({
    cards,
    setCards,
    dashboardConfig,
}) => {
    const {cardHeight, dashColumns} = dashboardConfig;
    const [dropPosition, setDropPosition] = useState<number | null>(null);

    // const cardHeight = 200;
    const cardWidth = 200;
    const columns = dashColumns;
    const gridRef = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover: (item: DraggableCard, monitor) => {
            const dragIndex = cards.findIndex((card) => card.id === item.id);
            const clientOffset = monitor.getClientOffset();

            if (!clientOffset || dragIndex < 0) return;

            const hoverClientY = clientOffset.y;
            const hoverClientX = clientOffset.x;
            const hoverBoundingRect = gridRef.current?.getBoundingClientRect();

            if (!hoverBoundingRect) return;

            const hoverY = hoverClientY - hoverBoundingRect.top;
            const hoverX = hoverClientX - hoverBoundingRect.left;
            const dropRow = Math.floor(hoverY / cardHeight);
            const dropCol = Math.min(
                columns - 1,
                Math.floor(hoverX / cardWidth / 2),
            );
            const dropPosition = dropRow * columns + dropCol;

            setDropPosition(dropPosition);
        },
        drop: (item: DraggableCard, monitor) => {
            const dragIndex = cards.findIndex((card) => card.id === item.id);

            if (dragIndex < 0 || dropPosition === null) return;

            const updatedCards = [...cards];
            const [removed] = updatedCards.splice(dragIndex, 1);

            updatedCards.splice(dropPosition, 0, removed);

            setCards(updatedCards);
            setDropPosition(null);
        },
    });

    drop(gridRef);

    const renderContent = () => {
        const content = [];
        let currentPosition = 0;

        for (const card of cards) {
            while (currentPosition === dropPosition) {
                content.push(
                    <DropZone
                        key={"dropzone-" + currentPosition}
                        dashboardConfig={dashboardConfig}
                    />,
                );
                currentPosition++;
            }
            content.push(
                <DashBoardCard
                    style={{
                        borderRadius: 15,
                        // overflow: "hidden",
                    }}
                    key={card.id}
                    id={card.id}
                    title={card.title}
                    content={card.content}
                    actions={card.actions}
                    extra={card.extra}
                    dashboardConfig={dashboardConfig}
                >
                    {card.content}
                </DashBoardCard>,
            );
            currentPosition++;
        }

        while (currentPosition === dropPosition) {
            content.push(
                <DropZone
                    key={"dropzone-" + currentPosition}
                    dashboardConfig={dashboardConfig}
                />,
            );
            currentPosition++;
        }

        return content;
    };

    type GardGridCSSProperties = React.CSSProperties & {
        "--columnCount": number;
    };

    const actualStyle: GardGridCSSProperties = {
        "--columnCount": dashboardConfig.dashColumns,
    };

    return (
        <div ref={gridRef} className={css.gridContainer} style={actualStyle}>
            {renderContent()}
        </div>
    );
};

export default memo(CardGridComponent);
