import tinycolor from "tinycolor2";

export type Color = {
    name?: string;
    value: string;
};

export const getTextColor = (color?: Color): string =>
    color !== undefined && tinycolor(color.value).isLight()
        ? "rgba(0, 0, 0, 0.7)"
        : "rgba(255, 255, 255, 0.8)";
