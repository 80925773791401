import {FilePdfOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {FC, memo, useState} from "react";
import {
    GetInvoiceBodyByIdQuery,
    GetReversalInvoiceBodyByIdQuery,
    Invoice,
    useGetInvoiceBodyByIdLazyQuery,
    useGetReversalInvoiceBodyByIdLazyQuery,
} from "../../generated/graphql";
import PDFViewerModal from "../PDFViewerModal/PDFViewerModal";

type RenderInvoicePreviewProps = {
    invoiceData: {
        id: Invoice["id"];
        invoiceNumber: number;
        invoiceNumberPrefix: string;
        isReversalInvoice?: boolean;
    };
};

const RenderInvoicePreview: FC<RenderInvoicePreviewProps> = ({invoiceData}) => {
    const [isOpen, setIsOpen] = useState(false);

    const {
        id,
        invoiceNumber,
        invoiceNumberPrefix,
        // canceled,
        isReversalInvoice = false,
    } = invoiceData;

    const [getInvoice, {data, loading}] = useGetInvoiceBodyByIdLazyQuery({
        fetchPolicy: "network-only",
    });

    const invoice: GetInvoiceBodyByIdQuery["invoiceBodyById"] | undefined =
        data?.invoiceBodyById;

    const [
        getReversalInvoiceBody,
        {data: reversalData, loading: reversalLoading},
    ] = useGetReversalInvoiceBodyByIdLazyQuery({variables: {id}});

    const reversalInvoice:
        | GetReversalInvoiceBodyByIdQuery["reversalInvoiceBodyById"]
        | undefined = reversalData?.reversalInvoiceBodyById;

    const handleClick = () => {
        if (isReversalInvoice) {
            getReversalInvoiceBody({
                variables: {id},
            });
            setIsOpen(true);
        } else {
            getInvoice({variables: {id}});
            setIsOpen(true);
        }
    };

    const renderModal = () => {
        if (reversalInvoice) {
            return (
                <PDFViewerModal
                    title={reversalInvoice.reversalInvoiceFileName}
                    visible={isOpen}
                    handleOk={() => {
                        setIsOpen(false);
                    }}
                    handleCancel={() => {
                        setIsOpen(false);
                    }}
                    invoiceBody={reversalInvoice.reversalInvoiceBody}
                    invoiceFileName={reversalInvoice.reversalInvoiceFileName}
                />
            );
        } else if (invoice) {
            return (
                <PDFViewerModal
                    title={invoice.invoiceFileName}
                    visible={isOpen}
                    handleOk={() => {
                        setIsOpen(false);
                    }}
                    handleCancel={() => {
                        setIsOpen(false);
                    }}
                    invoiceBody={invoice.invoiceBody}
                    invoiceFileName={invoice.invoiceFileName}
                />
            );
        }

        return null;
    };

    return (
        <>
            <Button
                onClick={handleClick}
                size="small"
                style={{fontSize: 11, height: 20}}
                loading={loading || reversalLoading}
            >
                {loading || reversalLoading ? null : (
                    <FilePdfOutlined style={{color: "rgb(7, 96, 255)"}} />
                )}
                {`${invoiceNumberPrefix} - ${invoiceNumber}`}
            </Button>
            {renderModal()}
        </>
    );
};

export default memo(RenderInvoicePreview);
