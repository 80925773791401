import React, {FC, memo} from "react";
import {Empty, Select} from "antd";
import {SelectProps} from "antd/es/select";
import moment from "moment";
import css from "./TableSelectInstructors.less";
import {Instructor} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {getPopupContainer} from "../../helpers/getPopupContainer";

type TableSelectInstructorsProps = SelectProps & {
    options?: Array<Partial<Instructor>>;
    value?: Array<string>;
    multiple?: boolean;
};

// const TableSelectInstructors: FC<TableSelectInstructorsProps> = ({
//     options = [],
//     ...restProps
// }) => {
//     return (
//         <Select
//             className={css.root}
//             mode="multiple"
//             showArrow
//             size="small"
//             {...restProps}
//         >
//             {Array.isArray(options) &&
//                 options.map((instructor) => (
//                     <Select.Option key={instructor.id} value={instructor.id}>
//                         {instructor.firstname + " " + instructor.lastname}
//                     </Select.Option>
//                 ))}
//         </Select>
//     );
// };

// export default memo(TableSelectInstructors);

const TableSelectInstructors: FC<TableSelectInstructorsProps> = ({
    options = [],
    value,
    ...restProps
}) => {
    type LeaveDateResult = "leaveDateInPast" | "isLeaveDateInNext90Days" | null;
    const instructors = options;
    const renderLevel = (level: number) => {
        if (level === 1) {
            return "[KL]";
        }

        return "[AS]";
    };
    const checkLeaveDate = (
        leaveDate: string | null | undefined,
    ): LeaveDateResult => {
        if (leaveDate === null || leaveDate === undefined) {
            return null;
        }
        const leaveDateInPast = new Date(leaveDate) < new Date();
        const leaveDateAsDate = new Date(leaveDate);
        const today = new Date();
        const ninetyDaysFromNow = new Date();

        ninetyDaysFromNow.setDate(today.getDate() + 90);

        const isLeaveDateInNext90Days = leaveDateAsDate < ninetyDaysFromNow;

        if (leaveDateInPast) {
            return "leaveDateInPast";
        }

        if (isLeaveDateInNext90Days) {
            return "isLeaveDateInNext90Days";
        }

        return null;
    };
    const renderLeaveDate = (leaveDate?: string | null, active?: boolean) => {
        if (leaveDate === null || leaveDate === undefined) {
            return "";
        } else if (active === false) {
            return "";
        } else if (leaveDate) {
            const leaveDateInPast = new Date(leaveDate) < new Date();
            const leaveDateAsDate = new Date(leaveDate);
            const today = new Date();
            const ninetyDaysFromNow = new Date();

            ninetyDaysFromNow.setDate(today.getDate() + 90);

            const isLeaveDateInNext90Days = leaveDateAsDate < ninetyDaysFromNow;

            if (leaveDateInPast) {
                return `Austrittsdatum ${moment(leaveDate).format(
                    "DD.MM.YYYY",
                )}`;
            }

            if (isLeaveDateInNext90Days) {
                return `Austrittsdatum ist ${moment(leaveDate).format(
                    "DD.MM.YYYY",
                )}`;
            }
        }

        return "";
    };

    const renderIsInactive = (active?: boolean) => {
        if (active) {
            return "";
        }

        return ` !${i18n.containers.courses.CourseCreate.courseTrainer.inactiveShort()}`;
    };

    const instructorsOptions = instructors.map(
        (instructor: Partial<Instructor>) => ({
            value: instructor.id,
            label: `${instructor.firstname} ${instructor.lastname}${renderLevel(
                Number(instructor.level ?? 0),
            )} ${renderLeaveDate(
                instructor.leaveDate,
                instructor.active,
            )}${renderIsInactive(instructor.active)}`,
            level: instructor.level,
            className:
                instructor.active === false ||
                checkLeaveDate(instructor.leaveDate) !== null
                    ? css.inactive
                    : "",
        }),
    );

    return (
        <>
            <Select
                options={instructorsOptions}
                filterOption={(inputValue, option) => {
                    if (
                        !option ||
                        !option.label ||
                        typeof option.label !== "string"
                    ) {
                        return false;
                    }

                    return option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase());
                }}
                filterSort={(optionA, optionB) =>
                    typeof optionA.label === "string" &&
                    typeof optionB.label === "string"
                        ? optionA.label
                              .toLowerCase()
                              .localeCompare(optionB.label.toLowerCase())
                        : 0
                }
                className={css.root}
                mode="multiple"
                notFoundContent={
                    <Empty
                        className="ant-empty-small" // Not accessible through props, but the default empty style
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            options.length < 1
                                ? i18n.containers.courses.CourseCreate.courseTrainer.emptyNoInstructors()
                                : i18n.containers.courses.CourseCreate.courseTrainer.emptyForLocation()
                        }
                    />
                }
                showArrow
                size="small"
                getPopupContainer={getPopupContainer}
                value={value}
                {...restProps}
            />
        </>
    );
};

export default memo(TableSelectInstructors);
