import React, {FC, memo} from "react";
import {Button, Modal, Space, Tag} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {GetBookingByIdQuery} from "../../generated/graphql";
import {RenderAmount} from "../TableColumnRenderers/TableColumnRenderers";
import {marginXs} from "../../styles/layout";

type MemberDiscountProps = {
    booking?: GetBookingByIdQuery["bookingById"];
};

const MemberDiscount: FC<MemberDiscountProps> = ({booking}) => {
    const memberDiscount = booking?.memberDiscount;

    if (!memberDiscount) {
        return null;
    }

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: `calc(${marginXs} * -1)`,
                marginBottom: marginXs,
            }}
        >
            <Button
                type="text"
                style={{paddingRight: marginXs}}
                onClick={() =>
                    Modal.info({
                        icon: null,
                        okButtonProps: {block: true},
                        content: (
                            <p style={{textAlign: "center"}}>
                                {`Dieser Kurs hat einen Mitgliederrabatt. Dieser wurde für diese Buchung verrechnet.`}
                            </p>
                        ),
                    })
                }
            >
                <InfoCircleOutlined style={{color: "blue"}} />
                <Tag color="blue">
                    <Space size={5}>
                        <span>{"Verrechneter Mitgliederrabatt von"}</span>
                        <RenderAmount
                            amount={memberDiscount ?? 0}
                            style={{fontSize: "unset", fontWeight: 600}}
                        />
                    </Space>
                </Tag>
            </Button>
        </div>
    );
};

export default memo(MemberDiscount);
