import {Table, Button, Space, Modal, Tooltip} from "antd";
import {
    ColumnProps,
    RowSelectionType,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from "antd/lib/table";
import {FilterValue} from "antd/lib/table/interface";
import React, {FC, memo, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {blue} from "@ant-design/colors";
import {
    ArrayDataOptions,
    BlankMailTypes,
    Booker,
    MailDataType,
    Maybe,
    ScheduledPaymentType,
    SortOrder,
    TablesEnum,
} from "../../generated/graphql";
import {getPagination} from "../../helpers/getPagination";
import {updateFilters} from "../../helpers/updateFilters";
import i18n from "../../services/i18n";
import Section from "../../views/Section/Section";
import ActiveFilters from "../common/tableTools/ActiveFilters";
import TableToolBar, {
    ReceiverType,
    useRowSelection,
} from "../common/tableTools/TableToolBar";
import {TableType, useHandleColumns} from "./PaymentsListColumns";
import EditScheduledPayment from "./EditScheduledPayment";
import {marginXs} from "../../styles/layout";
import css from "./Payments.less";
import PaymentsFooter from "./PaymentsFooter";
import useHighestRole from "../../helpers/useHighestRole";
import roles from "../../../shared/models/roles";

const checkbox: RowSelectionType = "checkbox";

export const allColumns = (): {[key in keyof TableType]: string} => {
    const columnNames: {[key in keyof TableType]: string} = {
        id: i18n.views.TableColumnTitles.id(),
        paymentDue: i18n.views.TableColumnTitles.paymentDue(),
        paymentAmount: i18n.views.TableColumnTitles.paymentAmount(),
        openAmount: i18n.views.TableColumnTitles.openAmount(),
        createDate: i18n.views.TableColumnTitles.createDate(),
        bookingNumber: i18n.views.TableColumnTitles.bookingNumber(),
        courseType: i18n.views.TableColumnTitles.courseType(),
        courseNumber: i18n.views.TableColumnTitles.courseNumber(),
        booker: i18n.views.TableColumnTitles.booker(),
        attendee: i18n.views.TableColumnTitles.attendee(),
        city: i18n.views.TableColumnTitles.city(),
        location: i18n.views.TableColumnTitles.location(),
        firstCourseLesson: i18n.views.TableColumnTitles.firstCourseLesson(),
        paymentType: i18n.views.TableColumnTitles.paymentType(),
        paymentMethod: i18n.views.TableColumnTitles.paymentMethod(),
        setPaidAction: i18n.views.TableColumnTitles.setPaidAction(),
        setUnPaidAction: i18n.views.TableColumnTitles.setUnPaidAction(),
        invoice: i18n.views.TableColumnTitles.invoice(),
        paymentDone: i18n.views.TableColumnTitles.paymentDone(),
        sepaClearance: i18n.views.TableColumnTitles.sepaClearance(),
        vat: i18n.views.TableColumnTitles.vat(),
        booking: i18n.views.TableColumnTitles.booking(),
        title: i18n.views.TableColumnTitles.invoiceTitle(),
        invoiceDate: i18n.views.TableColumnTitles.invoiceDate(),
    };

    return columnNames;
};

const hiddenColumns: Array<Extract<keyof TableType, string>> = [
    "id",
    "setPaidAction",
    "setUnPaidAction",
];

const standardColumns: Array<Extract<keyof TableType, string>> = [
    "bookingNumber",
    "courseType",
    "courseNumber",
    "booker",
    "attendee",
    "location",
    "firstCourseLesson",
    "invoice",
    "paymentMethod",
    "paymentDue",
    "paymentAmount",
    "setPaidAction",
];

type FooterDataType = Pick<TableType, "paymentAmount">;

type PaymentsListProps = {
    toolBarButtons?: Array<
        "columnSelector" | "pdf" | "eMail" | "bulkDelete" | "checkList" | "sms"
    >;
    hideCreate?: boolean;
    tableTitle?: string;
    defaultPageSize?: number;
    hideHeader?: boolean;
    defaultColumns?: Array<Extract<keyof TableType, string>>;
    tableData: {
        dataSource: Array<TableType>;
        existMore?: boolean;
        total?: number;
        loading?: boolean;
    };
    optionsCallBack: Function;
    createLink?: string;
    editLink?: string;
    detailsLink?: string;
    specialFunctions?: Array<React.ReactNode>;
    selection?: Function;
    minPadding?: boolean;
    hidePagination?: boolean;
    tablesEnum: TablesEnum;
    createXmlBtn?: React.ReactNode;
    options?: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    refetch: () => void;
    style?: React.CSSProperties;
    hiddenFilters?: Array<Extract<keyof TableType, string>>;
    contentType: "openPayment" | "donePayment";
    hideFooter?: boolean;
    mandatoryColumns?: Array<Extract<keyof TableType, string>>;
    extraFunctions?: Array<React.ReactNode>;
    notSelectable?: boolean;
};

const PaymentsList: FC<PaymentsListProps> = ({
    toolBarButtons = ["columnSelector", "pdf", "eMail", "bulkDelete", "sms"],
    hideCreate = true,
    tableTitle = i18n.containers.payments.PaymentsList.tableTitle(),
    defaultPageSize = 15,
    hideHeader = false,
    defaultColumns = standardColumns,
    tableData,
    optionsCallBack,
    createLink = "/",
    editLink = "/",
    detailsLink = "/",
    specialFunctions,
    selection,
    minPadding = false,
    hidePagination,
    tablesEnum,
    createXmlBtn,
    options,
    setOptions,
    refetch,
    style,
    hiddenFilters,
    contentType,
    hideFooter = false,
    mandatoryColumns = ["paymentDue"],
    extraFunctions,
    notSelectable,
}) => {
    const [editPaymentOpen, setEditPaymentOpen] = useState<boolean>(false);
    const [recordToEdit, setRecordToEdit] = useState<TableType | undefined>();
    const [columnsToShow, setColumnsToShow] = useState<
        Array<Extract<keyof TableType, string>>
    >([]);
    const highestRole = useHighestRole();
    const footerIsToBeShown = [
        roles.Superuser,
        roles.BetaTester,
        roles.SuperAdmin,
    ].includes(highestRole);

    const pageSizeOptions = ["10", "15", "20", "25"];

    const columns: Array<ColumnProps<TableType>> = useHandleColumns(
        columnsToShow,
        options,
        setOptions,
        editLink,
        detailsLink,
        // tableData.dataSource,
        false,
        options?.filter,
        refetch,
        editPaymentOpen,
        setEditPaymentOpen,
        setRecordToEdit,
    );

    const [selectionType, setSelectionType] = useState<RowSelectionType>(
        checkbox,
    );
    const rowSelection = useRowSelection({type: selectionType});

    useEffect(() => {
        if (selection) {
            selection(rowSelection.selectedRowKeys);
        }
    }, [rowSelection.selectedRowKeys, selection]);

    const onChange: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TableType> | Array<SorterResult<TableType>>,
        extra?: TableCurrentDataSource<TableType>,
    ) => void = (pagination, filters, sorter, extra) => {
        const updatedFilters = updateFilters({
            filters,
            options,
            table: "scheduledPayment",
        });

        const sortColumn = sorter.field;
        const sortOrder: Maybe<SortOrder> =
            sorter.order === "ascend"
                ? SortOrder.Ascending
                : sorter.order === "descend"
                ? SortOrder.Descending
                : undefined;

        const updatedOptions = {
            ...options,
            filter: updatedFilters,
            limit: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).limit,
            offset: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).offset,
            sortColumn,
            sortOrder,
        };

        setOptions(updatedOptions);
    };

    const renderMenu = () => {
        const menu: Array<React.ReactNode> = [];

        if (extraFunctions) {
            menu.push(extraFunctions);
        }

        if (specialFunctions && rowSelection.selectedRowKeys.length > 0) {
            specialFunctions.forEach((specialFunction) =>
                menu.push(specialFunction),
            );
        }

        if (createXmlBtn) {
            menu.push(createXmlBtn);
        }

        if (!hideCreate) {
            menu.push(
                <Button key="createButton" type="primary" size="small">
                    <Link
                        to={{
                            pathname: createLink,
                        }}
                    >
                        {i18n.containers.payments.PaymentsList.create()}
                    </Link>
                </Button>,
            );
        }

        return <Space>{menu}</Space>;
    };

    const getSelection = (
        bookingIds: Array<string>,
    ): Array<Partial<Booker>> => {
        const rows: Array<TableType> = tableData.dataSource.filter((booking) =>
            bookingIds.includes(booking.id),
        );
        const selection: Array<Partial<Booker>> = [];

        rows.forEach((row) => {
            const booking = row.booking;

            if (booking.attendee?.booker) {
                const booker = booking.attendee.booker;

                selection.push(booker);
            }
        });

        return selection;
    };

    const getReceivers = (
        bookingIds: Array<string>,
    ): Array<ReceiverType> | undefined => {
        const rows: Array<TableType> = tableData.dataSource.filter((booking) =>
            bookingIds.includes(booking.id),
        );
        const receivers: Array<ReceiverType> = [];

        rows.forEach((row) => {
            // console.log("row:::", row);
            if (row.booking.attendee?.booker?.email) {
                const booker = row.booking.attendee.booker;

                const receiver = {
                    key: booker.id,
                    value: booker.email ?? "",
                    label: `${booker.firstname} ${booker.lastname}`,
                };

                receivers.push(receiver);
            }
        });

        return receivers;
    };

    const EditScheduledPaymentModal = () => {
        const modalTitleText = (
            <div
                style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    paddingBottom: 7.5,
                    paddingTop: 7.5,
                    borderBottom: `1px solid ${blue[2]}`,
                    marginBottom: 10,
                }}
            >
                {recordToEdit?.paymentDone === null
                    ? "Offenen Posten bearbeiten"
                    : "Umsatz bearbeiten"}
            </div>
        );

        const modalCloseAfterSubmit = () => {
            setEditPaymentOpen(false);
            setTimeout(() => {
                refetch();
            }, 300);
        };

        return (
            <Modal
                open={editPaymentOpen}
                onCancel={() => setEditPaymentOpen(false)}
                closable
                destroyOnClose={true}
                width={800}
                bodyStyle={{
                    paddingBottom: 7.5,
                    paddingTop: marginXs,
                }}
                footer={null}
            >
                <>
                    {modalTitleText}
                    <EditScheduledPayment
                        record={recordToEdit}
                        setEditPaymentOpen={modalCloseAfterSubmit}
                        refetch={refetch}
                    />
                </>
            </Modal>
        );
    };

    const SpecialPaymentCell: FC<{
        record: TableType;
    }> = ({record, ...restProps}) => {
        const ref = useRef<HTMLTableRowElement>(null);
        const recordTitle = record?.title ?? undefined;

        const className = (record?: TableType) => {
            const type = record?.type;

            switch (type) {
                case ScheduledPaymentType.Standard:
                    return css.standardPaymentRow;
                case ScheduledPaymentType.DunningLevel1:
                    return css.specaialPaymentRowDunningLevel1;
                case ScheduledPaymentType.DunningLevel2:
                    return css.specaialPaymentRowDunningLevel2;
                case ScheduledPaymentType.DunningLevel3:
                    return css.specaialPaymentRowDunningLevel3;
                case ScheduledPaymentType.CancelationFee:
                    return css.specaialPaymentRowCancelationFee;
                case ScheduledPaymentType.Chargeback:
                    return css.specaialPaymentRowChargeback;
                case ScheduledPaymentType.Custom:
                    return css.specaialPaymentRowCustom;
                default:
                    return css.standardPaymentRow;
            }
        };

        return (
            <Tooltip title={recordTitle}>
                <tr ref={ref} {...restProps} className={className(record)}>
                    {}
                </tr>
            </Tooltip>
        );
    };

    const components = {
        body: {
            row: SpecialPaymentCell,
        },
    };

    const renderContent = () => {
        return (
            <>
                <EditScheduledPaymentModal />
                <Section
                    title={tableTitle}
                    menu={renderMenu()}
                    minPadding={minPadding}
                    showHeader={!hideHeader}
                    style={style}
                    secondLineLeft={
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignContent: "center",
                                padding: "2px 0px",
                            }}
                        >
                            {tableData.dataSource.length ? (
                                <TableToolBar
                                    buttons={toolBarButtons}
                                    columns={columns}
                                    allColumns={allColumns()}
                                    variableKeys={allColumns()}
                                    columnsToShow={columnsToShow}
                                    setSelectedColumns={(
                                        e: Array<keyof TableType>,
                                    ) => setColumnsToShow(e)}
                                    mandatoryColumns={mandatoryColumns}
                                    hiddenColumns={hiddenColumns}
                                    rowSelection={rowSelection}
                                    selected={getSelection(
                                        rowSelection.selectedRowKeys,
                                    )}
                                    selectMode={(type: RowSelectionType) =>
                                        setSelectionType(type)
                                    }
                                    exportFileName={tableTitle}
                                    dataSource={tableData.dataSource}
                                    data={getReceivers(
                                        rowSelection.selectedRowKeys,
                                    )}
                                    type={BlankMailTypes.Booking}
                                    mailDataType={MailDataType.ScheduledPayment}
                                    tablesEnum={tablesEnum}
                                    defaultColumns={
                                        defaultColumns as Array<string>
                                    }
                                />
                            ) : (
                                <div>{""}</div>
                            )}
                            <ActiveFilters
                                options={options}
                                setOptions={setOptions}
                                refetch={refetch}
                                hiddenFilters={hiddenFilters}
                            />
                        </Space>
                    }
                    content={
                        <Table<TableType>
                            size="small"
                            bordered
                            columns={columns}
                            dataSource={tableData.dataSource}
                            // rowKey="id"
                            rowKey={(record) => record.id}
                            loading={tableData.loading}
                            // rowSelection={rowSelection.result}
                            rowSelection={
                                notSelectable ? undefined : rowSelection.result
                            }
                            sticky={tableData.dataSource.length > 0}
                            scroll={
                                tableData.dataSource.length
                                    ? {
                                          x: `max-content`,
                                          y: "calc(100vh - 280px)",
                                      }
                                    : {x: "auto"}
                            }
                            onChange={onChange}
                            pagination={
                                hidePagination
                                    ? false
                                    : {
                                          showSizeChanger: true,
                                          pageSizeOptions,
                                          defaultPageSize,
                                          total: tableData.total,
                                          hideOnSinglePage: true,
                                      }
                            }
                            footer={() => {
                                if (
                                    footerIsToBeShown &&
                                    !tableData.loading &&
                                    !hideFooter
                                ) {
                                    return (
                                        <PaymentsFooter
                                            contentType={contentType}
                                            options={options}
                                        />
                                    );
                                }

                                return undefined;
                            }}
                            components={components}
                            onRow={(record, rowIndex) => {
                                return {
                                    key: record.id,
                                    index: rowIndex,
                                    record,
                                    onDoubleClick: (event) => {
                                        setRecordToEdit(record);
                                        setEditPaymentOpen(true);
                                    },
                                };
                            }}
                        />
                    }
                />
            </>
        );
    };

    return renderContent();
};

export default memo(PaymentsList);
