export const getFirstPathnameSegment = () => {
    const url = new URL(window.location.href);
    const firstSegment = url.pathname.split("/")[1];

    return firstSegment;
};

export const getSubDomain = () => {
    const url = new URL(window.location.href);
    const subDomain = url.hostname.split(".")[0];

    return subDomain;
};
