import React, {FC, memo} from "react";
import moment, {Moment} from "moment";
import {TimePicker} from "antd";
import {PickerProps} from "antd/lib/date-picker/generatePicker";

type CustomTimeInputProps = {
    placeholder?: string;
    value: moment.Moment | null;
    onChange:
        | ((value: moment.Moment | null, dateString: string) => void)
        | undefined;
    status?: "error" | "warning";
    size?: "large" | "middle" | "small";
    style?: React.CSSProperties;
    className?: string;
};

const CustomTimeInput: FC<CustomTimeInputProps> = ({
    placeholder,
    onChange,
    value,
    size,
    style,
    className,
}) => {
    const TimePickerAutoaccept = (props: PickerProps<Moment>) => {
        const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
            console.log("blur", elem.target.value);
            // Ensure props.format is a string or use a default format
            const format =
                typeof props.format === "string" ? props.format : "HH:mm";
            const value = moment(elem.target.value, format);

            if (value.isValid() && props.onChange) {
                props.onChange(value, elem.target.value);
            }
        };

        return <TimePicker {...props} onBlur={onBlur} />;
    };

    return (
        <TimePickerAutoaccept
            allowClear={false}
            format="HH:mm"
            value={value}
            onChange={onChange}
            size={size}
            style={style}
            className={className}
            placeholder={placeholder}
        />
    );
};

export default memo(CustomTimeInput);
