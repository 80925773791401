import {Dispatch, SetStateAction} from "react";
import {BookerEmailNotificationCourseLesson} from "../../../../shared/models/booker";
import {BookingTemplateVariables} from "../../../../shared/models/template";
// import {replaceTemplateTextBlockVariables} from "../../../../shared/templates/handleTemplateVariables";
import {
    GetAttendeeVariableForTemplateQuery,
    GetCourseVariablesForTemplateQuery,
    GetMjmlTemplateByIdQuery,
    GetTemplateByIdQuery,
    GetVariablesForBookingWithCourseQuery,
} from "../../../generated/graphql";
import {toMjml} from "../MJMLTemplates/toMjml";
import {MjMlOutputType} from "../MjmlPreview";

export const processTemplateData = ({
    allVariables,
    userTemplateTextBlocks,
    mailjetTemplateData,
    setHtmlTemplate,
    mjmlTemplate,
    variables,
    templateVariablesData,
    attendeeData,
    courseLessonDataNewBooking,
}: {
    allVariables?: BookingTemplateVariables;
    userTemplateTextBlocks?: GetTemplateByIdQuery["template"];
    mailjetTemplateData?: GetMjmlTemplateByIdQuery["getEmailTemplateById"]["Data"];
    setHtmlTemplate?: Dispatch<SetStateAction<MjMlOutputType | undefined>>;
    mjmlTemplate?: string;
    variables: {};
    templateVariablesData?: GetVariablesForBookingWithCourseQuery;
    attendeeData?: GetAttendeeVariableForTemplateQuery;
    courseLessonDataNewBooking?: GetCourseVariablesForTemplateQuery;
}) => {
    // This function should contain logic to process the templates
    if (allVariables && userTemplateTextBlocks) {
        // Process your template here
        const finalHtml = () => {
            let convertedTemplateHtml: MjMlOutputType | undefined;

            const courseLessons:
                | Array<BookerEmailNotificationCourseLesson>
                | undefined = allVariables.courseLessons;

            // THIS PART IS COMMENTED OUT BECAUSE IT OVERWRITES THE TEMPLATE !!!
            // const bookingTemplateVariables = allVariables;

            // replaceTemplateTextBlockVariables({
            //     variables: bookingTemplateVariables,
            //     textBlocks: userTemplateTextBlocks,
            // });

            if (
                mailjetTemplateData &&
                (templateVariablesData ||
                    (attendeeData && courseLessonDataNewBooking))
            ) {
                convertedTemplateHtml = {
                    html: toMjml({
                        mjmlTemplate: mailjetTemplateData,
                        variables: userTemplateTextBlocks,
                        courseLessons,
                    }),
                };

                if (setHtmlTemplate) {
                    setHtmlTemplate(convertedTemplateHtml);
                }
            }

            return convertedTemplateHtml;
        };

        if (setHtmlTemplate) {
            setHtmlTemplate(finalHtml);
        }
    } else if (mjmlTemplate) {
        // Fallback or alternative processing if required
        const fallbackHtml = {
            html: toMjml({
                mjmlTemplate,
                variables,
            }),
        };

        if (setHtmlTemplate) {
            setHtmlTemplate(fallbackHtml);
        }
    }
};
