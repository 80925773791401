import React, {FC, memo} from "react";
import {Modal, Space} from "antd";
import {red} from "@ant-design/colors";
import {ReceiverByNotifySelection} from "../emailHelpers";
import {Receiver, ReceiverWithBookingInfo} from "../ReceiversBar";

type ReceiversWithNoMailModalProps = {
    receivers: Array<
        ReceiverByNotifySelection | ReceiverWithBookingInfo | Receiver
    >;
};

const ReceiversWithNoMailModal: FC<ReceiversWithNoMailModalProps> = ({
    receivers,
}) => {
    const [open, setOpen] = React.useState(receivers.length > 0);

    return (
        <Modal
            title="Bucher ohne Email-Adresse gefunden!"
            open={open}
            onOk={() => setOpen(false)}
            okText="Hinweis schließen"
            cancelButtonProps={{style: {display: "none"}}}
            style={{textAlign: "center"}}
            bodyStyle={{color: red[6]}}
        >
            <Space direction="vertical">
                <div>
                    {
                        "Bei folgenden Bucher:innen ist keine Email-Adresse hinterlegt. Diese Bucher:innen werden deshalb nicht benachrichtigt. Bitte trage die Email-Adresse nach, um die Bucher:innen zu benachrichtigen."
                    }
                </div>
                {receivers.map((receiver) => {
                    const receiverName = `${receiver.firstname} ${receiver.lastname}`;
                    const prefixedBookingNumber = `${receiver.bookingNumberPrefix}-${receiver.bookingNumber}`;

                    return (
                        <div
                            style={{fontWeight: 500, fontSize: "1.2em"}}
                            key={receiver.id}
                        >
                            <div>
                                {`${receiverName} (${prefixedBookingNumber})`}
                            </div>
                        </div>
                    );
                })}
            </Space>
        </Modal>
    );
};

export default memo(ReceiversWithNoMailModal);
