import {blue} from "@ant-design/colors";
import {
    Button,
    Checkbox,
    Collapse,
    Form,
    message,
    Modal,
    Radio,
    Table,
} from "antd";
import RadioGroup from "antd/lib/radio/group";
import {ColumnProps} from "antd/lib/table";
import React, {FC, memo, useState} from "react";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {
    EditParamsType,
    useHandleColumns,
} from "../../containers/bookings/BookingList/BookingListColumns";
import {TableType} from "../../containers/customers/BookersOverviewColumns";
import {
    Booking,
    BookingDeleteActionType,
    GetWaitlistByCourseDocument,
    GetWaitlistByCourseQuery,
    GetBookinsDocument,
    GetCourseByIdDocument,
    useRemoveBookingMutation,
    useGetWaitlistByCourseQuery,
    GetFreePlacesByCourseIdDocument,
    GetBookingsByCourseDocument,
    GetUnsettledBookingsDocument,
    GetSettledBookingsDocument,
    GetBookedAttendeesByCourseIdDocument,
    GetScheduledPaymentsByBookingIdDocument,
    RemoveBookingComponentProps,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {colorDarkGrey} from "../../styles/colors";
import PanelCard from "../PanelCard/PanelCard";
import css from "./FullyBookedAndDeleteModal.less";

type FullyBookedAndDeleteModalProps = {
    record: TableType;
    editParams: EditParamsType;
    setModalVisible: (e: boolean) => void;
    modalVisible: boolean;
    refetch?: () => void;
};

const FullyBookedAndDeleteModal: FC<FullyBookedAndDeleteModalProps> = ({
    record,
    editParams,
    setModalVisible,
    modalVisible,
    refetch,
}) => {
    const [notifyBooker, setNotifyBooker] = useState(false);
    const courseId = record.courseLessons[0].course.id;
    const bookingToDeleteId: Booking["id"] = record.id;

    // CHOOSE FROM WAITING LIST ----------------------------------------------------------------------------------------
    const [bookingId, setBookingId] = useState<string | undefined>(undefined);
    const [waitingListVisible, setWaitingListVisible] = useState(false);

    const {data} = useGetWaitlistByCourseQuery({
        skip: !courseId || !waitingListVisible,
        variables: {
            id: courseId,
        },
        fetchPolicy: "network-only",
    });

    const waitingList = data?.getWaitlistByCourse;

    const getRefetchQueries = () => {
        const refetchQueries: RemoveBookingComponentProps["refetchQueries"] = [
            "GetBookins",
            {query: GetBookinsDocument},
            "GetUnsettledBookings",
            {query: GetUnsettledBookingsDocument},
            "GetSettledBookings",
            {query: GetSettledBookingsDocument},
        ];

        if (courseId) {
            refetchQueries.push(
                "GetWaitlistByCourse",
                {
                    query: GetWaitlistByCourseDocument,
                    variables: {id: courseId},
                },
                "GetBookedAttendeesByCourseId",
                {
                    query: GetBookedAttendeesByCourseIdDocument,
                    variables: {courseId},
                },
                "GetFreePlacesByCourseId",
                {
                    query: GetFreePlacesByCourseIdDocument,
                    variables: {id: courseId},
                },
                "GetBookingsByCourse",
                {
                    query: GetBookingsByCourseDocument,
                    variables: {id: courseId},
                },
                {
                    query: GetCourseByIdDocument,
                    variables: {id: courseId},
                },
            );
        }

        if (bookingId) {
            refetchQueries.push("GetScheduledPaymentsByBookingId", {
                query: GetScheduledPaymentsByBookingIdDocument,
                variables: {bookingId},
            });
        }

        return refetchQueries;
    };

    const [
        removeBooking,
        {loading: removeBookingLoading, error: removeBookingError},
    ] = useRemoveBookingMutation({
        awaitRefetchQueries: true,
        refetchQueries: getRefetchQueries(),
    });

    if (removeBookingError) {
        console.error(removeBookingError);
    }

    type WaitinlistTableType = keyof GetWaitlistByCourseQuery["getWaitlistByCourse"]["items"][0];

    const standardColumns: Array<WaitinlistTableType | "booker"> = [
        "bookingNumber",
        "attendee",
        "booker",
        "grossPrice",
    ];

    const detailsLink = "/";
    const editLink = "/";
    const actionColumn = () => {};

    const columns: Array<ColumnProps<TableType>> = useHandleColumns(
        standardColumns,
        editLink,
        detailsLink,
        undefined,
        () => {},
        actionColumn,
        // waitingList.dataSource,
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>(
        [],
    );

    const onSelectChange = (newSelectedRowKeys: Array<React.Key>) => {
        if (newSelectedRowKeys.length > 1) {
            setSelectedRowKeys([
                newSelectedRowKeys[newSelectedRowKeys.length - 1],
            ]);

            const updatedBookingId = newSelectedRowKeys[
                newSelectedRowKeys.length - 1
            ].toString();

            setBookingId(updatedBookingId);
        } else if (newSelectedRowKeys.length !== 0) {
            setSelectedRowKeys(newSelectedRowKeys);
            const updatedBookingId = newSelectedRowKeys[0].toString();

            setBookingId(updatedBookingId);
        }
    };

    const WaitingList = () => {
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
            singleSelect: true,
            hideSelectAll: true,
            defaultSelectedKeys: [bookingId],
        };

        return (
            <>
                <Table
                    dataSource={waitingList?.items}
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                    size="small"
                    rowSelection={rowSelection}
                />
            </>
        );
    };

    // OPTIONS ----------------------------------------------------------------------------------------

    type OptionType = {
        key: "option1" | "option2" | "option3" | "option4";
        buttonText: string;
        icon: string;
        action: Function;
        loading: boolean;
        title: string;
        description: string | React.ReactNode;
    };

    const options: Array<OptionType> = [
        {
            key: "option1",
            buttonText:
                "Freiwerdenden Kursplatz automatisch mit Warteliste befüllen",
            icon: "setting",
            action: () => console.log("option1"),
            loading: removeBookingLoading,
            title: "Auto",
            description:
                "Die Buchung wird gelöscht. Der dadurch frei werdende Kursplatz wird automatisch mit dem ältesten Wartelistenplatz befüllt. Der Teilnehmer der neu in den Kurs gebucht wurde erhält automatisch eine Buchungsbestätigung.",
        },
        {
            key: "option2",
            buttonText: "In eine Platzreservierung umwandeln",
            icon: "pushpin",
            action: () => console.log("option2"),
            loading: removeBookingLoading,
            title: "Platzreservierung",
            description: `Damit der frei werdende Kursplatz nicht aus dem Web gebucht werden kann,
                    wird eine Platzreservierung an die Stelle der gelöschten Buchung treten.
                        Diese kann zu einem beliebigen Zeitpunkt an einen neuen Teilnehmer vergeben werden.`,
        },
        {
            key: "option3",
            buttonText: "Manuell einen Wartelisteneintrag wählen",
            icon: "control",
            action: () => console.log("option3"),
            // action: () => handleWaitingListSelection(),
            // action: async () => {
            //     await handleWaitingListSelection();
            // },
            loading: removeBookingLoading,
            title: "Manuell",
            description: (
                <div>
                    <div>
                        {
                            "Hier kannst du manuell einen Eintrag aus der Warteliste wählen, der den frei werdenden Kursplatz bekommen soll."
                        }
                    </div>
                    {waitingListVisible && (
                        <div>
                            <h5
                                style={{
                                    marginBottom: -35,
                                    marginTop: 15,
                                    width: "100%",
                                    borderBottom: `2px solid ${blue[3]}`,
                                }}
                            >
                                {"Wartelisteneinträge"}
                            </h5>
                            <WaitingList />
                        </div>
                    )}
                </div>
            ),
        },
        {
            key: "option4",
            buttonText: "Kursplatz freigeben",
            icon: "setting",
            action: () => console.log("option4"),
            loading: removeBookingLoading,
            title: "Nothing",
            description:
                "Die Buchung wird gelöscht. Der dadurch frei werdende Kursplatz wird nicht neu belegt und ist wieder frei für eine neue Buchung.",
        },
    ];

    // If type of booking is placereservation, then option 2 is not available
    if (record.bookingNumberPrefix === "P") {
        options.splice(1, 1);
    }

    const [selectedOption, setSelectedOption] = useState<OptionType["key"]>(
        options[0].key,
    );

    const onChange = (option: OptionType) => {
        setSelectedOption(option.key);
        if (option.key === "option3") {
            setWaitingListVisible(true);
        } else {
            setWaitingListVisible(false);
        }
    };

    const RadioSolution = () => {
        const {Panel} = Collapse;

        const getStyle = (key: OptionType["key"]) => {
            const both = {borderRadius: 6, marginBottom: 10};

            if (key === selectedOption) {
                return {
                    background: blue[5],
                    border: `2px solid ${blue[5]}`,
                    ...both,
                };
            }

            return {
                background: "transparent",
                border: `2px solid lightgrey`,
                ...both,
            };
        };

        const getTextStyle = (key: OptionType["key"]) => {
            const both = {
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
            };

            if (key === selectedOption) {
                return {
                    color: "white",
                    fontSize: "1.1rem",
                    ...both,
                };
            }

            return {
                background: "transparent",
                fontSize: "0.9rem",
                color: colorDarkGrey,
                ...both,
            };
        };

        return (
            <RadioGroup defaultValue={selectedOption}>
                <Collapse
                    style={{borderColor: "transparent", marginTop: 20}}
                    activeKey={selectedOption}
                    onChange={(key: string | Array<string>) =>
                        setSelectedOption(
                            Array.isArray(key)
                                ? (key[0] as OptionType["key"])
                                : (key as OptionType["key"]),
                        )
                    }
                >
                    {options.map((option) => {
                        return (
                            <Panel
                                style={getStyle(option.key)}
                                key={option.key}
                                showArrow={false}
                                // extra="extra"
                                header={
                                    <Radio
                                        className={css.radioButton}
                                        style={getTextStyle(option.key)}
                                        value={option.key}
                                        checked={option.key === selectedOption}
                                        onChange={() => onChange(option)}
                                    >
                                        {option.buttonText}
                                    </Radio>
                                }
                            >
                                <div>{option.description}</div>
                            </Panel>
                        );
                    })}
                </Collapse>
            </RadioGroup>
        );
    };

    const onCancel = () => {
        setModalVisible(false);
        setWaitingListVisible(false);
    };

    const [loading, setLoading] = useState(false);

    const handelRemove = async () => {
        const selected = options.find(
            (option) => option.key === selectedOption,
        );
        const key = selected?.key;

        setLoading(true);
        switch (key) {
            case "option1":
                try {
                    await removeBooking({
                        variables: {
                            id: bookingToDeleteId,
                            bookingDeleteAction: {
                                type:
                                    BookingDeleteActionType.NextFromWaitingList,
                            },
                            notifyBooker,
                        },
                        refetchQueries: getRefetchQueries(),
                    }).then(
                        message.success(
                            "Buchung wurde erfolgreich gelöscht und der freie Platz neu vergeben.",
                            3,
                        ),
                    );
                } catch (error) {
                    throw new Error(
                        `Error removeBooking NextFromWaitingList: ${error}`,
                    );
                } finally {
                    refetch?.();
                    setModalVisible(false);
                    setLoading(false);
                }

                break;
            case "option2":
                try {
                    await removeBooking({
                        variables: {
                            id: bookingToDeleteId,
                            bookingDeleteAction: {
                                // bookingId: id,
                                type: BookingDeleteActionType.PlaceReservation,
                            },
                            notifyBooker: false,
                        },
                        refetchQueries: getRefetchQueries(),
                    }).then(
                        message.success(
                            "Buchung wurde erfolgreich in eine Platzreservierung konvertiert.",
                            3,
                        ),
                    );
                } catch (error) {
                    throw new Error(
                        `Error removeBooking PlaceReservation: ${error}`,
                    );
                } finally {
                    refetch?.();
                    setModalVisible(false);
                    setLoading(false);
                }

                break;
            case "option3":
                try {
                    if (bookingId) {
                        await removeBooking({
                            variables: {
                                id: bookingToDeleteId,
                                bookingDeleteAction: {
                                    bookingId,
                                    type:
                                        BookingDeleteActionType.SelectFromWaitingList,
                                },
                                notifyBooker,
                            },
                            refetchQueries: getRefetchQueries(),
                        }).then(
                            message.success(
                                "Buchung wurde erfolgreich gelöscht und der freie Platz neu vergeben.",
                                3,
                            ),
                        );
                    } else {
                        console.log("missing bookingId:", bookingId);
                    }
                } catch (error) {
                    throw new Error(
                        `Error removeBooking SelectFromWaitingList: ${error}`,
                    );
                } finally {
                    refetch?.();
                    setModalVisible(false);
                    setLoading(false);
                }

                break;
            case "option4":
                try {
                    await removeBooking({
                        variables: {
                            id: bookingToDeleteId,
                            bookingDeleteAction: {
                                type: BookingDeleteActionType.DoNothing,
                            },
                            notifyBooker: false,
                        },
                        refetchQueries: getRefetchQueries(),
                    }).then(
                        message.success(
                            "Buchung wurde erfolgreich gelöscht.",
                            3,
                        ),
                    );
                } catch (error) {
                    throw new Error(`Error removeBooking DoNothing: ${error}`);
                } finally {
                    refetch?.();
                    setModalVisible(false);

                    setLoading(false);
                }

                break;
            default:
                console.log("nichts geählt");
                setLoading(false);
                break;
        }
    };

    const buttonText = (key: OptionType["key"]) => {
        switch (key) {
            case "option1":
                return i18n.containers.bookings.BookingList.waitingListActions.deleteOption1();
            case "option2":
                return i18n.containers.bookings.BookingList.waitingListActions.deleteOption2();
            case "option3":
                if (bookingId === undefined) {
                    return i18n.containers.bookings.BookingList.waitingListActions.deleteOption3Disabled();
                }

                return i18n.containers.bookings.BookingList.waitingListActions.deleteOption3();
            case "option4":
                return i18n.containers.bookings.BookingList.waitingListActions.deleteOption4();

            default:
                return i18n.containers.bookings.BookingList.waitingListActions.deleteOption4();
        }
    };

    const notifyUserConfirmation = (key: OptionType["key"]) => {
        const enabled = ["option1", "option3"].includes(key);

        const handleChange = (e: CheckboxChangeEvent) => {
            setNotifyBooker(e.target.checked);
        };

        return (
            <div className={css.notifyBookerInput}>
                <Form.Item
                    label="Buchungsbestätigung automatisch versenden"
                    colon={false}
                    style={{marginBottom: 0}}
                >
                    <Checkbox
                        disabled={!enabled}
                        onChange={handleChange}
                        checked={notifyBooker}
                    />
                </Form.Item>
            </div>
        );
    };

    return (
        <Modal
            key={courseId}
            // visible={modalVisible}
            open={modalVisible}
            title="Kurs mit Warteliste"
            width={600}
            onCancel={onCancel}
            destroyOnClose
            footer={
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button type="danger" ghost block onClick={onCancel}>
                        {"Abbrechen"}
                    </Button>
                    <Button
                        type="primary"
                        block
                        loading={removeBookingLoading || loading}
                        onClick={async () => {
                            await handelRemove();
                        }}
                        disabled={
                            selectedOption === "option3" &&
                            bookingId === undefined
                        }
                    >
                        {buttonText(selectedOption)}
                    </Button>
                </div>
            }
        >
            <PanelCard columns={1} gapAfter>
                <span className={css.panelContent}>
                    <h6>{"Dieser Kurs hat bereits eine Warteliste."}</h6>
                    <div>
                        {
                            "Du kannst hier wählen, was mit dem freigegebenen Kursplatz passieren soll."
                        }
                    </div>
                </span>
            </PanelCard>
            <PanelCard columns={1} noBorder>
                <RadioSolution />
                {notifyUserConfirmation(selectedOption)}
            </PanelCard>
        </Modal>
    );
};

export default memo(FullyBookedAndDeleteModal);
