import {TemplateType} from "../generated/graphql";
import i18n from "../services/i18n";

export const renderTitle = (type: TemplateType | undefined) => {
    // console.log("=========> type: ", type);
    switch (type) {
        case "Warning":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.warning();
        case "BookingConfirmation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.bookingConfirmation();
        case "WaitingListConfirmation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.waitingListConfirmation();
        case "SettlementConfirmation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.invoice();
        case "Reminder":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.courseMemory();
        case "SettledBookingCancellation":
            return i18n.containers.templates.EmailTemplates.defaultTemplates.cancelInvoice();
        default:
            return i18n.containers.templates.EmailTemplates.defaultTemplates.bookingConfirmation();
    }
};
