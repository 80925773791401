import React from "react";
import {Space, Tooltip, Badge, Button, Modal} from "antd";
import moment from "moment";
import {InfoCircleOutlined, SendOutlined} from "@ant-design/icons";
import {grey, orange} from "@ant-design/colors";
import {EmailNotification, TemplateType} from "../../generated/graphql";
import {colorLightGrey} from "../../styles/colors";
import {
    renderBooleanAsCheckmark,
    renderTemplateType,
} from "./TableColumnRenderers";
import css from "./TableColumnRenderers.less";

export const renderEmailNotifications = (
    emailNotifications?: Array<
        Pick<
            EmailNotification,
            | "id"
            | "createDate"
            | "templateType"
            | "subject"
            | "bookingId"
            | "email"
        >
    > &
        "emailNotifications",
) => {
    if (!emailNotifications?.length || emailNotifications.length === 0) {
        return (
            <Space size="small" style={{color: colorLightGrey}}>
                <span style={{fontSize: "0.6em"}}>
                    {renderBooleanAsCheckmark(false)}
                </span>
                {"Keine Nachrichten"}
            </Space>
        );
    }

    const verifiedEmailNotifications = emailNotifications.filter(
        (notification) => {
            return notification.bookingId;
        },
    );

    const notVerifiedEmailNotifications = emailNotifications.filter(
        (notification) => {
            return !notification.bookingId;
        },
    );

    // Find TemplateType.BookingConfirmation, TemplateType.WaitingListConfirmation and TemplateType.Invoice as standardNotifications
    const standardNotifications = verifiedEmailNotifications.filter(
        (notification) => {
            return (
                notification.templateType ===
                    TemplateType.BookingConfirmation ||
                notification.templateType ===
                    TemplateType.WaitingListConfirmation ||
                notification.templateType === TemplateType.Invoice ||
                notification.templateType ===
                    TemplateType.SettlementConfirmation ||
                notification.templateType ===
                    TemplateType.SettledBookingCancellation
            );
        },
    );

    const noneStandardNotificationsCount = verifiedEmailNotifications.length /* && standardNotifications.length */
        ? verifiedEmailNotifications.length - standardNotifications.length
        : 0;

    const tooltipStyle: React.CSSProperties = {
        whiteSpace: "pre-line",
        width: "100%",
        minWidth: 600,
    };

    const renderStandardNotifications = () =>
        standardNotifications.map((notification, index) => {
            return (
                <div key={notification.id}>
                    <Tooltip
                        title={`Versendet am: ${moment(
                            notification.createDate,
                        ).format("DD.MM.YY - HH:mm")}\nBetreff: ${
                            notification.subject
                        }`}
                        style={tooltipStyle}
                        placement="left"
                        trigger="click"
                    >
                        <Space size="small" className={css.moreNotifications}>
                            <span style={{fontSize: "0.6em"}}>
                                {renderBooleanAsCheckmark(true)}
                            </span>
                            {renderTemplateType({
                                templateType: notification.templateType,
                            })}
                        </Space>
                    </Tooltip>
                </div>
            );
        });

    const renderNoneStandardNotifications = () => {
        const noneStandardNotifications = verifiedEmailNotifications
            .filter((notification) => {
                return (
                    notification.templateType !==
                        TemplateType.BookingConfirmation &&
                    notification.templateType !==
                        TemplateType.WaitingListConfirmation &&
                    notification.templateType !== TemplateType.Invoice &&
                    notification.templateType !==
                        TemplateType.SettlementConfirmation &&
                    notification.templateType !==
                        TemplateType.SettledBookingCancellation
                );
            })
            .map((notification) => {
                return notification;
            });

        return noneStandardNotifications.map((notification, index) => {
            const maxLength = 20;
            const shortenedSubject =
                notification.subject?.length &&
                notification.subject.length > maxLength
                    ? notification.subject.substring(0, maxLength) + "..."
                    : notification.subject;

            return (
                <div key={notification.id}>
                    <Tooltip
                        title={`Versendet am: ${moment(
                            notification.createDate,
                        ).format("DD.MM.YY - HH:mm")}\nBetreff: ${
                            notification.subject
                        }`}
                        style={tooltipStyle}
                        placement="left"
                        trigger="click"
                    >
                        <Space size="small" className={css.moreNotifications}>
                            <span
                                style={{
                                    fontSize: "0.8em",
                                    width: 22,
                                    paddingLeft: 2,
                                }}
                            >
                                <SendOutlined />
                            </span>
                            {shortenedSubject}
                        </Space>
                    </Tooltip>
                </div>
            );
        });
    };
    // const renderNoneStandardNotifications = () => {
    //     const notificationsText =
    //         noneStandardNotificationsCount === 1 ? "Nachricht" : "Nachrichten";

    //     const noneStandardNotificationsSubjects = verifiedEmailNotifications
    //         .filter((notification) => {
    //             return (
    //                 notification.templateType !==
    //                     TemplateType.BookingConfirmation &&
    //                 notification.templateType !==
    //                     TemplateType.WaitingListConfirmation &&
    //                 notification.templateType !== TemplateType.Invoice &&
    //                 notification.templateType !==
    //                     TemplateType.SettlementConfirmation &&
    //                 notification.templateType !==
    //                     TemplateType.SettledBookingCancellation
    //             );
    //         })
    //         .map((notification) => {
    //             return (
    //                 <div key={notification.id}>
    //                     <Badge
    //                         style={{color: "white"}}
    //                         color="blue"
    //                         text={`${moment(notification.createDate).format(
    //                             "DD.MM.YY HH:mm",
    //                         )}`}
    //                     />
    //                     <div
    //                         style={{paddingLeft: 15}}
    //                     >{`${notification.subject}`}</div>
    //                 </div>
    //             );
    //         });

    //     if (
    //         standardNotifications.length &&
    //         standardNotifications.length > 0 &&
    //         noneStandardNotificationsCount > 0
    //     ) {
    //         return (
    //             <Tooltip
    //                 title={noneStandardNotificationsSubjects}
    //                 style={tooltipStyle}
    //                 placement="left"
    //                 trigger="click"
    //             >
    //                 <div
    //                     key="more"
    //                     style={{whiteSpace: "nowrap"}}
    //                     className={css.moreNotifications}
    //                 >{`+ ${noneStandardNotificationsCount} weitere ${notificationsText} zur Buchung`}</div>
    //             </Tooltip>
    //         );
    //     } else if (noneStandardNotificationsCount > 0) {
    //         return (
    //             <Tooltip
    //                 title={noneStandardNotificationsSubjects}
    //                 style={tooltipStyle}
    //                 placement="left"
    //                 trigger="click"
    //             >
    //                 <div
    //                     key="more"
    //                     className={css.moreNotifications}
    //                 >{`${noneStandardNotificationsCount} ${notificationsText}`}</div>
    //             </Tooltip>
    //         );
    //     }

    //     return null;
    // };

    // Bookings that have no bookingId but the same emailAdress
    const renderNotVerifiedEmailNotifications = () => {
        if (!notVerifiedEmailNotifications.length) {
            return null;
        }

        const notificationsText =
            notVerifiedEmailNotifications.length === 1
                ? "andere Nachricht"
                : "andere Nachrichten";

        const notVerifiedEmailNotificationsSubjects = notVerifiedEmailNotifications.map(
            (notification) => {
                return (
                    <div key={notification.id}>
                        <Badge
                            style={{color: "white"}}
                            color="blue"
                            text={`${moment(notification.createDate).format(
                                "DD.MM.YY HH:mm",
                            )}`}
                        />
                        <div
                            style={{paddingLeft: 15}}
                        >{`${notification.subject}`}</div>
                    </div>
                );
            },
        );

        const MoreInfo = ({email}: {email?: string}) => {
            const Content = () => {
                return (
                    <div style={{whiteSpace: "pre-line"}}>
                        <p>
                            {`Diese Nachrichten wurden an die gleiche Email-Adresse versendet.`}
                            <b>{email && `\n${email}\n`}</b>
                            {`Wir konnten diese allerdings keiner Buchung eindeutig zuordnen.`}
                        </p>
                        <p>
                            {`Möglicherweise wurde die Buchung zwischenzeitlich gelöscht oder die Email-Adresse wurde geändert.`}
                        </p>
                    </div>
                );
            };

            return (
                <div
                    style={{
                        lineBreak: "strict",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "1em",
                        color: grey[4],
                        // paddingLeft: 0,
                    }}
                >
                    <Tooltip
                        title={notVerifiedEmailNotificationsSubjects}
                        style={tooltipStyle}
                        placement="left"
                        trigger="click"
                    >
                        <span
                            className={css.moreNotifications}
                            style={{flex: 2}}
                        >
                            {`+ ${notVerifiedEmailNotifications.length} ${notificationsText}`}{" "}
                        </span>
                    </Tooltip>
                    <Button
                        type="text"
                        size="small"
                        style={{fontSize: "1em", paddingLeft: 0}}
                        onClick={() => {
                            Modal.info({
                                title: "Nicht zugeordnete Nachrichten",
                                content: <Content />,
                                style: {textAlign: "center"},
                                icon: null,
                            });
                        }}
                        className={css.moreNotificationsInfo}
                    >
                        <InfoCircleOutlined style={{color: orange[4]}} />
                    </Button>
                </div>
            );
        };

        return (
            <div key="more">
                <MoreInfo email={notVerifiedEmailNotifications[0].email} />
            </div>
        );
    };

    return (
        <>
            {renderStandardNotifications()}
            {renderNoneStandardNotifications()}
            {renderNotVerifiedEmailNotifications()}
        </>
    );
};
