import React, {memo, FC, useState} from "react";
import {Button, Modal, message} from "antd";
import {blue} from "@ant-design/colors";
import {
    Booking,
    GetBookingByIdDocument,
    GetInvoiceBodyByIdDocument,
    useCreateCorrectionInvoiceMutation,
} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import Spin from "../../../views/Spin/Spin";

type CorrectionInvoiceProps = {bookingId: Booking["id"]; invoiceId?: string};

const CorrectionInvoice: FC<CorrectionInvoiceProps> = ({
    bookingId,
    invoiceId,
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const [
        create,
        {loading: creatingInvoice},
    ] = useCreateCorrectionInvoiceMutation({
        variables: {bookingId},
        refetchQueries: [
            "GetInvoiceBodyById",
            {query: GetInvoiceBodyByIdDocument, variables: {id: invoiceId}},
            "useGetBookingByIdQuery",
            {query: GetBookingByIdDocument, variables: {id: bookingId}},
        ],
    });

    const generateCorrectionInvoice = async () => {
        console.log("generateCorrectionInvoice");
        try {
            message.loading({
                key: "creatingCorrectionInvoice",
                loading: true,
                content: i18n.containers.invoice.CorrectionInvoice.messages.loading(),
            });
            create().then((res) => {
                console.log("res", res);
                const fileName =
                    res.data?.createCorrectionInvoice?.invoiceFileName ?? "";

                // message.success(`Korrekturrechnung ${fileName} wurde erstellt`);
                message.success(
                    i18n.containers.invoice.CorrectionInvoice.messages.success({
                        fileName,
                    }),
                );
                setModalOpen(false);
            });
        } catch (error) {
            message.error(
                i18n.containers.invoice.CorrectionInvoice.messages.error(),
            );
            console.log(error);
        }
    };

    const part1 = i18n.containers.invoice.CorrectionInvoice.description.part1();
    const part2 = i18n.containers.invoice.CorrectionInvoice.description.part2();

    const description = (
        <span>
            <p>{part1}</p>
            <p>{part2}</p>
        </span>
    );

    const renderModal = () => {
        return (
            <Modal
                title="Korrekturrechnung erstellen"
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                okText="Jetzt Erstellen"
                onOk={async () => generateCorrectionInvoice()}
                okButtonProps={{loading: creatingInvoice}}
            >
                {creatingInvoice ? <Spin>{description}</Spin> : description}
            </Modal>
        );
    };

    if (modalOpen) {
        return renderModal();
    }

    return (
        <>
            <Button
                style={{color: blue[5], borderColor: blue[3]}}
                size="small"
                onClick={() => setModalOpen(!modalOpen)}
                loading={creatingInvoice}
            >
                {"Korrekturrechnung erstellen"}
            </Button>
        </>
    );
};

export default memo(CorrectionInvoice);
