import moment from "moment";
import {SelectValue} from "antd/lib/select";
import {FormikProps, getIn} from "formik";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {RadioChangeEvent} from "antd/lib/radio";
import {Instructor} from "../generated/graphql";
import {AgeScale} from "../types";

export const filterInstructors = (
    allInstructors: Array<Partial<Instructor>>,
) => (values: Array<Instructor["id"]>) => {
    const filteredInstructors = allInstructors.reduce(
        (acc: Array<Partial<Instructor>>, curr: Partial<Instructor>) => {
            if (curr.id !== undefined && values.includes(curr.id)) {
                acc.push(curr);
            }

            return acc;
        },
        [],
    );

    return filteredInstructors;
};

export type InputHelperFormikDependencies<T extends object> = Pick<
    FormikProps<T>,
    "setFieldValue" | "setFieldTouched" | "errors" | "touched" | "values"
>;

export const createInputHelpers = <T extends object>(
    formikDependencies: InputHelperFormikDependencies<T>,
) => {
    const {
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        values,
    } = formikDependencies;

    // For each function is a version for nested form field with less strict typings.
    // This it is possible to pass in a `fieldName` like `venues[0][name]`.
    return {
        handleTextInputChange: (fieldName: keyof T & string) => (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setFieldValue(fieldName, event.target.value),
        handleNestedTextInputChange: (fieldName: string) => (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setFieldValue(fieldName, event.target.value),
        handleCheckboxChange: (fieldName: keyof T & string) => (
            event: CheckboxChangeEvent,
        ) => setFieldValue(fieldName, event.target.checked),
        handleNestedCheckboxChange: (fieldName: string) => (
            event: CheckboxChangeEvent,
        ) => setFieldValue(fieldName, event.target.checked),
        handleNumberInputChange: (fieldName: keyof T & string) => (
            value: number | undefined,
        ) => setFieldValue(fieldName, value),
        handleNestedNumberInputChange: (fieldName: string) => (
            value: number | undefined,
        ) => setFieldValue(fieldName, value),
        handleDatePickerChange: (fieldName: string) => (
            value: moment.Moment | null,
        ) => {
            setFieldValue(
                fieldName,
                value === null ? undefined : moment(value).format(),
            );
            // setFieldValue(
            //     fieldName,
            //     value === null ? undefined : moment.utc(value).format(),
            // );
        },
        handleSelectInputChange: <U extends SelectValue = SelectValue>(
            fieldName: keyof T & string,
        ) => (value: U) => setFieldValue(fieldName, value),
        handleRadioInputChange: (fieldName: keyof T & string) => (
            e: RadioChangeEvent,
        ) => setFieldValue(fieldName, e.target.value),
        handleNestedSelectInputChange: <U extends SelectValue = SelectValue>(
            fieldName: string,
        ) => (value: U) => setFieldValue(fieldName, value),
        handleSwitchInputChange: (fieldName: keyof T & string) => (
            checked: boolean,
            event: MouseEvent,
        ) => setFieldValue(fieldName, checked),
        handleAgeInputChange: (
            fieldName: keyof T & string,
            ageScale: AgeScale,
        ) => (
            value: number | undefined, // ant design typings are wrong. This can be a string.
        ) => {
            if (typeof value !== "number") {
                return setFieldValue(fieldName, value);
            }

            return setFieldValue(
                fieldName,
                ageScale === "months" ? value : value * 12,
            );
        },
        ageValue: (fieldName: keyof T & string, ageScale: AgeScale) => {
            const value = values[fieldName];

            if (value === undefined || value === null) {
                return undefined;
            }

            if (typeof value !== "number") {
                return (value as unknown) as number; // ant design typings are wrong. This can be a string.
            }

            return ageScale === "months" ? value : Math.floor(value / 12);
        },
        handleAgeScaleInputChange: (
            fieldName: keyof T & string,
            setAgeScale?: React.Dispatch<React.SetStateAction<AgeScale>>,
        ) => (newAgeScale: AgeScale) => {
            const ageInMonths = values[fieldName] ?? 0;

            if (ageInMonths === undefined || ageInMonths === null) {
                return ageInMonths;
            }

            if (typeof ageInMonths !== "number") {
                throw new Error(
                    `The type of "${fieldName}" is "${typeof ageInMonths}", but needs to be of type number.`,
                );
            }

            const normalizedAge =
                newAgeScale === "months"
                    ? ageInMonths
                    : ageInMonths - (ageInMonths % 12);

            if (setAgeScale !== undefined) {
                setAgeScale(newAgeScale);
            }
            setFieldValue(fieldName, normalizedAge);

            return undefined;
        },
        handleBlur: (fieldName: keyof T & string) => () =>
            setFieldTouched(fieldName, true),
        handleDatePickerBlur: (fieldName: keyof T & string) => (
            open: boolean,
        ) => !open && setFieldTouched(fieldName, true),
        handleNestedBlur: (fieldName: string) => () =>
            setFieldTouched(fieldName, true),
        help: (fieldName: (keyof T & string) | Array<keyof T & string>) => {
            const fields = Array.isArray(fieldName) ? fieldName : [fieldName];

            for (const field of fields) {
                if (
                    touched[field] === undefined ||
                    errors[field] === undefined
                ) {
                    continue;
                }

                return errors[field];
            }

            return undefined;
        },
        nestedHelp: (fieldName: string | Array<string>) => {
            const fieldNames = Array.isArray(fieldName)
                ? fieldName
                : [fieldName];

            for (const _fieldName of fieldNames) {
                return getIn(touched, _fieldName) === undefined
                    ? undefined
                    : getIn(errors, _fieldName);
            }

            return undefined;
        },
        validateStatus: (
            fieldName: (keyof T & string) | Array<keyof T & string>,
        ) => {
            const fields = Array.isArray(fieldName) ? fieldName : [fieldName];

            for (const field of fields) {
                if (
                    touched[field] === undefined ||
                    errors[field] === undefined
                ) {
                    continue;
                }

                return "error" as const;
            }

            return undefined;
        },
        nestedValidateStatus: (fieldName: string | Array<string>) => {
            const fieldNames = Array.isArray(fieldName)
                ? fieldName
                : [fieldName];

            for (const _fieldName of fieldNames) {
                if (
                    getIn(touched, _fieldName) !== undefined &&
                    getIn(errors, _fieldName) !== undefined
                ) {
                    return "error" as const;
                }
            }

            return undefined;
        },
        resetField: (fieldName: keyof T & string) => {
            setFieldValue(fieldName, "");
            setFieldTouched(fieldName, false);
        },
        resetNestedField: (fieldName: string) => {
            setFieldValue(fieldName, "");
            setFieldTouched(fieldName, false);
        },
    };
};

export const unNullValue = (value: string | undefined | null) => {
    if (value === null) {
        return undefined;
    }

    return value;
};

export const unNullBoolean = (value: boolean | undefined | null) => {
    if (value === null) {
        return undefined;
    }

    return value;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const ensureArray = (value: Array<any> | undefined | null) => {
    if (value === null || value === undefined) {
        return [];
    }

    return value;
};

export const getEnsureObject = <T>() => {
    const esnureObject = (value: T | undefined | null) => {
        if (value === null || value === undefined) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return {} as any;
        }

        return value;
    };

    return esnureObject;
};
