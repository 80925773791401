import moment from "moment";
import {panelWidth} from "../../../styles/layout";
import {XmlResponse} from "./CreateXmlModal";
import {CreateXmlModalPreviewProps} from "./CreateXmlModalPreview";

export const convertXmlData = ({
    xmlData,
}: {
    xmlData: XmlResponse;
}): CreateXmlModalPreviewProps => {
    // const transactionCount = xmlData.object.positions[0].payments.length;

    // Replace lower case letters in IBAN with upper case letters
    const sanatizedPayments = xmlData.object.positions[0].payments.map(
        (payment) => {
            payment.debtorIBAN = payment.debtorIBAN.replace(/[a-z]/g, (match) =>
                match.toUpperCase(),
            );

            return payment;
        },
    );

    const transactionCountWithoutZeroAmounts = sanatizedPayments.filter(
        (payment) => payment.amount > 0,
    ).length;

    if (sanatizedPayments.length === 0) {
        return {
            documentInfo: [],
            creditorInfo: [],
            transactionInfo: [],
            cardWidth: panelWidth - 45,
            panelWidth,
        };
    }

    // Kontrollsumme ----------------------------------------------------------------------------------
    const amounts = sanatizedPayments.map((payment) => Number(payment.amount));
    const controllSum = amounts.reduce((acc, cur) => acc + cur);
    const currency = sanatizedPayments[0].currency;

    // documentInfo ----------------------------------------------------------------------------------

    const documentInfo = [
        {label: "Initiator", value: xmlData.object.initiatorName},
        {
            label: "Erstellungsdatum",
            value: moment(xmlData.object.creationDate).format(
                "DD.MM.YYYY HH:mm",
            ),
        },
        {label: "Transaktionen", value: transactionCountWithoutZeroAmounts},
        {
            label: "Kontrollsumme",
            value: `${controllSum.toFixed(2)} ${currency}`,
        },
        // {label: "Art der Lastschriften", value: "tbd"},
    ];

    // creditor ----------------------------------------------------------------------------------

    const creditor = xmlData.object.positions[0];

    const creditorInfo = [
        {label: "Kreditor", value: creditor.creditorName},
        {label: "Kreditor BIC", value: creditor.creditorBIC},
        {label: "Kreditor IBAN", value: creditor.creditorIBAN},
        {label: "GläubigerId", value: creditor.creditorId},
        {
            label: "Ausführungsdatum",
            value: moment(creditor.collectionDate).format("DD.MM.YYYY"),
        },
    ];

    // transactions ----------------------------------------------------------------------------------

    const transactions = sanatizedPayments;

    const transactionInfo = transactions.map((transaction) => {
        return [
            {label: "Debitor", value: transaction.debtorName},
            {label: "Debitor BIC", value: transaction.debtorBIC},
            {label: "Debitor IBAN", value: transaction.debtorIBAN},
            {
                label: "Betrag",
                value: `${Number(transaction.amount).toFixed(2)} ${
                    transaction.currency
                }`,
            },
            {label: "Mandatsreferenz", value: transaction.mandateId},
            {
                label: "Unterschriftsdatum",
                value: moment(transaction.mandateSignatureDate).format(
                    "DD.MM.YYYY",
                ),
            },
            {label: "Verwendungszweck", value: transaction.remittanceInfo},
        ];
    });

    const convertedData: CreateXmlModalPreviewProps = {
        documentInfo,
        creditorInfo,
        transactionInfo,
        cardWidth: panelWidth - 45,
        panelWidth,
    };

    return convertedData;
};
