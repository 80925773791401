import {FormikProps} from "formik";
import React, {FC, memo, useState} from "react";
import {Tabs} from "antd";
import {
    AddNamedScheduledPaymentInput,
    Booking,
    MonthlySchedule,
    ScheduledPaymentType,
} from "../../generated/graphql";
import AddNamedScheduledPaymentForm, {
    options,
} from "./AddNamedScheduledPaymentForm";
import AddNamedScheduledPaymentDescription from "./AddNamedScheduledPaymentDescription";

type AddScheduledPaymetTabsProps = {
    formikProps: FormikProps<AddNamedScheduledPaymentInput>;
    setEditPaymentOpen: (value: boolean) => void;
    bookingId: Booking["id"];
    monthlySchedule?: MonthlySchedule;
};

export type TabKeys = "standard" | "preset" | "custom";

const columnCount = 6;
const customPadding = 15;

const AddScheduledPaymetTabs: FC<AddScheduledPaymetTabsProps> = ({
    formikProps,
    setEditPaymentOpen,
    bookingId,
    monthlySchedule,
}) => {
    const [activeKey, setActiveKey] = useState<TabKeys>("standard");

    const handleTabChange = (activeKey: TabKeys) => {
        setActiveKey(activeKey);
        if (activeKey === "custom") {
            formikProps.setFieldValue("title", "");
            formikProps.setFieldValue("type", ScheduledPaymentType.Custom);
        } else if (activeKey === "standard") {
            formikProps.setFieldValue("type", ScheduledPaymentType.Standard);
        } else {
            formikProps.setFieldValue(
                "type",
                ScheduledPaymentType.DunningLevel1,
            );
            formikProps.setFieldValue(
                "title",
                options.find(
                    (option) =>
                        option.type === ScheduledPaymentType.DunningLevel1,
                )?.label,
            );
        }
    };

    const tabs: Array<{
        key: TabKeys;
        label: string;
        content: React.ReactNode;
    }> = [
        {
            key: "standard",
            label: "Standard",
            content: <div>{"Standard"}</div>,
        },
        {
            key: "preset",
            label: "Spezielle Bezeichnungen",
            content: <div>{"Spezielle Bezeichnungen"}</div>,
        },
        {
            key: "custom",
            label: "Eigene Bezeichnung",
            content: <div>{"Eigene Bezeichnung"}</div>,
        },
    ];

    // REMOVE THE OPTION TO CREATE STANDARD PAYMENTS FOR CONSISTENT MONTHLY SCHEDULE
    if (monthlySchedule === MonthlySchedule.Consistent) {
        tabs.shift();
        if (activeKey === "standard") {
            setActiveKey("preset");
            formikProps.setFieldValue(
                "type",
                ScheduledPaymentType.DunningLevel1,
            );
            formikProps.setFieldValue(
                "title",
                options.find(
                    (option) =>
                        option.type === ScheduledPaymentType.DunningLevel1,
                )?.label,
            );
        }
    }

    const selectedOption = options.find(
        (option) => option.type === formikProps.values.type,
    );

    return (
        <Tabs
            defaultActiveKey="standard"
            type="card"
            activeKey={activeKey}
            onChange={(activeKey) => handleTabChange(activeKey as TabKeys)}
        >
            {tabs.map((tab) => (
                <Tabs.TabPane
                    tab={tab.label}
                    key={tab.key}
                    style={{padding: customPadding}}
                >
                    <AddNamedScheduledPaymentForm
                        tabKey={tab.key}
                        formikProps={formikProps}
                        setEditPaymentOpen={setEditPaymentOpen}
                        bookingId={bookingId}
                        activeKey={activeKey}
                    />
                    <AddNamedScheduledPaymentDescription
                        option={selectedOption}
                        style={{
                            gridColumn: `span ${columnCount}`,
                            padding: "30px 0 0 0",
                        }}
                    />
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
};

export default memo(AddScheduledPaymetTabs);
