import React, {FC, memo, useState} from "react";
import {Button, Modal} from "antd";
import {SubscriptionType} from "../../generated/graphql";
import i18n from "../../services/i18n";
import css from "./SubscriptionBadge.less";

type SubscriptionBadgeProps = {
    subscriptionType?: SubscriptionType;
    color?: React.CSSProperties["color"];
    style?: React.CSSProperties;
    menuClosed?: boolean;
};

const SubscriptionBadge: FC<SubscriptionBadgeProps> = ({
    subscriptionType = SubscriptionType.StandardSubscription,
    color,
    style,
    menuClosed,
}) => {
    const [modalVisible, setModalVisible] = useState(false);

    const toggleVisibility = () => {
        setModalVisible(!modalVisible);
    };

    type ContactCardCSSProperties = React.CSSProperties & {
        "--badgeColor": React.CSSProperties["color"];
    };

    const actualStyle: ContactCardCSSProperties = {
        "--badgeColor": color,
        cursor: "pointer",
        ...style,
    };

    const VersionInfoModal = () => {
        return (
            <Modal
                // visible={modalVisible}
                open={modalVisible}
                title={i18n.views.VersionInfoModal.title()}
                width={800}
                style={{whiteSpace: "pre-line"}}
                footer={
                    <Button type="primary" onClick={toggleVisibility}>
                        {"OK"}
                    </Button>
                }
                onOk={toggleVisibility}
                onCancel={toggleVisibility}
            >
                <p>
                    {i18n.views.VersionInfoModal.content[
                        subscriptionType
                    ].paragraph1()}
                </p>
                <p>
                    {i18n.views.VersionInfoModal.content[
                        subscriptionType
                    ].paragraph2()}
                </p>
                <p>
                    {i18n.views.VersionInfoModal.content[
                        subscriptionType
                    ].paragraph3()}
                </p>
            </Modal>
        );
    };

    return (
        <>
            <VersionInfoModal />
            <div
                className={css.badge}
                color={color}
                style={actualStyle}
                onClickCapture={toggleVisibility}
            >
                <div>
                    {menuClosed
                        ? i18n.containers.koOrganization.koOrganization.short[
                              subscriptionType
                          ]()
                        : i18n.containers.koOrganization.koOrganization[
                              subscriptionType
                          ]()}
                </div>
            </div>
        </>
    );
};

export default memo(SubscriptionBadge);
