import React, {FC, memo} from "react";
import {Alert, Form, Select, Space, Tag, Tooltip} from "antd";
import {red} from "@ant-design/colors";
import {WarningOutlined} from "@ant-design/icons";
import {InputStatus} from "antd/lib/_util/statusUtils";
import {SelectionType} from "../EmailTemplatePicker";
import {NotifySelection} from "../SendMailHandlers/CourseBookingTypeSelector";
import {
    ReceiverByNotifySelection,
    useReceiversByBookingsOfCourse,
    useTestSubscription,
} from "../emailHelpers";
import css from "../Email.less";
import LoadingContent from "../../../../views/LoadingContent/LoadingContent";
import ReceiversWithNoMailModal from "./ReceiversWithNoMailModal";

type GetReceiversCourseProps = {
    selection?: Array<SelectionType>;
    notifySelection?: Array<NotifySelection>;
    setDisabledSendButton?: (disabled: boolean) => void;
    setReceiverCount?: (count: number) => void;
};

const GetReceiversCourse: FC<GetReceiversCourseProps> = ({
    selection,
    notifySelection,
    setDisabledSendButton,
    setReceiverCount,
}) => {
    const isTestSubscription = useTestSubscription().isTestSubscription;
    const courseIds = selection?.map((selection) => {
        return selection.id;
    });
    const useReceivers = useReceiversByBookingsOfCourse({
        courseId: courseIds?.[0] ?? "",
        notifySelection: notifySelection ?? [],
    });
    const receivers = useReceivers.receivers;
    const receiverWithMailCount = receivers.filter((receiver) => receiver.email)
        .length;

    setReceiverCount?.(receiverWithMailCount);

    const Warning = ({missingEmail}: {missingEmail: boolean}) => {
        if (!missingEmail) return null;

        return <WarningOutlined style={{color: "white", marginRight: 7}} />;
    };

    const getValue = () => {
        if (!receivers.length) return undefined;

        return receivers.map((receiver) => {
            return {
                value: receiver.email,
                label: `${receiver.firstname} ${receiver.lastname} | ${receiver.bookingNumberPrefix}-${receiver.bookingNumber}`,
                fieldNames: {"data-booking-id": receiver.id},
                key: `${receiver.id}-${receiver.bookingNumber}`,
            };
        });
    };

    const inputStatus: InputStatus = receivers.length
        ? ("success" as InputStatus)
        : ("error" as InputStatus);

    const maxReceivers = 25;

    React.useEffect(() => {
        if (setDisabledSendButton) {
            if (!receivers.length) {
                setDisabledSendButton(!receivers.length);
            } else if (receivers.length > maxReceivers) {
                setDisabledSendButton(true);
            } else {
                setDisabledSendButton(false);
            }
        }
    }, [receivers.length, setDisabledSendButton]);

    const alertStyle: React.CSSProperties = {
        border: `2px solid ${red[2]}`,
        textAlign: "center",
        marginLeft: -2,
        marginRight: -2,
    };

    const isLoading = useReceivers.loading;

    if (isLoading) {
        return <LoadingContent content="Empfängerdaten laden" />;
    }

    if (!receivers.length) {
        return (
            <Alert
                style={alertStyle}
                message="Keine Empfänger gefunden"
                description="Es kann keine Email versendet werden, wenn keine Empfänger ausgewählt sind."
                type="error"
            />
        );
    }

    const MaxReceiversWarning = () => {
        return (
            <Alert
                style={alertStyle}
                message="Zu viele Empfänger"
                description={`Es können derzeit maximal ${maxReceivers} Empfänger ausgewählt werden.`}
                type="error"
            />
        );
    };

    const DemoWarning = () => {
        return (
            <Alert
                style={{
                    ...alertStyle,
                    fontSize: "0.9em",
                    padding: 3,
                    lineHeight: "1.1em",
                }}
                message="Demoversion!"
                description="Mails werden nicht an den Empfänger gesendet, sondern an die E-Mail-Adresse, die in den Einstellungen hinterlegt ist."
                type="warning"
            />
        );
    };

    const RenderContent = ({
        receivers,
    }: {
        receivers: Array<ReceiverByNotifySelection>;
    }) => {
        return (
            <Form.Item
                label={
                    receivers.length < 8 &&
                    receivers.length < 25 &&
                    !isTestSubscription
                        ? "An"
                        : undefined
                }
                className={css.receiversBar}
            >
                {receivers.length > 25 && <MaxReceiversWarning />}
                {isTestSubscription && <DemoWarning />}
                <Select
                    // style={{width: `calc(100% - ${marginXs})`}}
                    style={{width: `100%`}}
                    open={false}
                    mode="tags"
                    labelInValue={true}
                    value={getValue()}
                    status={inputStatus}
                    defaultValue={getValue()}
                    maxTagCount={50}
                    // maxTagTextLength={2}
                    optionFilterProp="label"
                    loading={useReceivers.loading}
                    tagRender={(props) => {
                        const missingEmail = !props.value || props.value === "";
                        const firstLabelPart = (props.label as string).split(
                            "|",
                        )[0];
                        const secondLabelPart = (props.label as string).split(
                            "|",
                        )[1];
                        const color = (num: number) =>
                            missingEmail ? red[num] : undefined;
                        const maxLength = receivers.length > 4 ? 10 : 30;
                        const style = {
                            marginRight: 2,
                            color: missingEmail ? "white" : undefined,
                            borderColor: color(2),
                            fontWeight: missingEmail ? 500 : undefined,
                            backgroundColor: color(2),
                        };

                        const tooltipTitle = missingEmail ? (
                            <Space size={2} direction="vertical">
                                {`Für Bucher:in ${firstLabelPart} mit der Buchungsnummer ${secondLabelPart} ist keine Email-Adresse hinterlegt. Bucher:in wird deshalb nicht benachrichtigt.`}
                            </Space>
                        ) : (
                            <Space size={2} direction="vertical">
                                {secondLabelPart}
                                {firstLabelPart}
                                {props.value}
                            </Space>
                        );

                        return (props.label as string).length > maxLength ? (
                            <Tooltip
                                title={tooltipTitle}
                                overlayStyle={{
                                    minWidth: 350,
                                }}
                                placement="bottom"
                                mouseEnterDelay={0.3}
                            >
                                <Tag style={style}>
                                    <Warning missingEmail={missingEmail} />
                                    {firstLabelPart.slice(0, maxLength)}
                                    {"..."}
                                </Tag>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={tooltipTitle}
                                overlayStyle={{
                                    minWidth: 350,
                                }}
                            >
                                <Tag style={style}>
                                    <Warning missingEmail={missingEmail} />
                                    {firstLabelPart}
                                </Tag>
                            </Tooltip>
                        );
                    }}
                >
                    {receivers.map((receiver) => {
                        const receiverName = `${receiver.firstname} ${receiver.lastname}`;
                        const receiverEmail = receiver.email;
                        const key = `${receiver.id}-${receiver.bookingNumber}`;

                        return (
                            <Select.Option
                                key={key}
                                value={receiverEmail}
                                disabled
                            >
                                <Tooltip title={receiverEmail}>
                                    {receiverName}
                                </Tooltip>
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const receiversWithNoEmail = receivers.filter(
        (receiver) => !receiver.email,
    );

    return (
        <>
            {<ReceiversWithNoMailModal receivers={receiversWithNoEmail} />}
            <RenderContent receivers={receivers} />
        </>
    );
};

export default memo(GetReceiversCourse);
