import React, {useState} from "react";
import {ColumnProps, ColumnsType} from "antd/lib/table";
import {
    ArrayDataOptions,
    GetTemplatesWithPaginationQuery,
    MailDataType,
    Maybe,
} from "../../../generated/graphql";
import {
    moveActionColumnToLastPosition,
    moveLeftAligndColumnToFirstPosition,
    moveRowIndexToFirstPosition,
} from "../../courseLessonBookings/AttendanceListInteractive/AttendanceListColumnsHelpers";
import i18n from "../../../services/i18n";
import columnSortFilterTest, {
    InputType,
} from "../../../helpers/columnSortFilterTest";
import {
    renderDate,
    renderMailDataType,
    renderTimeFromNow,
} from "../../../views/TableColumnRenderers/TableColumnRenderers";
import {badgeRender} from "./MailDataTypeBadge";

type ExtraColumns = {};

export type TableType = {
    dataIndex?: string;
} & GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"][number] &
    ExtraColumns;

export const standardColumns: Array<Extract<keyof TableType, string>> = [
    "name",
    "subject",
    "mailDataType",
    "createDate",
    "updateDate",
];

export const allColumns = (): {[key: string]: string} => {
    const columnNames: {[key: string]: string} = {
        name: i18n.views.TableColumnTitles.name(),
        subject: i18n.views.TableColumnTitles.subject(),
        mailDataType: i18n.views.TableColumnTitles.mailDataType(),
        createDate: i18n.views.TableColumnTitles.createDate(),
        updateDate: i18n.views.TableColumnTitles.updateDate(),
    };

    return columnNames;
};

export const useHandleColumns = (
    columnsToShow: Array<Extract<keyof TableType, string>>,
    options: Maybe<ArrayDataOptions> | undefined,
    setOptions: (options: ArrayDataOptions) => void,
) => {
    const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
    const shownColumns: Array<ColumnProps<TableType>> = [];
    const getColumnsToShow = (
        columns: Array<{dataIndex: string} & TableType>,
    ) => {
        columns.forEach((col) => {
            if (columnsToShow.some((value) => value === col.dataIndex)) {
                shownColumns.push(col);
            }
        });

        moveLeftAligndColumnToFirstPosition(shownColumns);
        moveActionColumnToLastPosition(shownColumns);
        moveRowIndexToFirstPosition(shownColumns);
    };
    const columns: Array<{dataIndex: string} & TableType> = [];
    const stdColumn: ColumnProps<TableType> = {
        title: "",
        fixed: false,
    };

    columnsToShow.forEach((col: Extract<keyof TableType, string>) => {
        const c = {...stdColumn};

        const colTitle = i18n.views.TableColumnTitles[col]();

        const rawTitle = colTitle;
        const title =
            rawTitle.length <= 12 ? (
                rawTitle
            ) : (
                <span style={{whiteSpace: "pre-line"}}>
                    {rawTitle.replace(" ", "\n")}
                </span>
            );

        const standardEllipsis = false;

        c.ellipsis = standardEllipsis;
        c.title = title;
        c.dataIndex = col;
        c.width = 100 + colTitle.length * 2;

        const path = c.dataIndex;

        const sortFilter = ({
            dataIndex,
            disabled,
            type,
            selectOptions,
        }: {
            dataIndex: string;
            disabled?: boolean;
            type: InputType;
            selectOptions?: Array<{
                label: string;
                value: string;
            }>;
            activeFilters?: ArrayDataOptions["filter"] | undefined;
        }) =>
            // eslint-disable-next-line max-params
            {
                // if (hideFiltersAndSorters) {
                //     return null;
                // }

                Object.entries(
                    columnSortFilterTest({
                        dataIndex,
                        type,
                        selectOptions,
                        options,
                        setOptions,
                        dropdownOpen,
                        setDropdownOpen,
                    }),
                ).map(([key, value]) => {
                    if (key !== "filteredValue") {
                        // @ts-ignore
                        return (c[key] = disabled ? null : value);
                    }

                    return null;
                });
            };

        switch (col) {
            case "name":
                c.width = 150;
                c.render = (_, record: TableType) => (
                    <div style={{fontWeight: 600, fontSize: "1.1em"}}>
                        {record.name}
                    </div>
                );
                sortFilter({
                    dataIndex: path,
                    type: "search",
                });
                break;
            case "subject":
                // c.width = 300;
                sortFilter({
                    dataIndex: path,
                    type: "search",
                });
                break;
            case "mailDataType":
                c.width = 80;
                c.render = (_, record: TableType) =>
                    renderMailDataType({mailDataType: record.mailDataType});

                sortFilter({
                    dataIndex: path,
                    type: "select",
                    selectOptions: [
                        {
                            label: badgeRender(MailDataType.Booker).text,
                            value: MailDataType.Booker,
                        },
                        {
                            label: badgeRender(MailDataType.Attendee).text,
                            value: MailDataType.Attendee,
                        },
                        {
                            label: badgeRender(MailDataType.Booking).text,
                            value: MailDataType.Booking,
                        },
                        {
                            label: badgeRender(MailDataType.Course).text,
                            value: MailDataType.Course,
                        },
                    ],
                });
                break;
            case "createDate":
                c.width = 80;
                c.render = (_, record: TableType) =>
                    // renderDate(record.createDate, true);
                    renderTimeFromNow({date: record.createDate, short: true});
                sortFilter({
                    dataIndex: path,
                    type: "date",
                });
                c.filterIcon = () => null;
                break;
            case "updateDate":
                c.width = 80;
                c.render = (_, record: TableType) =>
                    // renderDate(record.updateDate, true);
                    renderTimeFromNow({date: record.updateDate, short: false});
                sortFilter({
                    dataIndex: path,
                    type: "date",
                });
                c.filterIcon = () => null;
                break;
            default:
                break;
        }
        columns.push(c);
    });

    // getColumnsToShow(columnsWithDataIndex);
    getColumnsToShow(columns);

    return shownColumns;
};
