import {layoutScale} from "./scales";

export const layoutPaddingSmall = layoutScale(2);
export const layoutPaddingRegular = layoutScale(4);
export const layoutPaddingBig = layoutScale(8);

// Ant overrides
// See defaults here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
export const layoutHeaderHeight = layoutScale(12.5);
export const contextButtonSpacing = layoutScale(7.5);

export const cardPaddingBase = layoutPaddingSmall;
export const cardHeadPadding = layoutPaddingSmall;

export const btnHeightSm = "25px";
export const btnHeightBase = "27px";
export const formItemMarginBottom = "17px";

export const panelWidth = 1400;
export const menuWidth = 220;

export const marginXs = "7.5px";
export const marginS = "15px";
export const margin = "30px";
export const marginL = "60px";

export const schadow1 = "0 0 55px rgba(0, 0, 0, 0.1)";
export const schadow2 = "0 0 55px rgba(0, 0, 0, 0.2)";
export const schadow3 = "0 0 55px rgba(0, 0, 0, 0.3)";

// Radio
export const radioSize = "17px";
// export const radioDotSize = "7px";
// export const radioDotColor = "hotpink";

// Radius
export const roundnesfactor = 1;

export const radiusXs = roundnesfactor * 3.75;
export const radiusS = roundnesfactor * 7.5;
export const radius = roundnesfactor * 15;
export const radiusL = roundnesfactor * 30;

// @roundnesfactor: 1;

// @radius-xs: ~"calc(@{roundnesfactor} * 3.75px)";
// @radius-s: ~"calc(@{roundnesfactor} * 7.5px)";
// @radius: ~"calc(@{roundnesfactor} * 15px)";
// @radius-l: ~"calc(@{roundnesfactor} * 30px)";
