enum Roles {
    Superuser = "Superuser",
    BetaTester = "BetaTester",
    Admin = "Admin",
    SuperAdmin = "SuperAdmin",
    Instructor = "Instructor",
    Staff = "Staff",
    EndUser = "EndUser",
}

export default Roles;
